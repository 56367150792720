import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { addVisaApplication_action, getVisaApplicationDetails_action, updateVisaApplication_action, deleteVisaApplicationDoc_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/invitationVisaApplication_action/visaApplication_action'
import { fill_action } from '../../../../../actions/fillCombo_action'
import { BASE_URL, countryActionId, toastError, toastSuccess, visaIdTypeActionId, ListOfExhibitorBadgeActionId } from "../../../../../utils/constants";
import { emailValidator, mobileNoValidator } from "../../../../../utils/Validator";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import Resizer from "react-image-file-resizer";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import Passport_Front_1008_VIS_903747 from "../../../../../files/Passport_Front_1008_VIS_903747.jpeg"

class InvitationVisaApplicationAU extends Component {

    // initialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            is_add: false,
            is_update: false,
            isView: false,
            isDelOpen: false,
            passportFilePath: "",

            // fields
            firstName: '',
            lastName: '',
            middleName: '',
            email: '',
            countryId: '',
            mobile: '',
            idTypeId: '',
            passportNo: '',
            passportIssueDate: "",
            passportExpiryDate: "",
            passportFrontImage: '',
            passportImage: '',
            passportFrontImageType: '',
            VisaApplicationFormID: '',
            // List
            visaApplicationList: [],
            countryList: [],
            idTypeList: [],
            passportFrontImageList: [],
            passportFrontImageTypeList: [],

            // err messages
            firstNameErr: '',
            lastNameErr: '',
            emailErr: '',
            countryIdErr: '',
            mobileErr: '',
            idTypeIdErr: '',
            passportNoErr: '',
            passportIssueDateErr: "",
            passportExpiryDateErr: "",
            passportFrontImageErr: '',
            url: '',
            Deadline: '',
            ListOfExhibitorBadgeList: [],
            ListOfExhibitorBadgeId: [],
            isPassportView: false
        }
        this.OnCancel = this.OnCancel.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.renderVisaApplicationList = this.renderVisaApplicationList.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.handleAddMore = this.handleAddMore.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.ClearValue = this.ClearValue.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.getDetails = this.getDetails.bind(this)
        this.emailValidation = this.emailValidation.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.mobileValidation = this.mobileValidation.bind(this)
        this.onDocDelete = this.onDocDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount() {
        this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.getFillList(visaIdTypeActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.getFillList(ListOfExhibitorBadgeActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
        this.setState({
            is_update: this.props.location.state != "" && this.props.location.state.is_update != null && this.props.location.state.is_update != false ? this.props.location.state.is_update : false,
            isView: this.props.location.state != "" && this.props.location.state.is_View != null && this.props.location.state.is_View != false ? this.props.location.state.is_View : false,
            is_add: this.props.location.state != "" && this.props.location.state.is_add != null && this.props.location.state.is_add != false ? this.props.location.state.is_add : false,
            VisaApplicationFormID: this.props.location.state != "" && this.props.location.state.VisaApplicationFormID != null && this.props.location.state.VisaApplicationFormID != '' ? this.props.location.state.VisaApplicationFormID : '',
            Deadline: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? new Date(this.props.location.state.Deadline).toString() : '',

        })
        setTimeout(() => {
            console.log(this.state.VisaApplicationFormID)
            console.log('state value : ', this.props.location.state)
            this.props.location.state != "" && (this.props.location.state.is_View != false || this.props.location.state.is_update != false) && this.getDetails()
            // this.props.location.state != "" && this.props.location.state.is_View != null && this.props.location.state.is_View != false && this.getDetails()
        }, 100);

        const url = BASE_URL.split('/api')
        this.setState({ url: url[0] })
    }

    // get Fill List
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props.fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data,
                            isLoading: false
                        })
                    }
                    // visa  id type
                    if (actionID === visaIdTypeActionId) {
                        console.log("visaIdTypeActionId data", data.data)
                        this.setState({
                            idTypeList: data.data,
                            isLoading: false
                        })
                    }
                    if (actionID === ListOfExhibitorBadgeActionId) {
                        this.setState({
                            ListOfExhibitorBadgeList: data.data,
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On cancel Click
    OnCancel() {
        // this.props.navigate('/visaApplication')
        this.props.navigate(-1)
    }

    // Input Fields Validation
    inputValidate() {
        const { firstName, lastName, email, countryId, mobile, idTypeId, passportNo, passportFrontImage, passportExpiryDate, passportIssueDate } = this.state
        if (firstName.trim() == "") {
            this.setState({ firstNameErr: "Please Enter first Name" })
        }
        if (lastName.trim() == "") {
            this.setState({ lastNameErr: "Please Enter last Name" })
        }
        if (email.trim() == "") {
            this.setState({ emailErr: "Please Enter email" })
        }
        // if (email != "") {
        //     var emailError = emailValidator(email)
        //     if (emailError != '') {
        //         this.setState({ emailErr: emailError })
        //     }
        // }
        if (countryId == "") {
            this.setState({ countryIdErr: "Please select country" })
        }
        if (mobile.trim() == "") {
            this.setState({ mobileErr: "Please Enter mobile" })
        }
        // if (mobile != "") {
        //     var mobileError = mobileNoValidator(mobile)
        //     if (mobileError != '') {
        //         this.setState({ mobileErr: mobileError })
        //     }
        // }
        if (idTypeId == "") {
            this.setState({ idTypeIdErr: "Please select id type" })
        }
        if (passportNo.trim() == "") {
            this.setState({ passportNoErr: "Please Enter passport No" })
        }
        if (passportFrontImage == "") {
            this.setState({ passportFrontImageErr: "Please upload passport Front Image" })
        }
        if (passportIssueDate == "") {
            this.setState({ passportIssueDateErr: "Please Select Passport Issue Date" })
        }
        if (passportExpiryDate == "") {
            this.setState({ passportExpiryDateErr: "Please Select Passport Expiry Date" })
        }
    }

    // render Visa Application
    renderVisaApplicationList() {
        const { visaApplicationList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    visaApplicationList.length > 0 && visaApplicationList.map((data, index) => (
                        <li class="list-group-item" key={Math.random()}>
                            <div className="added-more p-3 rounded-2">
                                <h5 style={{ color: "#0056ff" }}>Added Visa Application {++index}</h5>
                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <h6>First Name: {data.firstName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Middel Name: {data.middleName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Last Name: {data.lastName}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>Email: {data.email}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Country: {data.countryName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Mobile: {data.mobile}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>ID Type:  {data.idTypeName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Passport No: {data.passportNo}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Passport Issue Date: {(data.passportIssueDate).toDateString()}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Passport Expiry Date: {(data.passportExpiryDate).toDateString()}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        {/* <h6>Passport Front Page</h6> */}
                                        <img
                                            src={data.passportFrontImage}
                                            style={{ width: '200px', height: '80px', display: 'block' }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr className="m-1" />

                        </li>
                    ))
                }
            </ul>
        )
    }

    emailValidation(e) {
        var emailError = emailValidator(e.target.value)
        this.setState({ email: e.target.value })
        if (emailError != '') {
            this.setState({ emailErr: emailError })
        }
        else {
            this.setState({ emailErr: '' })
        }
    }

    mobileValidation(e) {
        // var mobileError = mobileNoValidator(e.target.value)
        this.setState({ mobile: e.target.value })
        // if (mobileError != '') {
        //     this.setState({ mobileErr: mobileError })
        // }
        // else {
        //     this.setState({ mobileErr: '' })
        // }
    }

    onFilePick = async (e, file) => {
        e.preventDefault()
        const { } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        this.setState({ passportFrontImage: image, passportFrontImageErr: '' })
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelect(file[0]);
                        this.setState({ passportFrontImage: image, passportFrontImageErr: '' })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ passportFrontImageType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }

    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    handleAddMore() {
        const { visaApplicationList, passportFrontImageList, passportFrontImageTypeList, firstName, lastName, middleName, email, countryId,
            mobile, idTypeId, passportNo, passportFrontImage, passportFrontImageType, countryList, idTypeList,
            firstNameErr, lastNameErr, emailErr, countryIdErr, mobileErr, idTypeIdErr, passportNoErr, passportFrontImageErr, passportExpiryDate,
            passportExpiryDateErr, passportIssueDate, passportIssueDateErr, ListOfExhibitorBadgeId
        } = this.state
        this.inputValidate()
        if (firstName != '' && countryId != '' &&
            lastName != '' && mobile != '' &&
            email != '' &&
            passportNo != '' && passportFrontImage != '' && passportFrontImageType != '' && passportIssueDate != "" && passportExpiryDate != "") {
            if (firstNameErr == '' && lastNameErr == '' && emailErr == '' && countryIdErr == '' && mobileErr == '' &&
                passportNoErr == '' && passportFrontImageErr == '' && passportExpiryDateErr == '' && passportIssueDateErr == '') {
                console.log('fields', idTypeId)
                var countryName = ''
                var idTypeName = ''

                countryList.length > 0 && countryList.map(data => {
                    if (data.Code == countryId) {
                        countryName = data.Description
                    }
                })
                idTypeList.length > 0 && idTypeList.map(data => {
                    if (data.FormDocumentTypeID == idTypeId) {
                        idTypeName = data.Name
                    }
                })
                visaApplicationList.push({
                    'SeqNo': visaApplicationList.length + 1,
                    'firstName': firstName,
                    'middleName': middleName,
                    'lastName': lastName,
                    'email': email,
                    'countryId': countryId,
                    'countryName': countryName,
                    'mobile': mobile,
                    'idTypeId': idTypeId,
                    'idTypeName': idTypeName,
                    'passportNo': passportNo,
                    'passportFrontImage': passportFrontImage,
                    'passportIssueDate': passportIssueDate,
                    'passportExpiryDate': passportExpiryDate,
                    'ListOfExhibitorBadgeId': ListOfExhibitorBadgeId,
                })
                passportFrontImageList.push(passportFrontImage)
                passportFrontImageTypeList.push(passportFrontImageType)
                console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", visaApplicationList)
                this.setState({ visaApplicationList: visaApplicationList })
                setTimeout(() => { this.ClearValue() }, 200);
            }
            else {
                toastError('Please enter proper fields')
            }
        }
        else {
            toastError('Please enter mandatory fields')
            console.log('Please enter mandatory fields')
        }
        console.log(this.state.visaApplicationList)
    }

    handleSave() {
        const { visaApplicationList, passportFrontImageList, passportFrontImageTypeList, Deadline } = this.state
        this.setState({ isLoading: true })
        this.props.addVisaApplication_action(visaApplicationList, passportFrontImageList, passportFrontImageTypeList)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Record Added Successfully")
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.props.navigate('/visaApplication', { state: { Deadline: Deadline, } })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleUpdate() {

        const { VisaApplicationFormID, visaApplicationList, passportFrontImageList, passportFrontImageTypeList, firstName, lastName, middleName, email, countryId, passportImage, Deadline,
            mobile, idTypeId, passportNo, passportFrontImage, passportFrontImageType, countryList, idTypeList,
            firstNameErr, lastNameErr, emailErr, countryIdErr, mobileErr, idTypeIdErr, passportNoErr, passportFrontImageErr,
            passportExpiryDate, passportExpiryDateErr, passportIssueDate, passportIssueDateErr
        } = this.state
        this.inputValidate()
        if (firstName != '' && countryId != '' &&
            lastName != '' && mobile != '' &&
            email != '' && idTypeId != '' && passportExpiryDate != "" && passportIssueDate != "" &&
            passportNo != '' && ((passportImage == '' || passportImage == null) ? (passportFrontImage != '' &&
                passportFrontImageType != '') : true)) {
            if (firstNameErr == '' && lastNameErr == '' && emailErr == '' && countryIdErr == '' && mobileErr == '' && idTypeIdErr == '' &&
                passportNoErr == '' && ((passportImage == '' || passportImage == null) ? passportFrontImageErr == '' : true) &&
                passportExpiryDateErr == '' && passportIssueDateErr == ''
            ) {
                this.setState({ isLoading: true })
                this.props.updateVisaApplication_action(VisaApplicationFormID, firstName, middleName, lastName, email, countryId, mobile,
                    idTypeId, passportNo, passportFrontImage, passportFrontImageType, passportIssueDate, passportExpiryDate)
                    .then((data) => {
                        if (data.error != 1) {
                            toastSuccess("Record updated Successfully")
                            setTimeout(() => {
                                this.setState({ isLoading: false })
                                this.props.navigate('/visaApplication', { state: { Deadline: Deadline, } })
                            }, 300);
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }


    getDetails() {
        const { visaApplicationList, VisaApplicationFormID } = this.state
        this.setState({ isLoading: true })
        var id = VisaApplicationFormID
        this.props.getVisaApplicationDetails_action(id)
            .then((data) => {
                console.log(data)
                if (data.error != 1) {
                    this.setState({
                        firstName: data.data.length > 0 ? data.data[0]['FirstName'] : '',
                        lastName: data.data.length > 0 ? data.data[0]['LastName'] : '',
                        middleName: data.data.length > 0 ? data.data[0]['MiddleName'] : '',
                        email: data.data.length > 0 ? data.data[0]['Email'] : '',
                        countryId: data.data.length > 0 ? data.data[0]['CountryID'] : '',
                        mobile: data.data.length > 0 ? data.data[0]['MobileNo'] : '',
                        idTypeId: data.data.length > 0 ? data.data[0]['FormDocumentTypeID'] : '',
                        passportNo: data.data.length > 0 ? data.data[0]['PassportNo'] : '',
                        passportIssueDate: data.data.length > 0 ? new Date(data.data[0]['PassportIssueDate']) : '',
                        passportExpiryDate: data.data.length > 0 ? new Date(data.data[0]['PassportExpiryDate']) : '',
                        isLoading: false,
                    })
                    if (data.data.length > 0) {
                        var filePath = data.data[0]['PassportImage']
                        var array = data.data[0]['PassportImage'] != null &&
                            data.data[0]['PassportImage'] != "" && filePath.split("/")
                        const FileName = array[array.length - 1]
                        this.setState({
                            passportFilePath: filePath,
                            passportImage: FileName
                        })
                    }

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    ClearValue() {
        this.setState({
            firstName: '',
            lastName: '',
            middleName: '',
            email: '',
            countryId: '',
            mobile: '',
            idTypeId: '',
            passportNo: '',
            passportFrontImage: '',
            passportImage: '',
            ListOfExhibitorBadgeId: '',
            passportExpiryDate: "",
            passportIssueDate: ""
        })
        // document.getElementById("path").value = ""
    }

    onCrossClick() {
        this.setState({ passportFrontImage: "" })
        document.getElementById("path").value = ""
    }

    // On Document Delete Modal Toggle
    onDocDelete() {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen })
    }

    // Delete Passport Document Delete
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, VisaApplicationFormID, passportImage } = this.state
        this.setState({ isLoading: true })
        this.props.deleteVisaApplicationDoc_action(VisaApplicationFormID)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Passport Front Image Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen, passportImage: "" })
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    onSelectListofExhibitor(e) {
        const { ListOfExhibitorBadgeList, visaApplicationList } = this.state
        this.setState({
            middleName: '',
            lastName: '',
        })
        this.setState({ isLoading: true })
        if (e.target.value != '' && e.target.value != 'Select') {
            const checkedValue = visaApplicationList.some(item => item.ListOfExhibitorBadgeId == e.target.value)
            if (!checkedValue) {
                ListOfExhibitorBadgeList.map(data => {
                    if (data.ExhBadgesFormID == e.target.value) {
                        const nameList = data.Name.split(' ')
                        if (nameList.length == 3) {
                            this.setState({
                                firstName: nameList[0],
                                middleName: nameList[1],
                                lastName: nameList[2],
                            })
                        } else if (nameList.length == 2) {
                            this.setState({
                                firstName: nameList[0],
                                lastName: nameList[1],
                            })
                        } else {
                            this.setState({
                                firstName: nameList[0]
                            })
                        }
                        this.setState({
                            ListOfExhibitorBadgeId: e.target.value,
                            email: data.Email,
                            mobile: data.MobileNo,
                            isLoading: false
                        })
                    }
                })
            } else {
                toastError("Exhibitor already selected")
                this.setState({ isLoading: false })
            }
        } else {
            this.setState({
                ListOfExhibitorBadgeId: '',
                firstName: '',
                email: '',
                countryId: '',
                mobile: '',
                isLoading: false
            })
        }

    }

    render() {
        const { isLoading, is_add, is_update, firstName, firstNameErr, middleName, lastName, lastNameErr, idTypeId, idTypeIdErr, passportFrontImage,
            passportFrontImageErr, countryId, countryIdErr, countryList, passportExpiryDate, passportExpiryDateErr, passportIssueDate, passportIssueDateErr,
            email, emailErr, passportNo, passportNoErr, mobile, mobileErr, visaApplicationList, idTypeList, passportImage, isDelOpen, isView, ListOfExhibitorBadgeList, ListOfExhibitorBadgeId,
            passportFilePath, url, isPassportView
        } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer></ToastContainer>
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="page">
                    <div className="page-main">
                        <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                            <div className=" width-dr-nav">
                                <div className="row">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                            <ExhibitorTopMenu /> : null
                                    }
                                </div>
                            </div>
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title" style={{ fontSize: 15 }}>
                                            Invitation letter for visa Application
                                        </h3>
                                    </div>
                                    <div className="card-body p-2 mt-1">
                                        {is_add && <div className="row mb-3">
                                            <div className="col-xl-7">
                                                <label >List of Exhibitor <span className="text-red" style={{ fontSize: '14px', marginLeft: '5px' }}> ({`You can request visa invitation letter only for exhibitor badges applied for ${sessionStorage.getItem('sessionExhibitionName')}`})</span></label>
                                                <select className="form-control"
                                                    value={ListOfExhibitorBadgeId}
                                                    disabled={isView}
                                                    onChange={(e) => this.onSelectListofExhibitor(e)}  >
                                                    <option value={''}>Select</option>
                                                    {
                                                        ListOfExhibitorBadgeList.length > 0 && ListOfExhibitorBadgeList.map(data => (
                                                            <option
                                                                value={data.ExhBadgesFormID}
                                                                key={data.ExhBadgesFormID}>
                                                                {data.CompanyName}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>}
                                        {(is_add ? ListOfExhibitorBadgeId != '' && ListOfExhibitorBadgeId != 'Select' : true) ?
                                            <div>
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">
                                                            First Name (As per Passport)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            disabled={isView}
                                                            required=""
                                                            maxLength={'50'}
                                                            value={firstName}
                                                            onChange={(e) => this.setState({ firstName: e.target.value, firstNameErr: '' })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{firstNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">
                                                            Middel Name (As per Passport)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            maxLength={'50'}
                                                            value={middleName}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ middleName: e.target.value })}
                                                        />

                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">
                                                            Last Name (As per Passport)
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            required=""
                                                            value={lastName}
                                                            maxLength={'50'}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ lastName: e.target.value, lastNameErr: '' })}
                                                        />

                                                        {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErr}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            required=""
                                                            maxLength={'50'}
                                                            value={email}
                                                            disabled={isView}
                                                            // onChange={(e) => this.setState({ email: e.target.value, emailErr: '' })}
                                                            onChange={(e) => this.emailValidation(e)}
                                                        />

                                                        {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Select Passport issue in Country</label>
                                                        <select className="form-control"
                                                            value={countryId}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ countryId: e.target.value != '' && e.target.value, countryIdErr: '' })}  >
                                                            <option value={''}>Select</option>
                                                            {
                                                                countryList.length > 0 && countryList.map(data => (
                                                                    <option value={data.Code} key={data.Code}>{data.Description}</option>
                                                                ))
                                                            }
                                                        </select>

                                                        {<span className='text-danger text-start text-left d-block mt-1'>{countryIdErr}</span>}
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Mobile</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            required=""
                                                            maxLength={'20'}
                                                            value={mobile}
                                                            disabled={isView}
                                                            // onChange={(e) => this.setState({ mobile: e.target.value, mobileErr: '' })}
                                                            onChange={(e) => this.mobileValidation(e)}
                                                        />

                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobileErr}</span>}
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    {/* <div className="col-xl-4">
                                                <label htmlFor="validationCustom03">ID Type</label>
                                                <select className="form-control" value={idTypeId}
                                                    disabled={isView}
                                                    onChange={(e) => this.setState({ idTypeId: e.target.value != '' && e.target.value, idTypeIdErr: '' })}>
                                                    <option value={''}>Select</option>
                                                    {
                                                        idTypeList.length > 0 && idTypeList.map(data => (
                                                            <option value={data.FormDocumentTypeID} key={data.FormDocumentTypeID}>{data.Name}</option>
                                                        ))
                                                    }
                                                </select>

                                                {<span className='text-danger text-start text-left d-block mt-1'>{idTypeIdErr}</span>}
                                            </div> */}
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03">Passport No</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            maxLength={'20'}
                                                            required=""
                                                            value={passportNo}
                                                            disabled={isView}
                                                            onChange={(e) => this.setState({ passportNo: e.target.value, passportNoErr: '' })}
                                                        />

                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passportNoErr}</span>}
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label className="">Passport issue Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker
                                                            selected={passportIssueDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    passportIssueDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    passportIssueDateErr: ""
                                                                })}
                                                            disabled={isView}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            maxDate={passportExpiryDate}
                                                            dropdownMode="select"
                                                            placeholderText="Passport Issued Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passportIssueDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label >Passport Expiry Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker
                                                            selected={passportExpiryDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    passportExpiryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    passportExpiryDateErr: ""
                                                                })}
                                                            disabled={isView}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            minDate={passportIssueDate}
                                                            dropdownMode="select"
                                                            placeholderText="Passport Expiry Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passportExpiryDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <label htmlFor="validationCustom03" className="mt-3">
                                                            Passport Front Page with Photo (Upto 5MB){" "}<i class="fa fa-exclamation passport-eg"
                                                                onClick={() => this.setState({ isPassportView: true })}
                                                            ></i>
                                                        </label>
                                                        <div className="box">
                                                            <div className="js--image-preview" />

                                                            {
                                                                is_add ?
                                                                    <div className="upload-options mt-1">
                                                                        <label>
                                                                            <input
                                                                                type="file"
                                                                                id="path"
                                                                                className="image-upload"
                                                                                accept=".jpg, .jpeg, .png, .pdf"
                                                                                onChange={e => this.onFilePick(e, e.target.files)}
                                                                                disabled={isView}
                                                                            />
                                                                            {
                                                                                passportFrontImage != "" &&
                                                                                <i class="fa fa-times text-danger img-can"
                                                                                    onClick={() => this.onCrossClick()}></i>
                                                                            }
                                                                        </label>

                                                                    </div> : passportImage != "" && passportImage != null ?
                                                                        <div style={{
                                                                            border: '1px solid #ccc',
                                                                            padding: '10px',
                                                                            display: 'inline-flex',
                                                                            alignItems: 'center',
                                                                            // marginTop: '10px'
                                                                        }}>
                                                                            <a
                                                                                href={passportFilePath}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <span style={{ marginRight: '10px' }}
                                                                                >{passportImage}</span>
                                                                                <i className={`fa fa-times text-danger img-can ${isView ? 'disabled' : ''}`}
                                                                                    onClick={!isView ? (e) => this.onDocDelete() : undefined}
                                                                                ></i>
                                                                            </a>
                                                                        </div> : <label>
                                                                            <input
                                                                                type="file"
                                                                                id="path"
                                                                                className="image-upload"
                                                                                accept=".jpg, .jpeg, .png, .pdf"
                                                                                disabled={isView}
                                                                                onChange={e => this.onFilePick(e, e.target.files)}
                                                                            />
                                                                            {
                                                                                passportFrontImage != "" &&
                                                                                <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick()}></i>
                                                                            }
                                                                        </label>
                                                            }
                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passportFrontImageErr}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            : <></>
                                        }
                                        {
                                            is_add &&
                                            <div className="btn-group mt-4 save-can d-block">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    {ListOfExhibitorBadgeId != '' && ListOfExhibitorBadgeId != 'Select' && <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0 d-block"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => this.handleAddMore()}
                                                    >
                                                        Add More
                                                    </button>}
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0"
                                                        style={{ marginRight: '10px', width: '100px' }}
                                                        onClick={() => this.OnCancel()}
                                                    >
                                                        Cancel
                                                    </button>

                                                    {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                                </div>

                                            </div>
                                        }

                                        <hr />
                                        {
                                            // isView && sessionStorage.getItem &&
                                            //     JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 2 &&
                                            isView &&
                                            <div>
                                                <iframe
                                                    title="Passport Front and Back Page"
                                                    src={url + passportFilePath}
                                                    // src='https://ems_uat.india-itme.com/document/VisaApplication/GTTES25_50037/GTTES25_50037_visaApplication_30.pdf'
                                                    style={{ width: "800px", height: "1000px" }}
                                                />
                                            </div>
                                        }

                                        {this.renderVisaApplicationList()}


                                        <div className="row mt-4">
                                            <div className="btn-group mt-4 save-can d-block" role="group" aria-label="Basic example">
                                                {
                                                    is_add &&
                                                    visaApplicationList.length > 0 && <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0"
                                                        style={{ marginRight: '10px', width: '100px' }}
                                                        onClick={() => this.handleSave()}
                                                    >
                                                        Save
                                                    </button>
                                                }
                                                {is_update && <button
                                                    type="button"
                                                    className="btn btn-primary rounded-0"
                                                    style={{ marginRight: '10px', width: '100px' }}
                                                    onClick={() => this.handleUpdate()}
                                                >
                                                    Update
                                                </button>}
                                                {
                                                    is_update && <button
                                                        type="button"
                                                        className="btn btn-primary rounded-0"
                                                        style={{ marginRight: '10px', width: '100px' }}
                                                        onClick={() => this.OnCancel()}
                                                    >
                                                        Back
                                                    </button>
                                                }

                                                {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this Document?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.handleDelete(e)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={isPassportView} onRequestClose={() => this.setState({ isPassportView: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text">Passport eg.</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isPassportView: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src="images/passport-eg.jpg"></img>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, { addVisaApplication_action, getVisaApplicationDetails_action, updateVisaApplication_action, deleteVisaApplicationDoc_action, fill_action })(InvitationVisaApplicationAU))