import { axiosPost, axiosGet } from "../react_axios/Axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// export const BASE_URL = "http://localhost:9000";

//production urls..............................................
export const BASE_URL = "https://india-itme.com/api";
export var link_BASE_URL = `https://india-itme.com/#/`;
export const DOC_BASE_URL = "https://india-itme.com";
export var REPORT_BASE_URL = `https://india-itme.com/doc`;
//..............................................................

//UAT urls..............................................
// export const BASE_URL = "https://ems_uat.india-itme.com/api";
// export var link_BASE_URL = `https://ems_uat.india-itme.com/#/`;
// export const DOC_BASE_URL = "https://ems_uat.india-itme.com";
// export var REPORT_BASE_URL = `https://ems_uat.india-itme.com/doc`;
//..............................................................



// prod url with HTTPS.....................................................
// export var BASE_URL = `https://${window.location.host}/api`;
// prod url with HTTP only.....................................................
// export var BASE_URL = `http://${window.location.host}/api`;


// link for Download submitted undertaking for uat


export const fetchAuthReport = `${REPORT_BASE_URL}/report/SetParameterData`;
export const downloadReport = `${REPORT_BASE_URL}/WebForm/ItmeReportViewer.aspx`;

export const reportName = 'ExhibitorRegistration.rpt';
export const bonafideDocName = 'bonafideCertificate.rpt';
export const ParticipationCertificateDocName = 'Participation_Certificate.rpt'
export const signatureDocName = 'ExhibitorSignature.rpt'
export const NoDuesCertificateDoc = 'NoDuesCertificate.rpt'
export const manualFormDoc = 'Exhibitioncpy.rpt'
export const taxInvoiceDoc = 'TaxInvoice.rpt'
// Visitor badge report
export const badgeReportName = 'VisitorBadge.rpt'

//
export const paymentPortalLink = "https://test.ccavenue.com//transaction/transaction.do?command=initiateTransaction"; //Dev
// export const paymentPortalLink = "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"; //UAT
export const paymentAccessCode = 'AVVI64IL27CE56IVEC'
export const redirect_url = `${BASE_URL}/payment/paymentResponse`


// auth
export const authUrl = `${BASE_URL}/auth/loginUser`;
export const changePasswordUrl = `${BASE_URL}/auth/changePassword`;
export const updatePasswordUrl = `${BASE_URL}/auth/updatePassword`;
export const getLoginPassUrl = `${BASE_URL}/auth/getLoginPass`;
// forget login password
export const forgetCheckedEmailUrl = `${BASE_URL}/auth/forgetCheckedEmail`;
export const forgetGetOtpUrl = `${BASE_URL}/auth/forgetGetOtp`;
export const forgetUpdatePasswordUrl = `${BASE_URL}/auth/forgetUpdatePassword`;

// link to share exhibiton registration
export const exhRegUrl = "/#/IA23"

// login url 
export const loginUrl = `/#/login/`


// dropdown list
export const fillListUrl = `${BASE_URL}/fillList/getDropdownList`;

// dashboard
export const adminDashboardUrl = `${BASE_URL}/dashboard/getDashboardData`;
export const getAdminApplicationSummaryListUrl = `${BASE_URL}/dashboard/getAdminApplicationSummaryList`;
export const getAdminAreaSummaryListUrl = `${BASE_URL}/dashboard/getAreaSummaryList`;
export const getAdminPaymentSummaryListUrl = `${BASE_URL}/dashboard/getPaymentSummaryList`;
export const getAdminAdditionalSummaryListUrl = `${BASE_URL}/dashboard/getAdditionalSummaryList`;
export const getAdminParticipantSummaryListUrl = `${BASE_URL}/dashboard/getParticipantSummaryList`;

//PAYMENT
export const getDuePaymentListUrl = `${BASE_URL}/payment/getDuePaymentList`;
export const sendPaymentFormUrl = `${BASE_URL}/payment/sendPaymentForm`;

//USER
export const getAdminUserListUrl = `${BASE_URL}/user/getAdminUserList`;
export const getAdminUserDetailsUrl = `${BASE_URL}/user/getAdminUserDetails`;
export const addAdminUserUrl = `${BASE_URL}/user/addAdminUser`;
export const updateAdminUserUrl = `${BASE_URL}/user/updateAdminUser`;
export const deleteAdminUserUrl = `${BASE_URL}/user/deleteAdminUser`;

// master
//exhibition
export const getExhibitionMasterlistUrl = `${BASE_URL}/exhibitionMaster/getExhibitionMasterlist`;
export const addExhibitionMasterUrl = `${BASE_URL}/exhibitionMaster/addExhibitionMaster`;
export const updateExhibitionMasterUrl = `${BASE_URL}/exhibitionMaster/updateExhibitionMaster`;
export const deleteExhibitionMasterUrl = `${BASE_URL}/exhibitionMaster/deleteExhibitionMaster`;
export const deleteExhibitionDocumentUrl = `${BASE_URL}/exhibitionMaster/deleteExhibitionDocument`;
export const getExhibitionMasterDetailsUrl = `${BASE_URL}/exhibitionMaster/getExhibitionMasterDetails`;
export const clearExhibitionMasterDataUrl = `${BASE_URL}/exhibitionMaster/clearExhibitionData`;
//role
export const getRoleMasterListUrl = `${BASE_URL}/roleMaster/getRoleMasterList`;
export const addRoleMasterUrl = `${BASE_URL}/roleMaster/addRoleMaster`;
export const updateRoleMasterUrl = `${BASE_URL}/roleMaster/updateRoleMaster`;
export const deleteRoleMasterUrl = `${BASE_URL}/roleMaster/deleteRoleMaster`;
export const getRoleMasterDetailsUrl = `${BASE_URL}/roleMaster/getRoleMasterDetails`;
export const getRoleMasterDetailsDefaultUrl = `${BASE_URL}/roleMaster/getRoleMasterDetailsDefault`;

//manage area
export const getManageAreaListUrl = `${BASE_URL}/manageArea/getManageAreaList`;
export const updateManageAreaUrl = `${BASE_URL}/manageArea/updateManageArea`;
export const addManageAreaStallUrl = `${BASE_URL}/manageArea/addManageAreaStall`;

//mconfigratiom 
export const updateExhibitionConfigrationUrl = `${BASE_URL}/configration/updateExhibitionConfigration`;
export const getExhibitionConfigrationDetailsUrl = `${BASE_URL}/configration/getExhibitionConfigrationDetails`;

export const updateExhibitionPaymentConfigrationUrl = `${BASE_URL}/paymentConfig/updateExhibitionPaymentConfigration`;

//email Configration
export const getEmailConfigrationDetailsUrl = `${BASE_URL}/emailConfigration/getEmailConfigrationDetails`;
export const insertUpdateEmailConfigrationUrl = `${BASE_URL}/emailConfigration/insertUpdateEmailConfigration`;

//Exhibition Note
export const getExhNoteDetailsUrl = `${BASE_URL}/exhNote/getExhNoteDetails`;
export const insertUpdateExhNoteUrl = `${BASE_URL}/exhNote/insertUpdateExhNote`;


//manual forms master
export const getManualFormsMasterListUrl = `${BASE_URL}/manualformsMaster/getManualFormsMasterList`;
export const deleteManualFormsMasterUrl = `${BASE_URL}/manualformsMaster/deleteManualFormsMaster`;
export const insertUpdateManualformsMasterList = `${BASE_URL}/manualformsMaster/insertUpdateManualformsMasterList`;

//hall master
export const getHallMasterDetailsUrl = `${BASE_URL}/hallMaster/getHallMasterDetails`;
export const insertUpdateHallMasterUrl = `${BASE_URL}/hallMaster/insertUpdateHallMaster`;
export const getHallMasterListUrl = `${BASE_URL}/hallMaster/getHallMasterList`;
export const deleteHallMasterUrl = `${BASE_URL}/hallMaster/deleteHallMaster`;

//stall master
export const getStallMasterDetailsUrl = `${BASE_URL}/stallMaster/getStallMasterDetails`;
export const insertUpdateStallMasterUrl = `${BASE_URL}/stallMaster/insertUpdateStallMaster`;
export const getStallMasterListUrl = `${BASE_URL}/stallMaster/getStallMasterList`;
export const deleteStallMasterUrl = `${BASE_URL}/stallMaster/deleteStallMaster`;

//GST master
export const getGSTmasterListUrl = `${BASE_URL}/gstMaster/getGSTmasterList`;
export const insertUpdateGSTMasterUrl = `${BASE_URL}/gstMaster/insertUpdateGSTMaster`;
export const deleteGSTMasterUrl = `${BASE_URL}/gstMaster/deleteGSTMaster`;
// export const getGstMasterDetailsUrl = `${BASE_URL}/gstMaster/getGstMasterDetails`;

//State master
export const getStateMasterListUrl = `${BASE_URL}/stateMaster/getStateMasterList`;
export const insertStateMasterUrl = `${BASE_URL}/stateMaster/insertStateMaster`;
export const deleteStateMasterUrl = `${BASE_URL}/stateMaster/deleteStateMaster`;
export const updateStateMasterUrl = `${BASE_URL}/stateMaster/updateStateMaster`;

//City master
export const getCityMasterListUrl = `${BASE_URL}/cityMaster/getCityMasterList`;
export const insertCityMasterUrl = `${BASE_URL}/cityMaster/insertCityMaster`;
export const deleteCityMasterUrl = `${BASE_URL}/cityMaster/deleteCityMaster`;
export const updateCityMasterUrl = `${BASE_URL}/cityMaster/updateCityMaster`;


//IS master
export const insertUpdateIndustrySegmentMasterListUrl = `${BASE_URL}/industrySegmentMaster/insertUpdateIndustrySegmentMasterList`;

//POV master
export const insertUpdatePurposeofVisitMasterListUrl = `${BASE_URL}/purposeofVisitMaster/insertUpdatePurposeofVisitMasterList`;
// export const insertUpdateGSTMasterUrl = `${BASE_URL}/industrySegmentMaster/insertUpdateOnlinePresencelMaster`;

//online presence master
export const getOnlinePresenceListUrl = `${BASE_URL}/onlinePresence/getOnlinePresenceList`;
export const insertUpdateOnlinePresencelMasterUrl = `${BASE_URL}/onlinePresence/insertUpdateOnlinePresencelMaster`;
export const getOnlinePresenceMasterDetailsUrl = `${BASE_URL}/onlinePresence/getOnlinePresenceMasterDetails`;
export const deleteonlinePresenceMasterUrl = `${BASE_URL}/onlinePresence/deleteonlinePresenceMaster`;

//bankDetailsMaster master
export const getBankDetailsMasterListUrl = `${BASE_URL}/bankDetailsMaster/getBankDetailsMasterList`;
export const insertUpdateBankDetailsMasterUrl = `${BASE_URL}/bankDetailsMaster/insertUpdateBankDetailsMaster`;
export const deleteBankDetailMasterUrl = `${BASE_URL}/bankDetailsMaster/deleteBankDetailMaster`;
// export const getGstMasterDetailsUrl = `${BASE_URL}/gstMaster/getGstMasterDetails`;

//manual form master
// LL master
export const getLiquorLicenseMasterListUrl = `${BASE_URL}/liquorLicenseMaster/getLiquorLicenseMasterList`;
export const insertUpdateLiquorLicenseMasterUrl = `${BASE_URL}/liquorLicenseMaster/insertUpdateLiquorLicenseMaster`;
export const deleteLiquorLicenseMasterUrl = `${BASE_URL}/liquorLicenseMaster/deleteLiquorLicenseMaster`;

// LeadRetrivalConnection master
export const getLeadRetrivalConnectionMasterListUrl = `${BASE_URL}/leadRetrivalConnectionMaster/getLeadRetrivalConnectionMasterList`;
export const insertUpdateLeadRetrivalConnectionMasterUrl = `${BASE_URL}/leadRetrivalConnectionMaster/insertUpdateLeadRetrivalConnectionMaster`;
export const deleteLeadRetrivalConnectionMasterUrl = `${BASE_URL}/leadRetrivalConnectionMaster/deleteLeadRetrivalConnectionMaster`;

// H&H master
export const getHostAndHostessMasterListUrl = `${BASE_URL}/hostAndHostessMaster/getHostAndHostessMasterList`;
export const insertUpdateHostAndHostessMasterUrl = `${BASE_URL}/hostAndHostessMaster/insertUpdateHostAndHostessMaster`;
export const deleteHostAndHostessMasterUrl = `${BASE_URL}/hostAndHostessMaster/deleteHostAndHostessMaster`;

// H&H master
export const getVisitorMeetingMasterListUrl = `${BASE_URL}/visitorMeetingMaster/getVisitorMeetingMasterList`;
export const insertUpdateVisitorMeetingMasterUrl = `${BASE_URL}/visitorMeetingMaster/insertUpdateVisitorMeetingMaster`;
export const deleteVisitorMeetingMasterUrl = `${BASE_URL}/visitorMeetingMaster/deleteVisitorMeetingMaster`;

// rental AV master
export const getRentalAudioVideoMasterListUrl = `${BASE_URL}/rentalAudioAndVideoMaster/getRentalAudioVideoMasterList`;
export const insertUpdateRentalAudioVideoMasterUrl = `${BASE_URL}/rentalAudioAndVideoMaster/insertUpdateRentalAudioVideoMaster`;
export const deleteRentalAudioVideoMasterUrl = `${BASE_URL}/rentalAudioAndVideoMaster/deleteRentalAudioVideoMaster`;

// catering services master
export const getCateringServicesMasterListUrl = `${BASE_URL}/cateringServicesMaster/getCateringServicesMasterList`;
export const insertUpdateCateringServicesMasterUrl = `${BASE_URL}/cateringServicesMaster/insertUpdateCateringServicesMaster`;
export const deleteCateringServicesMasterUrl = `${BASE_URL}/cateringServicesMaster/deleteCateringServicesMaster`;

// housekeeping  master
export const getHousekeepingApplicationMasterListUrl = `${BASE_URL}/housekeepingApplicationMaster/getHousekeepingApplicationMasterList`;
export const insertUpdateHousekeepingApplicationMasterUrl = `${BASE_URL}/housekeepingApplicationMaster/insertUpdateHousekeepingApplicationMaster`;
export const deleteHousekeepingApplicationMasterUrl = `${BASE_URL}/housekeepingApplicationMaster/deleteHousekeepingApplicationMaster`;

// IC  master
export const getInternetConnectionMasterListUrl = `${BASE_URL}/internetConnectionMaster/getInternetConnectionMasterList`;
export const insertUpdateInternetConnectionMasterUrl = `${BASE_URL}/internetConnectionMaster/insertUpdateInternetConnectionMaster`;
export const deleteInternetConnectionMasterUrl = `${BASE_URL}/internetConnectionMaster/deleteInternetConnectionMaster`;

// Security application  master
export const getSecurityApplicationMasterListUrl = `${BASE_URL}/securityApplicationMaster/getSecurityApplicationMasterList`;
export const insertUpdateSecurityApplicationMasterUrl = `${BASE_URL}/securityApplicationMaster/insertUpdateSecurityApplicationMaster`;
export const deleteSecurityApplicationMasterUrl = `${BASE_URL}/securityApplicationMaster/deleteSecurityApplicationMaster`;

// delegate meeting  master
export const getDelegateMeetingMasterListUrl = `${BASE_URL}/delegateMeetingMaster/getDelegateMeetingMasterList`;
export const insertUpdateDelegateMeetingMasterUrl = `${BASE_URL}/delegateMeetingMaster/insertUpdateDelegateMeetingMaster`;
export const deleteDelegateMeetingMasterUrl = `${BASE_URL}/delegateMeetingMaster/deleteDelegateMeetingMaster`;
//  meeting room  master
export const getMeetingRoomMasterListUrl = `${BASE_URL}/meetingRoomMaster/getMeetingRoomMasterList`;
export const insertUpdateMeetingRoomMasterUrl = `${BASE_URL}/meetingRoomMaster/insertUpdateMeetingRoomMaster`;
export const deleteMeetingRoomMasterUrl = `${BASE_URL}/meetingRoomMaster/deleteMeetingRoomMaster`;
//  meeting room  master
export const getBrandingOpportunityMasterListUrl = `${BASE_URL}/brandingOpportunityMaster/getBrandingOpportunityMasterList`;
export const insertUpdateBrandingOpportunityMasterUrl = `${BASE_URL}/brandingOpportunityMaster/insertUpdateBrandingOpportunityMaster`;
export const deleteBrandingOpportunityMasterUrl = `${BASE_URL}/brandingOpportunityMaster/deleteBrandingOpportunityMaster`;

// export const updateManageAreaUrl = `${BASE_URL}/manageArea/u pdateManageArea`;

//admin report
export const getExhibitorDetailsReportUrl = `${BASE_URL}/report/getExhibitorDetailsReport`;
export const getReportListUrl = `${BASE_URL}/report/getReportList`;
export const getReceiptReportUrl = `${BASE_URL}/report/getReceiptReport`;
export const getTaxInvoiceReportUrl = `${BASE_URL}/report/getTaxInvoiceReport`;

// timeline
export const getTimelineListUrl = `${BASE_URL}/timeline/getTimelineList`;
export const insertTimelineListUrl = `${BASE_URL}/timeline/insertTimeline`;
export const updateTimelineListUrl = `${BASE_URL}/timeline/updateTimeline`;
export const deleteTimelineListUrl = `${BASE_URL}/timeline/deleteTimeline`;

//chapter
export const addChapterMasterUrl = `${BASE_URL}/masterChapter/addChapters`
export const updateChapterMasterUrl = `${BASE_URL}/masterChapter/updateChapters`
export const deleteChapterMasterUrl = `${BASE_URL}/masterChapter/deleteChapter`

// registration 1
export const registrationFormAddUrl = `${BASE_URL}/registerForm/sendRegisterForm`;
export const registrationFormUpdateUrl = `${BASE_URL}/registerForm/updateRegisterForm`;
export const getregistrationDetailsUrl = `${BASE_URL}/registerForm/getExhRegisterData`;
export const getregistrationAgentDetailsUrl = `${BASE_URL}/registerForm/getAgentData`;
export const getExhRegisterListExcelDataUrl = `${BASE_URL}/registerForm/getExhRegisterListExcelData`;
export const getPendingRegistrationDetailsUrl = `${BASE_URL}/registerForm/getPendingRegDetails`;

//primary chapter
export const primaryChapterUrl = `${BASE_URL}/registerForm/insertChapter`;
export const getPreviewDetailsUrl = `${BASE_URL}/registerForm/getStallData`;
export const getDoubleStoreySSIUrl = `${BASE_URL}/registerForm/getDoubleStoreySSI`;
export const deleteSSiUrl = `${BASE_URL}/registerForm/deleteSSi`;
export const deleteTMMAITAMMAUrl = `${BASE_URL}/registerForm/deleteTMMAITAMMA`;


//description
export const getdescriptionDetailsUrl = `${BASE_URL}/registerForm/getEstimates`;
export const descriptionAddUrl = `${BASE_URL}/registerForm/insertUpdateComment`;
export const descriptionUpdateUrl = `${BASE_URL}/registerForm/`; // need to provide path
export const getdescriptionTermsAndConditionUrl = `${BASE_URL}/registerForm/`; // need to provide path

// last submit
export const lastSubmitAddUrl = `${BASE_URL}/registerForm/submit`;

//receipt
export const receiptUpdateUrl = `${BASE_URL}/receipt/updateReceipt`;
export const receiptListUrl = `${BASE_URL}/receipt/fetchReceiptList`;
export const proformaInvoiceUrl = `${BASE_URL}/receipt/getProformaInvoice`;
export const receiptDetailsUrl = `${BASE_URL}/receipt/getReceiptDetails`;
export const generateVoucherUrl = `${BASE_URL}/receipt/generateVoucher`;
export const receiptAddUrl = `${BASE_URL}/receipt/addReceipt`;
export const shareEmailUrl = `${BASE_URL}/receipt/shareEmail`;
export const receiptNoUrl = `${BASE_URL}/receipt/getReceiptNo`;
export const receiptSaveUrl = `${BASE_URL}/receipt/addReceipt`;
export const getEmailDataUrl = `${BASE_URL}/receipt/getEmailData`;
export const receiptDelUrl = `${BASE_URL}/receipt/delReceipt`;
export const getPendingReceiptDetailsUrl = `${BASE_URL}/receipt/getpendingreceipt`;
export const receiptShareUrl = `${BASE_URL}/receipt/receiptShare`;

//agent receipt
export const updateAgentReceiptUrl = `${BASE_URL}/agentReceipt/updateAgentReceipt`;
export const fetchAgentReceiptListUrl = `${BASE_URL}/agentReceipt/fetchAgentReceiptList`;
export const getAgentProformaInvoiceUrl = `${BASE_URL}/agentReceipt/getAgentProformaInvoice`;
export const getAgentReceiptDetailsUrl = `${BASE_URL}/agentReceipt/getAgentReceiptDetails`;
export const generateVoucherAgentUrl = `${BASE_URL}/agentReceipt/generateVoucherAgent`;
export const addAgentReceiptUrl = `${BASE_URL}/agentReceipt/addAgentReceipt`;
// export const shareEmailUrl = `${BASE_URL}/agentReceipt/shareEmail`;
export const getAgentReceiptNoUrl = `${BASE_URL}/agentReceipt/getAgentReceiptNo`;
// export const receiptSaveUrl = `${BASE_URL}/agentReceipt/addReceipt`;
// export const getEmailDataUrl = `${BASE_URL}/agentReceipt/getEmailData`;
export const deleteAgentReceiptDetailsUrl = `${BASE_URL}/agentReceipt/deleteAgentReceiptDetails`;
// export const getPendingReceiptDetailsUrl = `${BASE_URL}/agentReceipt/getpendingreceipt`;
export const agentReceiptShareUrl = `${BASE_URL}/agentReceipt/agentReceiptShare`;

//Tax Invoice
export const taxinvoiceListUrl = `${BASE_URL}/taxInvoice/taxInvoiceList`;
export const taxInvoiceDetailsUrl = `${BASE_URL}/taxInvoice/taxInvoiceDetails`;
export const taxInvoiceMailUrl = `${BASE_URL}/taxInvoice/taxInvoiceMail`;
export const sendNoDuesUrl = `${BASE_URL}/taxInvoice/sendNoDues`;
export const createTaxInvoiceUrl = `${BASE_URL}/taxInvoice/createTaxInvoice`;
export const getStallPossessionExportUrl = `${BASE_URL}/taxInvoice/getStallPossessiomExportData`;
// Agent
export const agentEntrySaveUrl = `${BASE_URL}/agent/addAgent`;
export const agentEntryUpdateUrl = `${BASE_URL}/agent/updateAgent`;
export const getAgentListUrl = `${BASE_URL}/agent/getAgentList`;
export const getAgentDetailsUrl = `${BASE_URL}/agent/getAgentDetails`;
export const sendAgentDetailsUrl = `${BASE_URL}/agent/sendCredentials`;
export const insertAgentPI1Url = `${BASE_URL}/agent/insertAgentPI1`;
export const insertAgentPI2Url = `${BASE_URL}/agent/insertAgentPI2`;
export const getAgentDetailsforMailUrl = `${BASE_URL}/agent/getAgentDetailsforMail`;
export const ForSendMailUrl = `${BASE_URL}/agent/ForSendMail`;
export const verifyAgentEmailUrl = `${BASE_URL}/agent/verifyAgentEmail`;
export const getAgentDashboardUrl = `${BASE_URL}/agentDashboard/getAgentDashboardData`;
export const getExhibitorDetailUrl = `${BASE_URL}/agentDashboard/getEstimateDetail`;

// Admin account
export const getPaymentListUrl = `${BASE_URL}/adminaccount/getPaymentList`;

// Reminder Email Url
export const reminderEmailUrl = `${BASE_URL}/OutstandingReminder/reminderEmail`;
export const getReminderListUrl = `${BASE_URL}/OutstandingReminder/getReminderList`;

// Circular 
export const getCircularCompantUrl = `${BASE_URL}/circular/getCircularCompany`
export const getCircularListUrl = `${BASE_URL}/circular/getCircularList`
export const insertCircularUrl = `${BASE_URL}/circular/insertCircular`
export const deleteCircularUrl = `${BASE_URL}/circular/deleteCircular`

// Visitor Circular 
export const getVisitorCircularCompanyUrl = `${BASE_URL}/visitorCircular/getVisitorCircularCompany`;
export const getVisitorCircularListUrl = `${BASE_URL}/visitorCircular/getVisitorCircularList`;
export const insertVisitorCircularUrl = `${BASE_URL}/visitorCircular/insertVisitorCircular`;
export const delVisitorCircularUrl = `${BASE_URL}/visitorCircular/deleteVisitorCircular`;
export const assignCircularUrl = `${BASE_URL}/visitorCircular/assignCircular`;

// Exhibitor Manual Form Summary
export const getExhibitorManualFormSummaryListUrl = `${BASE_URL}/exhibitorManualFormSummary/getExhibitorManualFormSummaryList`
export const getExhibitorManualFormSummaryExportUrl = `${BASE_URL}/exhibitorManualFormSummary/getExhibitorManualFormSummaryExport`

// Admin Manual Forms Approval
// Fascia approval 
export const getFasciaApplicationsUrl = `${BASE_URL}/fasciaApproval/getFasciaApplications`
export const updateApproveFasciaFormUrl = `${BASE_URL}/fasciaApproval/updateApproveFasciaForm`
export const getFasciaExportUrl = `${BASE_URL}/fasciaApproval/getFasciaExportData`
// Exhibitor Badges Approval
export const getExhBadgesSummaryUrl = `${BASE_URL}/exhBadgeApproval/getExhBadgeSummary`
export const getExhBadgesApplicationsUrl = `${BASE_URL}/exhBadgeApproval/getExhBadgeApplications`
export const updateExhBadgeApprovalUrl = `${BASE_URL}/exhBadgeApproval/updateExhBadgeApproval`
export const getExhBadgeExportUrl = `${BASE_URL}/exhBadgeApproval/getExhibitorBadgeExportData`
// E Directory Approval
export const getEDirectorySummaryUrl = `${BASE_URL}/eDirectoryApproval/getEDirectorySummary`
export const getDirectoryExportUrl = `${BASE_URL}/eDirectoryApproval/getEdirectoryExport`
export const getEDirectoryApplicationUrl = `${BASE_URL}/eDirectoryApproval/getEDirectoryApplications`
export const updateEDirectoryApprovalUrl = `${BASE_URL}/eDirectoryApproval/updateEDirectoryApproval`
// Power Approval
export const getPowerConnectionApprovalDetailsUrl = `${BASE_URL}/powerApproval/getPowerConnectionApprovalDetails`
export const getPowerConnectionApprovalListUrl = `${BASE_URL}/powerApproval/getPowerConnectionApprovalList`
export const getPowerConnectionExportDataUrl = `${BASE_URL}/powerApproval/getPowerConnectionExportData`
export const updatePowerApprovalUrl = `${BASE_URL}/powerApproval/updatePowerApproval`

// Visa approval 
export const getVisaInvitationUrl = `${BASE_URL}/visaInvitation/getVisaInvitations`;
export const getVisaApplicationUrl = `${BASE_URL}/visaInvitation/getVisaApplications`;
export const updateVisaApprovalUrl = `${BASE_URL}/visaInvitation/updateVisaAproval`;
export const getVisaApplicationExportUrl = `${BASE_URL}/visaInvitation/getVisaApplicationExportData`;
// Visitor Visa Approval
export const getVisitorVisaInvitationUrl = `${BASE_URL}/visitorvisainvitation/getVisitorVisaInvitation`;
export const getVisitorVisaApplicationUrl = `${BASE_URL}/visitorvisainvitation/getVisitorVisaApplication`;
export const getDetailsVisitorVisaApplicationUrl = `${BASE_URL}/visitorvisainvitation/getDetailsVisaApplicationVisitor`;
export const updateVisitorVisaApprovalUrl = `${BASE_URL}/visitorvisainvitation/updateVisitorVisaApproval`;
export const getVisitorVisaAppliacationExportUrl = `${BASE_URL}/visitorvisainvitation/getVisitorVisaApplicationExportData`;

// air conection approval
export const getAirConnectionApprovalDetailsUrl = `${BASE_URL}/airConnectionApproval/getAirConnectionApprovalDetails`;
export const getAirConnectionApprovalListUrl = `${BASE_URL}/airConnectionApproval/getAirConnectionApprovalList`;
export const getAirConnectionExportDataUrl = `${BASE_URL}/airConnectionApproval/getAirConnectionExportData`;
export const updateAirConnectionApprovalUrl = `${BASE_URL}/airConnectionApproval/updateAirConnectionApproval`;

// water conection approval
export const getWaterConnectionApprovalDetailsUrl = `${BASE_URL}/waterConnectionApproval/getWaterConnectionApprovalDetails`;
export const getWaterConnectionApprovalListUrl = `${BASE_URL}/waterConnectionApproval/getWaterConnectionApprovalList`;
export const getWaterConnectionExportDataUrl = `${BASE_URL}/waterConnectionApproval/getWaterConnectionExportData`;
export const updateWaterConnectionApprovalUrl = `${BASE_URL}/waterConnectionApproval/updateWaterConnectionApproval`;

// meeting room approval
export const getMeetingRoomApprovalDetailsUrl = `${BASE_URL}/meetingRoomApproval/getMeetingRoomApprovalDetails`;
export const getMeetingRoomApprovalListUrl = `${BASE_URL}/meetingRoomApproval/getMeetingRoomApprovalList`;
export const getMeetingRoomExportDataUrl = `${BASE_URL}/meetingRoomApproval/getMeetingRoomExportData`;
export const updateMeetingRoomApprovalUrl = `${BASE_URL}/meetingRoomApproval/updateMeetingRoomApproval`;

//Branding Opportunity approval
export const getBrandingOpportunityApprovalDetailsUrl = `${BASE_URL}/brandingOpportunityApproval/getBrandingOpportunityApprovalDetails`;
export const getBrandingOpportunityApprovalListUrl = `${BASE_URL}/brandingOpportunityApproval/getBrandingOpportunityApprovalList`;
export const getBrandingOpportunityExportDataUrl = `${BASE_URL}/brandingOpportunityApproval/getBrandingOpportunityExportData`;
export const updateBrandingOpportunityApprovalUrl = `${BASE_URL}/brandingOpportunityApproval/updateBrandingOpportunityApproval`;

//Stall Design approval
export const getStallDesignApprovalDetailsUrl = `${BASE_URL}/stallDesignApproval/getStallDesignApprovalDetails`;
export const getStallDesignApprovalListUrl = `${BASE_URL}/stallDesignApproval/getStallDesignApprovalList`;
export const getStallDesignExportDataUrl = `${BASE_URL}/stallDesignApproval/getStallDesignExportData`;
export const updateStallDesignApprovalUrl = `${BASE_URL}/stallDesignApproval/updateStallDesignApproval`;

// ledger
export const getLedgerListUrl = `${BASE_URL}/ledger/ledgerList`;
export const sendLedgerUrl = `${BASE_URL}/ledger/sendLedger`;
export const sendOutstandingUrl = `${BASE_URL}/ledger/sendOutstanding`;

// manage area
// application status management
export const sendEmailOfAvailableAreaUrl = `${BASE_URL}/applicationStatusManagement/sendEmailOfAvailableArea`;
export const moveToApplyStatusUrl = `${BASE_URL}/applicationStatusManagement/moveToApplyStatus`;
export const sendWaitingEmailUrl = `${BASE_URL}/applicationStatusManagement/sendWaitingEmail`;
export const getApplicationStatusManagementListUrl = `${BASE_URL}/applicationStatusManagement/getApplicationStatusManagementList`;

// Vendor
// Vendor registration
export const getVendorRegistrationListUrl = `${BASE_URL}/vendorRegistration/getVendorRegistrationList`;
export const addVendorRegistrationUrl = `${BASE_URL}/vendorRegistration/addVendorRegistration`;
export const updateVendorRegistrationUrl = `${BASE_URL}/vendorRegistration/updateVendorRegistration`;
export const deleteVendorRegistrationUrl = `${BASE_URL}/vendorRegistration/deleteVendorRegistration`;
export const detailsVendorRegistrationUrl = `${BASE_URL}/vendorRegistration/detailsVendorRegistration`;

// Vendor Allocation
export const addUpdateVendorAllocationUrl = `${BASE_URL}/vendorAllocation/addUpdateVendorAllocation`;
export const getVendorAllocationDetailsUrl = `${BASE_URL}/vendorAllocation/getVendorAllocationDetails`;
export const getHallStallListUrl = `${BASE_URL}/vendorAllocation/getHallStallList`;
export const deleteVendorAllocationUrl = `${BASE_URL}/vendorAllocation/deleteVendorAllocation`;

// area management
export const updateAreaManagementUrl = `${BASE_URL}/areaManagement/areaManagementUpdate`;
export const getAreaManagementListUrl = `${BASE_URL}/areaManagement/getAreaManagementList`;

// application History
export const getApplicationHistoryListUrl = `${BASE_URL}/applicationHistory/getApplicationHistoryList`;

//chapter allocation
export const chapterAllocationUrl = `${BASE_URL}/chapterAllocation/chapterAllocation`;


// Exhibitor Module
// exhibition dashboard
export const exhibitionDashboardUrl = `${BASE_URL}/exhibitorDashboard/getExhibitorDashboard`;
export const exhibitionDrawerDetailsUrl = `${BASE_URL}/exhibitorDashboard/getDrawerDetails`;
export const uploadUndertakingUrl = `${BASE_URL}/exhibitorDashboard/uploadUndertaking`;
export const insertExhibitorBarcodeUrl = `${BASE_URL}/exhibitorDashboard/insertExhibitorBarcode`;
// Space Booking
export const getSpaceBookingDetailsUrl = `${BASE_URL}/spaceBooking/getRegistrationDetails`;
// edit space Booking
export const editSpaceBookingUrl = `${BASE_URL}/spaceBooking/updatespacebooking`
//select Application
export const selectApplicationUrl = `${BASE_URL}/application/selectApplication`
// exh tax invoice
export const getExhTaxInvoiceListUrl = `${BASE_URL}/exhTaxInvoice/exhTaxInvoiceList`
//select exhibition
export const selectExhibitionUrl = `${BASE_URL}/exhibition/selectExhibition`;
// ExhibitionTimeline
export const getExhibitionTimelineUrl = `${BASE_URL}/exhibitionTimeline/getExhibitionTimeline`;
// accounts
export const getOldPaymentListUrl = `${BASE_URL}/account/getOldPaymentList`;
export const getProformaListUrl = `${BASE_URL}/account/getProformaInvList`;
export const insertPaymentUrl = `${BASE_URL}/account/addPayment`;
export const getPaymentDetailsUrl = `${BASE_URL}/account/getPaymentDetails`;
export const updatePaymentUrl = `${BASE_URL}/account/updatePayment`;

// Exhibitor Circular
export const getExhCircularListUrl = `${BASE_URL}/exhCircular/getExhCircularList`;
// Visitor Circular
export const getExhVisitorCircularListUrl = `${BASE_URL}/exhCircular/getExhVisitorCircularList`;

// Prpforma Inv 
export const getProformaInvListUrl = `${BASE_URL}/ProformaInv/getProformaInvList`;
export const proformaShareUrl = `${BASE_URL}/ProformaInv/proformaShare`;

// Exhibitor List
export const getExhibitorListUrl = `${BASE_URL}/exhibitorList/getExhibitorList`;
export const ExhibitorDetailsUrl = `${BASE_URL}/Exhibitorlist/getExhibitorDetails`;
export const generatePriorityNoUrl = `${BASE_URL}/Exhibitorlist/generatePriorityNo`
export const resendPriorityNoUrl = `${BASE_URL}/Exhibitorlist/resendPriorityNo`
export const submitManualPriorityNoUrl = `${BASE_URL}/Exhibitorlist/submitManualPriorityNo`;
export const submitAutoPriorityNoUrl = `${BASE_URL}/Exhibitorlist/submitAutoPriorityNo`;
export const submitDiscountUrl = `${BASE_URL}/Exhibitorlist/submitDiscount`;
export const submitOpenSidePIUrl = `${BASE_URL}/Exhibitorlist/submitOpenSidePI`;
export const updateStallTypeAreaUrl = `${BASE_URL}/Exhibitorlist/updateStallTypeArea`;
export const sendUserCredUrl = `${BASE_URL}/Exhibitorlist/sendCred`;
export const sendhallStallCredUrl = `${BASE_URL}/Exhibitorlist/sendhallStallCred`;
export const getExhRegListUrl = `${BASE_URL}/Exhibitorlist/getExhRegList`;
export const generateHallNoStallNoUrl = `${BASE_URL}/Exhibitorlist/generateHallNoStallNo`;
export const addEdirectoryManualUrl = `${BASE_URL}/Exhibitorlist/addEdirectoryManual`;
export const addFasciaManualUrl = `${BASE_URL}/Exhibitorlist/addFasciaManual`;
export const activateDeactivateExhibitorsUrl = `${BASE_URL}/Exhibitorlist/activateDeactivateExhibitors`;
export const generateOpenSideUrl = `${BASE_URL}/Exhibitorlist/generateOpenSide`;
export const SSIDiscountApproveUrl = `${BASE_URL}/Exhibitorlist/SSIDiscountApprove`;
export const generate2ndPIUrl = `${BASE_URL}/taxInvoice/createSecondPI`;


//account exhibitor list
export const getAccountExhibitorListUrl = `${BASE_URL}/taxInvoice/getAccountExhibitorList`;
// export const ExhibitorDetailsUrl = `${BASE_URL}/Exhibitorlist/getExhibitorDetails`;
// export const generatePriorityNoUrl = `${BASE_URL}/Exhibitorlist/generatePriorityNo`
// export const submitManualPriorityNoUrl = `${BASE_URL}/Exhibitorlist/submitManualPriorityNo`;
// export const submitAutoPriorityNoUrl = `${BASE_URL}/Exhibitorlist/submitAutoPriorityNo`;
// export const sendUserCredUrl = `${BASE_URL}/Exhibitorlist/sendCred`;
// export const getExhRegListUrl = `${BASE_URL}/Exhibitorlist/getExhRegList`;
// export const generateHallNoStallNoUrl = `${BASE_URL}/Exhibitorlist/generateHallNoStallNo`;
// export const generateOpenSideUrl = `${BASE_URL}/Exhibitorlist/generateOpenSide`;

// Features 
export const getCheckedExhibitorsUrl = `${BASE_URL}/Features/getCheckedExhibitors`;
export const insertUpdateFeaturesUrl = `${BASE_URL}/Features/insertUpdateFeatures`;

// Visitor List
export const getVisitorListUrl = `${BASE_URL}/visitorList/getVisitorList`;
export const getVisitorBadgeAccessLogUrl = `${BASE_URL}/visitorList/getVisitorBadgeAccessLog`;
export const sendUserCredVisUrl = `${BASE_URL}/visitorList/sendCred`;
export const sendVisitorBadgeUrl = `${BASE_URL}/visitorList/sendVisitorBadge`;
export const insertVisitorBadgeAccessLogUrl = `${BASE_URL}/visitorList/insertVisitorBadgeAccessLog`;

// Payment
export const paymentUrl = `${BASE_URL}/visitor/sendPaymentForm`;

// Manual Forms
export const getManualFormsUrl = `${BASE_URL}/manualForm/getManualFromList`

// Keyword Information
export const getKeywordAndInformationListUrl = `${BASE_URL}/keywordAndInformation/getKeywordAndInformationList`
export const insertUpdateKeywordAndInformationUrl = `${BASE_URL}/keywordAndInformation/insertUpdateKeywordAndInformation`
export const deleteKeywordAndInformationUrl = `${BASE_URL}/keywordAndInformation/deleteKeywordAndInformation`

//power connection
export const getPowerConnectionListUrl = `${BASE_URL}/powerConnection/getPowerConnectionList`
export const getPowerConnectionDetailsUrl = `${BASE_URL}/powerConnection/getPowerConnectionDetails`
export const insertUpdatePowerConnectionUrl = `${BASE_URL}/powerConnection/submitPowerConnection`

//water connection
export const getWaterDetailsUrl = `${BASE_URL}/water/getWaterDetails`
export const insertUpdateWaterUrl = `${BASE_URL}/water/insertUpdateWater`
//water connection
export const getVisitorMeetingListUrl = `${BASE_URL}/visitorMeeting/getVisitorMeetingList`
export const insertUpdateVisitorMeetingUrl = `${BASE_URL}/visitorMeeting/insertUpdateVisitorMeeting`

// car passess application
export const insertUpdateCarPassessApplicationUrl = `${BASE_URL}/carPassessApplication/insertUpdateCarPassessApplication`
export const getCarPassessApplicationDetailsUrl = `${BASE_URL}/carPassessApplication/getCarPassessApplicationDetails`

// catering Services
export const insertUpdateCateringServicesUrl = `${BASE_URL}/cateringServices/insertUpdateCateringServices`
export const getCateringServicesDetailsUrl = `${BASE_URL}/cateringServices/getCateringServicesDetails`

// frieght forward
export const insertUpdateFrieghtForwardUrl = `${BASE_URL}/frieghtForward/insertUpdateFreightForward`
export const getFrieghtForwardDetailsUrl = `${BASE_URL}/frieghtForward/getFreightForwardDetails`

// exhibitor Contractor Badge ApllicationRoute
export const insertUpdateExhibitorContractorBadgeApplicationUrl = `${BASE_URL}/exhibitorContractorBadgeApllication/insertUpdateExhibitorContractorBadgeApplication`
export const getExhibitorContractorBadgeApplicationDetailsUrl = `${BASE_URL}/exhibitorContractorBadgeApllication/getExhibitorContractorBadgeApplicationDetails`

// Liquor License
export const insertUpdateLiquorLicenseUrl = `${BASE_URL}/liqaureLicense/insertUpdateLiqaureLicense`
export const getLiquorLicenseDetailsUrl = `${BASE_URL}/liqaureLicense/getLiquareLicenseDetails`

// delegate meetingg
export const getDefaultDelegateMeetingUrl = `${BASE_URL}/delegateMeeting/getDefaultDelegateMeeting`
export const getDelegateMeetingDetailsUrl = `${BASE_URL}/delegateMeeting/getDelegateMeetingDetails`
export const insertUpdateDelegateMeetingUrl = `${BASE_URL}/delegateMeeting/insertUpdateDelegateMeeting`

// lead Retrieval  form application
export const insertUpdateLeadRetrievalFormUrl = `${BASE_URL}/leadRetrievalForm/insertUpdateLeadRetrieval`
export const getLeadRetrievalFormDetailsUrl = `${BASE_URL}/leadRetrievalForm/getLeadRetrievalDetails`

// meeeting Room
export const getMeetingRoomDetailsUrl = `${BASE_URL}/meeetingRoom/getMeetingRoomDetails`
export const insertUpdateMeetingRoomUrl = `${BASE_URL}/meeetingRoom/insertUpdateMeetingRoom`

// internet Connection
export const getInternetConnectionDetailsUrl = `${BASE_URL}/internetConnection/getInternetConnectionDetails`
export const insertUpdateInternetConnectionUrl = `${BASE_URL}/internetConnection/insertUpdateInternetConnection`

// house Keeping 
export const getHouseKeepingDefaultDetailsUrl = `${BASE_URL}/houseKeeping/getHouseKeepingDefaultDetails`
export const getHouseKeepingDetailsUrl = `${BASE_URL}/houseKeeping/getHouseKeepingDetails`
export const insertUpdateHouseKeepingUrl = `${BASE_URL}/houseKeeping/insertUpdateHouseKeeping`

// security application
export const getSecurityApplicationDetailsUrl = `${BASE_URL}/securityApplication/getSecurityApplicationDetails`
export const insertUpdateSecurityApplicationUrl = `${BASE_URL}/securityApplication/insertUpdateSecurityApplication`

//Host and hostess
export const getHostAndHostessDetailsUrl = `${BASE_URL}/hostAndHostess/getHostAndHostessDetails`
export const insertUpdateHostAndHostessUrl = `${BASE_URL}/hostAndHostess/insertUpdateHostAndHostess`

//Branding Opportunity
export const getBrandingOpportunityDetailsUrl = `${BASE_URL}/brandingOpportunity/getBrandingOpportunityDetails`
export const insertUpdateBrandingOpportunityUrl = `${BASE_URL}/brandingOpportunity/insertUpdateBrandingOpportunity`

//Air Connection Application
export const getAirConnectionDetailsUrl = `${BASE_URL}/airConnection/getAirConnectionDetails`
export const insertUpdateAirConnectionUrl = `${BASE_URL}/airConnection/insertUpdateAirConnection`

//Rental Audio And Video
export const getRentalAudioAndVideoDetailsUrl = `${BASE_URL}/RentalAudioAndVideo/getRentalAudioAndVideoDetails`
export const insertUpdateRentalAudioAndVideoUrl = `${BASE_URL}/RentalAudioAndVideo/insertUpdateRentalAudioAndVideo`

//Stall Design
export const getStallDesignDetailsUrl = `${BASE_URL}/stallDesign/getStallDesignDetails`
export const insertUpdateStallDesignUrl = `${BASE_URL}/stallDesign/insertUpdateStallDesign`

// Manual From Report
export const getAirConnetionManualFormReportUrl = `${BASE_URL}/manualFormReport/getAirFormReport`
export const getWaterConnetionManualFormReportUrl = `${BASE_URL}/manualFormReport/getWaterFormReport`
export const getPowerConnetionManualFormReportUrl = `${BASE_URL}/manualFormReport/getPowerFormReport`
export const getFreightForwardersManualFormReportUrl = `${BASE_URL}/manualFormReport/getFreightForwardersFormReport`
export const getFasciaFormManualReportUrl = `${BASE_URL}/manualFormReport/getFasciaFormReport`
export const getEDirectoryFormReportUrl = `${BASE_URL}/manualFormReport/getEDirectoryFormReport`
export const getMeetingRoomFormReportUrl = `${BASE_URL}/manualFormReport/getMeetingRoomFormReport`
export const getExhBadgesFormReportUrl = `${BASE_URL}/manualFormReport/getExhBadgesFormReport`
export const getExhibitDetailFormReportUrl = `${BASE_URL}/manualFormReport/getExhibitDetailFormReport`
export const getExhContractorBadgeFormReportUrl = `${BASE_URL}/manualFormReport/getExhContractorBadgeFormReport`
export const getStallBookingSummaryFormReportUrl = `${BASE_URL}/manualFormReport/getStallBookingSummaryFormReport`
export const getStallDesignFormFormReportUrl = `${BASE_URL}/manualFormReport/getStallDesignFormFormReport`
export const getVisitorBadgeFormFormReportUrl = `${BASE_URL}/manualFormReport/getVisitorBadgeFormFormReport`
export const getStallPossessionFormReportUrl = `${BASE_URL}/manualFormReport/getStallPossessionFormReport`
export const getVisaApplicationFormReportUrl = `${BASE_URL}/manualFormReport/getVisaApplicationFormReport`

// Fascia Form 
export const insertUpdateFasciaUrl = `${BASE_URL}/fasciaForm/inserUpdateFascia`
export const addFasciaFormUrl = `${BASE_URL}/fasciaForm/addFascia`
export const updateFasciaFormUrl = `${BASE_URL}/fasciaForm/updateFascia`
export const getFasciaFormDetailsUrl = `${BASE_URL}/fasciaForm/getFasciaDetails`
export const getDefaultFasciaDataUrl = `${BASE_URL}/fasciaForm/getDefaultFasciaData`
// Exhibitor Badge
export const getExhibitorBadgeUrl = `${BASE_URL}/exhibitorBadges/fetchExhibitorBadgesList`
export const addExhibitorBadgeUrl = `${BASE_URL}/exhibitorBadges/addExhibitorBadges`
export const submitExhBadgesUrl = `${BASE_URL}/exhibitorBadges/submitExhBadges`
export const getExhibitorBadgeDetailsUrl = `${BASE_URL}/exhibitorBadges/getExhibitorBadgesDetails`
export const updateExhibitionBadgeUrl = `${BASE_URL}/exhibitorBadges/updateExhibitorBadges`
export const delExhibitorBadgeUrl = `${BASE_URL}/exhibitorBadges/deleteExhibitorBadges`
export const delExBadgeDocUrl = `${BASE_URL}/exhibitorBadges/deleteExBadgeDoc`

// E-Directory Form
export const getEdirectoryListUrl = `${BASE_URL}/eDirectory/getEDirectoryList`
export const delDirectoryUrl = `${BASE_URL}/eDirectory/delDirectory`
export const addEDirectoryUrl = `${BASE_URL}/eDirectory/addEDirectory`
export const getEDirectoryDetailsUrl = `${BASE_URL}/eDirectory/getEDirectoryDetails`
export const updateEDirectoryUrl = `${BASE_URL}/eDirectory/updateEDirectory`
export const submitEDirectoryListUrl = `${BASE_URL}/eDirectory/submitEDirectoryList`

// visa Application
export const getVisaApplicationListUrl = `${BASE_URL}/visaApplication/fetchVisaApplicationList`
export const addVisaApplicationUrl = `${BASE_URL}/visaApplication/addVisaApplication`
export const submitVisaApplicationUrl = `${BASE_URL}/visaApplication/submitVisaApplication`
export const getVisaApplicationDetailsUrl = `${BASE_URL}/visaApplication/getVisaApplicationDetails`
export const updateVisaApplicationUrl = `${BASE_URL}/visaApplication/updateVisaApplication`
export const deleteVisaApplicationUrl = `${BASE_URL}/visaApplication/deleteVisaApplication`
export const deleteVisaFilePathUrl = `${BASE_URL}/visaApplication/deleteFilePath`

// Stall Possession
export const getStallPossessionUrl = `${BASE_URL}/stallpossession/getStallPossession`
export const insertStallPossessionUrl = `${BASE_URL}/stallpossession/insertStallPossession`

//Exhibitor details
export const getExhibitorDetailsUrl = `${BASE_URL}/spaceBooking/getRegistrationDetails`;
export const updateExhibitorDetailsUrl = `${BASE_URL}/exhibitorDetails/updateExhibitorDetails`;
// Visitor Module
// Visitor Registration 
export const getVisitorRegistrationDetailsUrl = `${BASE_URL}/visitor/getVisitorRegistrationDetails`
export const getVisitorRegistrationDetails2Url = `${BASE_URL}/visitor/getVisitorDetailsStep2`
export const getCarParkingDetailsUrl = `${BASE_URL}/visitor/getCarParkingDetails`
export const visitorRegistrationNextUrl = `${BASE_URL}/visitor/visitorRegistrationNextSubmit`
export const visitorRegistrationSubmitUrl = `${BASE_URL}/visitor/visitorRegistrationFinalSubmit`
export const verifyVisitorEmailUrl = `${BASE_URL}/visitor/verifyVisitorEmail`
export const visitorPreviewSubmitUrl = `${BASE_URL}/visitor/visitorPreviewSubmit`
export const visitorRegistrationGroupNextSubmitUrl = `${BASE_URL}/visitor/visitorRegistrationGroupNextSubmit`
export const visitorGroupRegistrationFinalSubmitUrl = `${BASE_URL}/visitor/visitorGroupRegistrationFinalSubmit`
// Visitor Dashboard
export const getVisitorDashboardUrl = `${BASE_URL}/visitorDashboard/getVisitorDashboard`
export const getVisitorRegistrationCredUrl = `${BASE_URL}/visitorDashboard/getVisitorRegistrationCredetials`
// Visitor Form 
export const getCombineVisitorRegistrationDetailsUrl = `${BASE_URL}/visitorForm/visitorRegistrationDetail`
export const visitorRegistrationUpdateUrl = `${BASE_URL}/visitorForm/visitorRegistrationUpdate`
export const deleteVisaDocUrl = `${BASE_URL}/visitorForm/delVisaDoc`

// partner Registration 
export const verifyPartnerCodeUrl = `${BASE_URL}/partnerRegistration/verifyPartnerCode`
export const getPartnerStaticDataUrl = `${BASE_URL}/partnerRegistration/getPartnerStaticData`
export const sendPartnerRegistrationFormUrl = `${BASE_URL}/partnerRegistration/sendPartnerRegistrationForm`

export const getPartnerApplicationDetailsUrl = `${BASE_URL}/partnerApplication/getPartnerApplicationDetails`
export const updatePartnerApplicationFormUrl = `${BASE_URL}/partnerApplication/updatePartnerApplicationForm`
export const partnerApplicationDeleteDocUrl = `${BASE_URL}/partnerApplication/deleteDoc`

//manual form approval admin panel
// tally
export const getAccountTallyListUrl = `${BASE_URL}/accountTally/getAccountTallyList`

// Visitor Registration link
export const visitorRegistrationLink = `${link_BASE_URL}visitorregistration/1`

// vendor module
export const getVendorExhibitionListListUrl = `${BASE_URL}/vendorExhibitionList/getVendorExhibitionList`


//  platform  name  const platform = window.navigator.platform
export const platform = window.navigator.platform


//get user manual pdf download...............
export const userManualDownloadUrl = `${BASE_URL}/userManualDownload`;

export const headers = {
  'Accept': 'application/json, text/plain, */*',  // It can be used to overcome cors errors
  'Content-Type': 'application/json',
}

// registrationDeskListUrl, platform, REGISTRATION_DESK_LIST_ACTION_TYPE,

//action type start.....................
//dashboard
export const DASHBOARD_ACTION_TYPE = "DASHBOARD_ACTION_TYPE";
export const GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE = "GET_ADMIN_DASHBOARD_DATA_ACTION_TYPE"
export const GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE = "GET_ADMIN_APPLICATION_SUMMARY_LIST_ACTION_TYPE"
export const GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE = "GET_ADMIN_AREA_SUMMARY_LIST_ACTION_TYPE"
export const GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE = "GET_ADMIN_PAYMENT_SUMMARY_LIST_ACTION_TYPE"
export const GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE = "GET_ADMIN_ADDITIONAL_SUMMARY_LIST_ACTION_TYPE"
export const GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE = "GET_ADMIN_PARTICIPANT_SUMMARY_LIST_ACTION_TYPE"

//master
//exhibition
export const GET_EXHIBITION_MASTER_LIST = "GET_EXHIBITION_MASTER_LIST";
export const ADD_EXHIBITION_MASTER = "ADD_EXHIBITION_MASTER";
export const UPDATE_EXHIBITION_MASTER = "UPDATE_EXHIBITION_MASTER";
export const DELETE_EXHIBITION_MASTER = "DELETE_EXHIBITION_MASTER";
export const DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE = "DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE"
export const GET_EXHIBITION_MASTER_DETAILS = "GET_EXHIBITION_MASTER_DETAILS";
export const CLEAR_EXHIBITION_MASTER_DATA_DETAILS = "CLEAR_EXHIBITION_MASTER_DATA_DETAILS";
//role
export const GET_ROLE_MASTER_LIST = "GET_ROLE_MASTER_LIST";
export const ADD_ROLE_MASTER = "ADD_ROLE_MASTER";
export const UPDATE_ROLE_MASTER = "UPDATE_ROLE_MASTER";
export const DELETE_ROLE_MASTER = "DELETE_ROLE_MASTER";
export const GET_ROLE_MASTER_DETAILS = "GET_ROLE_MASTER_DETAILS";
export const GET_ROLE_MASTER_DETAILS_DEFAULT = 'UPDATE_ROLE_MASTER';

//manage area
export const GET_MANAGE_AREA_LIST = "GET_MANAGE_AREA_LIST";
export const UPDATE_MANAGE_AREA = "UPDATE_MANAGE_AREA";
export const ADD_MANAGE_AREA_STALL = "ADD_MANAGE_AREA_STALL";

//congifration
export const UPDATE_EXHIBITION_CONFIGRATION = "UPDATE_EXHIBITION_CONFIGRATION";
export const GET_EXHIBITION_CONFIGRATION_DETAILS = "GET_EXHIBITION_CONFIGRATION_DETAILS";

export const UPDATE_PAYMENT_CONFIG = "UPDATE_PAYMENT_CONFIG";
//Email Congifration
export const INSERT_UPDATE_EMAIL_CONFIGRATION = "INSERT_UPDATE_EMAIL_CONFIGRATION";
export const GET_EMAIL_CONFIGRATION_DETAILS = "GET_EMAIL_CONFIGRATION_DETAILS";

//Exhibition Note
export const INSERT_UPDATE_EXH_NOTE_CONFIGRATION = "INSERT_UPDATE_EXH_NOTE_CONFIGRATION";
export const GET_EXH_NOTE_DETAILS = "GET_EXH_NOTE_DETAILS";

// vendor
// vendor Registartion
export const GET_VENDOR_REGISTRATION_LIST = "GET_VENDOR_REGISTRATION_LIST";
export const ADD_VENDOR_REGISTRATION = "ADD_VENDOR_REGISTRATION";
export const UPDATE_VENDOR_REGISTRATION = "UPDATE_VENDOR_REGISTRATION";
export const DELETE_VENDOR_REGISTRATION = "DELETE_VENDOR_REGISTRATION";
export const DETAILS_VENDOR_REGISTRATION = "DETAILS_VENDOR_REGISTRATION";

// Vendor Allocation
export const ADD_UPDATE_VENDOR_ALLOCATION = "ADD_UPDATE_VENDOR_ALLOCATION";
export const GET_DETAILS_VENDOR_ALLOCATION = "GET_DETAILS_VENDOR_ALLOCATION";
export const GET_HALL_STALL_LIST_ALLOCATION = "GET_HALL_STALL_LIST_ALLOCATION";
export const DELETE_VENDOR_ALLOCATION = "DELETE_VENDOR_ALLOCATION";

//manual Forms master
export const GET_MANUAL_FORMS_MASTER_LIST = "GET_MANUAL_FORMS_MASTER_LIST";
export const DELETE_MANUAL_FORMS_MASTER = "GET_MANUAL_FORMS_MASTER";
export const INSERT_UPDATE_MANUAL_FORMS_MASTER = "INSERT_UPDATE_MANUAL_FORMS_MASTER";


//delegate meeting
export const GET_DEFAULT_DELEGATE_MEETING = "GET_DEFAULT_DELEGATE_MEETING";
export const GET_DELEGATE_MEETING_DETAILS = "GET_DELEGATE_MEETING_DETAILS";
export const INSERT_UPDATE_DELEGATE_MEETING = "INSERT_UPDATE_DELEGATE_MEETING";

//hallmaster
export const GET_HALL_MASTER_LIST = "GET_HALL_MASTER_LIST";
export const INSERT_UPDATE_HALL_MASTER = "INSERT_UPDATE_HALL_MASTER";
export const DELETE_HALL_MASTER = "DELETE_HALL_MASTER";
export const GET_HALL_MASTER_DETAILS = "GET_HALL_MASTER_DETAILS";

//stallmaster
export const GET_STALL_MASTER_LIST = "GET_STALL_MASTER_LIST";
export const INSERT_UPDATE_STALL_MASTER = "INSERT_UPDATE_STALL_MASTER";
export const DELETE_STALL_MASTER = "DELETE_STALL_MASTER";
export const GET_STALL_MASTER_DETAILS = "GET_STALL_MASTER_DETAILS";

//gst
export const GET_GST_MASTER_LIST = "GET_GST_MASTER_LIST";
export const INSERT_UPDATE_GST_MASTER = "INSERT_UPDATE_GST_MASTER";
export const DELETE_GST_MASTER = "DELETE_GST_MASTER";
// export const GET_GST_MASTER_DETAILS = "GET_GST_MASTER_DETAILS";

//state
export const GET_STATE_MASTER_LIST = "GET_STATE_MASTER_LIST";
export const INSERT_STATE_MASTER = "INSERT_STATE_MASTER";
export const DELETE_STATE_MASTER = "DELETE_STATE_MASTER";
export const UPDATE_STATE_MASTER = "UPDATE_STATE_MASTER";

//city
export const GET_CITY_MASTER_LIST = "GET_CITY_MASTER_LIST";
export const INSERT_CITY_MASTER = "INSERT_CITY_MASTER";
export const DELETE_CITY_MASTER = "DELETE_CITY_MASTER";
export const UPDATE_CITY_MASTER = "UPDATE_CITY_MASTER";


//IS
export const INSERT_UPDATE_INDUSTRY_SEGMENT_MASTER = "INSERT_UPDATE_INDUSTRY_SEGMENT_MASTER";
// export const INSERT_UPDATE_GST_MASTER = "INSERT_UPDATE_GST_MASTER";

// POV
export const INSERT_UPDATE_PURPOSE_OF_VISIT_MASTER = "INSERT_UPDATE_PURPOSE_OF_VISIT_MASTER";
// export const INSERT_UPDATE_GST_MASTER = "INSERT_UPDATE_GST_MASTER";

//OP
export const GET_ONLINE_PRESENCE_MASTER_LIST = "GET_ONLINE_PRESENCE_MASTER_LIST";
export const INSERT_UPDATE_ONLINE_PRESENCE_MASTER = "INSERT_UPDATE_ONLINE_PRESENCE_MASTER";
export const DELETE_ONLINE_PRESENCE_MASTER = "DELETE_ONLINE_PRESENCE_MASTER";
export const GET_ONLINE_PRESENCE_MASTER_DETAILS = "GET_ONLINE_PRESENCE_MASTER_DETAILS";

//bankdetaisl
export const GET_BANK_DETAILS_MASTER_LIST = "GET_BANK_DETAILS_MASTER_LIST";
export const INSERT_UPDATE_BANK_DETAILS_MASTER = "INSERT_UPDATE_BANK_DETAILS_MASTER";
export const DELETE_BANK_DETAILS_MASTER = "DELETE_BANK_DETAILS_MASTER";
// export const GET_BANK_DETAILS_MASTER_DETAILS = "GET_GST_MASTER_DETAILS";

// export const UPDATE_MANAGE_AREA = "UPDATE_MANAGE_AREA";
// timeline
export const GET_TIMELINE_MASTER_LIST = "GET_TIMELINE_MASTER_LIST";
export const INSERT_TIMELINE_MASTER_LIST = "INSERT_TIMELINE_MASTER_LIST";
export const UPDATE_TIMELINE_MASTER_LIST = "UPDATE_TIMELINE_MASTER_LIST";
export const DELETE_TIMELINE_MASTER_LIST = "DELETE_TIMELINE_MASTER_LIST";
//chapter
export const ADD_CHAPTERS_MASTER = "ADD_CHAPTERS_MASTER";
export const UPDATE_CHAPTERS_MASTER = "UPDATE_CHAPTERS_MASTER";
export const DELETE_CHAPTERS_MASTER = "DELETE_CHAPTERS_MASTER";
// LL master
export const GET_LIQUOR_LICENSE_MASTER_LIST = "GET_LIQUOR_LICENSE_MASTER_LIST";
export const INSERT_UPDATE_LIQUOR_LICENSE_MASTER = "INSERT_UPDATE_LIQUOR_LICENSE_MASTER";
export const DELETE_LIQUOR_LICENSE_MASTER = "DELETE_LIQUOR_LICENSE_MASTER";
// Lead retrival connection  master
export const GET_LEAD_RETRIVAL_CONNECTION_MASTER_LIST = "GET_LEAD_RETRIVAL_CONNECTION_MASTER_LIST";
export const INSERT_UPDATE_LEAD_RETRIVAL_CONNECTION_MASTER = "INSERT_UPDATE_LEAD_RETRIVAL_CONNECTION_MASTER";
export const DELETE_LEAD_RETRIVAL_CONNECTION_MASTER = "DELETE_LEAD_RETRIVAL_CONNECTION_MASTER";
// H&H  master
export const GET_HOST_AND_HOSTESS_MASTER_LIST = "GET_HOST_AND_HOSTESS_MASTER_LIST";
export const INSERT_UPDATE_HOST_AND_HOSTESS_MASTER = "INSERT_UPDATE_HOST_AND_HOSTESS_MASTER";
export const DELETE_HOST_AND_HOSTESS_MASTER = "DELETE_HOST_AND_HOSTESS_MASTER";
//visitor master
export const GET_VISITOR_MEETING_MASTER_LIST = "GET_VISITOR_MEETING_MASTER_LIST";
export const INSERT_UPDATE_VISITOR_MEETING_MASTER = "INSERT_UPDATE_VISITOR_MEETING_MASTER";
export const DELETE_VISITOR_MEETING_MASTER = "DELETE_VISITOR_MEETING_MASTER";
//rental av master
export const GET_RENTAL_AUDIO_VIDEO_MASTER_LIST = "GET_RENTAL_AUDIO_VIDEO_MASTER_LIST";
export const INSERT_UPDATE_RENTAL_AUDIO_VIDEO_MASTER = "INSERT_UPDATE_RENTAL_AUDIO_VIDEO_MASTER";
export const DELETE_RENTAL_AUDIO_VIDEO_MASTER = "DELETE_RENTAL_AUDIO_VIDEO_MASTER";
//catering services master
export const GET_CATERING_SERVICES_MASTER_LIST = "GET_CATERING_SERVICES_MASTER_LIST";
export const INSERT_UPDATE_CATERING_SERVICES_MASTER = "INSERT_UPDATE_CATERING_SERVICES_MASTER";
export const DELETE_CATERING_SERVICES_MASTER = "DELETE_CATERING_SERVICES_MASTER";
//house keeping aaplication master
export const GET_HOUSEKEEPING_APPLICATION_MASTER_LIST = "GET_HOUSEKEEPING_APPLICATION_MASTER_LIST";
export const INSERT_UPDATE_HOUSEKEEPING_APPLICATION_MASTER = "INSERT_UPDATE_HOUSEKEEPING_APPLICATION_MASTER";
export const DELETE_HOUSEKEEPING_APPLICATION_MASTER = "DELETE_HOUSEKEEPING_APPLICATION_MASTER";
//ICmaster
export const GET_INTERNET_CONNECTION_MASTER_LIST = "GET_INTERNET_CONNECTION_MASTER_LIST";
export const INSERT_UPDATE_INTERNET_CONNECTION_MASTER = "INSERT_UPDATE_INTERNET_CONNECTION_MASTER";
export const DELETE_INTERNET_CONNECTION_MASTER = "DELETE_INTERNET_CONNECTION_MASTER";
//security application master
export const GET_SECURITY_APPLICATION_MASTER_LIST = "GET_SECURITY_APPLICATION_MASTER_LIST";
export const INSERT_UPDATE_SECURITY_APPLICATION_MASTER = "INSERT_UPDATE_SECURITY_APPLICATION_MASTER";
export const DELETE_SECURITY_APPLICATION_MASTER = "DELETE_SECURITY_APPLICATION_MASTER";
//delegate meeting master
export const GET_DELAGATE_MEETING_MASTER_LIST = "GET_DELAGATE_MEETING_MASTER_LIST";
export const INSERT_UPDATE_DELAGATE_MEETING_MASTER = "INSERT_UPDATE_DELAGATE_MEETING_MASTER";
export const DELETE_DELAGATE_MEETING_MASTER = "DELETE_DELAGATE_MEETING_MASTER";
//meeting room master
export const GET_MEETING_ROOM_MASTER_LIST = "GET_MEETING_ROOM_MASTER_LIST";
export const INSERT_UPDATE_MEETING_ROOM_MASTER = "INSERT_UPDATE_MEETING_ROOM_MASTER";
export const DELETE_MEETING_ROOM_MASTER = "DELETE_MEETING_ROOM_MASTER";
//br opp master
export const GET_BRANDING_OPPORTUNITY_MASTER_LIST = "GET_BRANDING_OPPORTUNITY_MASTER_LIST";
export const INSERT_UPDATE_BRANDING_OPPORTUNITY_MASTER = "INSERT_UPDATE_BRANDING_OPPORTUNITY_MASTER";
export const DELETE_BRANDING_OPPORTUNITY_MASTER = "DELETE_BRANDING_OPPORTUNITY_MASTER";


// login
export const LOGIN_ACTION_TYPE = "LOGIN";
export const CHANGE_PASSWORD_ACTION_TYPE = "CHANGE_PASSWORD_ACTION_TYPE";
export const UPDATE_PASSWORD_ACTION_TYPE = "UPDATE_PASSWORD_ACTION_TYPE";
export const GET_LOGIN_PASS_ACTION_TYPE = "GET_LOGIN_PASS_ACTION_TYPE";
export const FILL_LIST_ACTION_TYPE = "FILL_LIST_ACTION_TYPE";
// forget login password
export const FORGET_CHECKED_EMAIL_ACTION_TYPE = "FORGET_CHECKED_EMAIL_ACTION_TYPE";
export const FORGET_GET_OTP_ACTION_TYPE = "FORGET_GET_OTP_ACTION_TYPE";
export const FORGET_UPDATE_PASSWORD_ACTION_TYPE = "FORGET_UPDATE_PASSWORD_ACTION_TYPE";

// registration 1
export const REGISTRATION_FORM_ADD_ACTION_TYPE = "REGISTRATION_FORM_ADD_ACTION_TYPE";
export const REGISTRATION_FORM_UPDATE_ACTION_TYPE = "REGISTRATION_FORM_UPDATE_ACTION_TYPE";
export const REGISTRATION_DETAILS_ACTION_TYPE = "REGISTRATION_DETAILS_ACTION_TYPE";
export const REGISTRATION_AGENT_DETAILS_ACTION_TYPE = "REGISTRATION_AGENT_DETAILS_ACTION_TYPE";
export const GET_EXCEL_DATA_ACTION_TYPE = "GET_EXCEL_DATA_ACTION_TYPE";
export const REGISTRATION_PENDING_DETAILS_ACTION_TYPE = "REGISTRATION_PENDING_DETAILS_ACTION_TYPE";

//admin report
export const GET_EXHIBITOR_DETAILS_REPORT = "GET_EXHIBITOR_DETAILS_REPORT";
export const GET_RECEIPT_REPORT = "GET_RECEIPT_REPORT";
export const GET_REPORT_LIST = "GET_REPORT_LIST";
export const GET_TAX_INVOICE_REPORT = "GET_TAX_INVOICE_REPORT";


// primary_chapter
export const PRIMARY_CHAPTER_ADD_ACTION_TYPE = "PRIMARY_CHAPTER_ADD_ACTION_TYPE";
export const DELETE_SSI_CERTIFICATE = "DELETE_SSI_CERTIFICATE";
export const DELETE_TMMAITAMMA_CERTIFICATE = "DELETE_TMMAITAMMA_CERTIFICATE";


//description
export const DESCRIPTION_DETAILS_ACTION_TYPE = "DESCRIPTION_DETAILS_ACTION_TYPE";
export const DESCRIPTION_ADD_ACTION_TYPE = "DESCRIPTION_ADD_ACTION_TYPE";
export const DESCRIPTION_UPDATE_ACTION_TYPE = "DESCRIPTION_UPDATE_ACTION_TYPE";
export const DESCRIPTION_TERMSANDCONDITION_ACTION_TYPE = "DESCRIPTION_TERMSANDCONDITION_ACTION_TYPE";

// preview
export const DESCRIPTION_PREVIEW_ACTION_TYPE = "DESCRIPTION_PREVIEW_ACTION_TYPE";
export const PREVIEW_DETAILS_ACTION_TYPE = "PREVIEW_DETAILS_ACTION_TYPE";
export const GET_DOUBLE_STOREY_SSI = "GET_DOUBLE_STOREY_SSI";

// last submit
export const LAST_SUBMIT_ADD_ACTION_TYPE = "LAST_SUBMIT_ADD_ACTION_TYPE";

// Registration Receipt
export const RECEIPT_ENTRY_ACTION_TYPE = "RECEIPT_ENTRY_ACTION_TYPE";
export const RECEIPT_LIST_ACTION_TYPE = "RECEIPT_LIST_ACTION_TYPE";
export const PERFORMA_INVOICE_ACTION_TYPE = "PERFORMA_INVOICE_ACTION_TYPE";
export const RECEIPT_DETAILS_ACTION_TYPE = "RECEIPT_LIST_ACTION_TYPE";
export const RECEIPT_GENERATE_VOUCHER_ACTION_TYPE = "RECEIPT_GENERATE_VOUCHER_ACTION_TYPE";
export const SHARE_EMAIL_ACTION_TYPE = "SHARE_EMAIL_ACTION_TYPE";
export const RECEIPT_NUMBER_ACTION_TYPE = "RECEIPT_NUMBER_ACTION_TYPE";
export const GET_EMAIL_ACTION_TYPE = "GET_EMAIL_ACTION_TYPE";
export const RECEIPT_DEL_ACTION_TYPE = "RECEIPT_DEL_ACTION_TYPE";
export const RECEIPT_UPDATE_ACTION_TYPE = "RECEIPT_UPDATE_ACTION_TYPE";
export const PENDING_RECEIPT_DETAILS_ACTION_TYPE = "PENDING_RECEIPT_DETAILS_ACTION_TYPE"
export const GET_RECEIPT_PDF_ACTION_TYPE = "GET_RECEIPT_PDF_ACTION_TYPE"
export const RECEIPT_SHARE_ACTION_TYPE = "RECEIPT_SHARE_ACTION_TYPE"

// Agent Receipt
export const AGENT_RECEIPT_ENTRY_ACTION_TYPE = "AGENT_RECEIPT_ENTRY_ACTION_TYPE";
export const AGENT_RECEIPT_LIST_ACTION_TYPE = "AGENT_RECEIPT_LIST_ACTION_TYPE";
export const AGENT_PERFORMA_INVOICE_ACTION_TYPE = "AGENT_PERFORMA_INVOICE_ACTION_TYPE";
export const AGENT_RECEIPT_DETAILS_ACTION_TYPE = "AGENT_RECEIPT_DETAILS_ACTION_TYPE";
export const AGENT_RECEIPT_GENERATE_VOUCHER_ACTION_TYPE = "AGENT_RECEIPT_GENERATE_VOUCHER_ACTION_TYPE";
// export const SHARE_EMAIL_ACTION_TYPE = "SHARE_EMAIL_ACTION_TYPE";
export const AGENT_RECEIPT_NUMBER_ACTION_TYPE = "AGENT_RECEIPT_NUMBER_ACTION_TYPE";
// export const GET_EMAIL_ACTION_TYPE = "GET_EMAIL_ACTION_TYPE";
export const AGENT_RECEIPT_DEL_ACTION_TYPE = "AGENT_RECEIPT_DEL_ACTION_TYPE";
export const AGENT_RECEIPT_UPDATE_ACTION_TYPE = "AGENT_RECEIPT_UPDATE_ACTION_TYPE";
// export const PENDING_RECEIPT_DETAILS_ACTION_TYPE = "PENDING_RECEIPT_DETAILS_ACTION_TYPE"
// export const GET_RECEIPT_PDF_ACTION_TYPE = "GET_RECEIPT_PDF_ACTION_TYPE"
export const AGENT_RECEIPT_SHARE_ACTION_TYPE = "AGENT_RECEIPT_SHARE_ACTION_TYPE"

//Tax Invoice
export const TAX_INVOICE_LIST_ACTION_TYPE = "TAX_INVOICE_LIST_ACTION_TYPE";
export const TAX_INVOICE_DETAILS_ACTION_TYPE = "TAX_INVOICE_DETAILS_ACTION_TYPE";
export const TAX_INVOICE_MAIL_ACTION_TYPE = "TAX_INVOICE_MAIL_ACTION_TYPE"
export const SEND_NO_DUES_ACTION_TYPE = "SEND_NO_DUES_ACTION_TYPE"
export const CREATE_TAX_INVOICE_ACTION_TYPE = "CREATE_TAX_INVOICE_ACTION_TYPE"
export const GET_STALL_POSSESSION_EXPORT_ACTION_TYPE = "GET_STALL_POSSESSION_EXPORT_ACTION_TYPE"
// Proforma Inv
export const GET_PROFORMA_INV_LIST_ACTION = "GET_PROFORMA_INV_LIST_ACTION"
export const SHARE_PROFORMA_INV_ACTION = "SHARE_PROFORMA_INV_ACTION"

// Agent Registration
export const AGENT_ENTRY_SAVE_ACTION_TYPE = "AGENT_ENTRY_SAVE_ACTION_TYPE"
export const AGENT_ENTRY_UPDATE_ACTION_TYPE = "AGENT_ENTRY_UPDATE_ACTION_TYPE"
export const AGENT_LIST_ACTION_TYPE = "AGENT_LIST_ACTION_TYPE"
export const GET_AGENT_DETAILS_ACTION_TYPE = "GET_AGENT_DETAILS_ACTION_TYPE"
export const VERIFY_AGENT_EMAIL_ACTION_TYPE = "VERIFY_AGENT_EMAIL_ACTION_TYPE"
export const GET_AGENT_DASHBOARD_ACTION_TYPE = "GET_AGENT_DASHBOARD_ACTION_TYPE"
export const GET_EXHIBITOR_DETAIL_ACTION_TYPE = "GET_EXHIBITOR_DETAIL_ACTION_TYPE"
export const INSERT_AGENT_PI_1 = "INSERT_AGENT_PI_1"
export const INSERT_AGENT_PI_2 = "INSERT_AGENT_PI_2"
export const GET_AGENT_DETAILS_FOR_MAIL = "GET_AGENT_DETAILS_FOR_MAIL"
export const SEND_AGENT_MAIL = "SEND_AGENT_MAIL"
export const DOWNLOAD_PERFORMA_INVOICE = "DOWNLOAD_PERFORMA_INVOICE"
// Exhibitor List 
export const GET_EXHIBITOR_LIST_ACTION_TYPE = "GET_EXHIBITOR_LIST_ACTION_TYPE"
export const SSI_DOCUMENT_APPROVAL = "SSI_DOCUMENT_APPROVAL"
export const EXHIBITOR_DETAIL_ACTION_TYPE = "EXHIBITOR_DETAIL_ACTION_TYPE"
export const GENERATE_PRIORITY_NO_ACTION_TYPE = "GENERATE_PRIORITY_NO_ACTION_TYPE"
export const SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE = "SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE"
export const SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE = "SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE"
export const SUBMIT_DISCOUNT_ACTION_TYPE = "SUBMIT_DISCOUNT_ACTION_TYPE"
export const GENERATE_OPEN_SIDE_PI_ACTION_TYPE = "GENERATE_OPEN_SIDE_PI_ACTION_TYPE"
export const SEND_USER_CRED_ACTION_TYPE = "SEND_USER_CRED_ACTION_TYPE"
export const SEND_USER_HALL_STALL_ACTION_TYPE = "SEND_USER_HALL_STALL_ACTION_TYPE"
export const GET_EXH_REG_LIST_ACTION_TYPE = "GET_EXH_REG_LIST_ACTION_TYPE"
export const GENERATE_HALLNO_STALLNO_ACTION_TYPE = "GENERATE_HALLNO_STALLNO_ACTION_TYPE"
export const GENERATE_OPENSIDE_ACTION_TYPE = "GENERATE_OPENSIDE_ACTION_TYPE"
export const ADD_EDIRECTORY_MANUAL_ACTION_TYPE = "ADD_EDIRECTORY_MANUAL_ACTION_TYPE"
export const ADD_FASCIA_MANUAL_ACTION_TYPE = "ADD_FASCIA_MANUAL_ACTION_TYPE"
export const ACTIVATE_DEACTIVATE_EXHIBITORS_ACTION_TYPE = "ACTIVATE_DEACTIVATE_EXHIBITORS_ACTION_TYPE"

// Account Exhibitor List 
export const GET_ACCOUNT_EXHIBITOR_LIST_ACTION_TYPE = "GET_ACCOUNT_EXHIBITOR_LIST_ACTION_TYPE"
export const DOWNLOAD_NODUE = "DOWNLOAD_NODUE"
// export const EXHIBITOR_DETAIL_ACTION_TYPE = "EXHIBITOR_DETAIL_ACTION_TYPE"
// export const GENERATE_PRIORITY_NO_ACTION_TYPE = "GENERATE_PRIORITY_NO_ACTION_TYPE"
// export const SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE = "SUBMIT_MANUAL_PRIORITY_NO_ACTION_TYPE"
// export const SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE = "SUBMIT_AUTO_PRIORITY_NO_ACTION_TYPE"
// export const SEND_USER_CRED_ACTION_TYPE = "SEND_USER_CRED_ACTION_TYPE"
// export const GET_EXH_REG_LIST_ACTION_TYPE = "GET_EXH_REG_LIST_ACTION_TYPE"
// export const GENERATE_HALLNO_STALLNO_ACTION_TYPE = "GENERATE_HALLNO_STALLNO_ACTION_TYPE"
// export const GENERATE_OPENSIDE_ACTION_TYPE = "GENERATE_OPENSIDE_ACTION_TYPE"

// Features
export const GET_CHECKED_EXHIBITORS_ACTION_TYPE = "GET_CHECKED_EXHIBITORS_ACTION_TYPE"
export const INSERT_UPDATE_FEATURES_ACTION_TYPE = "INSERT_UPDATE_FEATURES_ACTION_TYPE"

// Visitor List
export const GET_VISITOR_LIST_ACTION_TYPE = "GET_VISITOR_LIST_ACTION_TYPE"
export const SEND_VISITOR_BADGE_ACTION_TYPE = "SEND_VISITOR_BADGE_ACTION_TYPE"
export const INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE = "INSERT_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE"
export const GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE = "GET_VISITOR_BADGE_ACCESS_LOG_ACTION_TYPE"

// Payment 
export const PAYMENT_ACTION_TYPE = "PAYMENT_ACTION_TYPE"

// Exhibitor Manual Form Summary
export const GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST = "GET_EXHIBITOR_MANUAL_FORM_SUMMARY_LIST"
export const GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT = "GET_EXHIBITOR_MANUAL_FORM_SUMMARY_EXPORT"

// Admin Account
export const GET_PAYMENT_LIST_ACTION_TYPE = "GET_PAYMENT_LIST_ACTION_TYPE";
export const GET_DUE_PAYMENT_LIST_ACTION_TYPE = "GET_DUE_PAYMENT_LIST_ACTION_TYPE";
export const SEND_PAYMENT_FORM = "SEND_PAYMENT_FORM";

// Reminder Email
export const REMINDER_EMAIL_ACTION_TYPE = "REMINDER_EMAIL_ACTION_TYPE";
export const GET_REMINDER_LIST_ACTION_TYPE = "GET_REMINDER_LIST_ACTION_TYPE"

// Circular
export const GET_CIRCULAR_COMPANY_ACTION_TYPE = "GET_CIRCULAR_COMPANY_ACTION_TYPE"
export const GET_CIRCULAR_LIST_ACTION_TYPE = "GET_CIRCULAR_LIST_ACTION_TYPE"
export const INSERT_CIRCULAR_ACTION_TYPE = "INSERT_CIRCULAR_ACTION_TYPE"
export const DEL_CIRCULAR_ACTION_TYPE = "DEL_CIRCULAR_ACTION_TYPE"

// Visitor Circular
export const GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE = "GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE";
export const GET_VISITOR_CIRCULAR_LIST_ACTION_TYPE = "GET_VISITOR_CIRCULAR_ACTION_TYPE";
export const INSERT_VISITOR_CIRCULAR_ACTION_TYPE = "INSERT_VISITOR_CIRCULAR_ACTION_TYPE";
export const DEL_VISITOR_CIRCULAR_ACTION_TYPE = "DEL_VISITOR_CIRCULAR_ACTION_TYPE"
export const ASSIGN_CIRCULAR_ACTION_TYPE = "ASSIGN_CIRCULAR_ACTION_TYPE"

// Admin Manual Forms Approval
// Fascia Approval
export const GET_FASCIA_APPLICATIONS_ACTION_TYPE = "GET_FASCIA_APPLICATIONS_ACTION_TYPE"
export const UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE = "UPDATE_APPROVE_FASCIA_FROM_ACTION_TYPE"
export const GET_FASCIA_EXPORT_ACTION_TYPE = "GET_FASCIA_EXPORT_ACTION_TYPE"
// Exhibitor Badges Approval
export const GET_EXH_BADGES_SUMMARY_ACTION_TYPE = "GET_EXH_BADGES_SUMMARY_ACTION_TYPE"
export const GET_EXH_BADGES_APPLICATION_ACTION_TYPE = "GET_EXH_BADGES_APPLICATION_ACTION_TYPE"
export const UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE = "UPDATE_EXH_BADGES_APPROVAL_ACTION_TYPE"
export const GET_EXH_BADGE_EXPORT_ACTION_TYPE = "GET_EXH_BADGE_EXPORT_ACTION_TYPE"
// E Directory Approval 
export const GET_E_DIRECTORY_SUMMARY_ACTION_TYPE = "GET_E_DIRECTORY_SUMMARY_ACTION_TYPE"
export const GET_DIRECTORY_EXPORT_ACTION_TYPE = "GET_DIRECTORY_EXPORT_ACTION_TYPE"
export const GET_E_DIRECTORY_APPLICATION_ACTION_TYPE = "GET_E_DIRECTORY_APPLICATION_ACTION_TYPE"
export const UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE = "UPDATE_E_DIRECTORY_APPROVAL_ACTION_TYPE"
// Power Approval
export const GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE = "GET_POWER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE = "GET_POWER_CONNECTION_APPROVAL_LIST_ACTION_TYPE"
export const GET_POWER_CONNECTION_EXPORT_ACTION_TYPE = "GET_POWER_CONNECTION_EXPORT_ACTION_TYPE"
export const UPDATE_POWER_APPROVAL_ACTION_TYPE = "UPDATE_POWER_APPROVAL_ACTION_TYPE"

// Visa Approval
export const GET_VISA_INVITATON_ACTION_TYPE = "GET_VISA_INVITATON_ACTION_TYPE"
export const GET_VISA_APPLICATION_ACTION_TYPE = "GET_VISA_APPLICATION_ACTION_TYPE"
export const UPDATE_VISA_APPROVAL_ACTION_TYPE = "UPDATE_VISA_APPROVAL_ACTION_TYPE"
export const GET_VISA_APPLICATION_EXPORT_ACTION_TYPE = "GET_VISA_APPLICATION_EXPORT_ACTION_TYPE"
// Visitor Visa Approval
export const GET_VISITOR_VISA_INVITATION_ACTION_TYPE = "GET_VISITOR_VISA_INVITATION_ACTION_TYPE"
export const GET_VISITOR_VISA_APPLICATION_ACTION_TYPE = "GET_VISITOR_VISA_APPLICATION_ACTION_TYPE"
export const GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE = "GET_DETAILS_VISITOR_VISA_APPLICATION_ACTION_TYPE"
export const UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE = "UPDATE_VISITOR_VISA_APPROVAL_ACTION_TYPE"
export const GET_VISITOR_VISA_APPLIACTION_EXPORT_ACTION_TYPE = "GET_VISITOR_VISA_APPLIACTION_EXPORT_ACTION_TYPE"

// air conection approval
export const GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE = "GET_AIR_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE = "GET_AIR_CONNECTION_APPROVAL_LIST_ACTION_TYPE"
export const GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE = "GET_AIR_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE"
export const UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE = "UPDATE_AIR_CONNECTION_APPROVAL__ACTION_TYPE"

// water conection approval
export const GET_WATER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE = "GET_WATER_CONNECTION_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_WATER_CONNECTION_APPROVAL_LIST_ACTION_TYPE = "GET_WATER_CONNECTION_APPROVAL_LIST_ACTION_TYPE"
export const GET_WATER_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE = "GET_WATER_CONNECTION_APPROVAL_EXPORT_DATA_ACTION_TYPE"
export const UPDATE_WATER_CONNECTION_APPROVAL__ACTION_TYPE = "UPDATE_WATER_CONNECTION_APPROVAL__ACTION_TYPE"

// meeting room approval
export const GET_MEETING_ROOM_APPROVAL_DETAILS_ACTION_TYPE = "GET_MEETING_ROOM_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_MEETING_ROOM_APPROVAL_LIST_ACTION_TYPE = "GET_MEETING_ROOM_APPROVAL_LIST_ACTION_TYPE"
export const GET_MEETING_ROOM_APPROVAL_EXPORT_DATA_ACTION_TYPE = "GET_MEETING_ROOM_APPROVAL_EXPORT_DATA_ACTION_TYPE"
export const UPDATE_MEETING_ROOM_APPROVAL__ACTION_TYPE = "UPDATE_MEETING_ROOM_APPROVAL__ACTION_TYPE"

//Branding Opportunity approval
export const GET_BRANDING_OPPORTUNITY_APPROVAL_DETAILS_ACTION_TYPE = "GET_BRANDING_OPPORTUNITY_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_BRANDING_OPPORTUNITY_APPROVAL_LIST_ACTION_TYPE = "GET_BRANDING_OPPORTUNITY_APPROVAL_LIST_ACTION_TYPE"
export const GET_BRANDING_OPPORTUNITY_APPROVAL_EXPORT_DATA_ACTION_TYPE = "GET_BRANDING_OPPORTUNITY_APPROVAL_EXPORT_DATA_ACTION_TYPE"
export const UPDATE_BRANDING_OPPORTUNITY_APPROVAL__ACTION_TYPE = "UPDATE_BRANDING_OPPORTUNITY_APPROVAL__ACTION_TYPE"

//Stall Design approval
export const GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE = "GET_STALL_DESIGN_APPROVAL_DETAILS_ACTION_TYPE"
export const GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE = "GET_STALL_DESIGN_APPROVAL_LIST_ACTION_TYPE"
export const GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE = "GET_STALL_DESIGN_APPROVAL_EXPORT_DATA_ACTION_TYPE"
export const UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE = "UPDATE_STALL_DESIGN_APPROVAL__ACTION_TYPE"

// Exhibitor Spacebooking in Admin Panel
export const GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE = "GET_EXHIBITOR_SPACEBOOKING_DETAILS_ACTION_TYPE"
export const EXHIBITOR_SPACE_BOOKING_EDIT_ACTION_TYPE = "EXHIBITOR_SPACE_BOOKING_EDIT_ACTION_TYPE"

// exhibition
export const SELECT_EXHIBITION_ACTION_TYPE = "SELECT_EXHIBITION_ACTION_TYPE";
export const GENERATE_VOUCHER_ACTION_TYPE = "GENERATE_VOUCHER_ACTION_TYPE";

// ledger
export const GET_LEDGER_LIST_ACTION_TYPE = "GET_LEDGER_LIST_ACTION_TYPE";
export const SEND_LEDGER_ACTION_TYPE = "SEND_LEDGER_ACTION_TYPE";
export const SEND_OUTSTANDING_ACTION_TYPE = "SEND_OUTSTANDING_ACTION_TYPE";

// manage area
// application status management
export const SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE = "SEND_EMAIL_OF_AVAILABLE_AREA_ACTION_TYPE";
export const MOVE_TO_APPLY_STATUS_ACTION_TYPE = "MOVE_TO_APPLY_STATUS_ACTION_TYPE";
export const SEND_WAITING_EMAIL_ACTION_TYPE = "SEND_WAITING_EMAIL_ACTION_TYPE";
export const GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE = "GET_APPLICATION_STATUS_MANAGEMENT_ACTION_TYPE";

//area management
export const UPDATE_AREA_MANAGEMENT_ACTION_TYPE = "UPDATE_AREA_MANAGEMENT_ACTION_TYPE";
export const GET_AREA_MANAGEMENT_ACTION_TYPE = "GET_AREA_MANAGEMENT_ACTION_TYPE";

// application History
export const GET_APPLICATION_HISTORY_LIST_ACTION_TYPE = "GET_APPLICATION_HISTORY_LIST_ACTION_TYPE";

//exhibitor Module
export const SELECT_APPLICATION_ACTION_TYPE = "SELECT_APPLICATION_ACTION_TYPE";
export const GET_SPACE_BOOKING_DETAILS_ACTION_TYPE = "GET_SPACE_BOOKING_DETAILS_ACTION_TYPE";
export const SPACE_BOOKING_EDIT_ACTION_TYPE = "SPACE_BOOKING_EDIT_ACTION_TYPE";
export const GET_TIMELINE_ACTION_TYPE = "GET_TIMELINE_ACTION_TYPE";
export const GET_OLD_PAYMENT_LIST_ACTION_TYPE = "GET_OLD_PAYMENT_LIST_ACTION_TYPE";
export const GET_PROFORMA_LIST_ACTION_TYPE = "GET_PROFORMA_LIST_ACTION_TYPE";
export const INSERT_PAYMENT_ACTION_TYPE = "INSERT_PAYMENT_ACTION_TYPE"
export const GET_PAYMENT_DETAILS_ACTION_TYPE = "GET_PAYMENT_DETAILS_ACTION_TYPE"
export const UPDATE_PAYMENT_ACTION_TYPE = "UPDATE_PAYMENT_ACTION_TYPE"
export const EXHIBITOR_DASHBOARD_ACTION_TYPE = "EXHIBITOR_DASHBOARD_ACTION_TYPE";
export const EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE = "EXHIBITOR_DRAWER_DETAILS_ACTION_TYPE";
export const UPLOAD_UNDERTAKING_ACTION_TYPE = "UPLOAD_UNDERTAKING_ACTION_TYPE";
export const GET_PROFORMA_PDF_ACTION_TYPE = "GET_PROFORMA_PDF_ACTION_TYPE";
export const UPDATE_STALL_TYPE_AREA = "UPDATE_STALL_TYPE_AREA";
export const INSERT_EXHIBITOR_BARCODE_ACTION_TYPE = "INSERT_EXHIBITOR_BARCODE_ACTION_TYPE"

// Exhibitor Circular 
export const GET_EXH_CIRCULAR_LIST_ACTION_TYPE = "GET_EXH_CIRCULAR_LIST_ACTION_TYPE"
// Visitor Circular
export const GET_EXH_VISITOR_CIRCULAR_LIST_ACTION_TYPE = "GET_EXH_CIRCULAR_LIST_ACTION_TYPE"

// Manual Form 
export const GET_MANUAL_FORMS_ACTION_TYPE = "GET_MANUAL_FORMS_ACTION_TYPE"
// Fascia Form
export const ADD_FASCIA_FORM_ACTION_TYPE = "ADD_FASCIA_FORM_ACTION_TYPE"
export const UPDATE_FASCIA_FORM_ACTION_TYPE = "UPDATE_FASCIA_FORM_ACTION_TYPE"
export const INSERT_UPDATE_FASCIA_ACTION_TYPE = "INSERT_UPDATE_FASCIA_ACTION_TYPE"
export const GET_FASCIA_FORM_ACTION_TYPE = "GET_FASCIA_FORM_ACTION_TYPE"
export const GET_FASCIA_DEFAULT_ACTION_TYPE = "GET_FASCIA_DEFAULT_ACTION_TYPE"
// Exhibitor Badge
export const GET_EXHIBITOR_BADGE_ACTION_TYPE = "GET_EXHIBITOR_BADGE_ACTION_TYPE"
export const ADD_EXHIBITOR_BADGE_ACTION_TYPE = "ADD_EXHIBITOR_BADGE_ACTION_TYPE"
export const SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE = "SUBMIT_EXHIBITOR_BADGES_ACTION_TYPE"
export const GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE = "GET_EXHIBITOR_BADGE_DETAILS_ACTION_TYPE"
export const UPDATE_EXHIBITOR_BADGE_ACTION_TYPE = "UPDATE_EXHIBITOR_BADGE_ACTION_TYPE"
export const DELETE_EXHIBITOR_BADGE_ACTION_TYPE = "DELETE_EXHIBITOR_BADGE_ACTION_TYPE"

// E-Directory Form
export const GET_E_DIRECTORY_LIST_ACTION_TYPE = "GET_E_DIRECTORY_LIST_ACTION_TYPE"
export const ADD_E_DIRECTORY_ACTION_TYPE = "ADD_E_DIRECTORY_ACTION_TYPE"
export const GET_E_DIRECTORY_DETAILS_ACTION_TYPE = "GET_E_DIRECTORY_DETAILS_ACTION_TYPE"
export const UPDATE_E_DIRECTORY_ACTION_TYPE = "UPDATE_E_DIRECTORY_ACTION_TYPE"
export const SUBMIT_E_DIRECTORY_LIST_ACTION_TYPE = "SUBMIT_E_DIRECTORY_LIST_ACTION_TYPE"
export const DEL_DIRECTORY_ACTION_TYPE = "DEL_DIRECTORY_ACTION_TYPE"

// Stall Possession
export const GET_STALL_POSSESSION_ACTION_TYPE = "GET_STALL_POSSESSION_ACTION_TYPE"
export const INSERT_STALL_POSSESSION_ACTION_TYPE = "INSERT_STALL_POSSESSION_ACTION_TYPE"

// visa application
export const VISA_APPLICATION_LIST_ACTION_TYPE = "VISA_APPLICATION_LIST_ACTION_TYPE"
export const ADD_VISA_APPLICATION_ACTION_TYPE = "ADD_VISA_APPLICATION_ACTION_TYPE"
export const SUBMIT_VISA_APPLICATION_ACTION_TYPE = "SUBMIT_VISA_APPLICATION_ACTION_TYPE"
export const GET_VISA_APPLICATION_DETAILS_ACTION_TYPE = "GET_VISA_APPLICATION_DETAILS_ACTION_TYPE"
export const UPDATE_VISA_APPLICATION_ACTION_TYPE = "UPDATE_VISA_APPLICATION_ACTION_TYPE"
export const DELETE_VISA_APPLICATION_ACTION_TYPE = "DELETE_VISA_APPLICATION_ACTION_TYPE"
export const DEL_EXHIBITOR_BADGE_ACTION_TYPE = "DEL_EXHIBITOR_BADGE_ACTION_TYPE"
export const DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE = "DEL_EXHIBITOR_BADGE_DOC_ACTION_TYPE"
export const DELETE_VISA_DOC_ACTION_TYPE = "DELETE_VISA_DOC_ACTION_TYPE"
//Exhibitor details
export const GET_EXHIBITOR_DETAILS_ACTION_TYPE = "GET_EXHIBITOR_DETAILS_ACTION_TYPE"
export const UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE = "UPDATE_EXHIBITOR_DETAILS_ACTION_TYPE"
// Visitor Module
// visitor Registartion 
export const GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE = "GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE"
export const GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE = "GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE"
export const GET_CAR_PARKING_DETAILS_ACTION_TYPE = "GET_CAR_PARKING_DETAILS_ACTION_TYPE"
export const VISITOR_REGISTRATION_NEXT_ACTION_TYPE = "VISITOR_REGISTRATION_NEXT_ACTION_TYPE"
export const VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE = "VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE"
export const VERIFY_VISITOR_EMAIL_ACTION_TYPE = "VERIFY_VISITOR_EMAIL_ACTION_TYPE"
export const GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE = "GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE"
export const VISITOR_PREVIEW_SUBMIT_ACTION_TYPE = "VISITOR_PREVIEW_SUBMIT_ACTION_TYPE"
export const DEL_VISA_DOCUMENT_ACTION_TYPE = "DEL_VISA_DOCUMENT_ACTION_TYPE"
export const VISITOR_REGISTRATION_GROUP_NEXT_ACTION_TYPE = "VISITOR_REGISTRATION_GROUP_NEXT_ACTION_TYPE"
export const VISITOR_REGISTRATION_GROUP_FINAL_ACTION_TYPE = "VISITOR_REGISTRATION_GROUP_FINAL_ACTION_TYPE"
// Visitor Dashboard
export const GET_VISITOR_DASHBOARD_ACTION_TYPE = "GET_VISITOR_DASHBOARD_ACTION_TYPE"
export const GET_VISITOR_REGISTRATION_CREDENTIALS_ACTION_TYPE = "GET_VISITOR_REGISTRATION_CREDENTIALS_ACTION_TYPE"
// Visitor Form
export const GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE = "GET_COMBINE_VISITOR_REGISTRATION_DETAIL_ACTION_TYPE"
export const VISITOR_REGISTRATION_UPDATE_ACTION_TYPE = "VISITOR_REGISTRATION_UPDATE_ACTION_TYPE"
export const DELETE_VISA_DOCUMENT_ACTION_TYPE = "DELETE_VISA_DOCUMENT_ACTION_TYPE"
// Visitor Badge 
export const GET_VISITOR_BADGE_ACTION_TYPE = "GET_VISITOR_BADGE_ACTION_TYPE"
//chapter allocation
export const CHAPTER_ALLOCATION_MASTER = "CHAPTER_ALLOCATION_MASTER"


// Keywords and Information
export const GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE = "GET_KEYWORDS_INFORMATION_LIST__ACTION_TYPE"
export const DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE = "DELETE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE"
export const INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE = "INSERT_AND_UPDATE_KEYWORDS_INFORMATION_LIST__ACTION_TYPE"

//power connection 
export const POWER_CONNECTION_LIST_ACTION_TYPE = "POWER_CONNECTION_LIST_ACTION_TYPE"
export const POWER_CONNECTION_DETAILS_ACTION_TYPE = "POWER_CONNECTION_DETAILS_ACTION_TYPE"
export const INSERT_AND_UPDATE_POWER_CONNECTION_ACTION_TYPE = "INSERT_AND_UPDATE_POWER_CONNECTION_ACTION_TYPE"

// exh tax invoce
export const GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE = "GET_EXH_TAX_INVOICE_LIST_ACTION_TYPE"
//water connection 
export const GET_WATER_DETAILS = "GET_WATER_DETAILS"
export const INSERT_UPDATE_WATER = "INSERT_UPDATE_WATER"
//water connection 
export const GET_VISITOR_MEETING_LIST = "GET_VISITOR_MEETING_LIST"
export const INSERT_UPDATE_VISITOR_MEETING = "INSERT_UPDATE_VISITOR_MEETING"

//USER
export const GET_ADMIN_USER_LIST = `GET_ADMIN_USER_LIST`;
export const GET_ADMIN_USER_DETAILS = `GET_ADMIN_USER_DETAILS`;
export const ADD_ADMIN_USER = `ADD_ADMIN_USER`;
export const UPDATE_ADMIN_USER = `UPDATE_ADMIN_USER`;
export const DELETE_ADMIN_USER = `DELETE_ADMIN_USER`;

// car Passess application
export const INSERT_AND_UPDATE_CAR_PASSESS_ACTION_TYPE = "INSERT_AND_UPDATE_CAR_PASSESS_ACTION_TYPE"
export const GET_CAR_PASSESS_DETAILS_ACTION_TYPE = "GET_CAR_PASSESS_DETAILS_ACTION_TYPE"

// catering Services
export const INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE = "INSERT_AND_UPDATE_CATERING_SERVICES_ACTION_TYPE"
export const GET_CATERING_SERVICES_DETAILS_ACTION_TYPE = "GET_CATERING_SERVICES_DETAILS_ACTION_TYPE"

// frieght forward
export const INSERT_AND_UPDATE_FRIEGHT_FORWARD_ACTION_TYPE = "INSERT_AND_UPDATE_FRIEGHT_FORWARD_ACTION_TYPE"
export const GET_FRIEGHT_FORWARD_DETAILS_ACTION_TYPE = "GET_FRIEGHT_FORWARD_DETAILS_ACTION_TYPE"

// exhibitor Contractor Badge ApllicationRoute
export const INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE = "INSERT_AND_UPDATE_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_ACTION_TYPE"
export const GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE = "GET_EXHIBITOR_CONTRACTOR_BADGE_APPLICATION_DETAILS_ACTION_TYPE"

// vendor module
export const GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE = "GET_VENDOR_EXHIBITION_LIST__ACTION_TYPE"

// Liquor License
export const INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE = "INSERT_AND_UPDATE_LIQUOR_LICENSE_ACTION_TYPE"
export const GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE = "GET_LIQUOR_LICENSE_DETAILS_ACTION_TYPE"

// lead Retrieval  form application
export const INSERT_AND_UPDATE_LEAD_RETRIEVAL_FORM_ACTION_TYPE = "INSERT_AND_UPDATE_LEAD_RETRIEVAL_FORM_ACTION_TYPE"
export const GET_LEAD_RETRIEVAL_FORM_DETAILS_ACTION_TYPE = "GET_LEAD_RETRIEVAL_FORM_DETAILS_ACTION_TYPE"

// Meeting Room
export const GET_MEETING_ROOM_DETAILS_ACTION_TYPE = "GET_MEETING_ROOM_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_MEETING_ROOM_ACTION_TYPE = "INSERT_UPDATE_MEETING_ROOM_ACTION_TYPE"

// internet Connection
export const GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE = "GET_INETRNET_CONNECTION_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE = "INSERT_UPDATE_INTERNET_CONNECTION_ACTION_TYPE"

// house Keeping 
export const GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE = "GET_HOUSE_KEEPING_DEFAULT_DETAILS_ACTION_TYPE"
export const GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE = "GET_HOUSE_KEEPING_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE = "INSERT_UPDATE_HOUSE_KEEPING_ACTION_TYPE"

// security application
export const GET_SECURITY_APPLICATION_DETAILS_ACTION_TYPE = "GET_SECURITY_APPLICATION_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_SECURITY_APPLICATION_ACTION_TYPE = "INSERT_UPDATE_SECURITY_APPLICATION_ACTION_TYPE"

//Host and hostess
export const GET_HOST_AND_HOSTESS_DETAILS_ACTION_TYPE = "GET_HOST_AND_HOSTESS_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_HOST_AND_HOSTESS_ACTION_TYPE = "INSERT_UPDATE_HOST_AND_HOSTESS_ACTION_TYPE"

//Branding Opportunity
export const GET_BRANDING_OPPORTUNITY_DETAILS_ACTION_TYPE = "GET_BRANDING_OPPORTUNITY_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_BRANDING_OPPORTUNITY_ACTION_TYPE = "INSERT_UPDATE_BRANDING_OPPORTUNITY_ACTION_TYPE"

//Air Connection Application
export const GET_AIR_CONNECTION_DETAILS_ACTION_TYPE = "GET_AIR_CONNECTION_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE = "INSERT_UPDATE_AIR_CONNECTION_ACTION_TYPE"

//Rental Audio And Video
export const GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE = "GET_RENTAL_AUDIO_AND_VIDEO_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE = "INSERT_UPDATE_RENTAL_AUDIO_AND_VIDEO_ACTION_TYPE"

////Stall Design
export const GET_STALL_DESIGN_DETAILS_ACTION_TYPE = "GET_STALL_DESIGN_DETAILS_ACTION_TYPE"
export const INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE = "INSERT_UPDATE_STALL_DESIGN_ACTION_TYPE"

//PARTNER
export const VERIFY_PARTNER_CODE = `VERIFY_PARTNER_CODE`;
export const GET_PARTNER_STATIC_DATA = `GET_PARTNER_STATIC_DATA`;
export const PARTNER_REGISTRATION_FORM = `PARTNER_REGISTRATION_FORM`;

export const PARTNER_APPLICATION_DETAILS = `PARTNER_APPLICATION_DETAILS`;
export const UPDATE_PARTER_APPLICATION = `UPDATE_PARTER_APPLICATION`;
export const PARTNER_DELETE_DOC = `PARTNER_DELETE_DOC`;

// Manual From Report
export const GET_AIR_CONNECTION_MANUAL_FORM_REPORT = `GET_AIR_CONNECTION_MANUAL_FORM_REPORT`;
export const GET_WATER_CONNECTION_MANUAL_FORM_REPORT = `GET_WATER_CONNECTION_MANUAL_FORM_REPORT`;
export const GET_POWER_CONNECTION_MANUAL_FORM_REPORT = `GET_POWER_CONNECTION_MANUAL_FORM_REPORT`;
export const GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT = `GET_FRIEGHT_FORWARDER_MANUAL_FORM_REPORT`;
export const GET_FASCIA_MANUAL_FORM_REPORT = `GET_FASCIA_MANUAL_FORM_REPORT`;
export const GET_E_DIRECTORY_MANUAL_FORM_REPORT = `GET_E_DIRECTORY_MANUAL_FORM_REPORT`;
export const GET_MEETING_ROOM_MANUAL_FORM_REPORT = `GET_MEETING_ROOM_MANUAL_FORM_REPORT`;
export const GET_EXH_BADGE_MANUAL_FORM_REPORT = `GET_EXH_BADGE_MANUAL_FORM_REPORT`;
export const GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT = `GET_EXHIBIT_DETAILS_MANUAL_FORM_REPORT`;
export const GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT = `GET_EXHIBITOR_CONTRACTOR_BADGE_FORM_REPORT`;
export const GET_STALL_BOOKING_SUMMARY_FORM_REPORT = `GET_STALL_BOOKING_SUMMARY_FORM_REPORT`;
export const GET_STALL_DESIGN_FORM_REPORT = `GET_STALL_DESIGN_FORM_REPORT`;
export const GET_VISITOR_BADGE_FORM_REPORT = `GET_VISITOR_BADGE_FORM_REPORT`;
export const GET_STALL_POSSESSION_FORM_REPORT = `GET_STALL_POSSESSION_FORM_REPORT`;
export const GET_VISA_APPLICATION_FORM_REPORT = `GET_VISA_APPLICATION_FORM_REPORT`;

//TALLY
export const GET_ACCOUNT_TALLY_DATA = `GET_ACCOUNT_TALLY_DATA`;
//action type end..........................

// action ID name
export const countryActionId = "1";
export const stateActionId = "2";
export const cityActionId = "3";
export const pincodeActionId = "4";
export const primaryChapterListActionId = "5";
export const stallTypeActionId = "6";
export const verifyPrimaryEmailIDActionId = "7";
export const stallSizeActionId = "8";
// actionid=9,code='ABC'
export const countryIWithCodeActionId = "9";
export const voucherTypeActionId = "10";
export const exhibitorActionId = "11";
export const bankAccountActionId = "12";
export const paymentModeActionId = "13";
export const otherChargesAccountTypeActionId = "14";
export const exhibitorBadgeDaysActionId = "15";
export const visaIdTypeActionId = "16";
export const attendeeTypeActionId = "17";
export const visitPurposeActionId = "18";
export const visitorInterestActionId = "19";
export const priorityNoActionId = "20";
export const reminderEventActionId = "21";
export const paymentTypeActionId = "22";
export const exhibitorTypeActionId = "23"
export const featureActionId = "24"
export const visitorStatusActionId = "25"
export const industryChaptersActionId = "29"
export const seminarInterestActionId = "30"
export const exhibitorNameActionId = "26";
export const accountTypeActionId = "27";
export const currencyActionId = "28";
export const exhibitionActionId = "31";
export const manualFormsListActionId = "37";
export const stallListActionId = "38";
export const statusTypeActionId = "32"
export const boothTypeActionId = "33"
export const hallNoActionId = "34"
export const agentListActionId = "35"
export const primaryChapterActionId = "36"
export const stallPosessionStatusActionId = "39"
export const statusId = "40"
export const exhibitionId = "41"
export const adjustAreaFromToId = "43"
export const adjustAreaId = "44"
export const applicationHistoryExhibitorId = "46"
export const allExhibitorNameActionIdId = "47"
export const roleListActionId = "42"
export const adminUserRoleListActionId = "48"
export const hallMasterListActionId = "50"
export const waterChargesActionId = "53"
export const primaryChapterMasterListActionId = "55"
export const primaryChapterAllocationListActionId = "56"
export const stalltypeMasterListActionId = "57"
export const countryMasterListActionId = "58"
export const hallListActionId = "59"
export const stallNoListActionId = "60"
export const serviceTypeId = "49"
export const hallActionId = "50"
export const stallActionId = "51"
export const vendorListId = "52"
export const opensSidesListActionId = "65"
export const onlinePresenceListActionId = "61"
export const mainChapterListActionId = "63"
export const segmentActionId = "64"
export const exhibitionStatusActionId = "67";
export const dashboardDownloadListId = "66"
export const invoiceTypeActionId = "68"
export const GSTvalidationActionId = "69"
export const airCompressedconsumptionId = "70"
export const paymentMasterListActionId = "71"
export const visitorListActionId = "72"
export const agentListReceiptActionId = "73"
export const visitorTypeListActionId = "74"
export const exhDescriptionActionId = "75"
export const exhNoteTypeActionId = "76"
export const exhMasterBankAccountActionId = "77"
export const powerGuidlineAmountActionId = "78"
export const ProductCategoryActionId = "79"
export const ListOfExhibitorBadgeActionId = "80"
export const exhibitorBadgeVisitDaysActionId = "81";

export const appVersion = '1.0.0';
export const buildNumber = '20220511';
export const NA = 'NA';


// for clear search fields
export const clearSessionStorage = (pageName) => {
  if (pageName === "registrationDesk") {

  }

}

const registrationDeskSS = () => {
  sessionStorage.setItem("campIdRDSS", 'Select')
  sessionStorage.setItem("campDateRDSS", '')
}





export const sort = (list, tableValue, isSort) => {
  isSort = !isSort
  if (!isSort) {
    // sorting
    var sort = list.sort((a, b) => {
      const nameA = tableValue.toUpperCase(); // ignore upper and lowercase
      const nameB = tableValue.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    // this.setState({list : sort})
    var data = [sort, !sort]
    return data;
  }
  else {
    // reverse
    var sort = list.sort((a, b) => {
      const nameA = tableValue.toUpperCase(); // ignore upper and lowercase
      const nameB = tableValue.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
    // this.setState({list : sort})
    var data = [sort, !sort]
    return data;
  }
  // this.setState({isToDateSort : !isToDateSort})
}

export const toastError = (msg) => {
  toast.error(msg, {
    theme: 'colored',
    autoClose: 3000,
    hideProgressBar: true,
  })
}

export const toastSuccess = (msg) => {
  toast.success(msg, {
    theme: 'colored',
    autoClose: 1000,
    hideProgressBar: true,
  })
}

export function timeout(delay) {
  return new Promise(res => setTimeout(res, delay));
}