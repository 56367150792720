import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";
import Header from "../../common_components/Header";
import Drawer from "../../common_components/Drawer";
import { lastSubmitAdd_action } from "../../actions/last_submit_action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "../../utils/withRouter";
import moment from "moment";
import Modal from "react-modal";
import { sendPaymentForm_action } from "../../actions/payment_action/payment_action";
import Loader from "../../utils/Loader";
import {
  clearSessionStorage,
  countryActionId,
  toastError,
  toastSuccess,
  countryIWithCodeActionId,
  timeout,
} from "../../utils/constants";
import Pagination from "../../utils/pagination";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from "../../actions/fillCombo_action";
import StepHeader from "./stepHeader";
import Footer from "../../common_components/Footer";
import $ from "jquery";
import { paymentPortalLink, paymentAccessCode, paymentMasterListActionId } from "../../utils/constants";

class LastSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      geturl: "",

      openPaymentPop: false,
      toPay: '',
      toPaydummy: '',
      paymentType: '',
      customPayment: '',
      isCustompayment: '',
      minPay: '',
      encPayload: '',
      remark: '',
      isPaymentEnable: false,
      paymentMasterList: [],
    };
    this.submitfrm = React.createRef()

    this.onSubmit = this.onSubmit.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.loadscript = this.loadscript.bind(this);

    this.onPayAndSubmit = this.onPayAndSubmit.bind(this)
    this.onPaymentTypeSelect = this.onPaymentTypeSelect.bind(this)
    this.navPayment = this.navPayment.bind(this)
  }

  getFillList(actionID, exhibitionID, moduleID, keyValue, value) {
    this.setState({ isLoading: true })
    this.props
      .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
      .then((data) => {
        if (data.error != 1) {
          // country list
          if (actionID === paymentMasterListActionId) {
            console.log("country data", data.data)
            this.setState({
              paymentMasterList: data.data, isLoading: false,
            })
            data.data.length > 0 && data.data.map(item => {
              item.PaymentModuleID == 2 && this.setState({ isPaymentEnable: item.IsPaymentEnable })
            })
          }
        }
        else {
          this.setState({ isLoading: false })
          toastError(data.msg)
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }

  onSubmit(e) {
    var exbid = sessionStorage.getItem("sessionExhibitionID");
    var regid = sessionStorage.getItem("sessionExhRegistrationID");

    this.setState({ isLoading: true });
    this.props
      .lastSubmitAdd_action(exbid, regid)
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.error != 1) {
          this.props.navigate("/thankYou");

          // toastSuccess("Your space booking application has been submitted successfully.");
        } else {
          toastError(data.msg);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    sessionStorage.setItem("renderPage", "summary", this.props.location);
    console.log('props==>', this.props.location)
    console.log('url from props==>', this.props.location.state.geturl)
    this.setState({
      geturl:
        this.props.location.state != null &&
          this.props.location.state != "" &&
          this.props.location.state.geturl != " "
          ? this.props.location.state.geturl
          : "",
      toPay:
        this.props.location.state != null &&
          this.props.location.state != "" &&
          this.props.location.state.toPay != " "
          ? this.props.location.state.toPay
          : "",
      toPaydummy:
        this.props.location.state != null &&
          this.props.location.state != "" &&
          this.props.location.state.toPay != " "
          ? this.props.location.state.toPay
          : "",
      minPay:
        this.props.location.state != null &&
          this.props.location.state != "" &&
          this.props.location.state.minPay != " "
          ? this.props.location.state.minPay
          : "",
      isCustompayment:
        this.props.location.state != null &&
          this.props.location.state != "" &&
          this.props.location.state.isCustompayment != " "
          ? this.props.location.state.isCustompayment
          : "",
    });
    this.getFillList(paymentMasterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
    setTimeout(() => {
      this.setState({ isLoading: false });
      console.log('geturl after assigned in state==>', this.state.geturl)
    }, 5000);
    this.loadscript();
  }

  loadscript() {
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
  }

  onPreviousClick() {
    sessionStorage.setItem("renderPage", "description");
    this.props.navigate("/description", { state: { is_update: true } });
  }

  onPayAndSubmit() {
    const { openPaymentPop, countryId, toPaydummy, EmailAuthorizedPerson, MobileAuthorizedPerson, ExhibitorCompanyName, DesignationAuthorizedPerson, AuthorizedPersonName } = this.state
    this.setState({ openPaymentPop: !openPaymentPop })
  }

  onPaymentTypeSelect(type) {
    const { openPaymentPop, toPaydummy } = this.state

    this.setState({ paymentType: type })
    if (type == 1) {
      this.setState({ toPay: toPaydummy })
    }
    else if (type == 2) {
      this.setState({ customPayment: '' })
    }
  }

  navPayment(e) {
    e.preventDefault()
    const { userId, selectedPIList, minPay, proformaInv, remark, paymentType, toPay, currency, } = this.state
    var ERROR = null
    if (toPay < minPay) {
      ERROR = `Total Payment amount should be greater than ${minPay}`
    }
    console.log(ERROR)
    if (ERROR == null) {
      this.setState({ isLoading: true })
      var moreInfo = null
      var exbid = sessionStorage.getItem("sessionExhibitionID");
      var regid = sessionStorage.getItem("sessionExhRegistrationID")
      console.log("++++++++++++++++++++++++++++++++++", sessionStorage.getItem("sessionContryName"))
      this.props.sendPaymentForm_action(exbid, regid, toPay, sessionStorage.getItem("countryId") == 91 ? 'INR' : 'USD', [], 'estimates', moreInfo, remark)
        .then((data) => {
          console.log(data)
          if (data.error != 1) {
            if (data != null) {
              console.log("payment response : ", data)

              this.setState({ encPayload: data.data }, () => {
                this.submitfrm.current.submit()
              })
            }
          }
          else {
            this.setState({ isLoading: false })
            toastError('There is some problem. Please try again later!')
          }
        })
        .catch((err) => {
          console.log(err)
        })

    }
    else {
      toastError(ERROR)
    }

  }

  render() {
    const { isLoading, geturl, openPaymentPop, paymentType, customPayment, toPay, encPayload, } = this.state;

    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        {/* <!-- PAGE --> */}
        {console.log('getURL in render==>',geturl)}
        <Header />
        {/* <!-- CONTAINER --> */}
        <div className="page-height" style={{ height: "auto" }}>
          <div className="main-container container-fluid">
            {/* <StepHeader /> */}

            {/* <!-- ROW-1 --> {geturl}*/}
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                <div className="row ">
                  <form className="needs-validation " novalidate="">
                    <div className="form-row mt-4">
                      {geturl != '' && <iframe
                        id="myIframe"
                        src={geturl}
                        width="400px"
                        height="400px"
                        title="Bing"
                      />}
                      {/* <div className="main-container container-fluid p-3 pt-0 pb-0">
                                                                <div className="mt-1 mb-4">
                                                                    <button className="btn apply-btn mt-2" type="submit">
                                                                        <a className="text-center " onClick={e => this.onSubmit(e)}>Submit</a>
                                                                    </button>
                                                                </div>
                                                            </div> */}

                      <div
                        class="mt-2 submit-center for-mob-bot-spc"
                        role="group"
                        aria-label="Basic example"
                        style={{ marginBottom: "100px !important" }}
                      >
                        <button
                          className="btn btn-preview"
                          style={{
                            marginRight: "35px",
                            scale: "1.1"
                          }}
                          type="submit"
                          onClick={(e) => this.onPreviousClick(e)}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          class="btn btn-submit"
                          style={{
                            marginRight: "35px",
                            scale: "1.1"
                          }}
                          onClick={(e) => this.onSubmit(e)}
                        >
                          {
                            sessionStorage.getItem("countryId") == 91 &&
                              this.state.isPaymentEnable ? 'Submit and Pay later' : 'Submit'}
                        </button>
                        {this.state.isPaymentEnable &&
                          sessionStorage.getItem("countryId") == 91 && <button
                            type="button"
                            class="btn btn-submit"
                            style={{ scale: "1.1" }}
                            onClick={(e) => this.onPayAndSubmit(e)}
                          >
                            Pay and Submit
                          </button>}
                      </div>

                      <Footer />
                      <div style={{ height: "80px" }}></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={openPaymentPop} onRequestClose={() => this.setState({ openPaymentPop: false })}>
          <div className="modal-dialog" style={{ marginTop: '20%' }}>
            <div className="modal-content confirm-logout">
              <div class="modal-header text-right p-3">
                <h5 class="modal-title modal_title_text text-Primary">Payment</h5>
                <button
                  type="button"
                  className="btn-close mr-3"
                  data-bs-dismiss="modal"
                  style={{ fontSize: 12 }}
                  aria-label="Close"
                  onClick={(e) => this.setState({ openPaymentPop: false })}
                />
              </div>
              <div className="row m-3" >
                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '18px' }} className="col-md-6">
                  <input type="radio" style={{ width: '18px', height: '18px' }}
                    value={paymentType}
                    checked={paymentType == 1}
                    onClick={(e) => this.onPaymentTypeSelect(1)}
                  />
                  Full Payment
                </div>
                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '18px' }} className="col-md-6">
                  <input type="radio" style={{ width: '18px', height: '18px' }}
                    value={paymentType}
                    disabled={this.state.isCustompayment != 1}
                    checked={paymentType == 2}
                    onClick={(e) => this.onPaymentTypeSelect(2)}
                  />
                  Custom Payment
                  {paymentType == 2 && <input type="number" className="form-control"
                    value={customPayment}
                    // checked={generatePriorityNoType == 1}
                    onChange={(e) => this.setState({ customPayment: e.target.value, toPay: e.target.value == '' ? 0 : e.target.value })}
                  />}
                </div>
                <div className="" >
                  <div style={{ textAlign: 'left' }} className="col-md-12 mt-2">
                    <label>Remark</label>
                    <textarea value={this.state.remark} className="form-control" onChange={(e) => this.setState({ remark: e.target.value })}>

                    </textarea>
                  </div>
                </div>
              </div>
              <div class="modal-footer p-1" style={{ justifyContent: 'normal' }}>
                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '22px', padding: '0', float: 'left', left: '0' }} className="col-8 ms-2">
                  Total: {toPay}
                </div>
                {/* <div className="row" > */}
                <div className=" mt-1 col-3">
                  <div style={{ float: 'right' }} className="mt-1 mb-1 mr-3 ">
                    <form
                      onSubmit={(e) => this.navPayment(e)}
                      ref={this.submitfrm}
                      id="nonseamless" method="post" name="redirect"
                      action={paymentPortalLink}
                    >

                      <input type="hidden" id="encRequest" name="encRequest"
                        value={encPayload}
                      />
                      <input type="hidden" name="access_code" id="access_code"
                        value={paymentAccessCode}
                      />
                      <button className="btn btn-success rounded-1" disabled={toPay <= 0 || toPay == '' || toPay == null || paymentType == ''} style={{ border: 'none', borderRadius: '2px', }}>       <span style={{ color: 'black', padding: "4px 20px", fontWeight: 500, }}>
                        pay
                      </span>
                        {/* {!isPaid && isCarParking == 1 ? 'Make Payment' : 'Update'} */}

                      </button>
                    </form>
                  </div>
                </div>
                {/* </div> */}
              </div>
              {/* <div class="modal-footer p-1">
                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '22px', padding: '0', float: 'left', left: '0' }} className="col-9">
                  Total: {toPay}
                </div>
                <div className=" mt-1">
                  <div className="row" >
                    <div style={{ float: 'right' }} className="mt-1 mb-1 mr-3 col-6">
                      <form
                        onSubmit={(e) => this.navPayment(e)}
                        ref={this.submitfrm}
                        id="nonseamless" method="post" name="redirect"
                        action={paymentPortalLink}
                      >

                        <input type="hidden" id="encRequest" name="encRequest"
                          value={encPayload}
                        />
                        <input type="hidden" name="access_code" id="access_code"
                          value={paymentAccessCode}
                        />
                        <button className="btn btn-success rounded-1" disabled={toPay <= 0 || toPay == '' || toPay == null || paymentType == ''} style={{ border: 'none', borderRadius: '2px', }}>       <span style={{ color: 'black', padding: "4px 20px", fontWeight: 500, }}>
                          pay
                        </span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </Modal >
        {/* <Footer/> */}
        {/* <!-- CONTAINER END --> */}
      </>
    );
  }
}

export default withRouter(
  connect(null, {
    fill_action,
    lastSubmitAdd_action, sendPaymentForm_action
  })(LastSubmit)
);
