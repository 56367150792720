import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../utils/Loader";
import { userManualDownloadUrl } from '../utils/constants';
import Modal from "react-modal";
import moment from 'moment';
import StepHeader from '../screens/registration/stepHeader'

Modal.setAppElement("#root");


// {head,subHeader}
const HeaderWithLogo = () => {

    const history = useNavigate();
    const navigate = useNavigate();
    const [isLoading, setLoader] = useState(false);


    const loadScripts = () => {
        // This array contains all the files/CDNs 
        // const dynamicScripts = [
        //   'js/jquery.sticky.js',
        //   'js/metisMenu.min.js',
        //   'js/metisMenu-active.js',
        //   'js/main.js',
        //   'js/azia.js'
        // ];

        // for (let i = 0; i < dynamicScripts.length; i++) {
        //   const node = document.createElement('script');
        //   node.src = dynamicScripts[i];
        //   node.type = 'text/javascript';
        //   node.async = false;
        //   document.getElementsByTagName('head')[0].appendChild(node);
        // }
    }

    useEffect(() => {
        loadScripts();
        console.log("Exhibition Logo---------------------->", sessionStorage.getItem('ExhibitionLogoSS'))
    }, []);



    const onLogoutClick = () => {
        localStorage.clear();
        navigate('/')
    }

    const openPdf = () => {
        var URL = userManualDownloadUrl /* http://0.0.0.0:8000/ or http://127.0.0.1:8000/; */
        window.open(URL, null);
    }

    // Exhibition From Date
    const dateString = sessionStorage.getItem('exhibitionDateFrom');
    const dateStringMoment = moment.utc(dateString).format('DD MMMM YYYY')
    const dateObject = new Date(dateStringMoment);
    const fromDay = dateObject.getDate();
    const fromMonth = dateObject.toLocaleString('default', { month: 'long' });
    const fromYear = dateObject.getFullYear();
    const fromDate = `${fromDay} ${fromMonth} ${fromYear}`;

    // Exhibition to Date
    const dateString1 = sessionStorage.getItem('exhibitionDateTo');
    const dateString1Moment = moment.utc(dateString1).format('DD MMMM YYYY')
    const dateObject1 = new Date(dateString1Moment);
    const toDay = dateObject1.getDate();
    const toMonth = dateObject1.toLocaleString('default', { month: 'long' });
    const toYear = dateObject1.getFullYear();
    const toDate = `${toDay} ${toMonth} ${toYear}`;

    // Exhibition Address
    const address = sessionStorage.getItem('ExhibitionAddressSS')

    return (
        <>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}

            {/* app-Header */}
            <div className="app-header header sticky app-heder-with-logo mb-4">
                <div className="container-fluid main-container">
                    <div className="d-flex">
                        {/* <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="javascript:void(0)"><i class="fa fa-bars"></i></a> */}
                        {/* sidebar-toggle*/}
                        <h5 class="m-0 logo-name" style={{width:'40%'}}> {`${sessionStorage.getItem('ExhibitionFromDateSS')}`} - {`${sessionStorage.getItem('ExhibitionToDateSS')}`}

                            <br /> <span style={{ fontSize: '15px' }}> {address}</span></h5>
                        <a className="logo-horizontal " >
                            <img
                                src={`${sessionStorage.getItem('ExhibitionLogoSS')}`}
                                className="header-brand-img desktop-logo"
                                alt="logo"
                            />
                            <img
                                src={`${sessionStorage.getItem('ExhibitionLogoSS')}`}
                                className="header-brand-img light-logo1"
                                alt="logo"
                            />
                        </a>
                        <h6 className="m-0 header-logo-name login-header">  New corporate  site please  <a href='https://corporate.india-itme.com' target='blank' className='header-login-a'>click here</a></h6>
                        {/* <span className='bg-secondary'>{sessionStorage.getItem('profileNameSS')}</span> */}

                        <div class="dropdown profile-1">
                            <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='user-in'> {sessionStorage.getItem('profileNameSS')}</span> </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <a className="dropdown-item text-center" onClick={() => onLogoutClick()}> <i className="fa fa-sign-out" aria-hidden="true"></i> Sign out </a>
                                <Link to={'/Change_Password'}> <a className="dropdown-item text-center"> <i className="fa fa-cog" aria-hidden="true"></i> Change Password</a></Link>
                            </div> </div>

                        {/* LOGO */}
                        {/*  <div class="main-header-center ms-3 d-none d-lg-block">
                      <input type="text" class="form-control" id="typehead" placeholder="Search for results...">
                      <button class="btn px-0 pt-2"><i class="fe fe-search" aria-hidden="true"></i></button>
                  </div> */}
                        <div className="d-flex order-lg-2 ms-auto header-right-icons">
                            {/* SEARCH */}
                            <button
                                className="navbar-toggler navresponsive-toggler d-lg-none ms-auto"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent-4"
                                aria-controls="navbarSupportedContent-4"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon fe fe-more-vertical" />
                            </button>
                            <div className="navbar navbar-collapse responsive-navbar p-0">
                                <div
                                    className="collapse navbar-collapse"
                                    id="navbarSupportedContent-4"
                                >
                                    <div className="d-flex order-lg-2">
                                        <div className="dropdown d-lg-none d-flex">
                                            <a
                                                href="javascript:void(0)"
                                                className="nav-link icon"
                                                data-bs-toggle="dropdown"
                                            >
                                                <i className="fe fe-search" />
                                            </a>
                                            <div className="dropdown-menu header-search dropdown-menu-start">
                                                <div className="input-group w-100 p-2">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search...."
                                                    />
                                                    <div className="input-group-text btn btn-primary">
                                                        <i className="fa fa-search" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex country">
                                            <a className="nav-link icon text-center">
                                                {/* <span class="fs-16 ms-2 d-none d-xl-block">Admin</span> */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /app-Header */}
        </>

    );
}
export default HeaderWithLogo;

