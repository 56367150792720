import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { toastError, toastSuccess, powerGuidlineAmountActionId } from "../../../../../utils/constants";
import { getPowerConnectionList_action, submitPowerConnection_action, getPowerConnectionDetails_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/power_connection_action/power_connection_action'
import Modal from "react-modal";
import { data, event } from "jquery";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import { fill_action } from '../../../../../actions/fillCombo_action';
// 
class PowerConnection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            PowerConnectionList: [],
            Is_Agree: false,
            Remark: '',
            CGST: '',
            SGST: '',
            Is_AgreeErr: '',
            DeadlineDate: "",
            currentDate: new Date(),
            Is_View: false,
            StatusID: '',
            isCollapse: false,
            CurrencyName: '',
            powerGuidlineAmountData: []
        }
        this.getPowerConnectionList = this.getPowerConnectionList.bind(this)
        this.calculateTotals = this.calculateTotals.bind(this)

    }

    componentDidMount() {
        this.getFillList(powerGuidlineAmountActionId, '', '', '', '', '');
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ?
                new Date(this.props.location.state.Deadline) : '',
            Is_View: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_View != null ? this.props.location.state.is_View : false,
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != null ? this.props.location.state.StatusID : '',
        })
        this.getPowerConnectionList()
        setTimeout(() => {
            this.getPowerConnectionDetails()
            console.log(this.props)
        }, 100);
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === powerGuidlineAmountActionId) {
                        this.setState({
                            powerGuidlineAmountData: data.data
                        })
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getPowerConnectionDetails() {
        this.setState({ isLoading: true })
        this.props
            .getPowerConnectionDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    if (data.data != '') {
                        this.setState({
                            PowerConnectionList: data.data,
                            CGST: data.data[0]['CGST'] * 100,
                            SGST: data.data[0]['SGST'] * 100,
                            Remark: data.data[0]['Remark'],
                            Is_Agree: data.data[0]['Is_Agree'],
                        })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    getPowerConnectionList() {
        this.setState({ isLoading: true })
        this.props
            .getPowerConnectionList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("hello", data.data)
                    data.data.map((item) => {
                        if (item.Quantity == '' || item.Quantity == null) {
                            item['Amount'] = 0
                        }
                    })
                    this.setState({
                        PowerConnectionList: data.data,
                        CGST: data.data[0]['CGST'] * 100,
                        SGST: data.data[0]['SGST'] * 100,
                        CurrencyName: data.data[0]['CurrencyName'],
                    })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    submitPowerConnection() {
        const { PowerConnectionList, Remark, Is_Agree } = this.state
        const { totalAmount } = this.calculateTotals

        const newArray = []

        PowerConnectionList.map((data, i) => {
            newArray.push(
                {
                    'ServiceID': data.ServiceID,
                    'Rates': data.Rates,
                    'Quantity': data.Quantity != '' ? parseInt(data.Quantity) : 0,
                    'Amount': data.Amount,
                }
            )
        })
        this.inputValidate()
        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .submitPowerConnection_action(Remark, Is_Agree == true ? 1 : 0, newArray)
                .then((data) => {
                    if (data.error != 1) {
                        setTimeout(() => {
                            this.props.navigate('/manualForms')
                            this.setState({ isLoading: false })
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.setState({ isLoading: false })
            toastError("kindly agree the terms and conditions")
        }
    }

    calculateTotals() {
        try {
            const { PowerConnectionList } = this.state
            let totalAmount = 0
            PowerConnectionList.forEach((data) => {
                totalAmount += Number(data.Amount)
            });
            return {
                totalAmount,
            };

        } catch (e) {
            console.log('exception : ', e);
        }

    }

    handleCancel() {
        if (this.state.Is_View) {
            const CompanyName = this.props.location.state != null && this.props.location.state.companyName != null ? this.props.location.state.companyName : ''
            this.props.navigate('/powerConnectionApproval', { state: { companyName: CompanyName } })
        } else {
            this.props.navigate('/manualForms')
        }
    }

    enterQuantity(e, index) {
        const { PowerConnectionList } = this.state
        const list = [...PowerConnectionList]
        const event = e.target.value.replace(/\D/, '')
        for (let i = 0; i < list.length; i++) {
            if (i == index) {
                list[i]['Quantity'] = event
                if (event != '' && event != 0) {
                    list[i]['Amount'] = event * list[i]['Rates']
                } else {
                    list[i]['Amount'] = 0
                }
            }
        }
        this.setState({
            PowerConnectionList: list
        })
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the terms and condition' })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, PowerConnectionList, SGST, CGST, Is_AgreeErr, currentDate, DeadlineDate, Is_View, StatusID, isCollapse, CurrencyName, powerGuidlineAmountData } = this.state
        const { totalAmount } = this.calculateTotals()
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content with-dr-container" style={{ marginTop: !Is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1">
                                    <div class="card-header p-3 d-flex" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "15px", marginRight: '15px' }}>Power Connection  Guideline's
                                        </h3>
                                        <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                            onClick={() => this.setState({ isCollapse: !isCollapse })}>click here to {isCollapse ? 'hide' : 'read'} Guidelines</span>
                                    </div>
                                    <div class="card-body p-1 m-2">
                                        {isCollapse && <div>
                                            <ol type="1)" style={{ textAlign: 'left' }}>
                                                <li>Exhibitors under Bare Space category are required to apply for power supply and pay electricity connection/consumption charges on the basis of required connected load for machinery, including lighting and air-conditioning. Exhibitors’ should inform their power requirements along with the payment towards power charges before <strong style={{ color: "#0056ff" }}>15.11.2024</strong>.
                                                </li>
                                                <li>Exhibitors should note that 1kw supply/outlet is compulsory in every single stall as a facility for lighting. Every exhibitor must submit their layout plan with indication of point of power supply.
                                                </li>
                                                <li>The stall contractors undertaking lighting work shall possess valid electrical wireman license..
                                                </li>
                                                <li>The load ≤ 3 kW shall be fed through single phase electrical supply at 230V AC ± 6 % and load {'>'} 3 kW shall be fed through 3 Phase electrical Supply at 415V AC ± 6 % The supply Frequency will ne 50Hz ± 3%. d.</li>
                                                <li>The exhibitor requiring supply at voltage level other than above shall make necessary arrangement for the level matching equipment such as transformer, voltage stabilizer, inverter etc. to match the supply voltage level to their load..</li>
                                                <li>The frame of stationary motor and the metallic parts, not intended as conductors, including transformers and any other apparatus used for regulating or controlling electricity of voltage exceeding 250V but not exceeding 650V shall be earthed firmly by the conductor size as mention. </li>
                                                <li>Exhibitor are advised to make arrangement for power conditioning equipment such as UPS, CVT etc. for their critical & supply sensitive load/ equipment operating under very narrow band of tolerance</li>
                                                <li>The contractors shall use BIS marked PVC insulated 650/1100 V copper conductor wire for supplying electrical power to lighting & motive load to be installed in stall</li>
                                                <li>All switch boards shall conform to BIS & to Indian Electricity Act (amended).</li>
                                                <li>The insulation tape used shall carry IS marking</li>
                                                <li>For lighting circuit, the minimum wire size shall be 1.5/2.5 sq.mm PVC insulated 650V grade FRLS copper wire. For 16A power point, the minimum wire size shall be 4sq.mm PVC insulated 650V grade FRLS copper wire. The IR value of cable used shall not be less than 1MΩ when measured with 500V DC megger</li>
                                                <li>Exhibitor can bring international adopter for their internal electrical connection</li>
                                                <li>The contractor shall use following size of protective conductors for equipment at their stall from point of supply (MCB Box).</li>
                                                <div class="table-responsive">
                                                    <table class="table border text-nowrap text-md-nowrap table-bordered mb-2 mt-2 w-50">
                                                        <thead>
                                                            <tr>
                                                                <th>Size of phase conductor</th>
                                                                <th>Size of protective conductor of the same material as phase conductor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Up to 4mm²</td>
                                                                <td>Same size as that of phase conductor</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 4mm² & up to 16mm²</td>
                                                                <td>Same size as that of phase conductor</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 16mm² & up to 35mm²</td>
                                                                <td>16mm²</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Above 35mm²</td>
                                                                <td>Half size of phase conductor</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <li>The earth wires shall be connected to the main earth firmly. After switching the entire load at stall, the voltage between neutral & earth shall not exceed 10V.</li>
                                                <li>Exhibitor shall ensure that, the contractor shall carry out all electrical works in the stall area as per Indian Electricity Rules 2005/ CEA Regulation 2010/ General Specifications for Electrical Works, Part I & II by CPWD.</li>
                                                <li>The Contractor shall carry out wiring at their installation as per IS-732:2010 with all safety precautions.</li>
                                                <li>The load connected to power point shall be suitable for the socket and under no circumstances exceed the rated current capacity of the socket.</li>
                                                <li>Exhibitors must install separate and independent MCBs/MCCBs/RCCBs of appropriated rating for their individual machines so as to automatically disconnect the supply in case of a) Overload b) Earth leakage or earth fault c) short circuit.</li>
                                                <li>It is mandatory to use only reputed make wires like Polycab, KEI, Anchor etc and MCB/RCCB like Legrand, Siemens, ABB, Schneider for the electrical installation.</li>
                                                <li>Wherever 3 ph connection is requested, the load shall be evenly distributed on all three phases. The current through neutral conductor or in other words, the current unbalance shall not exceed 5%.</li>
                                                <li>The contractor shall also ensure that conductor size of the cable/ wire is suitable to cater connected load without overloading/overheating in normal operation and during abnormal conditions. This may be checked and verified by electrical consultant.</li>
                                                <li>In case any material used by the contractor is found to be of inferior or sub-standard quality, the contractor shall be responsible for replacing the same without any loss of time as per the quality mutually agreed and mentioned above.</li>
                                                <li>It is recommended to make use of low loss/ low heating choke manufactured as per IS and of reputed make namely Philips, Crompton, Bajaj, Wipro or equivalent.</li>
                                                <li>The contractor shall control maximum 10 points or 800 watts whichever less, from single point.</li>
                                                <li>Joint to the wires in any place is unacceptable. However, good quality connector of adequate rating properly covered cab be used.</li>
                                                <li>Use 3-pin top / industrial to shall be made to take supply from sockets. The third pin shall be connected to earth through protective (loop earthing) conductor. It is strictly prohibited to insert wires directly into socket for drawing supply.</li>
                                                <li>Adequate access to main power supply (relieving point) at stall shall be provided for cleaning & maintenance purpose. A clear space of not less than 1 meter shall be provided in front of the switchboard. Materials particularly combustible shall not be dumped at this location.</li>
                                                <li>All main electrical supply points must be kept easily accessible for operation and repairs and for isolation of power in the event of emergency. These should not be concealed or covered under any circumstances.</li>
                                                <li>Exhibitors requesting for 3-phase supply shall draw single phase power for lighting purpose from 3-phase supply outlet using double pole MCBs.  The double pole MCB/RCCB shall be arranged by the exhibitor.</li>
                                                <li>The electrical supply will be released only after completion of wiring work conforming to the Indian standards & codes of wiring and to the satisfaction of Electrical consultant.</li>
                                                <li>UPS/Stabiliser with rating greater than 3kVA should have 3 Phase Input.</li>
                                                <li>1 point of power supply will be provided to below 15 kw.</li>
                                                <li>Exhibitors may contact the Electrical Consultant at venue office for any technical queries.</li>
                                                <li>Exhibitors under Shell Scheme package would be provided with a plug point socket and a switch for a 230 V, 6 Amp A.C. supply. Consumption of power through this plug point for the connected load upto 1 kw is included in the cost of shell scheme package. However, additional power would be made available to them on extra payment.</li>
                                                <li>Exhibitors shall bring their own electrical cables, wires, sockets, international electrical adaptor to connect their electrical installation.</li>
                                                <li>The Exhibition authority arrange for supply of power will be entrusted with the authority for regulating and controlling the power supply to the exhibition halls and individual stalls.</li>
                                                <li>ELECTRICAL INSTALLATION REGULATIONS</li>
                                                <ol>
                                                    <li>Electrical wiring and installation must be done by Exhibitors through registered professional technicians using proper wiring, switches etc. so as to ensure safety. The Organisers shall not be responsible or liable in case of any mishap due to use of sub-standard material or unskilled labour.</li>
                                                    <li>Lamps and appliances with high temperature surfaces should be guarded and used well away from combustible materials.</li>
                                                    <li>No light fitting or other appliance may be suspended from the roof of the exhibition hall.</li>
                                                    <li>Exhibitors are requested to review their internal wiring and connections thoroughly before going live. Also, Members should estimate the power requirement in advance so that, appropriate load management can be done. Switching from temporary to permanent power supply should be done in advance clear two days, so that testing and balancing can be done properly.</li>
                                                    <li>Exhibitor shall submit Electrical Work Completion Report to Hall Directors for permanent power connection as per the format. Power will be released on receipt of the completion report.</li>
                                                    <li>The Organisers reserve the right to disconnect any installation, which in their opinion is dangerous or likely to cause annoyance to visitors or other Exhibitors</li>
                                                    <li>In order to take care of voltage fluctuations, the Exhibitors are advised to fix up constant voltage transformers or insulation Transformer or stabilizers for CNC and other sensitive machines. Exhibitors are encouraged to use MCCB wherever necessary. The main supply points and electrical installations in the stand should be kept open for easy access and should not be concealed.</li>
                                                    <li>Electrical power should be drawn from designated points allocated to the exhibitor only. Any exhibitor or its designated contractor found drawing power from unauthorized sources will be liable to forfeiter of security deposit </li>
                                                </ol>

                                            </ol>
                                            <div style={{ textAlign: 'left' }}>
                                                <strong>Note :</strong> In case of any dispute arising in case of “Safety” and “consumption of power drawn”. The judgment by consultant will be final & binding on the contractor/Exhibitor.
                                            </div>
                                            <p>If details of requirements along with the payment from Exhibitors, received by INDIA ITME Society after <strong>deadline (15.11.2024), then additional surcharge of 25% of basic rates shall be applicable to the Exhibitors</strong>.</p>
                                        </div>}
                                        <p><strong>The tariff for electric power will be as under:</strong></p>
                                        <p><strong>Connection/Consumption charges for power supply to the exhibitors will be</strong></p>
                                        <ol style={{ textAlign: 'left' }}>
                                            <li>For Indian exhibitors Rs{powerGuidlineAmountData != '' && `₹ ${powerGuidlineAmountData[0]['Amount_in_INR']}/-`} per KW of connected load including lighting and air conditioning load. Plus GST @ 18% (as per Government rule) or as applicable is payable with all the payments will not be refunded.</li>
                                            <li>For Foreign exhibitors US Dollar {powerGuidlineAmountData != '' && `$ ${powerGuidlineAmountData[0]['Amount_in_USD']}/-`}/- per KW of connected load including lighting and air conditioning load. Plus GST @ 18% (as per Government rule) or as applicable is payable with all the payments will not be refunded.</li>
                                            <li>If the total power requirement is more than 3 KW, then the exhibitor will get 3 phase supply connection only.</li>
                                            <li>The power supply will be authorized by the Exhibition Authority after prescribed charges are received latest by 00.00.000.</li>
                                        </ol>
                                        <p>For more details or any queries please contact: itme@india-itme.com, gttes@india-itme.com </p>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>Description of Service</th>
                                                        <th>Rates ({CurrencyName})</th>
                                                        <th>Quantity</th>
                                                        <th>Amount({CurrencyName})</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        PowerConnectionList.length > 0 && PowerConnectionList != null ? (
                                                            PowerConnectionList.map((data, i) => (
                                                                <>
                                                                    <tr>
                                                                        <td>{data.Description}</td>
                                                                        <td>{data.Rates}</td>
                                                                        <td>
                                                                            <input type="text" class="form-control"
                                                                                value={data.Quantity}
                                                                                disabled={Is_View}
                                                                                maxLength={8}
                                                                                onChange={(e) => this.enterQuantity(e, i)}
                                                                            />
                                                                        </td>
                                                                        <td>{data.Amount}</td>
                                                                    </tr>
                                                                </>
                                                            ))
                                                        ) : <></>
                                                    }
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>Total</strong></td>
                                                        <td><strong>{totalAmount}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>CGST @ {CGST}%</strong></td>
                                                        <td><strong>{((CGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2"></td>
                                                        <td><strong>SGST @ {SGST}%</strong></td>
                                                        <td><strong>{((SGST / 100) * totalAmount)}</strong></td>
                                                    </tr>
                                                    <tr style={{ backgroundcolor: "#eee" }}>
                                                        <td colspan="2"></td>
                                                        <td><strong>Grand Total</strong></td>
                                                        <td><strong>{
                                                            (CGST / 100) * totalAmount + (SGST / 100) * totalAmount + totalAmount
                                                        }</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-1">
                                    <form>
                                        <label>Remark</label>
                                        <textarea class="form-control"
                                            value={Remark}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            disabled={Is_View}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                disabled={Is_View}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            We hereby read &amp; understood the above mentioned clauses &amp; consequences. We accept &amp; agree to abide by the same.</label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                {!Is_View ?
                                    <button
                                        type="button"
                                        className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                        style={{ marginRight: 10 }}
                                        disabled={currentDate > DeadlineDate}
                                        onClick={() => this.submitPowerConnection()}
                                    >
                                        Submit
                                    </button>
                                    : <></>
                                }
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(connect(null, { getPowerConnectionList_action, submitPowerConnection_action, getPowerConnectionDetails_action, fill_action })(PowerConnection));