import React, { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import VisitorHeader from "../../../common_components/visitor_module_header";
import { BASE_URL, countryActionId, industryChaptersActionId, paymentPortalLink, primaryChapterListActionId, seminarInterestActionId, toastError, toastSuccess, visitPurposeActionId, visitorInterestActionId } from "../../../utils/constants";
import { fill_action } from '../../../actions/fillCombo_action'
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { data } from "jquery";
import DatePicker from "react-datepicker";
import {
    visitorRegistrationSubmit_action, getCarParkingDetails_action, payment_action, getVistiorRegistarionDetails2_action, delVisaDoc_action, visitorRegistrationGroupSubmit_action
} from "../../../actions/visitor_module_action/visitor_registration_action/visitor_registration_action"
import Resizer from "react-image-file-resizer";
import Visitorletter from "../../../files/Visitorletter.PDF"
import { emailValidator } from "../../../utils/Validator"
import Modal from "react-modal";
import moment from "moment";


export class VisitorIndustrySegment extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen2: false,
            needVisaAssistance: false,
            isStudentAttendee: false,
            isAgree: false,
            isDisplayPassCountryList: false,
            isGrpDelegation: false,
            isDisplayDelegationCountryList: false,
            isPassportFilePresent: false,
            isVisaApplied: false,
            isInterstOne: false,
            isInterestTwo: false,
            isCarParking: 0,
            paymentAccessCode: 'AVVI64IL27CE56IVEC',
            encPayload: '',
            countryId: '',

            //fields
            accountId: "",
            visitorId: "",
            passName: "",
            passNo: "",
            passPortFileName: "",
            preapplicantMobNo: "",
            applicantMobNo: "",
            applicantEmail: "",
            passCountry: "",
            passCountryId: "",
            passDate: "",
            passExipryDate: "",
            passportFile: "",
            passportFileType: "",
            visitorLetterFile: "",
            visitorLetterFileType: "",
            carParking: "",
            remarks: "",
            leader: "",
            leaderErr: "",
            delegationCountry: "",
            delegationCountryId: "",
            arrivalDate: "",
            departureDate: "",
            delegationRemark: "",
            prefTime: "",
            preffDate: "",
            b2bRemark: "",
            parkingCharges: 0,
            isParkingAvailable: false,
            isParkingChargable: false,
            docDelAccountID: '',
            docDelExhVisitorID: '',

            // Lists
            level0: [],
            level1: [],
            level2: [],
            level3: [],
            level4: [],
            subChapters: [],
            passCountryList: [],
            tempPassCountryList: [],
            delgationCountryList: [],
            tempDelegationCountryList: [],
            subChapters: [],
            visitPurposeList: [],
            interestList: [],
            chapterList: [],
            seminarInterestList: [],
            primaryChapterListLevel2: [],
            primaryChapterListLevel3: [],
            primaryChapterListLevel4: [],
            primaryChapterListData: [],
            visitorGroupList: [],

            /// Errro Messages
            passNameErr: "",
            passNoErr: "",
            applicantMobNoErr: "",
            applicantEmailErr: "",
            passDateErr: "",
            passExipryDateErr: "",
            passCountryErr: "",
            passportFileErr: "",
            chapterErr: "",
            visitPurposeErr: "",
            delegationCountryErr: "",
            arrivalDateErr: "",
            departureDateErr: "",
            delegationRemarkErr: "",
            preffTimeErr: "",
            preffDateErr: "",
            b2bRemarkErr: "",
            ChapterHallErr: "",
            isOpen: '',
            isOtherOpen: false,
            other: '',
            isCoordinatorVisit: false
        }
        this.submitfrm = React.createRef()
        this.record = this.record.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        // this.recordFour = this.recordFour.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.onSelectPurpose = this.onSelectPurpose.bind(this)
        this.onSelectInterest = this.onSelectInterest.bind(this)
        this.onPrevClick = this.onPrevClick.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.onSelectPrimaryChapter = this.onSelectPrimaryChapter.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onPassportCountrySearch = this.onPassportCountrySearch.bind(this)
        this.renderPassCountryList = this.renderPassCountryList.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.onDelegationCountrySearch = this.onDelegationCountrySearch.bind(this)
        this.renderDelegationCountryList = this.renderDelegationCountryList.bind(this)
        this.onCheckedSeminar = this.onCheckedSeminar.bind(this)
        this.handlePrefTime = this.handlePrefTime.bind(this)
        this.onFileClick = this.onFileClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getParkingDetails = this.getParkingDetails.bind(this)
        this.navToPayment = this.navToPayment.bind(this)
        this.onCheckLevel1 = this.onCheckLevel1.bind(this)
        this.record = this.record.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.recordThree = this.recordThree.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        // this.recordFour = this.recordFour.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.recordTwo = this.recordTwo.bind(this)
        this.getVisitorRegistrationDetails = this.getVisitorRegistrationDetails.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onInput = this.onInput.bind(this)
        this.onVisitorCountryselect = this.onVisitorCountryselect.bind(this)
    }

    // On Component Load
    componentDidMount() {

        if (sessionStorage.length === 0) {
            console.log("7777777777", this.props)
            window.history.go(-1)
        }
        // Primary Chapters
        this.getFillList(primaryChapterListActionId, '', '', '', '', '').then(() => {
            this.getFillList(industryChaptersActionId, '', '', '', '', '').then(() => {
                this.getFillList(countryActionId, '', '', '', '', '').then(() => {
                    this.getFillList(visitPurposeActionId, '', '', '', '', '').then(() => {
                        this.getFillList(visitorInterestActionId, '', '', '', '', '').then(() => {
                            this.getFillList(seminarInterestActionId, '', '', '', '', '').then(() => {
                                this.getParkingDetails()

                                console.log("Visitor Id", JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID)

                                if (this.props.params != null && this.props.params.status == "failed") {
                                    toast.error('There is some problem. Please try again later!', {
                                        theme: 'colored',
                                        autoClose: 3000,
                                        hideProgressBar: true,
                                    })
                                }

                                // Setting States On navigate
                                this.setState({
                                    accountId: sessionStorage.getItem('VisitorTypeID') == 1 && JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
                                    visitorId: sessionStorage.getItem('VisitorTypeID') == 1 && JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,

                                    applicantMobNo: sessionStorage.getItem('sessionMobileNo'),
                                    preapplicantMobNo: sessionStorage.getItem('VisitorTypeID') == 1 && sessionStorage.getItem('sessionCountryNamePreRegisterMobileNo'),
                                    applicantEmail: sessionStorage.getItem('VisitorTypeID') == 1 && sessionStorage.getItem('sessionVisitorEmail'),
                                    countryId: sessionStorage.getItem('countryId'),
                                    isCoordinatorVisit: sessionStorage.getItem('VisitorTypeID') == 1 ? true : false
                                })

                                // Get Registration Details When Previous Click
                                // this.props.location.state != null && this.props.location.state.is_previous
                                // && 
                                this.getVisitorRegistrationDetails()

                            })
                        })
                    })
                })
            })
        })
        // Industry Chapters
        // Country List 
        // Visit Purpose List 
        // Visitor Interest List 
        // Visitor Interest List 


    }

    getVisitorRegistrationDetails() {
        setTimeout(() => {
            const { attendeeList, daysList, industryChapterList, visitPurposeList, chapterList, interestList, seminarInterestList, primaryChapterListData,
                level2, level3, level4 } = this.state
            // this.setState({ isLoading: true })
            this.props.getVistiorRegistarionDetails2_action()
                .then((data) => {
                    if (data.error != 1) {
                        console.log("getVisitorRegistrationDetails", data.data['registrationDetails'])


                        // Chapter List Prepare on basis on get Days list
                        var selectedChapters = data.data['chapterList'] != null ? data.data['chapterList'] : []
                        chapterList.length > 0 && chapterList.map((item) => {
                            selectedChapters.length > 0 && selectedChapters.map((element) => {
                                if (element.ChapterID == item.IndustrySegmentID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        // Hall Chapter List prepare 
                        var SelectedHallChapters = data.data['hallChapterList'] != null ? data.data['hallChapterList'] : []
                        primaryChapterListData.length > 0 && primaryChapterListData.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level2.length > 0 && level2.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level3.length > 0 && level3.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level4.length > 0 && level4.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })


                        // visit Purpose List Prepare on basis on get Days list
                        var selectedVisitPurpose = data.data['visitPurposeList'] != null ? data.data['visitPurposeList'] : []
                        visitPurposeList.length > 0 && visitPurposeList.map((item) => {
                            selectedVisitPurpose.length > 0 && selectedVisitPurpose.map((element) => {
                                if (element.PurposeofVisitID == item.PurposeofVisitID) {
                                    item.isSelect = true
                                    item.otherDetail = element.OtherDetails
                                }
                            })
                        })
                        // Applicant Mobile No
                        var appMobNo = ''
                        if (data.data['registrationDetails'][0]['ApplicantMobileNo'] != null && data.data['registrationDetails'][0]['ApplicantMobileNo'] != '') {
                            appMobNo = data.data['registrationDetails'][0]['ApplicantMobileNo']
                            var parts5 = '';
                            var appMobCode = '';
                            var appMobileNo = '';
                            if (appMobNo != null && appMobNo != '') {
                                parts5 = appMobNo.includes('-') && appMobNo.split("-")
                                appMobCode = parts5[0]
                                appMobileNo = parts5[1]
                            }
                        }

                        // Area Of Interest  List Prepare on basis on get Days list
                        var selectedInterest = data.data['areaOfInterestList'] != null ? data.data['areaOfInterestList'] : []
                        interestList.length > 0 && interestList.map((item) => {
                            selectedInterest.length > 0 && selectedInterest.map((element) => {
                                if (element.AreaofInterestID == 1) {
                                    this.setState({ isInterstOne: true })
                                } else if (element.AreaofInterestID == 2) {
                                    this.setState({ isInterestTwo: true })
                                }
                                if (element.AreaofInterestID == item.AreaofInterestID) {
                                    item.isSelect = true
                                }
                            })
                        })

                        // Seminar Interest List Prepare on Basis on get Days list 
                        var selectedSeminarList = data.data['seminarInterestList'] != null ? data.data['seminarInterestList'] : []
                        seminarInterestList.length > 0 && seminarInterestList.map((data) => {
                            selectedSeminarList.length > 0 && selectedSeminarList.map((item) => {
                                if (item.SeminarID == data.SeminarID) {
                                    data.isSelect = true
                                }
                            })
                        })
                        console.log("i", JSON.parse(sessionStorage.getItem('VisitorDataSS')))


                        var filePath = data.data['registrationDetails'][0]['PassportFilePath'] != null ? data.data['registrationDetails'][0]['PassportFilePath'] : ''
                        var array = data.data['registrationDetails'][0]['PassportFilePath'] != null &&
                            data.data['registrationDetails'][0]['PassportFilePath'] != "" ? filePath.split("/") : []
                        const FileName = array.length > 0 ? array[array.length - 1] : ''

                        this.setState({ passPortFileName: FileName })

                        // data.data['registrationDetails'].length > 1 ? sessionStorage.setItem('VisitorTypeID', 2) : sessionStorage.setItem('VisitorTypeID', 1)
                        var AccountIDArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs).split(',')
                        var VisitorIDArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs).split(',')
                        var UserIDArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(',')
                        var VisitorRegNoArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNos).split(',')
                        var MobNoArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).mobNo).split(',')
                        var EmailArr = sessionStorage.getItem('VisitorTypeID') == 2 && (JSON.parse(sessionStorage.getItem('VisitorDataSS')).email).split(',')
                        if (data.data['registrationDetails'].length > 1) {
                            data.data['registrationDetails'].map((item, i) => {
                                if (item.isCoordinator) {
                                    if (item.isVisit) {
                                        // this.setState({ isCoordinatorVisit: true })
                                    }
                                }
                            })
                        }
                        var finalArr = []
                        // if (this.props.location.state.is_previous != true) {
                        AccountIDArr.length > 0 && AccountIDArr.map((element, i) => {
                            var mob = MobNoArr[i].split('-')
                            var email = EmailArr[i]
                            var obj = { 'AccountID': element, 'passName': '', 'passExipryDateErr': '', 'passNameErr': '', 'preapplicantMobNo': mob[0], 'applicantMobNo': mob[1], 'applicantMobNoErr': '', 'applicantEmail': email, 'applicantEmailErr': '', 'passNo': '', 'passNoErr': '', 'passDate': '', 'passDateErr': '', 'passExipryDate': '', 'passCountry': '', 'passCountryId': '', 'passCountryErr': '', 'isPassportFilePresent': false, 'passportFileErr': '', 'passportFile': '', 'isDisplayPassCountryList': false, 'passportFileType': '', }
                            finalArr.push(obj)
                        })
                        for (var i = 0; i < finalArr.length; i++) {
                            finalArr[i].visitorID = VisitorIDArr[i]
                            finalArr[i].UserID = UserIDArr[i]
                            finalArr[i].VisitorRegistrationNo = VisitorRegNoArr[i]
                        }
                        // this.setState({ visitorGroupList: finalArr })
                        // }
                        for (var a = 0; a < finalArr.length; a++) {
                            if (data.data['registrationDetails'].length > 1) {
                                data.data['registrationDetails'].map((item, i) => {
                                    if (finalArr[a].AccountID == item.AccountID) {
                                        console.log('..................', item['PassportFilePath'])
                                        var filePath = item.PassportFilePath != null ? item['PassportFilePath'] : ''
                                        var array = item['PassportFilePath'] != null && item['PassportFilePath'] != "" && filePath != '' ? filePath.split("/") : []
                                        const FileName = array.length > 0 ? array[array.length - 1] : ''
                                        finalArr[a].passName = item.NameOnPassport
                                        finalArr[a].passNo = item.PassportNo
                                        finalArr[a].passDate = item.PassportIssueDate != null ? new Date(item.PassportIssueDate) : ''
                                        finalArr[a].passExipryDate = item.PassportExpiryDate != null ? new Date(item.PassportExpiryDate) : ''
                                        finalArr[a].passCountry = item.PassportIssuingCountryName
                                        finalArr[a].passCountryId = item.PassportIssuingCountryID
                                        finalArr[a].isPassportFilePresent = FileName != '' ? true : false
                                        finalArr[a].passportFile = FileName != '' ? FileName : ''
                                        finalArr[a].isCoordinator = item.isCoordinator != '' && item.isCoordinator != null ? item.isCoordinator : false
                                        finalArr[a].isVisit = item.isVisit != '' && item.isVisit != null ? item.isVisit : false
                                        finalArr[a].passportFilePath = item.PassportFilePath != null ? item['PassportFilePath'] : ''
                                        // finalArr.push(obj)
                                    }
                                    // this.state.GroupDetailsList.push(obj)
                                })
                            }
                        }
                        var finalfinalArr = []
                        for (var a = 0; a < finalArr.length; a++) {
                            if (finalArr[a].isCoordinator) {
                                finalfinalArr.push(finalArr[a])
                            }
                        }
                        this.setState({ visitorGroupList: finalfinalArr })

                        this.setState({

                            countryId: data.data['registrationDetails'][0]['CountryID'] != null ? data.data['registrationDetails'][0]['CountryID'] : '',
                            attendeeTypeId: data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : '',
                            attendeeList: attendeeList,
                            daysList: daysList,
                            preName: data.data['registrationDetails'][0]['Title'] != null ? data.data['registrationDetails'][0]['Title'] : '',
                            firstName: data.data['registrationDetails'][0]['FirstName'] != null ? data.data['registrationDetails'][0]['FirstName'] : '',
                            middleName: data.data['registrationDetails'][0]['MiddleName'] != null ? data.data['registrationDetails'][0]['MiddleName'] : '',
                            lastName: data.data['registrationDetails'][0]['LastName'] != null ? data.data['registrationDetails'][0]['LastName'] : '',
                            designation: data.data['registrationDetails'][0]['Designation'] != null ? data.data['registrationDetails'][0]['Designation'] : '',
                            institute: data.data['registrationDetails'][0]['Institute_Organisation'] != null ? data.data['registrationDetails'][0]['Institute_Organisation'] : '',
                            isSameAsMobNo: data.data['registrationDetails'][0]['IsSameAsMobileNo'] != null ? data.data['registrationDetails'][0]['IsSameAsMobileNo'] : '',
                            regState: data.data['registrationDetails'][0]['State'] != null ? data.data['registrationDetails'][0]['State'] : '',
                            city: data.data['registrationDetails'][0]['City'] != null ? data.data['registrationDetails'][0]['City'] : '',
                            pincode: data.data['registrationDetails'][0]['Pincode'] != null ? data.data['registrationDetails'][0]['Pincode'] : '',
                            website: data.data['registrationDetails'][0]['Website'] != null ? data.data['registrationDetails'][0]['Website'] : '',
                            email: data.data['registrationDetails'][0]['Email'] != null ? data.data['registrationDetails'][0]['Email'] : '',
                            chapterList: chapterList,
                            visitPurposeList: visitPurposeList,
                            primaryChapterListData: primaryChapterListData,
                            level2: level2,
                            level3: level3,
                            level4: level4,
                            isVisaApplied: data.data['registrationDetails'][0]['isVisaAssistance'] != null ? data.data['registrationDetails'][0]['isVisaAssistance'] : false,
                            isPassportFilePresent: data.data['registrationDetails'][0]['PassportFilePath'] != null && data.data['registrationDetails'][0]['PassportFilePath'] != "" ? true : false,
                            needVisaAssistance: data.data['registrationDetails'][0]['isVisaAssistance'] != null ? data.data['registrationDetails'][0]['isVisaAssistance'] : '',
                            passName: data.data['registrationDetails'][0]['NameOnPassport'] != null ? data.data['registrationDetails'][0]['NameOnPassport'] : '',
                            preapplicantMobNo: appMobNo != '' ? appMobCode : this.state.preapplicantMobNo,
                            applicantMobNo: appMobNo != '' ? appMobileNo : this.state.applicantMobNo,
                            applicantEmail: data.data['registrationDetails'][0]['ApplicantEmailID'] != null&& data.data['registrationDetails'][0]['ApplicantEmailID'] != '' ? data.data['registrationDetails'][0]['ApplicantEmailID'] : this.state.applicantEmail,
                            passNo: data.data['registrationDetails'][0]['PassportNo'] != null ? data.data['registrationDetails'][0]['PassportNo'] : '',
                            passDate: data.data['registrationDetails'][0]['PassportIssueDate'] != null ? new Date(data.data['registrationDetails'][0]['PassportIssueDate']) : '',
                            passExipryDate: data.data['registrationDetails'][0]['PassportExpiryDate'] != null ? new Date(data.data['registrationDetails'][0]['PassportExpiryDate']) : '',
                            passCountry: data.data['registrationDetails'][0]['PassportIssuingCountryName'] != null ? data.data['registrationDetails'][0]['PassportIssuingCountryName'] : '',
                            passCountryId: data.data['registrationDetails'][0]['PassportIssuingCountryID'] != null ? data.data['registrationDetails'][0]['PassportIssuingCountryID'] : '',
                            passportFile: data.data['registrationDetails'][0]['PassportFilePath'] != null ? data.data['registrationDetails'][0]['PassportFilePath'] : '',
                            interestList: interestList,
                            remarks: data.data['registrationDetails'][0]['Remarks'] != null ? data.data['registrationDetails'][0]['Remarks'] : '',
                            isAgree: data.data['registrationDetails'][0]['ISAgree'] != null ? data.data['registrationDetails'][0]['ISAgree'] : '',
                            isVisaApplied: data.data['registrationDetails'][0]['isVisaAssistance'] != null ? data.data['registrationDetails'][0]['isVisaAssistance'] : false,
                            isPassportFilePresent: data.data['registrationDetails'][0]['PassportFilePath'] != null && data.data['registrationDetails'][0]['PassportFilePath'] != "" ? true : false,
                            isGrpDelegation: data.data['registrationDetails'][0]['IsGroupDelegation'] != null ? data.data['registrationDetails'][0]['IsGroupDelegation'] : false,
                            leader: data.data['registrationDetails'][0]['DelegationName'] != null ? data.data['registrationDetails'][0]['DelegationName'] : "",
                            delegationCountry: data.data['registrationDetails'][0]['DelegationCountryName'] != null ? data.data['registrationDetails'][0]['DelegationCountryName'] : "",
                            delegationCountryId: data.data['registrationDetails'][0]['DelegationCountryID'] != null ? data.data['registrationDetails'][0]['DelegationCountryID'] : "",
                            arrivalDate: data.data['registrationDetails'][0]['DelegationArrivalDate'] != null ? new Date(data.data['registrationDetails'][0]['DelegationArrivalDate']) : '',
                            departureDate: data.data['registrationDetails'][0]['DelegationDepartureDate'] != null ? new Date(data.data['registrationDetails'][0]['DelegationDepartureDate']) : '',
                            delegationRemark: data.data['registrationDetails'][0]['DelegationRemark'] != null ? data.data['registrationDetails'][0]['DelegationRemark'] : "",
                            preffDate: data.data['registrationDetails'][0]['B2BDepartureDate'] != null ? new Date(data.data['registrationDetails'][0]['B2BDepartureDate']) : '',
                            prefTime: data.data['registrationDetails'][0]['B2BPreferredTime'] != null ? data.data['registrationDetails'][0]['B2BPreferredTime'] : "",
                            b2bRemark: data.data['registrationDetails'][0]['B2BRemark'] != null ? data.data['registrationDetails'][0]['B2BRemark'] : "",
                            isParkingAvailable: data.data['registrationDetails'][0]['CarparkingAvailable'] != null ? data.data['registrationDetails'][0]['CarparkingAvailable'] : "",
                            isParkingChargable: data.data['registrationDetails'][0]['ParkingChargable'] != null ? data.data['registrationDetails'][0]['ParkingChargable'] : false,
                            parkingCharges: data.data['registrationDetails'][0]['ParkingCharges'] != null ? data.data['registrationDetails'][0]['ParkingCharges'] : 0,
                            isCarParking: data.data['registrationDetails'][0]['CarParking'] != null ? data.data['registrationDetails'][0]['CarParking'] : 0,
                            isPaid: data.data['registrationDetails'][0]['CarParking'] != null ? data.data['registrationDetails'][0]['CarParking'] : 0,
                            seminarInterestList: seminarInterestList,
                            isLoading: false,
                        })

                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }, 3000);

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        var isPrevious = this.props.location.state != null ? this.props.location.state.is_previous : false
        return new Promise((resolve, reject) => {

            this.setState({ isLoading: true })
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value, from)
                .then((data) => {
                    if (data.error != 1) {
                        //Chapter List
                        if (actionID === primaryChapterListActionId) {
                            console.log("primaryChapterListActionId 121212", data.data)
                            async function seqnoFormat(arr) {
                                for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].seqno != '' && arr[i].seqno != null) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    }
                                }
                                return
                            }

                            for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                if (data.data['primaryChapter'][i].ChapterID == 2046) {
                                    data.data['primaryChapter'][i].ChapterName = 'TECHNICAL TEXTILE PRODUCTS & HOME TEXTILE PRODUCT'
                                }
                                if (data.data['primaryChapter'][i].ChapterID == 2033) {
                                    data.data['primaryChapter'].splice(i, 1)
                                }
                                console.log(data.data['primaryChapter'])
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                    var seq1 = data.data['level2'][j].seqno
                                    var seq2 = data.data['level2'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level2'][j]
                                        data.data['level2'][j] = data.data['level2'][j + 1]
                                        data.data['level2'][j + 1] = temp;
                                    }
                                }
                                data.data['level2'][i].primaryChapterId = data.data['level2'][i].ParentChapterID
                                seqnoFormat(data.data['level2'])
                            }
                            console.log(data.data['primaryChapter'])
                            for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                for (var j = 0; j < data.data['primaryChapter'].length - 1 - i; j++) {
                                    var seq1 = data.data['primaryChapter'][j].seqno
                                    var seq2 = data.data['primaryChapter'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['primaryChapter'][j]
                                        data.data['primaryChapter'][j] = data.data['primaryChapter'][j + 1]
                                        data.data['primaryChapter'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                if (data.data['primaryChapter'][i].seqno == null || data.data['primaryChapter'][i].seqno == undefined) {
                                    console.log(data.data['primaryChapter'][i].seqno, i)
                                }
                                // seqnoFormat(data.data['primaryChapter'])
                            }
                            for (var i = 0; i < data.data['level3'].length; i++) {
                                for (var j = 0; j < data.data['level3'].length - 1 - i; j++) {

                                    var seq1 = data.data['level3'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level3'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')

                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level3'][j]
                                        data.data['level3'][j] = data.data['level3'][j + 1]
                                        data.data['level3'][j + 1] = temp;
                                    }
                                }
                                for (var j = 0; j < data.data['level2'].length; j++) {
                                    if (data.data['level2'][j].ChapterID == data.data['level3'][i].ParentChapterID) {
                                        data.data['level3'][i].primaryChapterId = data.data['level2'][j].ParentChapterID
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level3'])
                            }
                            for (var i = 0; i < data.data['level4'].length; i++) {
                                for (var j = 0; j < data.data['level4'].length - 1 - i; j++) {
                                    var seq1 = data.data['level4'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level4'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level4'][j]
                                        data.data['level4'][j] = data.data['level4'][j + 1]
                                        data.data['level4'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                for (var j = 0; j < data.data['level3'].length; j++) {
                                    if (data.data['level3'][j].ChapterID == data.data['level4'][i].ParentChapterID) {
                                        for (var k = 0; k < data.data['level2'].length; k++) {
                                            if (data.data['level2'][k].ChapterID == data.data['level3'][j].ParentChapterID) {
                                                data.data['level4'][i].primaryChapterId = data.data['level2'][k].ParentChapterID
                                                break
                                            }
                                        }
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level4'])
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                data.data['level2'][i].isHighlight = false;
                            }
                            this.setState({
                                primaryChapterListData: data.data['primaryChapter'] ? data.data['primaryChapter'] : [],
                                level0: data.data['level0'] ? data.data['level0'] : [],
                                level2: data.data['level2'] ? data.data['level2'] : [],
                                level3: data.data['level3'] ? data.data['level3'] : [],
                                level4: data.data['level4'] ? data.data['level4'] : [],

                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                        // Industry Chapter List
                        if (actionID === industryChaptersActionId) {
                            console.log("industryChaptersActionId 121212", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.IsSelect = false
                            })
                            this.setState({
                                chapterList: data.data,
                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                        // Country List
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            this.setState({
                                countryList: data.data,
                                passCountryList: data.data,
                                delgationCountryList: data.data,
                                tempDelegationCountryList: data.data,
                                tempPassCountryList: data.data,
                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                        // Visit Purpose list
                        if (actionID === visitPurposeActionId) {
                            console.log(" Visit Purpose List data", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                                item.otherDetail = ""
                            })
                            this.setState({
                                visitPurposeList: data.data,
                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                        //Visitor Interest List
                        if (actionID === visitorInterestActionId) {
                            console.log("Visitor Interest List 121212", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                                item.AreaofInterestID == 2 && (item.status = sessionStorage.getItem('isVisitorB2B'))
                                item.AreaofInterestID == 1 && (item.status = sessionStorage.getItem('isVisitorSeminar'))
                            })
                            this.setState({
                                interestList: data.data,
                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                        //Visitor Semninar Interest List
                        if (actionID === seminarInterestActionId) {
                            console.log("Visitor Interest List 121212", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isSelect = false
                            })
                            this.setState({
                                seminarInterestList: data.data,
                            })
                            if (!isPrevious) {
                                this.setState({ isLoading: false, })
                            }
                            resolve(true)
                        }
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    // on check level 2
    // onCheckLevel2(e, id) {
    //     const { level2, subChapters } = this.state
    //     console.log("check 22222222222222")
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level2.length; i++) {
    //         if (level2[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level2[i])) {
    //                     level2[i].isLengthValidate = false;
    //                     subChapters.push(level2[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level2[i]['ChapterID']) })
    //             }

    //             level2[i]['IsSelect'] = !level2[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         this.setState({ level2: level2 })
    //     }, 200);
    // }

    // on check level 3
    // onCheckLevel3(e, id) {
    //     const { level3, subChapters } = this.state
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level3.length; i++) {
    //         if (level3[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level3[i])) {
    //                     level3[i].isLengthValidate = false;
    //                     subChapters.push(level3[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level3[i]['ChapterID']) })
    //             }
    //             level3[i]['IsSelect'] = !level3[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         this.setState({ level3: level3 })
    //     }, 200);
    // }

    // on check level 4
    // onSelectLevel4(e, id) {
    //     const { level4, subChapters } = this.state
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level4.length; i++) {
    //         if (level4[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level4[i])) {
    //                     level4[i].isLengthValidate = false;
    //                     subChapters.push(level4[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level4[i]['ChapterID']) })
    //             }

    //             level4[i]['IsSelect'] = !level4[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         this.setState({ level4: level4 })
    //     }, 200);
    // }

    // to call level third list 
    // record(level2, level3, level4) {
    //     // var newdata = JSON.stringify(data);
    //     var tempLevel3 = [];
    //     for (var j = 0; j < level3.length; j++) {
    //         if (level2.ChapterID == level3[j]['ParentChapterID']) {
    //             tempLevel3.push(level3[j]);
    //         }
    //     }
    //     return (
    //         <>
    //             <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} data-bs-parent="#accordionExample">
    //                 {
    //                     tempLevel3 && tempLevel3.map(level3data => {
    //                         return (
    //                             <div class="accordion-body">
    //                                 <div class="accordion-item">
    //                                     <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
    //                                         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level3data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level3data.ChapterID}>
    //                                             {/* // checkbox for level3 */}
    //                                             {
    //                                                 level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
    //                                                     name="" key={level3data.ChapterID} checked={level3data.IsSelect}
    //                                                     onClick={e => this.onCheckLevel3(e, level3data.ChapterID)}
    //                                                 />
    //                                             }

    //                                             {level3data.ChapterName} </button>
    //                                     </h2>
    //                                     {this.recordFour(level3data, level4)}
    //                                 </div>
    //                             </div>
    //                         )
    //                     })
    //                 }
    //             </div>
    //         </>
    //     );
    // }

    // to call level four list
    // recordFour(level3data, level4) {
    //     var tempLevel4 = [];
    //     for (var j = 0; j < level4.length; j++) {
    //         if (level3data.ChapterID == level4[j]['ParentChapterID']) {
    //             tempLevel4.push(level4[j]);
    //         }
    //     }
    //     return (<>
    //         <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
    //             {
    //                 tempLevel4 && tempLevel4.map(level4data => {
    //                     return (
    //                         <div class="accordion-body third-step">
    //                             {/* level4data.IsCheckbox && */}
    //                             <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
    //                                 onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
    //                             {level4data.ChapterName}
    //                         </div>
    //                     )
    //                 })
    //             }
    //         </div>
    //     </>);
    // }

    // On Purpose Select
    onSelectPurpose(e) {
        const { visitPurposeList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        visitPurposeList.length > 0 && visitPurposeList.map((data) => {
            if (checked) {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = true
                }
            }
            else {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = false
                    data.otherDetail = ""
                }

            }
        })
        console.log("hgdddhdjdjdjdkdj", visitPurposeList)
        this.setState({ visitPurposeList: visitPurposeList, visitPurposeErr: "" })
    }

    // On Primary Chapter Select
    onSelectPrimaryChapter(e) {
        const { chapterList } = this.state
        var id = e.target.value
        var checked = e.target.checked

        chapterList.length > 0 && chapterList.map((data) => {
            if (checked) {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = true
                    if (id == 16) {
                        this.setState({ isOtherOpen: true })
                    }
                }
            }
            else {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = false
                    if (id == 16) {
                        this.setState({ isOtherOpen: false })
                    }
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", chapterList)
        this.setState({ chapterList: chapterList, chapterErr: "" })
    }

    // On Select Interests
    onSelectInterest(e) {
        const { interestList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        interestList.length > 0 && interestList.map((data) => {
            if (checked) {

                if (code == data.AreaofInterestID) {
                    data.isSelect = true
                    if (code == 1) {
                        this.setState({
                            isInterstOne: true
                        })
                    } else if (code == 2) {
                        this.setState({ isInterestTwo: true })
                    }

                }
            }
            else {
                data.isSelect = false
                if (code == 1) {
                    this.setState({
                        isInterstOne: false
                    })
                } else if (code == 2) {
                    this.setState({ isInterestTwo: false })
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", interestList)
        this.setState({ interestList: interestList })
    }

    // On Previous Click
    onPrevClick(e) {
        e.preventDefault()
        const { accountId, visitorId } = this.state
        this.props.navigate(`/visitorregistration/${sessionStorage.getItem("sessionExhibitionID")}`, { state: { is_previous: true } })
    }

    // Input Validation
    inputValidate() {
        const { passName, applicantMobNo, applicantEmail, passNo, passDate, passExipryDate, passCountryId, needVisaAssistance,
            passportFile, level1, visitPurposeList, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
            delegationRemark, chapterList, isInterestTwo, prefTime, visitorGroupList, preffDate, b2bRemark, primaryChapterListData, level2, level3, level4 } = this.state

        if (!(chapterList.some(obj => obj.IsSelect == true))) {
            this.setState({ chapterErr: "Please Select Chapter" })
        }
        if (!(visitPurposeList.some(obj => obj.isSelect == true))) {
            this.setState({ visitPurposeErr: "Please Select Purpose of visit" })
        }
        var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
        if (!(filerArray.some(obj => obj.IsSelect == true))) {
            this.setState({ ChapterHallErr: "Please Select Hall" })
        }
        if (isGrpDelegation) {
            if (leader == "") {
                this.setState({ leaderErr: "Please enter Leader/Organisation" });
            }
            if (delegationCountryId == "") {
                this.setState({ delegationCountryErr: "Please enter Country" });
            }
            if (arrivalDate == "") {
                this.setState({ arrivalDateErr: "Please enter Arrival Date" });
            }
            if (departureDate == "") {
                this.setState({ departureDateErr: "Please enter Departure Date" });
            }
            if (delegationRemark == "") {
                this.setState({ delegationRemarkErr: "Please enter Delegation Remark" });
            }
        }
        else {
            this.setState({
                leaderErr: "",
                delegationCountryErr: "",
                arrivalDateErr: "",
                departureDateErr: "",
                delegationRemarkErr: "",
            })
        }
        if (needVisaAssistance && sessionStorage.getItem('VisitorTypeID') == 1) {
            if (passName == "") {
                this.setState({ passNameErr: "Please enter Name " });
            }
            if (applicantMobNo == "") {
                this.setState({ applicantMobNoErr: "Please enter Mobile NO " });
            }
            if (applicantEmail == "") {
                this.setState({ applicantEmailErr: "Please enter Email " });
            }

            if (passNo == "") {
                this.setState({ passNoErr: "Please enter Passport No " });
            }
            if (passDate == "") {
                this.setState({ passDateErr: "Please enter Passport Date " });
            }
            if (passExipryDate == "") {
                this.setState({ passExipryDateErr: "Please enter Passport Expiry Date " });
            }
            if (passCountryId == "") {
                this.setState({ passCountryErr: "Please Select Country " });
            }
            if (passportFile == "") {
                this.setState({ passportFileErr: "Please Upload File " });
            }
            if (applicantEmail != "") {
                var emailError = emailValidator(applicantEmail)
                if (emailError != '') {
                    this.setState({ applicantEmailErr: emailError })
                    return
                }
            }

        }
        else {
            this.setState({
                passNameErr: "",
                applicantMobNoErr: "",
                applicantEmailErr: "",
                passNoErr: "",
                passDateErr: "",
                passExipryDateErr: "",
                passCountryErr: "",
                passportFileErr: ""
            })
        }
        if (needVisaAssistance && sessionStorage.getItem('VisitorTypeID') == 2) {
            for (var i = 0; i < visitorGroupList.length; i++) {
                if (visitorGroupList[i].passName == "") {
                    visitorGroupList[i].passNameErr = "Please enter Name "
                }
                if (visitorGroupList[i].applicantMobNo == "") {
                    visitorGroupList[i].applicantMobNoErr = "Please enter Mobile NO  "
                    // this.setState({ applicantMobNoErr: "Please enter Mobile NO " });
                }
                if (visitorGroupList[i].applicantEmail == "") {
                    visitorGroupList[i].applicantEmailErr = "Please enter Email "
                    // this.setState({ applicantEmailErr: "Please enter Email " });
                }

                if (visitorGroupList[i].passNo == "") {
                    visitorGroupList[i].passNoErr = "Please enter Passport NO  "
                    // this.setState({ passNoErr: "Please enter Passport No " });
                }
                if (visitorGroupList[i].passDate == "") {
                    visitorGroupList[i].passDateErr = "Please enter Passport Date  "
                    // this.setState({ passDateErr: "Please enter Passport Date " });
                }
                if (visitorGroupList[i].passExipryDate == "") {
                    visitorGroupList[i].passExipryDateErr = "Please enter Passport Expiry Date "
                    // this.setState({ passExipryDateErr: "Please enter Passport Expiry Date " });
                }
                if (visitorGroupList[i].passCountryId == "") {
                    visitorGroupList[i].passCountryErr = "Please enter Country  "
                    // this.setState({ passCountryErr: "Please Select Country " });
                }
                if (visitorGroupList[i].passportFile == "") {
                    visitorGroupList[i].passportFileErr = "Please enter  Upload File  "
                    // this.setState({ passportFileErr: "Please Upload File " });
                }
                if (visitorGroupList[i].applicantEmail != "") {
                    var emailError = emailValidator(visitorGroupList[i].applicantEmail)
                    if (emailError != '') {
                        visitorGroupList[i].applicantEmailErr = emailError
                        // this.setState({ applicantEmailErr: emailError })
                        return
                    }
                }
            }
            this.setState({ visitorGroupList: visitorGroupList })
        }
        else {
            for (var i = 0; i < visitorGroupList.length; i++) {
                visitorGroupList[i].passNameErr = ''
                visitorGroupList[i].applicantMobNoErr = ''
                visitorGroupList[i].applicantEmailErr = ''
                visitorGroupList[i].passNoErr = ''
                visitorGroupList[i].passDateErr = ''
                visitorGroupList[i].passExipryDateErr = ''
                visitorGroupList[i].passCountryErr = ''
                visitorGroupList[i].passportFileErr = ''
            }
            this.setState({ visitorGroupList: visitorGroupList })
        }
        if (isInterestTwo) {
            if (preffDate == "") {
                this.setState({ preffDateErr: "Please enter Preffered Date" });
            }
            if (prefTime == "") {
                this.setState({ preffTimeErr: "Please enter Preffered Time" });
            }
        } else {
            this.setState({
                preffTimeErr: "", preffTimeErr: ""
            })
        }

    }

    // Nav To Payment
    navToPayment() {
        const { visitorId, parkingCharges, countryId } = this.state

        var cancel_url = `${BASE_URL}/visitor/paymentResponse`
        var redirect_url = `${BASE_URL}/visitor/paymentResponse`;//need to ask to redirect

        var request = {
            order_id: `${JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID}`,
            currency: `${countryId == 91 ? 'INR' : 'USD'}`,
            amount: `${parkingCharges}`,
            redirect_url: `${redirect_url}`,
            cancel_url: `${redirect_url}`,
            merchant_param1: `${'1'}`,
            language: `EN`
        }

        var mapData = {
            'orderParams': request
        }

        this.props.payment_action(mapData)
            .then((data) => {
                if (data != null) {
                    console.log("payment response : ", data)
                    this.setState({ encPayload: data.data })
                    setTimeout(() => {
                        console.log("submit form", this.submitfrm)
                        this.submitfrm.current.submit()
                        // this.props.navigate('/payment', { state: { encPayload: this.state.encPayload, } })
                    }, 1000);
                } else {
                    this.setState({ isLoading: false })
                    toast.error('There is some problem. Please try again later!', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Visitor Registration Submit
    onSubmit(e) {
        e.preventDefault()
        this.inputValidate()
        setTimeout(() => {
            const { visitPurposeList, interestList, chapterList, other, seminarInterestList, primaryChapterListData, level2, level3, level4
            } = this.state
            var visitPurposeListValidation = false
            for (var i = 0; i < visitPurposeList.length; i++) {
                if (visitPurposeList[i].isSelect == true) {
                    visitPurposeListValidation = true
                }
            }
            // Checking if atleast one chapter is selected
            var isAnyChapterSelected = chapterList.some(obj => obj.IsSelect)

            // Preparing Udt For Chapter List
            const selectedChapter = chapterList.filter(data => data.IsSelect == true)
            var chapterListData = []
            selectedChapter.map((data) => {
                var data = {
                    "ChapterID": data.IndustrySegmentID,
                    "ChapterNames": data['Industrial Segment'],
                    "OtherNames": data.IndustrySegmentID == 16 ? other : ''
                }
                chapterListData.push(data)
            })


            // Preparing Udt For Hall Chapter List
            var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
            var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
            var chapterListData2 = []
            filerArray.map((data) => {
                var data = {
                    "ChapterID": data.ChapterID,
                    "ChapterNames": data.ChapterName,
                    // "OtherNames": ''

                }
                chapterListData2.push(data)
            })
            // Preparing Udt For Purpose Of Visit List
            const selectedVisitPurposeList = visitPurposeList.filter(data => data.isSelect == true)
            var purposeList = []
            selectedVisitPurposeList.map((data) => {
                var data = {
                    "PurposeOfVisitID": data.PurposeofVisitID,
                    "OtherDetails": data.otherDetail
                }
                purposeList.push(data)
            })
            // Preparing Udt For Interest List
            const selectedInterestList = interestList.filter(data => data.isSelect == true)
            var interestListData = []
            selectedInterestList.map((data) => {
                var data = {
                    "AreaOfInterestID": data.AreaofInterestID,
                }
                interestListData.push(data)
            })
            // Preparing Udt For Seminar Interest List
            const selectedSeminarList = seminarInterestList.filter(data => data.isSelect == true)
            var seminarInterestListData = []
            selectedSeminarList.map((data) => {
                var data = {
                    "SeminarID": data.SeminarID,
                }
                seminarInterestListData.push(data)
            })
            const { passNoErr, passNameErr, applicantMobNoErr, applicantEmailErr, passDateErr, passExipryDateErr,
                passCountryErr, passportFileErr, isAgree, needVisaAssistance, passName, applicantMobNo, applicantEmail,
                passNo, passDate, passExipryDate, passCountryId, passportFile, remarks, passportFileType, visitPurposeErr, chapterErr,
                preapplicantMobNo, isGrpDelegation, leader, delegationCountryId, departureDate, arrivalDate, delegationRemark, leaderErr,
                delegationCountryErr, arrivalDateErr, departureDateErr, delegationRemarkErr, prefTime, preffDate, preffDateErr, preffTimeErr,
                isInterstOne, b2bRemark, isCarParking, visitorId, isParkingAvailable, isParkingChargable, parkingCharges,
                ChapterHallErr
            } = this.state
            if (passNameErr == "" && applicantMobNoErr == "" && applicantEmailErr == "" && passNoErr == "" && passDateErr == "" &&
                passExipryDateErr == "" && passCountryErr == "" && passportFileErr == "" && chapterErr == "" && visitPurposeErr == ""
                && leaderErr == "" && delegationCountryErr == "" && arrivalDateErr == "" && departureDateErr == "" && delegationRemarkErr == "" &&
                preffDateErr == "" && preffTimeErr == "" && ChapterHallErr == "") {
                if (isInterstOne ? seminarInterestList.some(obj => obj.isSelect) : true) {
                    if (isAgree) {
                        console.log("Submitted")

                        sessionStorage.setItem("sessionapplicantMobNoPre", preapplicantMobNo)
                        this.setState({ isLoading: true })
                        this.props.visitorRegistrationSubmit_action(chapterListData, purposeList, needVisaAssistance, passName,
                            applicantMobNo, applicantEmail, passNo, passDate, passExipryDate, passCountryId, passportFile, interestListData
                            , remarks, isAgree, passportFileType, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
                            delegationRemark, preffDate, prefTime, seminarInterestListData, b2bRemark, isCarParking, parkingCharges,
                            chapterListData2)
                            .then((data) => {
                                if (data.error != 1) {
                                    console.log("On Submit Visitor Registration", data.data)
                                    this.setState({ isLoading: false })
                                    this.props.navigate('/visitorpreview')
                                    // if (isParkingAvailable && isParkingChargable && parkingCharges > 0 && isCarParking == 1) {
                                    //     this.navToPayment()
                                    // } else {
                                    //     this.props.navigate('/visitorthankyou')
                                    // }
                                }
                                else {
                                    this.setState({ isLoading: false })
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        toastError("Please Accept Terms & Conditions")
                    }
                } else {
                    toastError("Please Select atleast one Seminar")
                }
            } else {
                toastError("Please Input Mandatory Fields")
            }
        }, 500);
    }

    onSubmitGroup(e) {
        e.preventDefault()
        this.inputValidate()
        setTimeout(() => {
            const { visitPurposeList, interestList, chapterList, other, visitorGroupList, seminarInterestList, primaryChapterListData, level2, level3, level4
            } = this.state
            var visitPurposeListValidation = false
            for (var i = 0; i < visitPurposeList.length; i++) {
                if (visitPurposeList[i].isSelect == true) {
                    visitPurposeListValidation = true
                }
            }

            var visitorGroupListValidation = false
            for (var i = 0; i < visitorGroupList.length; i++) {
                if (visitorGroupList[i].passNameErr != '' ||
                    visitorGroupList[i].applicantMobNoErr != '' ||
                    visitorGroupList[i].applicantEmailErr != '' ||
                    visitorGroupList[i].passNoErr != '' ||
                    visitorGroupList[i].passDateErr != '' ||
                    visitorGroupList[i].passExipryDateErr != '' ||
                    visitorGroupList[i].passCountryErr != '' ||
                    visitorGroupList[i].passportFileErr != '') {
                    visitorGroupListValidation = true
                    break
                }
            }
            // Checking if atleast one chapter is selected
            var isAnyChapterSelected = chapterList.some(obj => obj.IsSelect)

            // Preparing Udt For Chapter List
            const selectedChapter = chapterList.filter(data => data.IsSelect == true)
            var chapterListData = []
            selectedChapter.map((data) => {
                var data = {
                    "ChapterID": data.IndustrySegmentID,
                    "ChapterNames": data['Industrial Segment'],
                    "OtherNames": data.IndustrySegmentID == 16 ? other : ''
                }
                chapterListData.push(data)
            })


            // Preparing Udt For Hall Chapter List
            var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
            var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
            var chapterListData2 = []
            filerArray.map((data) => {
                var data = {
                    "ChapterID": data.ChapterID,
                    "ChapterNames": data.ChapterName,
                    // "OtherNames": ''

                }
                chapterListData2.push(data)
            })
            // Preparing Udt For Purpose Of Visit List
            const selectedVisitPurposeList = visitPurposeList.filter(data => data.isSelect == true)
            var purposeList = []
            selectedVisitPurposeList.map((data) => {
                var data = {
                    "PurposeOfVisitID": data.PurposeofVisitID,
                    "OtherDetails": data.otherDetail
                }
                purposeList.push(data)
            })
            // Preparing Udt For Interest List
            const selectedInterestList = interestList.filter(data => data.isSelect == true)
            var interestListData = []
            selectedInterestList.map((data) => {
                var data = {
                    "AreaOfInterestID": data.AreaofInterestID,
                }
                interestListData.push(data)
            })
            // Preparing Udt For Seminar Interest List
            const selectedSeminarList = seminarInterestList.filter(data => data.isSelect == true)
            var seminarInterestListData = []
            selectedSeminarList.map((data) => {
                var data = {
                    "SeminarID": data.SeminarID,
                }
                seminarInterestListData.push(data)
            })
            const { passNoErr, passNameErr, applicantMobNoErr, applicantEmailErr, passDateErr, passExipryDateErr,
                passCountryErr, passportFileErr, isAgree, needVisaAssistance, passName, applicantMobNo, applicantEmail,
                passNo, passDate, passExipryDate, passCountryId, passportFile, remarks, passportFileType, visitPurposeErr, chapterErr,
                preapplicantMobNo, isGrpDelegation, leader, delegationCountryId, departureDate, arrivalDate, delegationRemark, leaderErr,
                delegationCountryErr, arrivalDateErr, isCoordinatorVisit, departureDateErr, delegationRemarkErr, prefTime, preffDate, preffDateErr, preffTimeErr,
                isInterstOne, b2bRemark, isCarParking, visitorId, isParkingAvailable, isParkingChargable, parkingCharges,
                ChapterHallErr
            } = this.state
            if (visitPurposeErr == "" && isCoordinatorVisit ? (chapterErr == "" && leaderErr == "" && delegationCountryErr == "" && arrivalDateErr == "" && departureDateErr == "" && delegationRemarkErr == "" && preffDateErr == "" && preffTimeErr == "" && ChapterHallErr == "" && !visitorGroupListValidation) : true) {
                if (isInterstOne ? seminarInterestList.some(obj => obj.isSelect) : true) {
                    if (isAgree) {
                        console.log("Submitted")

                        // sessionStorage.setItem("sessionapplicantMobNoPre", preapplicantMobNo)
                        // this.setState({ isLoading: true })

                        this.props.visitorRegistrationGroupSubmit_action(isCoordinatorVisit ? chapterListData : [], purposeList, isCoordinatorVisit ? needVisaAssistance : false, isCoordinatorVisit ? interestListData : [], remarks, isAgree, passportFileType, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
                            delegationRemark, preffDate, prefTime, seminarInterestListData, b2bRemark, isCarParking, parkingCharges,
                            isCoordinatorVisit ? chapterListData2 : [], visitorGroupList.length > 0 ? visitorGroupList : [])
                            .then((data) => {
                                if (data.error != 1) {
                                    console.log("On Submit Visitor Registration", data.data)
                                    this.setState({ isLoading: false })
                                    this.props.navigate('/visitorpreview')
                                    // if (isParkingAvailable && isParkingChargable && parkingCharges > 0 && isCarParking == 1) {
                                    //     this.navToPayment()
                                    // } else {
                                    //     this.props.navigate('/visitorthankyou')
                                    // }
                                }
                                else {
                                    this.setState({ isLoading: false })
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        toastError("Please Accept Terms & Conditions")
                    }
                } else {
                    toastError("Please Select atleast one Seminar")
                }
            } else {
                toastError("Please Input Mandatory Fields")
            }
        }, 500);
    }

    // On File Pick
    onFilePick = async (e, file, key, i) => {
        e.preventDefault()
        const { visitorGroupList } = this.state
        console.log("File01010101------", file, key)
        const { } = this.state
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {

                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        if (key == 1) {
                            if (sessionStorage.getItem('VisitorTypeID') == 2) {
                                visitorGroupList[i].passportFile = image
                                visitorGroupList[i].passportFileErr = ''
                                this.setState({ visitorGroupList: visitorGroupList })
                            } else {
                                this.setState({ passportFile: image, passportFileErr: "" })
                            }
                        } else if (key == 2) {
                            this.setState({ visitorLetterFile: image })
                        }
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelect(file[0]);
                        if (key == 1) {
                            if (sessionStorage.getItem('VisitorTypeID') == 2) {
                                visitorGroupList[i].passportFile = image
                                this.setState({ visitorGroupList: visitorGroupList })
                            } else {
                                this.setState({ passportFile: image, passportFileErr: "" })
                            }
                            // this.setState({ passportFile: image, passportFileErr: "" })
                        } else if (key == 2) {
                            this.setState({ visitorLetterFile: image })
                        }
                    }

                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")
                    }
                    if (key == 1) {
                        if (sessionStorage.getItem('VisitorTypeID') == 2) {
                            visitorGroupList[i].passportFileType = fileTypeList.length > 0 ? fileTypeList[1] : ""
                            this.setState({ visitorGroupList: visitorGroupList })
                        } else {
                            this.setState({ passportFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        }
                    } else if (key == 2) {
                        this.setState({ visitorLetterFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                    }
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                    // if (key == 1) {
                    //     this.setState({ passportFile: "" })
                    //     document.getElementById("passportFile").value = ""
                    // } else if (key == 2) {
                    //     this.setState({ visitorLetterFile: "" })
                    //     document.getElementById("visitorLetter").value = ""
                    // }
                }
            }
        }
    }

    // If Pdf is selected in file
    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    // File resize
    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    // on Cross click
    onCrossClick(key, i) {
        const { passportFileType, isPassportFilePresent, passportFile, visitorGroupList } = this.state
        // i = i - 1
        console.log(isPassportFilePresent, i + 1)
        if (sessionStorage.getItem('VisitorTypeID') == 1) {
            if (isPassportFilePresent) {
                const { isOpen2 } = this.state
                this.setState({ isOpen2: !isOpen2 })
                console.log("condition------------1111111111")
            } else {
                console.log("condition------------2222222222")
                if (key == 1) {
                    this.setState({ passportFile: "" })
                    document.getElementById("passportFile").value = ""
                } else if (key == 2) {
                    this.setState({ visitorLetterFile: "" })
                    document.getElementById("visitorLetter").value = ""
                }
            }
        }
        if (sessionStorage.getItem('VisitorTypeID') == 2) {
            if (isPassportFilePresent) {
                const { isOpen2 } = this.state
                this.setState({ isOpen2: !isOpen2, docDelAccountID: visitorGroupList[i].AccountID, docDelExhVisitorID: visitorGroupList[i].ExhVisitorID })
                console.log("condition------------1111111111")
            } else {
                console.log("condition------------2222222222")
                if (key == 1) {
                    if (sessionStorage.getItem('VisitorTypeID') == 2) {
                        console.log(i + 1)
                        visitorGroupList[i].passportFile = ''
                        this.setState({ visitorGroupList: visitorGroupList })
                        document.getElementById(`path${i}`).value = ""
                    } else {
                        this.setState({ passportFile: "" })
                        document.getElementById("passportFile").value = ""
                    }
                } else if (key == 2) {
                    this.setState({ visitorLetterFile: "" })
                    document.getElementById("visitorLetter").value = ""
                }
            }
        }
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    // On Delegation Country Search
    onDelegationCountrySearch(e) {
        const { delgationCountryList, tempDelegationCountryList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempDelegationCountryList ++++----------->", tempDelegationCountryList)

            var searchCoutryList = tempDelegationCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                delegationCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                delegationCountryErr: '',
                tempDelegationCountryList: searchCoutryList,
                isDisplayDelegationCountryList: true
            })
        }
        else {

            this.setState({
                delegationCountry: e.target.value,
                tempDelegationCountryList: delgationCountryList,
                isDisplayDelegationCountryList: false
            })
        }
    }

    // Render Country for Delegation Country
    renderDelegationCountryList() {
        const { tempDelegationCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempDelegationCountryList.length > 0 && tempDelegationCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                delegationCountry: data.Description, delegationCountryId: data.Code,
                                isDisplayDelegationCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On PassPort Country Search
    onPassportCountrySearch(e, id) {
        const { passCountryList, tempPassCountryList, visitorGroupList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempPassCountryList ++++----------->", tempPassCountryList)

            var searchCoutryList = tempPassCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)
            sessionStorage.getItem('VisitorTypeID') == 1 && this.setState({
                passCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                passCountryErr: '',
                tempPassCountryList: searchCoutryList,
                isDisplayPassCountryList: true
            })
            if (sessionStorage.getItem('VisitorTypeID') == 2) {
                console.log("searchCoutryList ++++----------->", e.target.value)
                visitorGroupList[id].isDisplayPassCountryList = true
                visitorGroupList[id].passCountryErr = ''
                visitorGroupList[id].passCountry = e.target.value
                this.setState({ visitorGroupList: visitorGroupList, tempPassCountryList: searchCoutryList, })
            }
        }
        else {
            sessionStorage.getItem('VisitorTypeID') == 1 && this.setState({
                passCountry: e.target.value,
                tempPassCountryList: passCountryList,
                isDisplayPassCountryList: false
            })
            if (sessionStorage.getItem('VisitorTypeID') == 2) {
                visitorGroupList[id].isDisplayPassCountryList = false
                visitorGroupList[id].passCountry = e.target.value
                this.setState({ visitorGroupList: visitorGroupList, tempPassCountryList: passCountryList, })
            }
        }
    }

    onVisitorCountryselect(id, desc, code) {
        const { visitorGroupList, } = this.state
        visitorGroupList.map((data, index) => {
            if (id == index) {
                data.passCountryId = code
                data.passCountry = desc
                data.isDisplayPassCountryList = false
            }
        })
        this.setState({ visitorGroupList: visitorGroupList })
    }

    // Render Country for PassPort Country
    renderPassCountryList(id) {
        const { tempPassCountryList, } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPassCountryList.length > 0 && tempPassCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => sessionStorage.getItem('VisitorTypeID') == 2 ? this.onVisitorCountryselect(id, data.Description, data.Code) : this.setState({
                                passCountry: data.Description, passCountryId: data.Code,
                                isDisplayPassCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // Other Detail In purpose of visit
    otherDetailsChange(e) {
        const { visitPurposeList } = this.state
        var value = e.target.value

        visitPurposeList.length > 0 && visitPurposeList.map((data) => {
            if (data.PurposeofVisitID == 10 && data.isSelect) {
                data.otherDetail = value
            } else {
                data.otherDetail = ""
            }
        })
        this.setState({ visitPurposeList: visitPurposeList })
        console.log("sssssssssssssssssssssss", visitPurposeList)
    }

    // On Checked seminar
    onCheckedSeminar(e, id) {
        const { seminarInterestList } = this.state
        const checked = e.target.checked
        seminarInterestList.length > 0 && seminarInterestList.map((data) => {
            if (data.SeminarID == id) {
                if (checked) {
                    data.isSelect = true
                } else {
                    data.isSelect = false
                }
            }
        })
        console.log("aaaaaaaaaaa", seminarInterestList)
        this.setState({})
    }

    // On Check Preferred Time
    handlePrefTime(e) {
        const prefTiming = e.target.value;
        if (prefTiming >= '09:00' && prefTiming <= '17:00') {
            this.setState({ prefTime: prefTiming });
        } else {
            toastError('Preferred time must be between 9:00 and 17:00')
        }
    }


    onFileClick() {
        this.toggleModal()
        // const url = "images/B2BEXHIBITOR.pdf"
        // window.open(url, "_blank");
    }

    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    getParkingDetails() {
        var isPrevious = this.props.location.state != null ? this.props.location.state.is_previous : false
        this.setState({ isLoading: true })
        this.props.getCarParkingDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("aaaaaaaaaaaaa", data.data)
                    this.setState({
                        isParkingAvailable: data.data[0] != null ? data.data[0]['CarparkingAvailable'] : false,
                        isParkingChargable: data.data[0] != null ? data.data[0]['ParkingChargable'] : false,
                        parkingCharges: data.data[0] != null ? data.data[0]['ParkingCharges'] : 0,
                    })
                    if (!isPrevious) {
                        this.setState({ isLoading: false, })
                    }
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // on check level 1
    // onCheckLevel1(e, id) {
    //     const { primaryChapterListData, subChapters } = this.state

    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < primaryChapterListData.length; i++) {
    //         if (primaryChapterListData[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(primaryChapterListData[i])) {
    //                     primaryChapterListData[i].isLengthValidate = false;
    //                     subChapters.push(primaryChapterListData[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != primaryChapterListData[i]['ChapterID']) })
    //             }

    //             primaryChapterListData[i]['IsSelect'] = !primaryChapterListData[i]['IsSelect']
    //         }
    //     }
    //     console.log("Level-------------> 1", primaryChapterListData)
    //     console.log("Subchapters---------->", subChapters)
    //     setTimeout(() => {
    //         this.setState({ primaryChapterListData: primaryChapterListData })
    //     }, 200);
    // }
    onCheckLevel1(e, id) {
        const { level2, primaryChapterListLevel2, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData, level3, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < primaryChapterListData.length; i++) {
            if (primaryChapterListData[i]['ChapterID'] == id) {
                if (event == true) {
                    primaryChapterListData[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })
                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = true

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = true

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = true

                                        }
                                    });
                                }
                            });
                        }
                    });

                } else {
                    primaryChapterListData[i]['IsSelect'] = false

                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = false

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = false

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = false

                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);

    }

    // on check level 3
    // onCheckLevel2(e, id) {
    //     const { level2, subChapters } = this.state
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level2.length; i++) {
    //         if (level2[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level2[i])) {
    //                     level2[i].isLengthValidate = false;
    //                     subChapters.push(level2[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level2[i]['ChapterID']) })
    //             }

    //             level2[i]['IsSelect'] = !level2[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         this.setState({ level2: level2 })
    //     }, 200);
    // }
    onCheckLevel2(e, id, tempLevel1) {
        const { level2, primaryChapterListLevel3, level3, primaryChapterListLevel4, primaryChapterListData, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {
                if (event == true) {
                    level2[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = true
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = true
                                }
                            });
                        }
                    });

                } else {
                    level2[i]['IsSelect'] = false

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = false
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = false
                                }
                            });
                        }
                    });
                }

            }
        }
        console.log("tempLevel1", tempLevel1)

        for (let i = 0; i < primaryChapterListData.length; i++) {
            for (let j = 0; j < level2.length; j++) {
                if (level2[j]['ChapterID'] == id) {
                    if (primaryChapterListData[i]['ChapterID'] == level2[j]['ParentChapterID']) {
                        const allSelectedChecked = tempLevel1.every(item => item.IsSelect == 1)
                        console.log("allSelectedChecked", allSelectedChecked)
                        if (allSelectedChecked) {
                            primaryChapterListData[i]['IsSelect'] = true
                        } else {
                            primaryChapterListData[i]['IsSelect'] = false
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);


    }

    // on check level 3
    // onCheckLevel3(e, id) {
    //     const { level3, subChapters } = this.state
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level3.length; i++) {
    //         if (level3[i]['ChapterID'] == id) {

    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level3[i])) {
    //                     level3[i].isLengthValidate = false;
    //                     subChapters.push(level3[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level3[i]['ChapterID']) })
    //             }

    //             level3[i]['IsSelect'] = !level3[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         this.setState({ level3: level3 })
    //     }, 200);
    // }
    onCheckLevel3(e, id, tempLevel3) {
        const { level3, primaryChapterListLevel4, level2, level4, primaryChapterListData } = this.state
        this.setState({ level4DummyId: id })
        const event = e.target.checked

        var modelevel2 = []
        var modelevel3 = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                console.log("***********", level3[i])
                level2.map((s) => {
                    if (level3[i].ParentChapterID == s.ChapterID) {
                        primaryChapterListData.map(q => {
                            if (s.ParentChapterID == q.ChapterID) {
                                modelevel3.push(q)
                            }
                        })
                    }
                })
            }
        }


        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })

        console.log("finalListlevel2", finalListlevel2)


        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                if (event == true) {
                    level3[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {

                            level4.IsSelect = true
                        }
                    });

                } else {
                    level3[i]['IsSelect'] = false
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {
                            level4.IsSelect = false
                        }
                    });

                }
            }
        }

        for (let i = 0; i < level3.length; i++) {
            for (let index = 0; index < level2.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    if (level3[i]['ChapterID'] == id) {
                        if (level2[index]['ChapterID'] == level3[i]['ParentChapterID']) {
                            if (primaryChapterListData[j]['ChapterID'] == level2[index]['ParentChapterID']) {
                                const allSelectedChecked = tempLevel3.every(item => item.IsSelect == 1)
                                if (allSelectedChecked) {
                                    level2[index]['IsSelect'] = true
                                    const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                    console.log("allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                    if (allSelectedfinalListlevel2) {
                                        primaryChapterListData[j]['IsSelect'] = true
                                    } else {
                                        primaryChapterListData[j]['IsSelect'] = false
                                    }
                                }
                                else {
                                    level2[index]['IsSelect'] = false
                                    primaryChapterListData[j]['IsSelect'] = false
                                }
                            }
                        }
                    }
                }

            }

        }
        setTimeout(() => {
            this.setState({ level3: level3, level2: level2 })
        }, 100);

    }

    // to call level second list 
    // record(level1, level2, level3, level4) {
    //     // var newdata = JSON.stringify(data);
    //     console.log("record1111111111111111")
    //     var tempLevel2 = [];
    //     for (var j = 0; j < level2.length; j++) {
    //         if (level1.ChapterID == level2[j]['ParentChapterID']) {
    //             tempLevel2.push(level2[j]);
    //         }
    //     }
    //     return (
    //         <>
    //             <div id={'collapse_l1_' + level1.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level1.ChapterID} data-bs-parent="#accordionExample">
    //                 {
    //                     tempLevel2 && tempLevel2.map(level2data => {
    //                         return (
    //                             <div class="accordion-body">
    //                                 <div class="accordion-item">
    //                                     <h2 class="accordion-header" id={'heading_l2_' + level2data.ChapterID}>
    //                                         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level2data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level2data.ChapterID}>
    //                                             {/* // checkbox for level2 */}
    //                                             {
    //                                                 level2data.IsCheckbox && <input type="checkbox" className="from-left-sp"
    //                                                     name="" key={level2data.ChapterID} checked={level2data.IsSelect}
    //                                                     onClick={e => this.onCheckLevel2(e, level2data.ChapterID)} />
    //                                             }

    //                                             {level2data.ChapterName} </button>
    //                                     </h2>
    //                                     {this.recordThree(level2data, level3, level4)}
    //                                 </div>
    //                             </div>
    //                         )
    //                     })
    //                 }
    //             </div>
    //         </>
    //     );
    // }
    // to call level second list
    record(level2data) {
        const { primaryChapterListLevel2, level2, level3, level4 } = this.state
        var tempLevel1 = [];
        for (var j = 0; j < level2.length; j++) {
            if (level2data.ChapterID == level2[j]['ParentChapterID']) {
                tempLevel1.push(level2[j]);
                primaryChapterListLevel2.push(level2[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2data.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel1.length > 0 && tempLevel1.map((level2data, i) => (
                            <>
                                {
                                    // level2data.ParentChapterID == primaryChapterRadio &&
                                    <div class="accordion-body">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level2data.ChapterID}>

                                                <button class={"accordion-button collapsed  tit-name"}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={'#collapse_l1_' + level2data.ChapterID} aria-expanded="false"
                                                    aria-controls={'collapse_l1_' + level2data.ChapterID}>
                                                    {level2data.IsCheckbox &&
                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level2data.ChapterID}
                                                            checked={level2data.IsSelect}
                                                            onClick={e => this.onCheckLevel2(e, level2data.ChapterID, tempLevel1)}
                                                        />
                                                    }
                                                    {level2data.ChapterName}
                                                </button>
                                            </h2>
                                            {this.recordTwo(level2data, level3, level4)}
                                        </div>
                                    </div>
                                }

                            </>
                        ))
                    }
                </div>
            </>
        );
    }

    // to call level third list 
    recordTwo(level2, level3, level4) {
        const { primaryChapterListLevel3 } = this.state
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
                primaryChapterListLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} >
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed " type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse_l2_' + level3data.ChapterID}
                                                aria-expanded="false"
                                                aria-controls={'collapse_l2_' + level3data.ChapterID}
                                            >
                                                {/* // checkbox for level3 */}
                                                {level3data.IsCheckbox &&
                                                    <input type="checkbox" className="from-left-sp"

                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID, tempLevel3)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordThree(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // to call level three list
    // recordThree(level2, level3, level4) {
    //     console.log("record22222222222222222")
    //     var tempLevel3 = [];
    //     for (var j = 0; j < level3.length; j++) {
    //         if (level2.ChapterID == level3[j]['ParentChapterID']) {
    //             tempLevel3.push(level3[j]);
    //         }
    //     }
    //     console.log('pppppppppppppppppppppp', tempLevel3)
    //     return (
    //         <>
    //             <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level2.ChapterID} data-bs-parent="#accordionExample">
    //                 {
    //                     tempLevel3 && tempLevel3.map(level3data => {
    //                         return (
    //                             <div class="accordion-body">
    //                                 <div class="accordion-item">
    //                                     <h2 class="accordion-header" id={'heading_l3_' + level3data.ChapterID}>
    //                                         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l3_' + level3data.ChapterID} aria-controls={'collapse_l3_' + level3data.ChapterID}>
    //                                             {/* // checkbox for level2 */}
    //                                             {
    //                                                 level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
    //                                                     name="" key={level3data.ChapterID} checked={level3data.IsSelect}
    //                                                     onClick={e => this.onCheckLevel3(e, level3data.ChapterID)} />
    //                                             }
    //                                             {level3data.ChapterName} </button>
    //                                     </h2>
    //                                     {this.recordFour(level3data, level4)}
    //                                 </div>
    //                             </div>
    //                         )
    //                     })
    //                 }
    //             </div>
    //         </>
    //     );
    // }
    recordThree(level3data, level4) {
        const { primaryChapterListLevel4 } = this.state
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
                primaryChapterListLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">

                                {level4data.IsCheckbox &&
                                    <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                        onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID, tempLevel4)} />
                                }
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    // to call level four list
    // recordFour(level3data, level4) {
    //     var tempLevel4 = [];
    //     for (var j = 0; j < level4.length; j++) {
    //         if (level3data.ChapterID == level4[j]['ParentChapterID']) {
    //             tempLevel4.push(level4[j]);
    //         }
    //     }
    //     return (<>
    //         <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
    //             {
    //                 tempLevel4 && tempLevel4.map(level4data => {
    //                     return (
    //                         <div class="accordion-body third-step">
    //                             {/* level4data.IsCheckbox && */}
    //                             <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
    //                                 onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
    //                             {level4data.ChapterName}
    //                         </div>
    //                     )
    //                 })
    //             }
    //         </div>
    //     </>);
    // }

    // on check level 4
    // onSelectLevel4(e, id) {
    //     const { level4, subChapters } = this.state
    //     var tempExhibitDetailsList = []
    //     for (let i = 0; i < level4.length; i++) {
    //         if (level4[i]['ChapterID'] == id) {
    //             if (e.target.checked) {
    //                 if (!subChapters.includes(level4[i])) {
    //                     level4[i].isLengthValidate = false;
    //                     subChapters.push(level4[i])
    //                     this.setState({ subChapters: subChapters })
    //                 }
    //             }
    //             else {
    //                 this.setState({ subChapters: subChapters.filter(item => item.ChapterID != level4[i]['ChapterID']) })
    //             }

    //             level4[i]['IsSelect'] = !level4[i]['IsSelect']
    //         }
    //     }
    //     setTimeout(() => {
    //         console.log("xxxxxxxxxxxxxxxxxxxxxxxxx", subChapters)
    //         this.setState({ level4: level4 })
    //     }, 200);

    // }
    // on check level 4
    onSelectLevel4(e, id, tempLevel4) {
        const { level4, exhibitDetailsList, level3, level2, level4DummyId, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        this.setState({ ChapterHallErr: "" })
        var modelevel2 = []
        var modelevel3 = []

        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level3.map((s) => {
                    if (level4[i].ParentChapterID == s.ChapterID) {
                        level2.map(p => {
                            if (s.ParentChapterID == p.ChapterID) {
                                modelevel2.push(p)
                                primaryChapterListData.map(q => {
                                    if (p.ParentChapterID == q.ChapterID) {
                                        modelevel3.push(q)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }

        const finalListlevel3 = []
        level3.map(data => {
            modelevel2.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel3.push(data)
                }

            })
        })

        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })




        console.log("************** finalListlevel2", finalListlevel2)
        // console.log("************** finalListlevel3", finalListlevel3)


        for (let i = 0; i < tempLevel4.length; i++) {
            for (let index = 0; index < level3.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    for (let k = 0; k < level2.length; k++) {
                        if (tempLevel4[i]['ChapterID'] == id) {
                            if (level3[index]['ChapterID'] == tempLevel4[i]['ParentChapterID']) {
                                if (level2[k]['ChapterID'] == level3[index]['ParentChapterID']) {
                                    if (primaryChapterListData[j]['ChapterID'] == level2[k]['ParentChapterID']) {
                                        const allSelectedChecked = tempLevel4.every(item => item.IsSelect == 1)
                                        if (allSelectedChecked) {
                                            level3[index]['IsSelect'] = true

                                            const allSelectedfinalListlevel3 = finalListlevel3.every(item => item.IsSelect == 1)
                                            if (allSelectedfinalListlevel3) {
                                                level2[k]['IsSelect'] = true

                                                const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                                console.log("************** allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                                if (allSelectedfinalListlevel2) {
                                                    primaryChapterListData[j]['IsSelect'] = true

                                                } else {
                                                    primaryChapterListData[j]['IsSelect'] = false
                                                }

                                            } else {
                                                level2[k]['IsSelect'] = false
                                            }


                                        }
                                        else {
                                            level3[index]['IsSelect'] = false
                                            level2[k]['IsSelect'] = false
                                            primaryChapterListData[j]['IsSelect'] = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level4: level4, level3: level3, level2: level2 })
        }, 100);


    }

    handleDelete(e) {
        e.preventDefault()
        const { isOpen2, docDelAccountID, docDelExhVisitorID, visitorGroupList } = this.state
        this.setState({ isLoading: true })
        this.props.delVisaDoc_action(docDelAccountID, docDelExhVisitorID)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Passport Document Deleted SuccessFully")
                    sessionStorage.getItem('VisitorTypeID') == 1 && this.setState({ passportFile: "", passPortFileName: "", isPassportFilePresent: false })
                    sessionStorage.getItem('VisitorTypeID') == 2 && visitorGroupList.map(item => {
                        if (item.AccountID == docDelAccountID) {
                            item.passportFile = ''
                            item.passportFilePath = ''
                            item.isPassportFilePresent = false
                        }
                    })
                    sessionStorage.getItem('VisitorTypeID') == 2 && this.setState({ visitorGroupList: visitorGroupList })
                    this.setState({ isLoading: false, isOpen2: !isOpen2 })
                    this.getVisitorRegistrationDetails()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    onInput(e, value, i, flag) {
        const { visitorGroupList } = this.state
        console.log(value, i, flag)
        if (flag == 'name') {
            visitorGroupList[i].passName = value
            visitorGroupList[i].passNameErr = ''
        } else if (flag == 'passno') {
            visitorGroupList[i].passNo = value
            visitorGroupList[i].passNoErr = ''
        } else if (flag == 'passDate') {
            visitorGroupList[i].passDate = value
            visitorGroupList[i].passDateErr = ''
        } else if (flag == 'passDateEx') {
            visitorGroupList[i].passExipryDate = value
            visitorGroupList[i].passExipryDateErr = ''
        } else if (flag == 'passCountry') {
            // if (value != '' && value != null)
            this.onPassportCountrySearch(e, i)
        } else if (flag == 'passImg') {
            this.onFilePick(e, value, 1, i)
        }
        this.setState({ visitorGroupList: visitorGroupList })
    }


    render() {
        const { isLoading, level1, visitPurposeList, needVisaAssistance, passName, passNo, passDate, passExipryDate,
            isStudentAttendee, interestList, carParking, remarks, isAgree, passportFile, visitorLetterFile, applicantMobNo,
            applicantEmail, passCountry, passCountryId, isDisplayPassCountryList, passNameErr, applicantMobNoErr, applicantEmailErr,
            passNoErr, passDateErr, passExipryDateErr, passCountryErr, preapplicantMobNo, passportFileErr, chapterErr, visitPurposeErr,
            isGrpDelegation, leader, leaderErr, delegationCountry, delegationCountryErr, isDisplayDelegationCountryList, arrivalDate,
            arrivalDateErr, departureDate, departureDateErr, delegationRemark, delegationRemarkErr, chapterList, isInterestTwo, isInterstOne,
            prefTime, seminarInterestList, preffTimeErr, preffDate, preffDateErr, b2bRemark, b2bRemarkErr, isOpen, isCarParking,
            parkingCharges, isParkingAvailable, isParkingChargable, paymentAccessCode, encPayload, level2, level3, level4,
            primaryChapterListData, ChapterHallErr, isPassportFilePresent, isVisaApplied, passPortFileName, isCoordinatorVisit, isOpen2 } = this.state
        const allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        const filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                < VisitorHeader />
                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details2">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Exhibitor list for meeting at respective booth.</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <iframe
                                    id="myIframe"
                                    src='images/B2BEXHIBITOR.pdf#toolbar=0'
                                    width="800px"
                                    height="800px"
                                // title="Bing"
                                />

                            </div>
                        </div>
                    </div>
                </Modal>
                <div
                    className="main-content app-content mt-0"
                    style={{ marginInlineStart: 0 }}
                >
                    <div className="side-app">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div
                                    className="step-name"
                                    style={{ backgroundColor: "#bc202b", color: "#fff", marginTop: '85px' }}
                                >
                                    <p> Visitor Registration form</p>
                                </div>
                            </div>
                        </div>
                        <div className="needs-validation after-step mt-1" style={{ marginTop: 115 }}>
                            <div id="Menu2">
                                <div className=" mt-2" id="section-2">
                                    {isCoordinatorVisit &&
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">Industry Segment <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{chapterErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" mb-2">
                                                <div className="row mt-1">
                                                    <div className="asc">
                                                        <div className="" style={{ paddingLeft: 10 }}>
                                                            <form className="needs-validation pb-2" noValidate="">
                                                                <div className="form-row mt-3">
                                                                    {
                                                                        chapterList.length > 0 && chapterList.map((data) => (
                                                                            <div className="col-sm-3 mb-3">
                                                                                <section className="" key={data.IndustrySegmentID}>
                                                                                    {/* <input type="checkbox" name="collapse"
                                                                                select={data.IsSelect} checked={data.IndustrySegmentID}
                                                                                value={data.IndustrySegmentID}
                                                                            /> */}
                                                                                    <h2 className="handle-no-icon m-0" style={{ fontSize: '13px' }}>
                                                                                        <label style={{ color: '#666' }}>
                                                                                            <input type="checkbox"
                                                                                                select={data.IsSelect} checked={data.IsSelect}
                                                                                                value={data.IndustrySegmentID}
                                                                                                onChange={(e) => this.onSelectPrimaryChapter(e)} />
                                                                                            {data['Industrial Segment']}
                                                                                        </label>
                                                                                    </h2>
                                                                                    {data.IndustrySegmentID == 16 && this.state.isOtherOpen && <input type="text" className="form-control" value={this.state.other}
                                                                                        // style={{ marginRight: "50px" }}
                                                                                        // maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                        // onPaste={(e) => this.handlePaste(e)}
                                                                                        // onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                                        onChange={(e) =>
                                                                                            this.setState({ other: e.target.value, })}
                                                                                    />}
                                                                                </section>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {isCoordinatorVisit &&
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Hall to Visit <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{ChapterHallErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-4">
                                                <div className="row mt-4">
                                                    <div class="accordion" id="accordionExample">
                                                        {
                                                            level2.length > 0 && level2.map(level2Data => (
                                                                <>
                                                                    {
                                                                        // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                                <button class={level2Data.isHighlight ? "accordion-button collapsed selected-chapter" : "accordion-button collapsed"}
                                                                                    type="button" data-bs-toggle="collapse"
                                                                                    data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                                    aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                                    {/* // checkbox for level2 */}
                                                                                    {
                                                                                        level2Data.IsCheckbox &&
                                                                                        <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                            checked={level2Data.IsSelect}
                                                                                            onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)} />
                                                                                    }
                                                                                    {level2Data.ChapterName} </button>
                                                                            </h2>
                                                                            {this.recordTwo(level2Data, level3, level4)}
                                                                        </div>
                                                                    }

                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="" style={{ marginLeft: "10px" }}>
                                                <p className="mb-0" style={{ fontSize: 15 }}>
                                                    <strong>  Selected Hall to Visit</strong>
                                                </p>
                                            </div>
                                            <div style={{ padding: "0px 0px 0px 10px" }}>
                                                {
                                                    filerArray.length > 0 ? filerArray.map((data) => (
                                                        <p>
                                                            {data.ChapterName}
                                                        </p>
                                                    )) :
                                                        <p>
                                                            {"No Results"}
                                                        </p>
                                                }
                                            </div>
                                        </div>}
                                    <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg d-flex">
                                                        <p className="mb-0">
                                                            Purpose of Visit <span className="text-red">*</span>
                                                        </p>
                                                        {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{visitPurposeErr}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-4">
                                                {
                                                    visitPurposeList.length > 0 && visitPurposeList.map((data) => (
                                                        <div className="col-sm-3 mb-2" key={data.PurposeofVisitID}>
                                                            <label className="d-flex">
                                                                <input type="checkbox" name="fav_language"
                                                                    select={data.isSelect} checked={data.isSelect}
                                                                    value={data.PurposeofVisitID}
                                                                    onChange={(e) => this.onSelectPurpose(e)}
                                                                />
                                                                {data.PurposeofVisitName}
                                                            </label>
                                                            {
                                                                data.PurposeofVisitID == 10 && data.isSelect &&
                                                                <input
                                                                    className="form-control"
                                                                    maxLength={'100'}
                                                                    value={data.otherDetail}
                                                                    onChange={(e) => this.otherDetailsChange(e)}
                                                                >
                                                                </input>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {!(parseInt(this.state.countryId) == parseInt(sessionStorage.getItem('ExhibitionCountryID'))) && isCoordinatorVisit && <div className="one-section mb-3">
                                        <div className="col-sm-3 ">
                                            <label className="d-flex">
                                                <input className="do-you"
                                                    type="checkbox"
                                                    name="fav_language"
                                                    checked={isGrpDelegation}
                                                    onChange={(e) => this.setState({
                                                        isGrpDelegation: !isGrpDelegation

                                                    })}
                                                />
                                                <span className="do-you1" style={{ whiteSpace: "nowrap", color: "#bc202b", fontSize: "16px" }}><strong>Are you part of Group Delegation ?</strong>
                                                    (If yes below section would open)</span>
                                            </label>
                                        </div>
                                        {
                                            isGrpDelegation &&
                                            <div
                                                className="col-md-12 mb-2"
                                                id="divMsg"
                                            >
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Delegation Leader/Organisation<span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={leader} maxLength={'50'}
                                                            onChange={(e) => this.setState({ leader: e.target.value, leaderErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{leaderErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Country/Region<span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={delegationCountry}
                                                            onChange={(e) => this.onDelegationCountrySearch(e)}
                                                        />
                                                        {isDisplayDelegationCountryList && this.renderDelegationCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{delegationCountryErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Arrival Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={arrivalDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    arrivalDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    arrivalDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Arrival Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{arrivalDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Departure Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={departureDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    departureDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    departureDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Departure Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{departureDateErr}</span>}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12 mb-1">
                                                            <label className="mt-3">Remark
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                rows={3}
                                                                maxLength={'250'}
                                                                defaultValue={""}
                                                                value={delegationRemark}
                                                                onChange={(e) => this.setState({ delegationRemark: e.target.value, delegationRemarkErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{delegationRemarkErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>}

                                    {/* {
                                        !isStudentAttendee &&

                                        <div>
                                            <div>
                                                <p style={{ marginLeft: 12 }} className="mb-2">
                                                    <strong>
                                                        Letter from the employer giving the position of applicant in the
                                                        company, the reason for the visit with particulars of companies
                                                        to be visited.
                                                        <a
                                                            target="_blank"
                                                            href={Visitorletter}
                                                            download="Visitorletter"
                                                        >
                                                            <i
                                                                className="fa fa-file-pdf-o text-primary"
                                                                style={{ fontSize: 17 }}
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </strong>
                                                </p>
                                                <div className="col-xl-6">
                                                    <label className="">Upload file </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="visitorLetter" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                        />
                                                        {
                                                            visitorLetterFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(2)}></i>
                                                        }
                                                    </form>
                                                    <p>
                                                        <strong>NOTE:</strong> Upload Maximum 1mb Size Only / Allowed
                                                        File Type: pdf
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="one-section ">
                                        <div className="containerfluid">
                                            <div className="row">
                                                <div className="col-md-12 mt-2">
                                                    <div className="p-1 title-bg">
                                                        <p className="mb-0">Note</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 mb-1">
                                                    <label className="d-flex">
                                                        {" "}
                                                        1. In case you are applying for business visa kindly upload the
                                                        Permission/NOC from your company to travel to ITME Africa &amp; M.E.
                                                        2023 in Kenya
                                                    </label>
                                                </div>
                                                <div className="col-sm-12 mb-2">
                                                    <label className="d-flex">
                                                        {" "}
                                                        2. In case you are applying for non-business visa you need not
                                                        required to upload the document
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="one-section "> */}
                                        {(sessionStorage.getItem('isVisitorB2B') == true || sessionStorage.getItem('isVisitorSeminar') == true) && <div >

                                            <div className="containerfluid">
                                                <div className="row">
                                                    <div className="col-md-12 mt-2">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Interested</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-4">
                                                <div className="row mt-4">
                                                    {
                                                        interestList.length > 0 && interestList.map((data) => (
                                                            <>
                                                                {data.status == true && <div className="col-sm-9 mb-2" key={data.AreaofInterestID}>
                                                                    <label className="d-flex">
                                                                        <input type="checkbox" name="fav_language" value={data.AreaofInterestID}
                                                                            select={data.isSelect} checked={data.isSelect}
                                                                            onChange={(e) => this.onSelectInterest(e)}
                                                                        />
                                                                        {data.Name}

                                                                    </label>
                                                                    {isInterestTwo && data.AreaofInterestID == 2 &&
                                                                        < div >
                                                                            <div className="col-sm-3 ">
                                                                                <label className="d-flex">
                                                                                    <span className="do-you1" style={{ whiteSpace: "nowrap", fontSize: "16px" }}><strong>If interested Please fill preferred Date & Time</strong>
                                                                                        (Exhibition time is 09:30hrs to 18:00hrs) <a className="text-primary"
                                                                                            onClick={(e) => this.onFileClick(e)}><spa className="text-danger blink-soft">Click here</spa> to view exhibitor list for meeting at respective booth.</a></span>
                                                                                </label>
                                                                            </div>
                                                                            {console.log(new Date(sessionStorage.getItem('exhibitionDateFrom')), 'sessionStorage.getItem()', moment(sessionStorage.getItem('exhibitionDateFrom')).format('L'))}
                                                                            <div
                                                                                className="col-md-12 mb-2"
                                                                                id="divMsg"
                                                                            >
                                                                                <div className="row">
                                                                                    <div className="col-xl-3">
                                                                                        <label className="mt-1">Date
                                                                                            <span className="text-red">*</span>
                                                                                        </label>
                                                                                        <DatePicker selected={preffDate}
                                                                                            onChange={(date) =>
                                                                                                this.setState({
                                                                                                    preffDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                                                    preffDateErr: ""
                                                                                                })}
                                                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                                            peekNextMonth
                                                                                            value={preffDate}
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            minDate={moment(sessionStorage.getItem('exhibitionDateFrom'), 'YYYY-MM-DD').toDate()}
                                                                                            maxDate={moment(sessionStorage.getItem('exhibitionDateTo'), 'YYYY-MM-DD').toDate()}
                                                                                            placeholderText="Preferred Date"
                                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                                            popperClassName="example-datepicker-class" />
                                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{preffDateErr}</span>}
                                                                                        <div>
                                                                                            <div >
                                                                                                <label>preferred Time<span className="text-red">*</span></label>
                                                                                                <input
                                                                                                    type="time"
                                                                                                    className="form-control"
                                                                                                    value={prefTime}
                                                                                                    onChange={(e) => this.handlePrefTime(e)}
                                                                                                />
                                                                                                {<span className='text-danger text-start text-left d-block mt-1'>{preffTimeErr}</span>}
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>


                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-xl-8 mb-1">
                                                                                        <label className="mt-1">Remark
                                                                                        </label>
                                                                                        <textarea
                                                                                            className="form-control"
                                                                                            rows={3}
                                                                                            maxLength={'250'}
                                                                                            defaultValue={""}
                                                                                            value={b2bRemark}
                                                                                            onChange={(e) => this.setState({ b2bRemark: e.target.value, b2bRemarkErr: "" })}
                                                                                        />
                                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{b2bRemarkErr}</span>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {isInterstOne && data.AreaofInterestID == 1 &&
                                                                        <div className="table-responsive" >
                                                                            <table className="table border table-bordered mb-0 mt-1" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-center">Day</th>
                                                                                        <th style={{ width: '180px' }}>Date & Time</th>
                                                                                        <th style={{ width: '680px' }}>Description</th>
                                                                                        <th style={{ width: '380px' }}>Speaker</th>
                                                                                        <th className="text-center"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        seminarInterestList.length > 0 && seminarInterestList.map((data) => (
                                                                                            <tr key={Math.random()}>
                                                                                                <td className={data.ParentSeminarID != 0 ? "text-center" : "fw-bold"}>{data.Day}</td>
                                                                                                <td className={data.ParentSeminarID != 0 ? null : "fw-bold"}>{data['Date & Time']}</td>
                                                                                                <td className={data.ParentSeminarID != 0 ? null : "fw-bold"}>{data.Description}</td>
                                                                                                {data.SeminarID != 14 ? (
                                                                                                    <td>{data.Speaker}</td>
                                                                                                ) : (
                                                                                                    <td>{data.Speaker.split('==').map(item => (
                                                                                                        <tr className="text-start">{item}</tr>
                                                                                                    ))}</td>
                                                                                                )}
                                                                                                <td className="text-center">
                                                                                                    {data.ParentSeminarID != 0 &&
                                                                                                        <input type="checkbox"
                                                                                                            value={data.isSelect}
                                                                                                            checked={data.isSelect}
                                                                                                            onChange={(e) => this.onCheckedSeminar(e, data.SeminarID)}
                                                                                                        />
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>

                                                                            <div />
                                                                        </div>
                                                                    }
                                                                </div>}</>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>}
                                        {!(parseInt(this.state.countryId) == parseInt(sessionStorage.getItem('ExhibitionCountryID'))) && isCoordinatorVisit && <div className="one-section mb-3">
                                            <div className="col-sm-3 ">
                                                <label className="d-flex">
                                                    <input className="do-you" style={{ marginBottom: '25px' }}
                                                        type="checkbox"
                                                        name="fav_language"
                                                        checked={needVisaAssistance}
                                                        onChange={(e) => this.setState({
                                                            needVisaAssistance: !needVisaAssistance

                                                        })}
                                                    />
                                                    <span className="do-you1" style={{ whiteSpace: "nowrap", color: "#bc202b", fontSize: "16px" }}><strong>Do you need Visa Assistance?</strong>
                                                        (<b>Note</b> : Visa Application letter will be issued from {sessionStorage.getItem("ExhVisitorVisaIssueDate")}. Visitor can avail tourist visa also to attend the exhibition.In case applying for Business visa kindly<br /> fill the below details to obtain Visa Invitation Letter.)</span>
                                                </label>
                                            </div>
                                            {
                                                needVisaAssistance && sessionStorage.getItem('VisitorTypeID') == 1 &&
                                                <div
                                                    className="col-md-12 mb-2"
                                                    id="divMsg"
                                                >
                                                    <div className="row">
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Name as per Passport<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={passName} maxLength={'50'}
                                                                onChange={(e) => this.setState({ passName: e.target.value, passNameErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{passNameErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Applicant mobile number<span className="text-red">*</span>
                                                            </label>
                                                            <input type="number" className="form-control" value={applicantMobNo}
                                                                style={{ paddingLeft: "50px" }}
                                                                maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                onChange={(e) => e.target.value >= 0 &&
                                                                    this.setState({ applicantMobNo: e.target.value, applicantMobNoErr: "" })}
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control-1 mt-2"
                                                                // placeholder="ISD"
                                                                value={preapplicantMobNo}
                                                                onChange={(e) => this.setState({ preapplicantMobNo: e.target.value })}
                                                                maxLength={'10'}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 30,
                                                                    padding: " 6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 34
                                                                }}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{applicantMobNoErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Applicant email id<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={applicantEmail} maxLength={'50'}
                                                                onChange={(e) => this.setState({ applicantEmail: e.target.value, applicantEmailErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{applicantEmailErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport No
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={passNo} maxLength={'10'}
                                                                onChange={(e) => this.setState({ passNo: e.target.value, passNoErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{passNoErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport issue Date
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <DatePicker selected={passDate}
                                                                onChange={(date) =>
                                                                    this.setState({
                                                                        passDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                        passDateErr: ""
                                                                    })}
                                                                dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                maxDate={passExipryDate}
                                                                dropdownMode="select"
                                                                placeholderText="Passport Issued Date"
                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                popperClassName="example-datepicker-class" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{passDateErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport Expiry Date
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <DatePicker selected={passExipryDate}
                                                                onChange={(date) =>
                                                                    this.setState({
                                                                        passExipryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                        passExipryDateErr: ""
                                                                    })}
                                                                dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                minDate={passDate}
                                                                dropdownMode="select"
                                                                placeholderText="Passport Expiry Date"
                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                popperClassName="example-datepicker-class" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{passExipryDateErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Passport issuing Country<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={passCountry}
                                                                onChange={(e) => this.onPassportCountrySearch(e)}
                                                            />
                                                            {isDisplayPassCountryList && this.renderPassCountryList()}
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{passCountryErr}</span>}
                                                        </div>
                                                        {!(isPassportFilePresent) &&
                                                            <div className="col-xl-5">
                                                                <label className="mt-3">
                                                                    Passport Front Page (Maximum 5mb / File Type .jpg, .jpeg, .png, .pdf)
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <form action="/action_page.php" style={{ display: "inline-flex" }}>
                                                                    <input type="file" id="passportFile" name="filename"
                                                                        accept=".jpg, .jpeg, .png, .pdf" maxLength={"50"}
                                                                        onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                    />
                                                                    {<span className='text-danger text-start text-left d-block mt-1'>{passportFileErr}</span>}
                                                                    {
                                                                        passportFile != "" &&
                                                                        <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1)}></i>
                                                                    }
                                                                </form>
                                                            </div>
                                                        }
                                                        {
                                                            isVisaApplied && passportFile != "" && passportFile != null && isPassportFilePresent &&
                                                            <div className="col-xl-5">
                                                                <label className="mt-3">
                                                                    Passport Front Page File
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <div style={{
                                                                    border: '1px solid #ccc',
                                                                    padding: '10px',
                                                                    // display: 'inline-flex',
                                                                    alignItems: 'center',
                                                                    marginTop: '10px',
                                                                    // marginLeft: '12px',
                                                                    maxWidth: '300px'
                                                                }}>
                                                                    <span style={{ marginRight: '10px' }}
                                                                    >{passPortFileName}</span>
                                                                    <i className="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1)}></i>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {console.log(this.state.visitorGroupList)}
                                            {
                                                needVisaAssistance && sessionStorage.getItem('VisitorTypeID') == 2 &&
                                                //map+all data points
                                                this.state.visitorGroupList.length > 0 && this.state.visitorGroupList.map((data, i) => (<>
                                                    {data.isVisit && < div
                                                        className="list-group-item col-md-12 m-3"
                                                        id="divMsg"
                                                    // style={{backgroundColor:}}
                                                    >
                                                        <div className="row">
                                                            <h5>{data.isCoordinator == true ? 'Coordinator' : `Visitor ${i}`}</h5>
                                                            {console.log('ppp')}
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Name as per Passport<span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={data.passName} maxLength={'50'}
                                                                    onChange={(e) => this.onInput(e, e.target.value, i, 'name')}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.passNameErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Applicant mobile number<span className="text-red">*</span>
                                                                </label>
                                                                <input type="number" className="form-control" value={data.applicantMobNo}
                                                                    style={{ paddingLeft: "50px" }}
                                                                    maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                    onPaste={(e) => this.handlePaste(e)}
                                                                    onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                    disabled
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form-control-1 mt-2"
                                                                    // placeholder="ISD"
                                                                    value={data.preapplicantMobNo}
                                                                    disabled
                                                                    // onChange={(e) => this.setState({ preapplicantMobNo: e.target.value })}
                                                                    maxLength={'10'}
                                                                    style={{
                                                                        width: 45,
                                                                        position: "absolute",
                                                                        top: 30,
                                                                        padding: " 6px !important",
                                                                        paddingLeft: 5,
                                                                        borderLeft: 0,
                                                                        borderTop: 0,
                                                                        background: "none",
                                                                        borderBottom: 0,
                                                                        height: 34
                                                                    }}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.applicantMobNoErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Applicant email id<span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={data.applicantEmail} maxLength={'50'}
                                                                    disabled
                                                                // onChange={(e) => this.setState({ applicantEmail: e.target.value, applicantEmailErr: "" })}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.applicantEmailErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">Passport No
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={data.passNo} maxLength={'10'}
                                                                    // onChange={(e) => this.setState({ passNo: e.target.value, passNoErr: "" })}
                                                                    onChange={(e) => this.onInput(e, e.target.value, i, 'passno')}

                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.passNoErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">Passport issue Date
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <DatePicker selected={data.passDate}
                                                                    onChange={(date) => this.onInput(date, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), i, 'passDate')}
                                                                    // onChange={(date) =>
                                                                    //     this.setState({
                                                                    //         passDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    //         passDateErr: ""
                                                                    //     })}
                                                                    dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    maxDate={data.passExipryDate}
                                                                    dropdownMode="select"
                                                                    placeholderText="Passport Issued Date"
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class" />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.passDateErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">Passport Expiry Date
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <DatePicker selected={data.passExipryDate}
                                                                    onChange={(date) => this.onInput(date, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), i, 'passDateEx')}
                                                                    // onChange={(date) =>
                                                                    //     this.setState({
                                                                    //         passExipryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    //         passExipryDateErr: ""
                                                                    //     })}
                                                                    dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    minDate={data.passDate}
                                                                    dropdownMode="select"
                                                                    placeholderText="Passport Expiry Date"
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class" />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.passExipryDateErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Passport issuing Country<span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={data.passCountry}
                                                                    onChange={(e) => this.onInput(e, e, i, 'passCountry')}

                                                                />
                                                                {data.isDisplayPassCountryList && this.renderPassCountryList(i)}
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{data.passCountryErr}</span>}
                                                            </div>
                                                            {!(data.isPassportFilePresent) &&
                                                                <div className="col-xl-5">
                                                                    <label className="mt-3">
                                                                        Passport Front Page (Maximum 5mb / File Type .jpg, .jpeg, .png, .pdf)
                                                                        <span className="text-red">*</span>
                                                                    </label>
                                                                    <form action="/action_page.php" style={{ display: "inline-flex" }}>
                                                                        <input
                                                                            // type="file" id="passportFile" name="filename"
                                                                            //     accept=".jpg, .jpeg, .png, .pdf" maxLength={"50"}
                                                                            //     onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                            type="file"
                                                                            id={`path${i}`}
                                                                            className="image-upload"
                                                                            accept=".jpg, .jpeg, .png"
                                                                            onChange={e => this.onInput(e, e.target.files, i, 'passImg')}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{data.passportFileErr}</span>}
                                                                        {
                                                                            data.passportFile != "" &&
                                                                            <i class="fa fa-times text-danger img-can"
                                                                                onClick={() => this.onCrossClick(1, i)}
                                                                            ></i>
                                                                        }
                                                                    </form>
                                                                </div>
                                                            }
                                                            {
                                                                data.passportFilePath != "" && data.passportFilePath != null && data.isPassportFilePresent &&
                                                                <div className="col-xl-5">
                                                                    <label className="mt-3">
                                                                        Passport Front Page File
                                                                        <span className="text-red">*</span>
                                                                    </label>
                                                                    <div style={{
                                                                        border: '1px solid #ccc',
                                                                        padding: '10px',
                                                                        // display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        marginTop: '10px',
                                                                        // marginLeft: '12px',
                                                                        maxWidth: '300px'
                                                                    }}>
                                                                        <span style={{ marginRight: '10px' }}
                                                                        >{data.passportFile}</span>
                                                                        <i className="fa fa-times text-danger img-can"
                                                                            onClick={() => this.onCrossClick(1, i)}
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>}
                                                </>
                                                ))
                                            }
                                        </div>}
                                    {/* </div> */}
                                    {/* <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg">
                                                        <p className="mb-0">Car Parking Required (Paid Service)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 mb-4">
                                            <div className="row mt-4">
                                                <div className="col-sm-1 mb-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="carParking"
                                                            checked={carParking == "Yes"}
                                                            onChange={(e) => this.setState({ carParking: "Yes" })}
                                                        />
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="col-sm-1 mb-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="carParking"
                                                            checked={carParking == "No"}
                                                            onChange={(e) => this.setState({ carParking: "No" })}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {isParkingAvailable &&
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Car Parking</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-4">
                                                <div className="row mt-2">
                                                    <div className="col-sm-1">
                                                        <label className="d-flex" htmlFor="carParkingYes">
                                                            {" "}
                                                            <input type="radio" name="billingRadio"
                                                                id="carParkingYes"
                                                                checked={isCarParking == 1}
                                                                onChange={(e) => this.setState({ isCarParking: 1 })}
                                                            />
                                                            <strong>Yes</strong>
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-1">
                                                        <label className="d-flex" htmlFor="carParkingNo">
                                                            {" "}
                                                            <input type="radio" name="billingRadio"
                                                                id="carParkingNo"
                                                                checked={isCarParking == 0}
                                                                onChange={(e) => this.setState({ isCarParking: 0 })}
                                                            />
                                                            <strong>No</strong>
                                                        </label>
                                                    </div>
                                                </div>
                                                {isParkingChargable && isCarParking == 1 &&
                                                    <div className="row mt-3 left-align">
                                                        <strong>Car Parking Charges:- {parkingCharges}</strong>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg">
                                                        <p className="mb-0">Remarks</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-2">
                                                <div className="col-xl-12 mb-1">
                                                    <textarea
                                                        className="form-control"
                                                        rows={3}
                                                        maxLength={'250'}
                                                        defaultValue={""}
                                                        value={remarks}
                                                        onChange={(e) => this.setState({ remarks: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="one-section">
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-2">
                                                <div className="col-sm-12 mb-2">
                                                    <input
                                                        type="checkbox"
                                                        className="mt-1 d-flex"
                                                        style={{ float: "left" }}
                                                        checked={isAgree}
                                                        onChange={(e) => this.setState({ isAgree: !isAgree })}
                                                    />
                                                    <label className="term">
                                                        I have read and accept the Visitor
                                                        <a >Terms &amp; Conditions &amp; Privacy Policy</a> on this
                                                        website and agree to register as visitor for {sessionStorage.getItem('sessionExhibitionName')}. By Submitting my registration, I give my consent to India
                                                        ITME Society to verify the provided information submitted by me online
                                                        by telephonic call or by Email and also to send me promotional
                                                        information via digital and offline channels not limited to email,
                                                        SMS, WhatsApp, print newsletter and invitation card.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="main-container m-3">
                                        <div className="mt-1 mb-4 d-flex">
                                            <a
                                                className="btn btn-previous" style={{ marginRight: '10px' }}
                                                type="submit"
                                                onClick={(e) => this.onPrevClick(e)}
                                            >
                                                Previous
                                            </a>
                                            {/* <a
                                                className="btn btn-success rounded-0"
                                                style={{ padding: "4px 25px" }}
                                                type="submit"
                                                onClick={(e) => this.onSubmit(e)}
                                            >
                                                Submit
                                            </a> */}
                                            <form onSubmit={(e) => sessionStorage.getItem('VisitorTypeID') == 2 ? this.onSubmitGroup(e) : this.onSubmit(e)} ref={this.submitfrm} id="nonseamless" method="post" name="redirect" action={paymentPortalLink}>

                                                <input type="hidden" id="encRequest" name="encRequest"
                                                    value={encPayload} />
                                                <input type="hidden" name="access_code" id="access_code"
                                                    value={paymentAccessCode} />
                                                <button class="" >
                                                    <a className="btn btn-success rounded-0" style={{ padding: "4px 25px" }} >
                                                        Next</a>
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Modal isOpen={isOpen2} onRequestClose={() => this.setState({ isOpen2: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen2: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this Document?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isOpen2: false })}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {console.log("sess", sessionStorage.getItem('sessionMobileNo'), sessionStorage.getItem('VisitorTypeID'), this.state.applicantMobNo)}
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, visitorRegistrationSubmit_action, payment_action, getCarParkingDetails_action, delVisaDoc_action,
    getVistiorRegistarionDetails2_action, visitorRegistrationGroupSubmit_action
})(VisitorIndustrySegment));