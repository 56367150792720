import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../../utils/withRouter';
import Loader from '../../../utils/Loader'
import { clearSessionStorage } from '../../../utils/constants'
import Header from '../../../common_components/Header';
import { emailValidator } from '../../../utils/Validator'
import Modal from "react-modal";
import Footerwithdr from '../../../common_components/Footerwithdr';
import HeaderWithLogo from '../../../common_components/header_with_logo';
import AdminDrawer from '../../../common_components/admin_module_drawer';
import { fill_action } from '../../../actions/fillCombo_action'
import ReceiptList from '../registration_receipt/ReceiptList';
import { Link } from "react-router-dom";
import { getAdminDashboardData_action } from "../../../actions/admin_module_action/admin_dasboard_action/admin_dashboard_action"
import { data } from 'jquery';
import Applicationsummary from './application_summary/application_summary';
import AreaSummary from './area_summary/area_summary';
import ParticipantSummary from './participant_summary/participant_summary';
import PaymentSummary from './payment_summary/payment_summary';
import AdditionalSummary from './additional_summary/additional_summary';
import { Flag } from '@mui/icons-material';



class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            summaryPage: 'applicationSummary',
            // Input Fields
            pendingPaymentCount: "",
            updatedAreaPIcount: "",
            updatedOpenSidePIcount: "",
            loginAccesslist: []
        }
        this.getDashboardData = this.getDashboardData.bind(this)
        this.isRouteActive = this.isRouteActive.bind(this)
        this.toNavigate = this.toNavigate.bind(this)
        this.getModuleAccess = this.getModuleAccess.bind(this)
    }


    // On Component Load
    componentDidMount() {
        this.getDashboardData()
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })

    }

    // Get DashBoard Data
    getDashboardData() {
        this.setState({ isLoading: true })
        this.props.getAdminDashboardData_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Admin Dashboard Data ------->", data.data)
                    this.setState({
                        isLoading: false,
                        updatedAreaPIcount: data.data[1][0]['count_isGeneratePIarea'],
                        pendingPaymentCount: data.data[0][0]['Count_Of_Payment'],
                        updatedOpenSidePIcount: data.data[1][0]['count_isGeneratePIopenSide']

                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    isRouteActive(routePath) {
        console.log(this.props.location)
        return this.props.location.pathname == routePath
    }

    toNavigate() {
        const { summaryPage } = this.state
        if (summaryPage == 'applicationSummary') {
            return <Applicationsummary />
        }
        else if (summaryPage == 'areaSummary') {
            return <AreaSummary />
        }
        else if (summaryPage == 'participantSummary') {
            return <ParticipantSummary />
        }
        else if (summaryPage == 'paymentSummary') {
            return <PaymentSummary />
        }
        else if (summaryPage == 'additionalSummary') {
            return <AdditionalSummary />
        }
        else {
            return <Applicationsummary />
        }
    }

    getModuleAccess(ModuleID) {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == ModuleID);
        if (moduleAccess) {
            return moduleAccess.ReadAccess;
        } else {
            return false
        }
        return false;
    };


    render() {
        const { isLoading, pendingPaymentCount, updatedAreaPIcount, updatedOpenSidePIcount, summaryPage } = this.state
        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />
                    {/*app-content open*/}
                    <div className="app-content with-dr-container com-height">
                        {/* <AdminDashboardTopmenu /> */}
                        <div className="navg mt-0">
                            <ul className="nav nav-pills">
                                {/* <Link to={'/applicationSummary'}> */}
                                <li onClick={() => this.setState({ summaryPage: 'applicationSummary' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'applicationSummary' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Application Summary{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                {/* <Link to={'/areaSummary'}> */}
                                <li onClick={() => this.setState({ summaryPage: 'areaSummary' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'areaSummary' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Area Summary{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                {/* <Link to={'/participantSummary'}> */}
                                {/* <li onClick={() => this.setState({ summaryPage: 'participantSummary' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'participantSummary' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                        Participant Summary{" "}
                                    </a>{" "}
                                </li> */}
                                {/* </Link> */}
                                {/* <Link to={'/paymentSummary'}> */}
                                <li onClick={() => this.setState({ summaryPage: 'paymentSummary' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'paymentSummary' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                        Payment Summary{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                {/* <Link to={'/additionalSummary'}> */}
                                <li onClick={() => this.setState({ summaryPage: 'additionalSummary' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'additionalSummary' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                                        Additional Summary{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}

                                {/* <li className="nav-item "
                        onClick={(e) => profileView(e)}

                    >

                        <a className={isRouteActive('/agententry') ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"} >
                            Profile
                        </a>
                    </li> */}

                            </ul>
                        </div>
                        {/* <div className="">

                            <div className="row">
                                <div className="step-name-space">
                                </div>
                            </div>
                        </div> */}
                        {this.toNavigate()}
                        {/* <div className='container-fluid'>
                            <div className="col-md-3 mt-5">
                                <Link to={'/paymentlist'}>
                                    <div
                                        className="com-box com-box-1"
                                        style={{ borderBottom: "2px solid #ff9770" }}
                                    >
                                        <div
                                            className="box-count"
                                            style={{
                                                height: '98px',
                                                padding: '25px',
                                                textAlign: "center",
                                                fontSize: '17px',
                                                fontWeight: '500',
                                                fontFamily: '"Poppins", sans-serif'
                                            }}
                                        >
                                            <spa>Updated Payment Count</spa>
                                            <span
                                                style={{
                                                    background: "#fc9670",
                                                    position: "absolute",
                                                    top: '55px',
                                                    left: '115px'
                                                }}
                                            >
                                                <i
                                                    className="fa-solid fa-square-"
                                                    aria-hidden="true"
                                                    style={{ fontSize: '17px' }}
                                                >{pendingPaymentCount}</i>
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        </div> */}
                        <div className='container-fluid'>
                            <div className="col-md-3 mt-5">
                                {/* <Link to={'/paymentlist'}> */}
                                <div
                                    className="com-box com-box-1"
                                    style={{ borderBottom: "2px solid #ff9770", width: '400px' }}
                                >
                                    <h4>Today's Updates</h4>
                                    <div
                                        className="box-count"
                                        style={{
                                            height: '250px',
                                            padding: '25px',
                                            textAlign: "center",
                                            fontSize: '17px',
                                            fontWeight: '500',
                                            fontFamily: '"Poppins", sans-serif'
                                        }}
                                    >
                                        {this.getModuleAccess('19') && <>
                                            <p style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/paymentlist')}>Updated Payment Count <span style={{ float: 'right' }}>{pendingPaymentCount}</span></p>
                                            <p style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/proformalist', { state: { Flag: 2 } })}>Updated Area PI Count <span style={{ float: 'right' }}>{updatedAreaPIcount}</span></p>
                                            <p style={{ cursor: 'pointer' }} onClick={() => this.props.navigate('/exhibitorlist', { state: { Flag: true } })}>Generate OpenSide PI Count <span style={{ float: 'right' }}>{updatedOpenSidePIcount}</span></p>
                                        </>
                                        }
                                        <p style={{ cursor: 'pointer' }} >New Application  <span style={{ float: 'right' }}>0</span></p>
                                        <p style={{ cursor: 'pointer' }} >Applications in Waiting  <span style={{ float: 'right' }}>0</span></p>

                                    </div>
                                </div>
                                {/* </Link> */}
                            </div>

                        </div>
                        <div className="main-container container-fluid top-space">
                            {/* <ReceiptList /> */}
                        </div>
                    </div>
                </>

                <Footerwithdr />

            </>
        );
    }


}

export default withRouter(connect(null, { getAdminDashboardData_action })(Dashboard));
