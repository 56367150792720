import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { DEL_VISA_DOCUMENT_ACTION_TYPE, GET_CAR_PARKING_DETAILS_ACTION_TYPE, GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE, GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE, GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE, PAYMENT_ACTION_TYPE, VERIFY_VISITOR_EMAIL_ACTION_TYPE, VISITOR_PREVIEW_SUBMIT_ACTION_TYPE, VISITOR_REGISTRATION_NEXT_ACTION_TYPE, VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE, deleteVisaDocUrl, getCarParkingDetailsUrl, getCombineVisitorRegistrationDetailsUrl, getVisitorRegistrationDetails2Url, getVisitorRegistrationDetailsUrl, headers, paymentUrl, verifyVisitorEmailUrl, visitorPreviewSubmitUrl, visitorRegistrationNextUrl, visitorRegistrationSubmitUrl, visitorRegistrationGroupNextSubmitUrl, VISITOR_REGISTRATION_GROUP_NEXT_ACTION_TYPE, VISITOR_REGISTRATION_GROUP_FINAL_ACTION_TYPE, visitorGroupRegistrationFinalSubmitUrl } from "../../../utils/constants";


export const getVistiorRegistarionDetails_action = () => async (dispatch) => {
    console.log(JSON.parse(sessionStorage.getItem('VisitorDataSS')), 'ldaaaata')
    try {
        const param = {
            UserId: JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID ?? null,
            AccountID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhVisitorID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID
        }
        const res = await axiosGet(getVisitorRegistrationDetailsUrl, param, headers);
        dispatch({
            type: GET_VISITOR_REGISTRATION_DETAILS_ACTION_TYPE,
            payload: res,
        });
        console.log("getVistiorRegistarionDetails_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getCarParkingDetails_action = () => async (dispatch) => {
    try {
        const param = {
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        }

        const res = await axiosGet(getCarParkingDetailsUrl, param, headers);
        dispatch({
            type: GET_CAR_PARKING_DETAILS_ACTION_TYPE,
            payload: res,
        });
        console.log("getCarParkingDetails_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const visitorRegistrationNext_action = (countryId, attendeeTypeId, preName, firstName, middleName, lastName, designation,
    institute, mobNo, whatsAppMobNo, isSameAsMobNo, regState, city, pincode, website, email, telNo, tel1No, list) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID : "",
                AccountID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID : "",
                ExhVisitorID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID : "",
                ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                CountryID: countryId,
                AttendeeTypeID: attendeeTypeId,
                Title: preName,
                FirstName: firstName,
                MiddleName: middleName,
                LastName: lastName,
                Designation: designation,
                Institute: institute,
                MobileNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterMobileNo')}-${mobNo}`,
                WhatsappNo: `${sessionStorage.getItem('sessionCountryNamePreRegisterWhatsAppMobNo')}-${whatsAppMobNo}`,
                Is_SameMobileNo: isSameAsMobNo,
                State: regState,
                City: city,
                Pincode: pincode,
                Website: website,
                Email: email,
                Telephone: `${sessionStorage.getItem('sessionregisterTelephoneNoPre')}-${telNo}`,
                Telephone1: `${sessionStorage.getItem('sessionregisterTelephoneNo1Pre')}-${tel1No}`,
                visitDaysList: list
            }
            console.log("visitorRegistrationNext_action--------->", mapdata)
            const res = await axiosPost(visitorRegistrationNextUrl, mapdata, headers);
            dispatch({
                type: VISITOR_REGISTRATION_NEXT_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const visitorRegistrationGroupNext_action = (countryId, attendeeTypeId, GroupDetailsList) => async (dispatch) => {
    console.log(JSON.parse(sessionStorage.getItem('VisitorDataSS')), 'ldaaaata')
    try {
        const mapdata = {
            UserID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID : "",
            AccountID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID : "",
            ExhVisitorID: JSON.parse(sessionStorage.getItem('VisitorDataSS')) != null ? sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID : "",
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            // CountryID: countryId,
            AttendeeTypeID: attendeeTypeId,
            GroupDetailsList: GroupDetailsList,
            // visitDaysList: list
        }
        console.log("visitorRegistrationNext_action--------->", mapdata)
        const res = await axiosPost(visitorRegistrationGroupNextSubmitUrl, mapdata, headers);
        dispatch({
            type: VISITOR_REGISTRATION_GROUP_NEXT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getVistiorRegistarionDetails2_action = () => async (dispatch) => {
    try {
        const param = {
            UserId: sessionStorage.getItem('VisitorTypeID') == 2 ? ((JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(','))[0] : JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID,
            AccountID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhVisitorID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID
        }

        const res = await axiosGet(getVisitorRegistrationDetails2Url, param, headers);
        dispatch({
            type: GET_VISITOR_REGISTRATION_DETAILS2_ACTION_TYPE,
            payload: res,
        });
        console.log("getVistiorRegistarionDetails2_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const visitorPreviewSubmit_action = (totalCharges) => async (dispatch) => {
    try {
        const mapdata = {
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountId: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
            ExhvisitorID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
            PayableCharges: totalCharges,

        }
        console.log("visitorPreviewSubmit_action--------->", mapdata)
        const res = await axiosPost(visitorPreviewSubmitUrl, mapdata, headers);
        dispatch({
            type: VISITOR_PREVIEW_SUBMIT_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const visitorRegistrationSubmit_action = (chapterList, purposeList, needVisaAssistance, passName,
    applicantMobNo, applicantEmail, passNo, passDate, passExipryDate, passCountryId, passportFile, interestListData
    , remarks, isAgree, passportFileType, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
    delegationRemark, preffDate, prefTime, seminarInterestListData, b2bRemark, isCarParking, parkingCharges, chapterListData2) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID,
                AccountID: JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
                ExhvisitorID: JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
                ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                isVisaAssistance: needVisaAssistance,
                NameOnPassport: passName,
                ApplicantMobileNo: `${sessionStorage.getItem('sessionApplicantMobNoPre')}-${applicantMobNo}`,
                ApplicantEmailID: applicantEmail,
                PassportNo: passNo,
                PassportIssueDate: passDate != "" ? passDate : null,
                PassportExpiryDate: passExipryDate != "" ? passExipryDate : null,
                PassportIssuingCountryID: passCountryId,
                PassportFilePath: passportFile,
                Remarks: remarks,
                ISAgree: isAgree,
                visitorChapterList: chapterList,
                purposeOfVisitList: purposeList,
                areaOfInterestList: interestListData,
                PassportFileType: passportFileType,
                visitorRegNo: JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNo,
                IsGroupDelegation: isGrpDelegation,
                DelegationName: leader,
                DelegationCountryID: delegationCountryId,
                DelegationArrivalDate: arrivalDate,
                DelegationDepartureDate: departureDate,
                DelegationRemark: delegationRemark,
                B2BDepartureDate: preffDate != '' && preffDate != null ? preffDate : null,
                B2BPreferredTime: prefTime,
                interestSeminarList: seminarInterestListData,
                B2BRemark: b2bRemark,
                Carparking: isCarParking,
                ParkingCharges: parkingCharges,
                hallChapterList: chapterListData2,
            }
            console.log("visitorRegistrationSubmit_action--------->", mapdata)
            const res = await axiosPost(visitorRegistrationSubmitUrl, mapdata, headers);
            dispatch({
                type: VISITOR_REGISTRATION_SUBMIT_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const visitorRegistrationGroupSubmit_action = (chapterList, purposeList, needVisaAssistance, interestListData
    , remarks, isAgree, passportFileType, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
    delegationRemark, preffDate, prefTime, seminarInterestListData, b2bRemark, isCarParking, parkingCharges, chapterListData2, visitorGroupList) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: sessionStorage.getItem('VisitorTypeID') == 2 ? ((JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(','))[0] : JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID,
                // AccountID: visitorGroupList[0].AccountID,
                // ExhvisitorID: visitorGroupList[0].visitorID,
                ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                // isVisaAssistance: needVisaAssistance,
                ExhVisitorIDs: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
                Remarks: remarks,
                ISAgree: isAgree,
                // visitorChapterList: chapterList,
                purposeOfVisitList: purposeList,
                // areaOfInterestList: interestListData,
                // PassportFileType: passportFileType,
                // visitorRegNo: JSON.parse(sessionStorage.getItem('VisitorDataSS')).VisitorRegistrationNo,
                // IsGroupDelegation: isGrpDelegation,
                // DelegationName: leader,
                // DelegationCountryID: delegationCountryId,
                // DelegationArrivalDate: arrivalDate,
                // DelegationDepartureDate: departureDate,
                // DelegationRemark: delegationRemark,
                // B2BDepartureDate: preffDate != '' && preffDate != null ? preffDate : null,
                // B2BPreferredTime: prefTime,
                // interestSeminarList: seminarInterestListData,
                // B2BRemark: b2bRemark,
                // Carparking: isCarParking,
                // ParkingCharges: parkingCharges,
                // hallChapterList: chapterListData2,
                // visitorGroupList: visitorGroupList
            }
            console.log("visitorRegistrationSubmit_action--------->", mapdata)
            const res = await axiosPost(visitorGroupRegistrationFinalSubmitUrl, mapdata, headers);
            dispatch({
                type: VISITOR_REGISTRATION_GROUP_FINAL_ACTION_TYPE,
                payload: res,
            });

            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    };

export const verifyVisitorEmail_action = (value) => async (dispatch) => {
    try {
        const param = {
            EmailID: value,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
        }

        const res = await axiosGet(verifyVisitorEmailUrl, param, headers);
        dispatch({
            type: VERIFY_VISITOR_EMAIL_ACTION_TYPE,
            payload: res,
        });
        console.log("verifyVisitorEmail_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const payment_action = (payload) => async (dispatch) => {
    try {
        console.log("data----------", payload)
        const res = await axiosPost(paymentUrl, payload, headers);

        dispatch({
            type: PAYMENT_ACTION_TYPE,
            payload: res,
        });
        console.log(res)

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const getVisitorPreviewDetails_action = () => async (dispatch) => {
    var VisitorTypeIDUserID = sessionStorage.getItem('VisitorTypeID') == 2 && ((JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(','))[0]
    try {
        const param = {
            UserId: sessionStorage.getItem('VisitorTypeID') == 2 ? ((JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(','))[0] : JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID,
            AccountID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            ExhVisitorID: sessionStorage.getItem('VisitorTypeID') == 2 ? JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorIDs : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID
        }

        const res = await axiosGet(getCombineVisitorRegistrationDetailsUrl, param, headers);
        dispatch({
            type: GET_VISITOR_PREVIEW_DETAILS_ACTION_TYPE,
            payload: res,
        });
        console.log("getVisitorPreviewDetails_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const delVisaDoc_action = (docDelAccountID, docDelExhVisitorID) => async (dispatch) => {

    try {
        const params = {
            UserID: sessionStorage.getItem('VisitorTypeID') == 2 ? ((JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserIDs).split(','))[0] : JSON.parse(sessionStorage.getItem('VisitorDataSS')).UserID,
            AccountID: sessionStorage.getItem('VisitorTypeID') == 2 ? docDelAccountID : JSON.parse(sessionStorage.getItem('VisitorDataSS')).AccountID,
            ExhvisitorID: sessionStorage.getItem('VisitorTypeID') == 2 ? docDelExhVisitorID : JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
        }
        console.log("Delete deleteExhibitorBadge_ Document Action---------------->", params)
        const res = await axiosGet(deleteVisaDocUrl, params, headers)
        dispatch({
            type: DEL_VISA_DOCUMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

