import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import VisitorHeader from "../../../common_components/visitor_module_header";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action'
import { attendeeTypeActionId, cityActionId, countryActionId, countryIWithCodeActionId, exhibitorBadgeDaysActionId, pincodeActionId, visitorTypeListActionId, stateActionId, toastError, exhibitionStatusActionId, toastSuccess, industryChaptersActionId, primaryChapterListActionId } from "../../../utils/constants";
import { data } from "jquery";
import { getVistiorRegistarionDetails_action, visitorRegistrationNext_action, verifyVisitorEmail_action, visitorRegistrationGroupNext_action, delVisaDoc_action } from "../../../actions/visitor_module_action/visitor_registration_action/visitor_registration_action"
import Resizer from "react-image-file-resizer";
import Modal from 'react-bootstrap/Modal';
import { emailValidator } from "../../../utils/Validator";
import DatePicker from "react-datepicker";


class VisitorRegistration extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isModalEdit: false,
            isOtherPreName: false,
            isDisplayPincodeList: false,
            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayPersonalCountryList: false,
            isDisplayGroupPincodeList: false,
            isDisplayGroupCityList: false,
            isDisplayGroupStateList: false,
            isDisplayGroupPersonalCountryList: false,
            isSameAsMobNo: false,
            isDataLoad: false,
            VisitorTypeID: '',
            // Input Fields
            countryId: "",
            country: "",
            attendeeTypeId: "",
            otherName: "",
            preName: "Mr",
            firstName: "",
            middleName: "",
            lastName: "",
            designation: "",
            institute: "",
            preMobNo: "",
            mobNo: "",
            preWhatsAppMobNo: "",
            whatsAppMobNo: "",
            personalCountryId: "",
            personalCountry: "",
            pincode: "",
            city: "",
            regState: "",
            website: "",
            gstNo: "",
            panNo: "",
            email: "",
            preTelNo: "",
            telNo: "",
            preTel1No: "",
            tel1No: "",
            vaccineCertificateFile: "",
            vaccineCertificateFileType: "",
            photoIdFile: "",
            photoIdFileType: "",
            photoFile: "",
            photoFileType: "",

            isOtherPreNameModal: false,
            countryIdModal: "",
            countryIdErrModal: "",
            otherNameModal: '',
            firstNameModal: '',
            firstNameErrModal: '',
            prenameModal: 'Mr',
            middleNameModal: '',
            lastNameModal: '',
            lastNameErrModal: '',
            designationModal: '',
            designationErrModal: '',
            instituteModal: '',
            instituteErrModal: '',
            mobNoModal: '',
            mobNoErrModal: '',
            preMobNoModal: '',
            whatsAppMobNoModal: '',
            whatsAppMobNoErrModal: '',
            preWhatsAppMobNoModal: '',
            isSameAsMobNoModal: false,
            pincodeModal: '',
            pincodeErrModal: '',
            regStateModal: '',
            regStateErrModal: '',
            cityModal: '',
            cityErrModal: '',
            websiteModal: '',
            emailModal: '',
            telNoModal: '',
            preTelNoModal: '',
            tel1NoModal: '',
            preTel1NoModal: '',
            emailErrModal: '',
            modalID: '',
            //ERROR
            countryIdErr: '',
            attendeeTypeErr: '',
            daysErr: "",
            daysModalErr: "",
            cityErr: '',
            mobNoErr: '',
            regStateErr: '',
            pincodeErr: "",
            designationErr: '',
            photoFileErr: '',
            lastNameErr: '',
            personalCountryErr: "",
            photoIdFileErr: '',
            instituteErr: '',
            firstNameErr: '',
            whatsAppMobNoErr: '',
            visitorTypeErr: '',
            emailErr: "",
            isExhbitionClosed: false,
            modalStep2: false,
            activeModalStep: 1,
            // lists
            daysList: [],
            daysModalList: [],
            countryList: [],
            visitorTypeList: [],
            personalCountryList: [],
            tempPersonalCountryList: [],
            stateList: [],
            tempStateList: [],
            tempGroupStateList: [],
            cityList: [],
            tempCityList: [],
            tempGroupCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            tempGroupPincodeList: [],
            attendeeList: [],

            GroupDetailsList: [],
            AccountID: '',
            ExhVisitorID: '',
            AccountIDModal: '',
            ExhVisitorIDModal: '',
            isCoordinatorVisit: false,
            isFirst: true,

            chapterErr: '',
            chapterList: [],
            ChapterHallErr: '',
            level2: [],
            // filerArray: [],
            isOtherOpen: false,

            primaryChapterListData: [],
            level0: [],
            level2: [],
            level3: [],
            level4: [],
            primaryChapterListLevel3: [],
            primaryChapterListLevel4: [],
            tempDelegationCountryList: [],
            isGrpDelegation: false,

            leader: '',
            leaderErr: '',
            delegationCountry: '',
            // onDelegationCountrySearch
            isDisplayDelegationCountryList: false,
            // renderDelegationCountryList: false,
            delegationCountryErr: '',
            arrivalDate: '',
            arrivalDateErr: '',
            departureDate: '',
            departureDateErr: '',
            delegationRemark: '',
            delegationRemarkErr: '',
            delgationCountryList: [],
            tempDelegationCountryList: [],
            delegationCountryId: '',
            needVisaAssistance: false,
            passName: '',
            passNameErr: '',
            applicantMobNo: '',
            applicantMobNoErr: '',
            preApplicantMobNo: '',
            applicantEmail: '',
            applicantEmailErr: '',
            passNo: '',
            passNoErr: '',
            passDate: '',
            passDateErr: '',
            passExipryDate: '',
            passExipryDateErr: '',
            passCountry: '',
            passCountryErr: '',

            isVisaApplied: false,
            passPortFileName: '',
            passportFile: '',
            passportFilePath: '',
            passportFileType: '',
            passportFileErr: '',
            isDisplayPassCountryList: false,
            isPassportFilePresent: false,
            passCountryList: [],
            tempPassCountryList: [],
            other: '',
            tempObj: {},
            DateArrCoordinator: [],
            ApplicantEmailIDCoordinator: '',
            ApplicantMobileNoCoordinator: '',
            B2BDepartureDateCoordinator: '',
            B2BPreferredTimeCoordinator: '',
            B2BRemarkCoordinator: '',
            CarparkingCoordinator: '',
            DelegationArrivalDateCoordinator: '',
            DelegationCountryIDCoordinator: '',
            DelegationDepartureDateCoordinator: '',
            DelegationNameCoordinator: '',
            DelegationRemarkCoordinator: '',
            IsGroupDelegationullCoordinator: '',
            NameOnPassportCoordinator: '',
            ParkingChargesCoordinator: '',
            PassportExpiryDateCoordinator: '',
            PassportFileCoordinator: '',
            PassportFilePathCoordinator: '',
            PassportFileTypeCoordinator: '',
            PassportIssueDateCoordinator: '',
            PassportIssuingCountryIDCoordinator: '',
            PassportNoCoordinator: '',
            UserIDCoordinator: '',
            areaOfInterestListCoordinator: [],
            hallChapterListCoordinator: [],
            interestSeminarListCoordinator: [],
            isVisaAssistanceCoordinator: false,
            purposeOfVisitListCoordinator: [],
            visitorChapterListCoordinator: [],
            visitorRegNoCoordinator: '',
            PassportFileNameCoordinator: '',
            isPassportFilePresentCoordinator: false,
            IsGroupDelegationCoordinator: false
        }
        this.getFillList = this.getFillList.bind(this)
        this.onSelectDates = this.onSelectDates.bind(this)
        this.onVisitorRegistrationNext = this.onVisitorRegistrationNext.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.onCountrySelect = this.onCountrySelect.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.onSameAsMobNoClick = this.onSameAsMobNoClick.bind(this)
        this.onSameAsMobNoClick = this.onSameAsMobNoClick.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.getRegistrationDetails = this.getRegistrationDetails.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.onPersonalCountrySearch = this.onPersonalCountrySearch.bind(this)
        this.renderPersonalCountryList = this.renderPersonalCountryList.bind(this)
        this.onAttendeeSelect = this.onAttendeeSelect.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.verifyEmail = this.verifyEmail.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.handleAddGroupDetail = this.handleAddGroupDetail.bind(this)
        this.renderGroupList = this.renderGroupList.bind(this)
        this.handleDeleteAddedVisitor = this.handleDeleteAddedVisitor.bind(this)
        this.toggleModel = this.toggleModel.bind(this)
        this.renderGroupCityList = this.renderGroupCityList.bind(this)
        this.renderGroupPincodeList = this.renderGroupPincodeList.bind(this)
        this.renderGroupStateList = this.renderGroupStateList.bind(this)
        this.handleEditGroup = this.handleEditGroup.bind(this)
        this.onModalSelectDates = this.onModalSelectDates.bind(this)
        this.renderDelegationCountryList = this.renderDelegationCountryList.bind(this)
        this.onDelegationCountrySearch = this.onDelegationCountrySearch.bind(this)
        this.onPassportCountrySearch = this.onPassportCountrySearch.bind(this)
        this.renderPassCountryList = this.renderPassCountryList.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.inputValidateIndustrySegment = this.inputValidateIndustrySegment.bind(this)
        this.cancelModal = this.cancelModal.bind(this)
        this.handleDelete = this.handleDelete.bind(this)

    }




    // On Component Load
    componentDidMount() {
        // getting Exhibition Code from Url
        var exhCode = ""
        if (this.props.location != null && this.props.location.state == null) {
            var url = this.props.location.pathname != null && this.props.location.pathname.split("/")
            exhCode = url[url.length - 1]
            // sessionStorage.setItem("sessionExhibitionID", exhCode)
            // console.log("h7hyhyhh", this.props.location.state, 'this.props.location.state', exhCode)
            this.getFillList(countryIWithCodeActionId, '', '', '', exhCode, '').then(res => {
                //exhibition status..................
                this.getFillList(exhibitionStatusActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Country List 
                this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Attendee List
                this.getFillList(attendeeTypeActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Days List Action
                this.getFillList(exhibitorBadgeDaysActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
                this.getFillList(visitorTypeListActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
                this.getFillList(industryChaptersActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
                this.getFillList(primaryChapterListActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
                // Get Registration Details When Previous Click
            })
        }
        else if (this.props.location.state != null && this.props.location.state.is_previous) {
            //exhibition status..................
            this.getFillList(exhibitionStatusActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Country List 
            this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Attendee List
            this.getFillList(attendeeTypeActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Days List Action
            this.getFillList(exhibitorBadgeDaysActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
            this.getFillList(visitorTypeListActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
            this.getFillList(industryChaptersActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
            this.getFillList(primaryChapterListActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
            this.getRegistrationDetails()
        }

        //exhibition status..................
        this.getFillList(exhibitionStatusActionId, '', '', '', '', '')
        // Country List 
        this.getFillList(countryActionId, '', '', '', '', '')
        // Attendee List
        this.getFillList(attendeeTypeActionId, '', '', '', '', '')
        // Days List Action
        this.getFillList(exhibitorBadgeDaysActionId, "", "", "", "", "")

    }

    onSelectPrimaryChapter(e) {
        const { chapterList } = this.state
        var id = e.target.value
        var checked = e.target.checked

        chapterList.length > 0 && chapterList.map((data) => {
            if (checked) {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = true
                    if (id == 16) {
                        this.setState({ isOtherOpen: true })
                    }
                }
            }
            else {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = false
                    if (id == 16) {
                        this.setState({ isOtherOpen: false })
                    }
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", chapterList)
        this.setState({ chapterList: chapterList, chapterErr: "" })
    }

    onCheckLevel2(e, id, tempLevel1) {
        const { level2, primaryChapterListLevel3, level3, primaryChapterListLevel4, primaryChapterListData, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {
                if (event == true) {
                    level2[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = true
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = true
                                }
                            });
                        }
                    });

                } else {
                    level2[i]['IsSelect'] = false

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = false
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = false
                                }
                            });
                        }
                    });
                }

            }
        }
        console.log("tempLevel1", tempLevel1)

        for (let i = 0; i < primaryChapterListData.length; i++) {
            for (let j = 0; j < level2.length; j++) {
                if (level2[j]['ChapterID'] == id) {
                    if (primaryChapterListData[i]['ChapterID'] == level2[j]['ParentChapterID']) {
                        const allSelectedChecked = tempLevel1.every(item => item.IsSelect == 1)
                        console.log("allSelectedChecked", allSelectedChecked)
                        if (allSelectedChecked) {
                            primaryChapterListData[i]['IsSelect'] = true
                        } else {
                            primaryChapterListData[i]['IsSelect'] = false
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);


    }

    onCheckLevel3(e, id, tempLevel3) {
        const { level3, primaryChapterListLevel4, level2, level4, primaryChapterListData } = this.state
        this.setState({ level4DummyId: id })
        const event = e.target.checked

        var modelevel2 = []
        var modelevel3 = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                console.log("***********", level3[i])
                level2.map((s) => {
                    if (level3[i].ParentChapterID == s.ChapterID) {
                        primaryChapterListData.map(q => {
                            if (s.ParentChapterID == q.ChapterID) {
                                modelevel3.push(q)
                            }
                        })
                    }
                })
            }
        }


        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })

        console.log("finalListlevel2", finalListlevel2)


        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                if (event == true) {
                    level3[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {

                            level4.IsSelect = true
                        }
                    });

                } else {
                    level3[i]['IsSelect'] = false
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {
                            level4.IsSelect = false
                        }
                    });

                }
            }
        }

        for (let i = 0; i < level3.length; i++) {
            for (let index = 0; index < level2.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    if (level3[i]['ChapterID'] == id) {
                        if (level2[index]['ChapterID'] == level3[i]['ParentChapterID']) {
                            if (primaryChapterListData[j]['ChapterID'] == level2[index]['ParentChapterID']) {
                                const allSelectedChecked = tempLevel3.every(item => item.IsSelect == 1)
                                if (allSelectedChecked) {
                                    level2[index]['IsSelect'] = true
                                    const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                    console.log("allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                    if (allSelectedfinalListlevel2) {
                                        primaryChapterListData[j]['IsSelect'] = true
                                    } else {
                                        primaryChapterListData[j]['IsSelect'] = false
                                    }
                                }
                                else {
                                    level2[index]['IsSelect'] = false
                                    primaryChapterListData[j]['IsSelect'] = false
                                }
                            }
                        }
                    }
                }

            }

        }
        setTimeout(() => {
            this.setState({ level3: level3, level2: level2 })
        }, 100);

    }

    recordTwo(level2, level3, level4) {
        const { primaryChapterListLevel3 } = this.state
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
                primaryChapterListLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} >
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed " type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse_l2_' + level3data.ChapterID}
                                                aria-expanded="false"
                                                aria-controls={'collapse_l2_' + level3data.ChapterID}
                                            >
                                                {/* // checkbox for level3 */}
                                                {level3data.IsCheckbox &&
                                                    <input type="checkbox" className="from-left-sp"

                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID, tempLevel3)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordThree(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    recordThree(level3data, level4) {
        const { primaryChapterListLevel4 } = this.state
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
                primaryChapterListLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">

                                {level4data.IsCheckbox &&
                                    <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                        onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID, tempLevel4)} />
                                }
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    onSelectLevel4(e, id, tempLevel4) {
        const { level4, exhibitDetailsList, level3, level2, level4DummyId, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        this.setState({ ChapterHallErr: "" })
        var modelevel2 = []
        var modelevel3 = []

        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level3.map((s) => {
                    if (level4[i].ParentChapterID == s.ChapterID) {
                        level2.map(p => {
                            if (s.ParentChapterID == p.ChapterID) {
                                modelevel2.push(p)
                                primaryChapterListData.map(q => {
                                    if (p.ParentChapterID == q.ChapterID) {
                                        modelevel3.push(q)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }

        const finalListlevel3 = []
        level3.map(data => {
            modelevel2.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel3.push(data)
                }

            })
        })

        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })




        console.log("************** finalListlevel2", finalListlevel2)
        // console.log("************** finalListlevel3", finalListlevel3)


        for (let i = 0; i < tempLevel4.length; i++) {
            for (let index = 0; index < level3.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    for (let k = 0; k < level2.length; k++) {
                        if (tempLevel4[i]['ChapterID'] == id) {
                            if (level3[index]['ChapterID'] == tempLevel4[i]['ParentChapterID']) {
                                if (level2[k]['ChapterID'] == level3[index]['ParentChapterID']) {
                                    if (primaryChapterListData[j]['ChapterID'] == level2[k]['ParentChapterID']) {
                                        const allSelectedChecked = tempLevel4.every(item => item.IsSelect == 1)
                                        if (allSelectedChecked) {
                                            level3[index]['IsSelect'] = true

                                            const allSelectedfinalListlevel3 = finalListlevel3.every(item => item.IsSelect == 1)
                                            if (allSelectedfinalListlevel3) {
                                                level2[k]['IsSelect'] = true

                                                const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                                console.log("************** allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                                if (allSelectedfinalListlevel2) {
                                                    primaryChapterListData[j]['IsSelect'] = true

                                                } else {
                                                    primaryChapterListData[j]['IsSelect'] = false
                                                }

                                            } else {
                                                level2[k]['IsSelect'] = false
                                            }


                                        }
                                        else {
                                            level3[index]['IsSelect'] = false
                                            level2[k]['IsSelect'] = false
                                            primaryChapterListData[j]['IsSelect'] = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level4: level4, level3: level3, level2: level2 })
        }, 100);


    }

    // On Delegation Country Search
    onDelegationCountrySearch(e) {
        const { delgationCountryList, tempDelegationCountryList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempDelegationCountryList ++++----------->", tempDelegationCountryList)

            var searchCoutryList = tempDelegationCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                delegationCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                delegationCountryErr: '',
                tempDelegationCountryList: searchCoutryList,
                isDisplayDelegationCountryList: true
            })
        }
        else {

            this.setState({
                delegationCountry: e.target.value,
                tempDelegationCountryList: delgationCountryList,
                isDisplayDelegationCountryList: false
            })
        }
    }

    // Render Country for Delegation Country
    renderDelegationCountryList() {
        const { tempDelegationCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempDelegationCountryList.length > 0 && tempDelegationCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                delegationCountry: data.Description, delegationCountryId: data.Code,
                                isDisplayDelegationCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        return new Promise((resolve, reject) => {
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value, from)
                .then((data) => {
                    if (data.error != 1) {
                        if (actionID === primaryChapterListActionId) {
                            console.log("primaryChapterListActionId 121212", data.data)
                            async function seqnoFormat(arr) {
                                for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].seqno != '' && arr[i].seqno != null) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    }
                                }
                                return
                            }

                            for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                if (data.data['primaryChapter'][i].ChapterID == 2046) {
                                    data.data['primaryChapter'][i].ChapterName = 'TECHNICAL TEXTILE PRODUCTS & HOME TEXTILE PRODUCT'
                                }
                                if (data.data['primaryChapter'][i].ChapterID == 2033) {
                                    data.data['primaryChapter'].splice(i, 1)
                                }
                                console.log(data.data['primaryChapter'])
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                    var seq1 = data.data['level2'][j].seqno
                                    var seq2 = data.data['level2'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level2'][j]
                                        data.data['level2'][j] = data.data['level2'][j + 1]
                                        data.data['level2'][j + 1] = temp;
                                    }
                                }
                                data.data['level2'][i].primaryChapterId = data.data['level2'][i].ParentChapterID
                                seqnoFormat(data.data['level2'])
                            }
                            console.log(data.data['primaryChapter'])
                            for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                for (var j = 0; j < data.data['primaryChapter'].length - 1 - i; j++) {
                                    var seq1 = data.data['primaryChapter'][j].seqno
                                    var seq2 = data.data['primaryChapter'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['primaryChapter'][j]
                                        data.data['primaryChapter'][j] = data.data['primaryChapter'][j + 1]
                                        data.data['primaryChapter'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                if (data.data['primaryChapter'][i].seqno == null || data.data['primaryChapter'][i].seqno == undefined) {
                                    console.log(data.data['primaryChapter'][i].seqno, i)
                                }
                                // seqnoFormat(data.data['primaryChapter'])
                            }
                            for (var i = 0; i < data.data['level3'].length; i++) {
                                for (var j = 0; j < data.data['level3'].length - 1 - i; j++) {

                                    var seq1 = data.data['level3'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level3'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')

                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level3'][j]
                                        data.data['level3'][j] = data.data['level3'][j + 1]
                                        data.data['level3'][j + 1] = temp;
                                    }
                                }
                                for (var j = 0; j < data.data['level2'].length; j++) {
                                    if (data.data['level2'][j].ChapterID == data.data['level3'][i].ParentChapterID) {
                                        data.data['level3'][i].primaryChapterId = data.data['level2'][j].ParentChapterID
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level3'])
                            }
                            for (var i = 0; i < data.data['level4'].length; i++) {
                                for (var j = 0; j < data.data['level4'].length - 1 - i; j++) {
                                    var seq1 = data.data['level4'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level4'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level4'][j]
                                        data.data['level4'][j] = data.data['level4'][j + 1]
                                        data.data['level4'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                for (var j = 0; j < data.data['level3'].length; j++) {
                                    if (data.data['level3'][j].ChapterID == data.data['level4'][i].ParentChapterID) {
                                        for (var k = 0; k < data.data['level2'].length; k++) {
                                            if (data.data['level2'][k].ChapterID == data.data['level3'][j].ParentChapterID) {
                                                data.data['level4'][i].primaryChapterId = data.data['level2'][k].ParentChapterID
                                                break
                                            }
                                        }
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level4'])
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                data.data['level2'][i].isHighlight = false;
                            }
                            this.setState({
                                primaryChapterListData: data.data['primaryChapter'] ? data.data['primaryChapter'] : [],
                                level0: data.data['level0'] ? data.data['level0'] : [],
                                level2: data.data['level2'] ? data.data['level2'] : [],
                                level3: data.data['level3'] ? data.data['level3'] : [],
                                level4: data.data['level4'] ? data.data['level4'] : [],

                            })
                            // if (!isPrevious) {
                            //     this.setState({ isLoading: false, })
                            // }
                            resolve(true)
                        }
                        // country list 

                        if (actionID === exhibitionStatusActionId) {
                            console.log("exhibitionStatusActionId", data.data)
                            this.setState({
                                isExhbitionClosed: data != null && data.data[0]['IsExhibitionClosed'] == 1 ? true : false,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        if (actionID === visitorTypeListActionId) {
                            console.log("Attendee Type data", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isChecked = false
                            })
                            this.setState({
                                visitorTypeList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        if (actionID === industryChaptersActionId) {
                            console.log("industryChaptersActionId 121212", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.IsSelect = false
                            })
                            this.setState({
                                chapterList: data.data,
                            })
                            // if (!isPrevious) {
                            //     this.setState({ isLoading: false, })
                            // }
                            resolve(true)
                        }
                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            this.setState({
                                countryList: data != null ? data.data : [],
                                personalCountryList: data.data,
                                tempPersonalCountryList: data.data,
                                delgationCountryList: data.data,
                                tempDelegationCountryList: data.data,
                                passCountryList: data.data,
                                tempPassCountryList: data.data,
                                isLoading: false,

                            })
                            resolve(true)
                        }
                        // ExhibitionID
                        if (actionID === countryIWithCodeActionId) {
                            console.log("country data", data.data[0]['ExhibitionID'])
                            var emailArr = data.data[0].ExhMailEmail != '' && data.data[0].ExhMailEmail != null ? data.data[0].ExhMailEmail.split(",") : []
                            var mobileArr = data.data[0].ExhMailMobile != '' && data.data[0].ExhMailMobile != null ? data.data[0].ExhMailMobile.split(",") : []
                            sessionStorage.setItem("sessionExhibitionID", data.data[0]['ExhibitionID'])
                            sessionStorage.setItem("exhibitionAddress", data.data[0]['Address'])
                            sessionStorage.setItem("sessionBannerPath", data.data[0]['Banner'])
                            sessionStorage.setItem("exhibitionDateFrom", data.data[0]['ExhibitionDateFrom'])
                            sessionStorage.setItem("exhibitionDateTo", data.data[0]['ExhibitionDateTo'])
                            sessionStorage.setItem("sessionLogoPath", data.data[0]['Logo'])

                            sessionStorage.setItem("sessionExhibitionName", data.data[0]['ExhibitionName'])
                            sessionStorage.setItem("sessionExhibitionEmail", JSON.stringify(emailArr))
                            sessionStorage.setItem("sessionExhibitionMobileNo", JSON.stringify(mobileArr))
                            sessionStorage.setItem("sessionExhMailWebsite", data.data[0]['ExhMailWebsite'])
                            sessionStorage.setItem("isVisitorB2B", data.data[0]['isVisitorB2B'])
                            sessionStorage.setItem("isVisitorSeminar", data.data[0]['isVisitorSeminar'])
                            sessionStorage.setItem("ExhibitionCountryID", data.data[0]['ExhibitionCountryID'])
                            sessionStorage.setItem("ExhVisitorVisaIssueDate", data.data[0]['ExhVisitorVisaIssueDate'])
                            this.setState({
                                isDataLoad: true,
                                bannerPath: data.data[0]['Banner'] != null && data.data[0]['Banner']
                            })

                            this.getFillList(countryActionId, data.data[0]['ExhibitionID'], '', '', '', '')
                            resolve(true)
                        }
                        // state list
                        if (actionID === stateActionId) {
                            console.log("stateList data", data.data)
                            this.setState({
                                stateList: data.data,
                                tempStateList: data.data,
                                tempGroupStateList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // city list
                        if (actionID === cityActionId) {
                            console.log("city data", data.data)
                            this.setState({
                                cityList: data.data,
                                tempCityList: data.data,
                                tempGroupCityList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // pincode
                        if (actionID === pincodeActionId) {
                            console.log("pincode data", data.data)
                            this.setState({
                                pincodeList: data.data,
                                tempPincodeList: data.data,
                                tempGroupPincodeList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // Days List
                        if (actionID === exhibitorBadgeDaysActionId) {
                            console.log("exhibitor data", data.data)
                            var DateList = []
                            var DateListModal = []
                            // data.data.map(item => {
                            //     DateList.push(item)
                            // })
                            // data.data.map(item => {
                            //     DateListModal.push(item)
                            // })
                            data.data.map((item) => {
                                // item.isSelect = false
                                // item.isReadOnly = false
                                // item.Code = item.VisitDate
                                var mapData = {
                                    "Code": item['VisitDate'],
                                    "VisitDate": item['VisitDate'],
                                    "isSelect": false,
                                    "isReadOnly": false,
                                    'PriceINR': null,
                                    'PriceUSD': null,
                                    'PromoCode': null,
                                    'PromoCodeDiscount': null
                                }
                                DateList.push(mapData)
                                // DateListModal.push(mapData)
                            })
                            data.data.map((item) => {
                                // item.isSelect = false
                                // item.isReadOnly = false
                                // item.Code = item.VisitDate
                                var mapData = {
                                    "Code": item['VisitDate'],
                                    "VisitDate": item['VisitDate'],
                                    "isSelect": false,
                                    "isReadOnly": false,
                                    'PriceINR': null,
                                    'PriceUSD': null,
                                    'PromoCode': null,
                                    'PromoCodeDiscount': null
                                }
                                // DateList.push(mapData)
                                DateListModal.push(mapData)
                            })
                            this.setState({
                                daysList: DateList,
                                daysModalList: DateListModal,
                                isLoading: false
                            })
                            resolve(true)
                        }
                        // Attendee Type List
                        if (actionID === attendeeTypeActionId) {
                            console.log("Attendee Type data", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isChecked = false
                            })
                            this.setState({
                                attendeeList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })

    }

    // On Select Dates
    onSelectDates(e) {
        const { daysList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        daysList.length > 0 && daysList.map((data) => {
            if (checked) {
                if (code == data.Code) {
                    data.isSelect = true
                }
            } else {
                if (code == data.Code) {
                    data.isSelect = false
                }

            }
        })
        var selectedDays = daysList.filter(data => data.isSelect == true)
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.VisitDate
            }
            list.push(data)
        })
        console.log("hgdddhdjdjdjdkdj", daysList, this.state.daysModalList)
        this.setState({ daysList: daysList, DateArrCoordinator: list, daysErr: "" })
    }

    onModalSelectDates(e) {
        const { daysModalList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        daysModalList.length > 0 && daysModalList.map((data) => {
            if (checked) {
                if (code == data.Code) {
                    data.isSelect = true
                }
            } else {
                if (code == data.Code) {
                    data.isSelect = false
                }

            }
        })
        console.log("hgdddhdjdjdjdkdj", daysModalList)
        this.setState({ daysModalList: daysModalList, daysModalErr: "" })
    }

    //input validate
    inputValidate() {
        const { city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender, institute, firstName,
            preWhatsAppMobNo, whatsAppMobNo, VisitorTypeID, personalCountryId, pincode, email, countryId, attendeeTypeId, daysList,
            isOtherPreNameModal, otherNameModal, emailErrModal, firstNameModal, firstNameErrModal, prenameModal, middleNameModal, lastNameModal, lastNameErrModal, designationModal, designationErrModal, instituteModal, instituteErrModal, mobNoModal, mobNoErrModal, preMobNoModal, whatsAppMobNoModal, whatsAppMobNoErrModal, preWhatsAppMobNoModal, isSameAsMobNoModal, pincodeModal, pincodeErrModal, regStateModal, regStateErrModal, cityModal, cityErrModal, websiteModal, emailModal, telNoModal, preTelNoModal, tel1NoModal, preTel1NoModal, } = this.state
        if (countryId == "" || countryId == "Select") {
            this.setState({ countryIdErr: "Please Select Country" });
        }
        if (attendeeTypeId == "") {
            this.setState({ attendeeTypeErr: "Please Select Attendee Type" });
        }
        if (VisitorTypeID == "") {
            this.setState({ visitorTypeErr: "Please Select Visitor Type" });
        }
        if (!(daysList.some(obj => obj.isSelect == true))) {
            if (this.state.VisitorTypeID == 2) {
                this.state.isCoordinatorVisit && this.setState({ daysErr: "Please Select Days" })
            } else {
                this.setState({ daysErr: "Please Select Days" })
            }
        }
        if (firstName == "") {
            this.setState({ firstNameErr: "Please enter first name" });
        }
        if (lastName == "") {
            this.setState({ lastNameErr: "Please enter last name" });
        }
        if (designation == "") {
            this.setState({ designationErr: "Please enter designation" });
        }
        if (institute == "") {
            this.setState({ instituteErr: "Please enter institue/Organization" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please enter mobile no" });
        }
        if (whatsAppMobNo == "") {
            this.setState({ whatsAppMobNoErr: "Please enter whatsapp no" });
        }
        if (personalCountryId == "") {
            this.setState({ personalCountryErr: "Please enter Country" });
        }
        // if (photoFile == "") {
        //     this.setState({ photoFileErr: "Please upload photo" });
        // }
        // if (photoIdFile == "") {
        //     this.setState({ photoIdFileErr: "Please upload photo ID" });
        // }
        if (regState == "" && countryId == "91") {
            this.setState({ regStateErr: "Please enter province/state" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please enter city" });
        }
        if (pincode == "") {
            this.setState({ pincodeErr: "Please enter Pincode" });
        }
        if (email == "") {
            this.setState({ emailErr: "Please enter Email" });
        }
        if (email != "") {
            var emailError = emailValidator(email)
            if (emailError != '') {
                this.setState({ emailErr: emailError })
                return
            }
        }
    }

    //input validate
    inputValidateGroup() {
        const { city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender, institute, firstName,
            preWhatsAppMobNo, whatsAppMobNo, VisitorTypeID, personalCountryId, pincode, email, countryId, attendeeTypeId, daysList,
            isOtherPreNameModal, otherNameModal, emailErrModal, firstNameModal, firstNameErrModal, prenameModal, middleNameModal, lastNameModal, lastNameErrModal, designationModal, designationErrModal, instituteModal, instituteErrModal, mobNoModal, mobNoErrModal, preMobNoModal, whatsAppMobNoModal, whatsAppMobNoErrModal, preWhatsAppMobNoModal, isSameAsMobNoModal, pincodeModal, pincodeErrModal, regStateModal, regStateErrModal, cityModal, cityErrModal, websiteModal, emailModal, telNoModal, preTelNoModal, tel1NoModal, preTel1NoModal, } = this.state
        if (firstNameModal == "") {
            this.setState({ firstNameErrModal: "Please enter first name" });
        }
        if (lastNameModal == "") {
            this.setState({ lastNameErrModal: "Please enter last name" });
        }
        if (designationModal == "") {
            this.setState({ designationErrModal: "Please enter designation" });
        }
        if (instituteModal == "") {
            this.setState({ instituteErrModal: "Please enter institue/Organization" });
        }
        if (mobNoModal == "") {
            this.setState({ mobNoErrModal: "Please enter mobile no" });
        }
        if (whatsAppMobNoModal == "") {
            this.setState({ whatsAppMobNoErrModal: "Please enter whatsapp no" });
        }
        // if (photoFile == "") {
        //     this.setState({ photoFileErr: "Please upload photo" });
        // }
        // if (photoIdFile == "") {
        //     this.setState({ photoIdFileErr: "Please upload photo ID" });
        // }
        if (regStateModal == "" && countryId == "91") {
            this.setState({ regStateErrModal: "Please enter province/state" });
        }
        if (cityModal == "") {
            this.setState({ cityErrModal: "Please enter city" });
        }
        if (pincodeModal == "") {
            this.setState({ pincodeErrModal: "Please enter Pincode" });
        }
        if (emailModal == "") {
            this.setState({ emailErrModal: "Please enter Email" });
        }
        if (emailModal != "") {
            var emailError = emailValidator(emailModal)
            if (emailError != '') {
                this.setState({ emailErrModal: emailError })
                return
            }
        }
    }

    // On Visitor Registration Next
    onVisitorRegistrationNext(e, mode) {
        const { daysList } = this.state
        e.preventDefault()
        // Is Days Selcted Check
        var daysListValidation = false
        for (var i = 0; i < this.state.daysList.length; i++) {
            if (this.state.daysList[i].isSelect == true) {
                daysListValidation = true
            }
        }
        // preparing Selected Days for udt
        const selectedDays = daysList.filter(data => data.isSelect == true)
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.VisitDate
            }
            list.push(data)
        })
        this.inputValidate(1)
        setTimeout(() => {
            const { countryId, attendeeTypeId, city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender,
                institute, firstName, preWhatsAppMobNo, whatsAppMobNo, pincode, email, firstNameErr, lastNameErr, designationErr,
                instituteErr, mobNoErr, whatsAppMobNoErr, regStateErr, cityErr, pincodeErr, emailErr, preName, middleName,
                isSameAsMobNo, website, preTelNo, preTel1No, telNo, tel1No, countryIdErr, attendeeTypeErr, daysErr } = this.state

            if (firstName != "" && lastName != "" && designation != "" && institute != "" && mobNo != "" && whatsAppMobNo != "" &&
                city != "" && pincode != "" && email != "" && countryId != "" && attendeeTypeId != "") {
                if (firstNameErr == "" && lastNameErr == "" && designationErr == "" && instituteErr == "" && mobNoErr == "" &&
                    whatsAppMobNoErr == "" && regStateErr == "" && cityErr == "" && pincodeErr == "" && (emailErr == "" && emailErr != "Please enter proper email." && emailErr != "your emailId already register as a visitor account") && countryIdErr == ""
                    && attendeeTypeErr == "" && daysErr == ""
                ) {
                    this.setState({ isLoading: true })
                    /// Setting data in session storage For Insert Next
                    sessionStorage.setItem("sessionregisterTelephoneNoPre", preTelNo)
                    sessionStorage.setItem("sessionregisterTelephoneNo1Pre", preTel1No)
                    sessionStorage.setItem("sessionCountryNamePreRegisterMobileNo", preMobNo)
                    sessionStorage.setItem("sessionMobileNo", mobNo)
                    sessionStorage.setItem("sessionCountryNamePreRegisterWhatsAppMobNo", preWhatsAppMobNo)
                    sessionStorage.setItem("sessionVisitorEmail", email)

                    this.props.visitorRegistrationNext_action(countryId, attendeeTypeId, preName, firstName, middleName, lastName,
                        designation, institute, mobNo, whatsAppMobNo, isSameAsMobNo, regState, city,
                        pincode, website, email, telNo, tel1No, list)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("Visitor Registration Next", data.data[0])
                                this.setState({ isLoading: false })
                                sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                                sessionStorage.setItem('VisitorTypeID', this.state.VisitorTypeID)
                                sessionStorage.setItem('countryId', countryId)
                                if (mode == 1) {
                                    console.log(sessionStorage.getItem('VisitorDataSS'))
                                } else {
                                    this.props.navigate('/visitorindustrysegment', {
                                        state: {
                                            ExhVisitorID: data.data[0]['ExhVisitorID'],
                                            countryID: countryId
                                        }
                                    })
                                }

                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    toastError("Enter Mandatory Fields")
                }
            }
            else {
                toastError("Enter Mandatory Fields")
            }

        }, 200);
    }

    onVisitorRegistrationGroupNext(e, mode) {
        const { daysList, GroupDetailsList, isCoordinatorVisit, hallChapterListCoordinator, visitorChapterListCoordinator, VisitorTypeID, countryId, attendeeTypeId } = this.state
        e.preventDefault()
        // Is Days Selcted Check
        var daysListValidation = false
        for (var i = 0; i < this.state.daysList.length; i++) {
            if (this.state.daysList[i].isSelect == true) {
                daysListValidation = true
            }
        }
        this.inputValidate()
        // preparing Selected Days for udt
        const selectedDays = daysList.filter(data => data.isSelect == true)
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.VisitDate
            }
            list.push(data)
        })
        if (countryId == "" || countryId == "Select") {
            this.setState({ countryIdErr: "Please Select Country" });
        }
        if (attendeeTypeId == "") {
            this.setState({ attendeeTypeErr: "Please Select Attendee Type" });
        }
        if (VisitorTypeID == "") {
            this.setState({ visitorTypeErr: "Please Select Visitor Type" });
        }
        // if (!(daysList.some(obj => obj.isSelect == true))) {
        //     this.setState({ daysErr: "Please Select Days" })
        // }
        //     this.setState({ daysErr: "Please Select Days" })
        // }
        var CoordinatorErrFlag = true
        // if (isCoordinatorVisit) {
        //     if (list.length == 0) {
        //         CoordinatorErrFlag = false
        //     }
        //     if (hallChapterListCoordinator.length == 0) {
        //         CoordinatorErrFlag = false
        //     }
        //     if (visitorChapterListCoordinator.length == 0) {
        //         CoordinatorErrFlag = false
        //     }
        //     if (this.state.isVisaAssistanceCoordinator) {
        //         if (this.state.ApplicantEmailIDCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.ApplicantMobileNoCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.NameOnPassportCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportExpiryDateCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportFileCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportFilePathCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportFileTypeCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportFileTypeCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportIssueDateCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportIssuingCountryIDCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.PassportNoCoordinator) { CoordinatorErrFlag = false }
        //     }
        //     if (this.state.IsGroupDelegationCoordinator) {
        //         if (this.state.DelegationArrivalDateCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.DelegationCountryIDCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.DelegationDepartureDateCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.DelegationNameCoordinator) { CoordinatorErrFlag = false }
        //         if (this.state.DelegationRemarkCoordinator) { CoordinatorErrFlag = false }
        //     }
        // }
        setTimeout(() => {
            const { countryId, attendeeTypeId, firstName, city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender,
                instfirstitute, Name, preWhatsAppMobNo, whatsAppMobNo, pincode, email, firstNameErr, lastNameErr, designationErr,
                instituteErr, mobNoErr, whatsAppMobNoErr, regStateErr, institute, cityErr, pincodeErr, emailErr, preName, middleName,
                isSameAsMobNo, website, preTelNo, preTel1No, AccountID, ExhVisitorID, telNo, tel1No, countryIdErr, isOtherPreName, otherName, attendeeTypeErr, daysErr } = this.state

            if (firstName != "" && lastName != "" && designation != "" && institute != "" && mobNo != "" && whatsAppMobNo != "" &&
                city != "" && pincode != "" && email != "" && countryId != "" && attendeeTypeId != "") {
                if (firstNameErr == "" && lastNameErr == "" && designationErr == "" && instituteErr == "" && mobNoErr == "" &&
                    whatsAppMobNoErr == "" && regStateErr == "" && cityErr == "" && pincodeErr == "" && (emailErr == "" && emailErr != "Please enter proper email." && emailErr != "your emailId already register as a visitor account") && countryIdErr == ""
                    && attendeeTypeErr == "" && daysErr == "" && CoordinatorErrFlag
                ) {
                    if (this.state.GroupDetailsList.length == 0) {
                        toastError("Please Add Atleast 1 Visitor")
                    } else {
                        var obj = {
                            'Title': isOtherPreName ? otherName : preName,
                            'firstName': firstName,
                            'middleName': middleName,
                            'lastName': lastName,
                            'institute': institute,
                            'isOtherPreName': isOtherPreName,
                            'otherName': otherName,
                            'designation': designation,
                            'mobNo': preMobNo != '' && preMobNo != null ? `${preMobNo}-${mobNo}` : mobNo,
                            'whatsAppMobNo': preWhatsAppMobNo != '' && preWhatsAppMobNo != null ? `${preWhatsAppMobNo}-${whatsAppMobNo}` : whatsAppMobNo,
                            'pincode': pincode,
                            'regState': regState,
                            'isSameAsMobNo': isSameAsMobNo,
                            'city': city,
                            'website': website,
                            'AccountID': AccountID != null && AccountID != '' ? AccountID : null,
                            'ExhVisitorID': ExhVisitorID != null && ExhVisitorID != '' ? ExhVisitorID : null,
                            'email': email,
                            'telNo': preTelNo != '' && preTelNo != null ? `${preTelNo}-${telNo}` : telNo,
                            'tel1No': preTel1No != '' && preTel1No != null ? `${preTel1No}-${tel1No}` : tel1No,
                            'id': 0,
                            'isCoordinator': true,
                            'isVisit': this.state.isCoordinatorVisit,
                            'countryId': countryId,
                            'DateArr': this.state.isCoordinatorVisit ? list : [],
                            'ApplicantEmailID': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.ApplicantEmailIDCoordinator : '' : '',
                            'ApplicantMobileNo': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.ApplicantMobileNoCoordinator : '' : '',
                            'B2BDepartureDate': this.state.isCoordinatorVisit ? this.state.B2BDepartureDateCoordinator : '',
                            'B2BPreferredTime': this.state.isCoordinatorVisit ? this.state.B2BPreferredTimeCoordinator : '',
                            'B2BRemark': this.state.isCoordinatorVisit ? this.state.B2BRemarkCoordinator : '',
                            'Carparking': false,
                            'DelegationArrivalDate': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator ? this.state.DelegationArrivalDateCoordinator : '' : '',
                            'DelegationCountryID': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator ? this.state.DelegationCountryIDCoordinator : '' : '',
                            'DelegationDepartureDate': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator ? this.state.DelegationDepartureDateCoordinator : '' : '',
                            'DelegationName': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator ? this.state.DelegationNameCoordinator : '' : '',
                            'DelegationRemark': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator ? this.state.DelegationRemarkCoordinator : '' : '',
                            'ExhibitionID': parseInt(sessionStorage.getItem('sessionExhibitionID')),
                            'IsGroupDelegation': this.state.isCoordinatorVisit ? this.state.IsGroupDelegationCoordinator : '',
                            'NameOnPassport': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.NameOnPassportCoordinator : '' : '',
                            'ParkingCharges': 0,
                            'PassportExpiryDate': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportExpiryDateCoordinator : '' : '',
                            'PassportFile': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportFileCoordinator : '' : '',
                            'PassportFilePath': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportFilePathCoordinator : '' : '',
                            'PassportFileType': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportFileTypeCoordinator : '' : '',
                            'PassportIssueDate': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportIssueDateCoordinator : '' : '',
                            'PassportIssuingCountryID': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportIssuingCountryIDCoordinator : '' : '',
                            'PassportNo': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator ? this.state.PassportNoCoordinator : '' : '',
                            'UserID': null,
                            'areaOfInterestList': [],
                            'hallChapterList': this.state.isCoordinatorVisit ? this.state.hallChapterListCoordinator : [],
                            'interestSeminarList': [],
                            'isVisaAssistance': this.state.isCoordinatorVisit ? this.state.isVisaAssistanceCoordinator : false,
                            'purposeOfVisitList': [],
                            'visitorChapterList': this.state.isCoordinatorVisit ? this.state.visitorChapterListCoordinator : [],
                            'visitorRegNo': this.state.isCoordinatorVisit ? this.state.visitorRegNoCoordinator : ''

                        }
                        GroupDetailsList.unshift(obj)
                        this.setState({ isLoading: true })
                        /// Setting data in session storage For Insert Next
                        this.props.visitorRegistrationGroupNext_action(countryId, attendeeTypeId, GroupDetailsList)
                            .then((data) => {
                                if (data.error != 1) {
                                    console.log("Visitor Registration Next", data.data)
                                    this.setState({ isLoading: false })
                                    sessionStorage.setItem('VisitorTypeID', this.state.VisitorTypeID)
                                    var mobNoArr = []
                                    var emailArr = []
                                    for (var j = 0; j < GroupDetailsList.length; j++) {
                                        mobNoArr.push(GroupDetailsList[j].mobNo)
                                        emailArr.push(GroupDetailsList[j].email)
                                    }
                                    mobNoArr.toString()
                                    emailArr.toString()
                                    data.data[0][0].mobNo = mobNoArr.toString()
                                    data.data[0][0].email = emailArr.toString()
                                    sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0][0]))
                                    for (var i = 0; i < data.data[1].length; i++) {
                                        if (data.data[1][i].isCoordinator == 1) {
                                            // sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[1][i]))
                                            sessionStorage.setItem('VisitorTypeID', this.state.VisitorTypeID)
                                            sessionStorage.setItem('countryId', data.data[1][i].CountryID)
                                            this.setState({ ExhVisitorID: data.data[1][i].ExhVisitorID, AccountID: data.data[1][i].AccountID })
                                        }
                                        for (var j = 0; j < GroupDetailsList.length; j++) {
                                            if (GroupDetailsList[j].email == data.data[1][i].Email) {
                                                GroupDetailsList[j].AccountID = data.data[1][i].AccountID
                                                GroupDetailsList[j].ExhVisitorID = data.data[1][i].ExhVisitorID
                                                GroupDetailsList[j].visitorRegNo = data.data[1][i].visitorRegistrationNo
                                                if (GroupDetailsList[j].needVisaAssistance) {
                                                    GroupDetailsList[j].passportFileType = null
                                                    GroupDetailsList[j].isPassportFilePresent = true
                                                }

                                            }
                                        }
                                    }

                                    if (mode == 1) {
                                        GroupDetailsList.shift()
                                        // this.setState()
                                        console.log(sessionStorage.getItem('VisitorDataSS'))
                                        this.setState({ GroupDetailsList: [] }, () => {
                                            this.getRegistrationDetails()
                                            console.log(GroupDetailsList)
                                        })
                                    } else {
                                        this.props.navigate('/visitorindustrysegment', {
                                            state: {
                                                // ExhVisitorID: data.data[0]['ExhVisitorID'],
                                                countryID: countryId
                                            }
                                        })
                                    }
                                } else {
                                    this.setState({ isLoading: false })
                                    // console.log
                                    toastError(data.msg);
                                }
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                }
                else {
                    toastError("Enter Mandatory Fields")
                }
            }
            else {
                toastError("Enter Mandatory Fields")
            }

        }, 200);
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    //Pincode Search
    onPincodeSearch(e, status) {
        const { pincodeList, tempPincodeList, tempGroupPincodeList, pincode } = this.state
        if (status == 1) {
            if (e.target.value != '') {
                var searchPincodeList = tempGroupPincodeList.filter(data => {
                    return (
                        data
                            .Pincode
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    pincodeModal: e.target.value,
                    pincodeErrModal: "",
                    tempGroupPincodeList: searchPincodeList,
                    isDisplayGroupPincodeList: true
                })
            } else {
                this.setState({
                    pincodeModal: e.target.value,
                    tempGroupPincodeList: pincodeList,
                    isDisplayGroupPincodeList: false
                })
            }
        } else {
            if (e.target.value != '') {
                var searchPincodeList = tempPincodeList.filter(data => {
                    return (
                        data
                            .Pincode
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    pincode: e.target.value,
                    pincodeErr: "",
                    tempPincodeList: searchPincodeList,
                    isDisplayPincodeList: true
                })
            } else {
                this.setState({
                    pincode: e.target.value,
                    tempPincodeList: pincodeList,
                    isDisplayPincodeList: false
                })
            }
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pincode: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    renderGroupPincodeList() {
        const { tempPincodeList, tempGroupPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempGroupPincodeList.length > 0 && tempGroupPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pincodeModal: data.Pincode,
                                isDisplayGroupPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // On Country Select
    onCountrySelect(e, status) {
        const { countryList } = this.state
        var countryId = e.target.value
        if (status == 1) {
            this.setState({ countryIdModal: countryId, countryIdErrModal: "" })
            var countryCode = countryList.filter(obj => obj.Code == countryId)[0].CountryCode
            this.setState({
                preMobNoModal: `+${countryCode}`,
                preWhatsAppMobNoModal: `+${countryCode}`
            })
            this.getFillList(pincodeActionId, '', '', countryId, '', '')
            this.getFillList(stateActionId, '', '', countryId, '', '')
            this.getFillList(cityActionId, '', '', countryId, '', '')
            setTimeout(() => {
                this.setState({
                    isDisplayPincodeList: false,
                    isDisplayCityList: false,
                    isDisplayStateList: false,
                    isDisplayGroupPincodeList: false,
                    isDisplayGroupCityList: false,
                    isDisplayGroupStateList: false,
                })
            }, 200);
        } else if (status == 2) {
            this.setState({ passCountry: countryId, passCountryErr: "" })
            // var countryCode = countryList.filter(obj => obj.Code == countryId)[0].CountryCode
            // this.setState({
            //     preMobNoModal: `+${countryCode}`,
            //     preWhatsAppMobNoModal: `+${countryCode}`
            // })
        } else if (status == 3) {
            this.setState({ delegationCountry: countryId, delegationCountryErr: "" })
            // var countryCode = countryList.filter(obj => obj.Code == countryId)[0].CountryCode
            // this.setState({
            //     preMobNoModal: `+${countryCode}`,
            //     preWhatsAppMobNoModal: `+${countryCode}`
            // })
        } else {
            this.setState({ countryId: countryId, countryIdErr: "" })
            var countryCode = countryList.filter(obj => obj.Code == countryId)[0].CountryCode
            this.setState({
                preMobNo: `+${countryCode}`,
                preWhatsAppMobNo: `+${countryCode}`
            })
            // Calling City , State , Pincode filllist on country Select
            this.getFillList(pincodeActionId, '', '', countryId, '', '')
            this.getFillList(stateActionId, '', '', countryId, '', '')
            this.getFillList(cityActionId, '', '', countryId, '', '')
        }





    }

    // search city
    onCitySearch(e, status) {
        const { cityList, tempCityList, tempGroupCityList } = this.state
        if (status == 1) {
            if (e.target.value != '') {
                var searchCityList = tempGroupCityList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    cityModal: e.target.value,
                    cityErrModal: "",
                    tempGroupCityList: searchCityList,
                    isDisplayGroupCityList: true
                })
            } else {
                this.setState({
                    cityModal: e.target.value,
                    tempGroupCityList: cityList,
                    isDisplayGroupCityList: false
                })
            }
        } else {
            if (e.target.value != '') {
                var searchCityList = tempCityList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    city: e.target.value,
                    cityErr: "",
                    tempCityList: searchCityList,
                    isDisplayCityList: true
                })
            } else {
                this.setState({
                    city: e.target.value,
                    tempCityList: cityList,
                    isDisplayCityList: false
                })
            }
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    renderGroupCityList() {
        const { tempCityList, tempGroupCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempGroupCityList.length > 0 && tempGroupCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ cityModal: data.Description, isDisplayGroupCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onStateSearch(e, status) {
        const { tempStateList, stateList, tempGroupStateList } = this.state
        if (status == 1) {
            if (e.target.value != '') {
                var searchStateList = tempGroupStateList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    regStateModal: e.target.value,
                    regStateErrModal: "",
                    tempGroupStateList: searchStateList,
                    isDisplayGroupStateList: true
                })
            } else {
                this.setState({
                    regStateModal: e.target.value,
                    tempGroupStateList: stateList,
                    isDisplayGroupStateList: false
                })
            }
        } else {
            if (e.target.value != '') {
                var searchStateList = tempStateList.filter(data => {
                    return (
                        data
                            .Description
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                    )
                })
                this.setState({
                    regState: e.target.value,
                    regStateErr: "",
                    tempStateList: searchStateList,
                    isDisplayStateList: true
                })
            } else {
                this.setState({
                    regState: e.target.value,
                    tempStateList: stateList,
                    isDisplayStateList: false
                })
            }
        }
    }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regState: data.Description, isDisplayStateList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    renderGroupStateList() {
        const { tempStateList, tempGroupStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempGroupStateList.length > 0 && tempGroupStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regStateModal: data.Description, isDisplayGroupStateList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // On Same As Mobile No Click
    onSameAsMobNoClick(e, status) {
        const { isSameAsMobNo, mobNo, preMobNo, isSameAsMobNoModal, mobNoModal, preMobNoModal } = this.state
        if (status == 1) {
            this.setState({ isSameAsMobNoModal: !isSameAsMobNoModal })
            var checked = e.target.checked
            if (checked) {
                this.setState({
                    whatsAppMobNoModal: mobNoModal,
                    preWhatsAppMobNoModal: preMobNoModal,
                    whatsAppMobNoErrModal: ""
                })
            } else {
                this.setState({
                    whatsAppMobNoErrModal: "",
                })

            }
        } else {
            this.setState({ isSameAsMobNo: !isSameAsMobNo })
            var checked = e.target.checked
            if (checked) {
                this.setState({
                    whatsAppMobNo: mobNo,
                    preWhatsAppMobNo: preMobNo,
                    whatsAppMobNoErr: ""
                })
            } else {
                this.setState({
                    whatsAppMobNo: "",

                })

            }
        }
    }

    // Get Registration Details
    getRegistrationDetails() {

        setTimeout(() => {
            const { attendeeList, daysList, primaryChapterListData, level0, level2, level3, level4 } = this.state
            this.setState({ isLoading: true })

            this.props.getVistiorRegistarionDetails_action()
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get Registration Details", data.data['SelectedVisitDays'])
                        // Updating Attendee List
                        var attendeeId = data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : ''
                        attendeeList.length > 0 && attendeeList.map((item) => {
                            if (item.AttendeeTypeID == attendeeId) {
                                item.isChecked = true
                            }
                        })


                        // Split Phone Numbers and country Code 
                        //  Mobile No
                        var mob1 = data.data['registrationDetails'][0]['MobileNo']
                        const parts = mob1 != '' ? mob1.split("-") : '';
                        const Mob1Code = parts.length > 1 && parts != '' ? parts[0] : ''
                        const Mob1No = parts != '' ? parts.length > 1 ? parts[1] : parts[0] : ''
                        // WhatsApp Mobile No
                        var mob2 = data.data['registrationDetails'][0]['WhatsappNo']
                        const parts2 = mob2 != '' ? mob2.split("-") : ''
                        const Mob2Code = parts2.length > 1 && parts2 != '' ? parts2[0] : ''
                        const Mob2No = parts2 != '' ? parts2.length > 1 ? parts2[1] : parts2[0] : ''
                        // TelePhone No
                        var tel = data.data['registrationDetails'][0]['Telephone']
                        const parts3 = tel != '' ? tel.split("-") : ''
                        const telCode = parts3.length > 1 && parts3 != '' ? parts3[0] : ''
                        const telNumber = parts3 != '' ? parts3.length > 1 ? parts3[1] : parts3[0] : ''
                        // Telephone 1 Mobile No
                        var tel1 = data.data['registrationDetails'][0]['Telephone1']
                        const parts4 = tel1 != '' ? tel1.split("-") : ''
                        const tel1Code = parts4.length > 1 && parts4 != '' ? parts4[0] : ''
                        const tel1Number = parts4 != '' ? parts4.length > 1 ? parts4[1] : parts4[0] : ''

                        // var applicantMob = item['ApplicantMobileNo']
                        // const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
                        // const passMobNoCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
                        // const passMobNoNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''

                        // var mob1 = data.data['registrationDetails'][0]['MobileNo']
                        // const parts = mob1.split("-");
                        // const Mob1Code = parts[0]
                        // const Mob1No = parts[1]
                        // // WhatsApp Mobile No
                        // var mob2 = data.data['registrationDetails'][0]['WhatsappNo']
                        // const parts2 = mob2.split("-")
                        // const Mob2Code = parts2[0]
                        // const Mob2No = parts2[1]
                        // // TelePhone No
                        // var tel = data.data['registrationDetails'][0]['Telephone']
                        // const parts3 = tel.split("-")
                        // const telCode = parts3[0]
                        // const telNumber = parts3[1]
                        // // Telephone 1 Mobile No
                        // var tel1 = data.data['registrationDetails'][0]['Telephone1']
                        // const parts4 = tel1.split("-")
                        // const tel1Code = parts4[0]
                        // const tel1Number = parts4[1]

                        // Days List Prepare on basis on get Days list

                        data.data['registrationDetails'].length > 1 ? sessionStorage.setItem('VisitorTypeID', 2) : sessionStorage.setItem('VisitorTypeID', 1)
                        data.data['registrationDetails'].length > 1 && this.setState({ isFirst: false })
                        // data.data['registrationDetails'].length > 1 ?
                        data.data['registrationDetails'].length > 1 &&
                            data.data['registrationDetails'].map((item, i) => {
                                if (item.isCoordinator == true) {//

                                    var selectedDaysList = data.data['SelectedVisitDays'] != null ? data.data['SelectedVisitDays'] : []
                                    daysList.length > 0 && daysList.map((item2) => {
                                        selectedDaysList.length > 0 && selectedDaysList.map((element) => {
                                            if (element.ExhVisitorID == item.ExhVisitorID) {
                                                if (element.VisitDate == item2.VisitDate) {
                                                    item2.isSelect = true
                                                }
                                            }
                                        })
                                    })

                                    // var selectedDays = daysList.filter(data => data.isSelect == true)
                                    var listssss = []
                                    selectedDaysList.map((elementt) => {
                                        var data = {
                                            "visitDay": elementt.VisitDate
                                        }
                                        listssss.push(data)
                                    })
                                    // console.log("hgdddhdjdjdjdkdj", daysList, this.state.daysModalList)
                                    this.setState({ DateArrCoordinator: listssss, daysErr: "" })

                                    var chapterListData = []
                                    var selectedChapter = data.data['chapterList'] != null ? data.data['chapterList'] : []
                                    selectedChapter.map((item2) => {
                                        if (item2.ExhVisitorID == item.ExhVisitorID) {
                                            var mapdata = {
                                                "ChapterID": item2.ChapterID,
                                                "ChapterNames": item2['ChapterName'],
                                                "OtherNames": item2.ChapterID == 16 ? item2.other != undefined ? item2.other : '' : ''
                                            }
                                            chapterListData.push(mapdata)
                                        }
                                    })


                                    // Preparing Udt For Hall Chapter List
                                    // var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
                                    // var filerArray = allArray.filter((mapdata) => mapdata.IsCheckbox && mapdata.IsSelect)
                                    var chapterListData2 = []
                                    var selectedhallChapter = data.data['hallChapterList'] != null ? data.data['hallChapterList'] : []
                                    selectedhallChapter.map((item2) => {
                                        if (item2.MappingExhVisitorID == item.ExhVisitorID) {
                                            var mapdata = {
                                                "ChapterID": item2.MappingChapterID,
                                                "ChapterNames": item2.MappingChapterName,
                                                // "OtherNames": ''
                                            }
                                            chapterListData2.push(mapdata)
                                        }
                                    })
                                    // console.log('item', item.FirstName)
                                    var mob1 = item['MobileNo']
                                    const parts = mob1 != '' ? mob1.split("-") : '';
                                    const Mob1Code = parts.length > 1 && parts != '' ? parts[0] : ''
                                    const Mob1No = parts != '' ? parts.length > 1 ? parts[1] : parts[0] : ''
                                    // WhatsApp Mobile No
                                    var mob2 = item['WhatsappNo']
                                    const parts2 = mob2 != '' ? mob2.split("-") : ''
                                    const Mob2Code = parts2.length > 1 && parts2 != '' ? parts2[0] : ''
                                    const Mob2No = parts2 != '' ? parts2.length > 1 ? parts2[1] : parts2[0] : ''
                                    // TelePhone No
                                    var tel = item['Telephone']
                                    const parts3 = tel != '' ? tel.split("-") : ''
                                    const telCode = parts3.length > 1 && parts3 != '' ? parts3[0] : ''
                                    const telNumber = parts3 != '' ? parts3.length > 1 ? parts3[1] : parts3[0] : ''
                                    // Telephone 1 Mobile No
                                    var tel1 = item['Telephone1']
                                    const parts4 = tel1 != '' ? tel1.split("-") : ''
                                    const tel1Code = parts4.length > 1 && parts4 != '' ? parts4[0] : ''
                                    const tel1Number = parts4 != '' ? parts4.length > 1 ? parts4[1] : parts4[0] : ''

                                    var applicantMob = item['ApplicantMobileNo']
                                    const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
                                    const passMobNoCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
                                    const passMobNoNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''

                                    // var applicantMob = item['ApplicantMobileNo']
                                    // const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
                                    // const passMobNoCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
                                    // const passMobNoNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''

                                    // var mob1 = item['MobileNo']
                                    // const parts = mob1.split("-");
                                    // const Mob1Code = parts[0]
                                    // const Mob1No = parts[1]
                                    // // WhatsApp Mobile No
                                    // var mob2 = item['WhatsappNo']
                                    // const parts2 = mob2.split("-")
                                    // const Mob2Code = parts2[0]
                                    // const Mob2No = parts2[1]
                                    // // TelePhone No
                                    // var tel = item['Telephone']
                                    // const parts3 = tel.split("-")
                                    // const telCode = parts3[0]
                                    // const telNumber = parts3[1]
                                    // // Telephone 1 Mobile No
                                    // var tel1 = item['Telephone1']
                                    // const parts4 = tel1.split("-")
                                    // const tel1Code = parts4[0]
                                    // const tel1Number = parts4[1]

                                    var needVisaAssistance = item.isVisaAssistance
                                    var IsGroupDelegation = item.IsGroupDelegation
                                    var FileName = ''
                                    if (needVisaAssistance) {
                                        var filePath = item['PassportFilePath'] != null ? item['PassportFilePath'] : ''
                                        var array = item['PassportFilePath'] != null && item['PassportFilePath'] != "" ? filePath.split("/") : []
                                        FileName = array.length > 0 ? array[array.length - 1] : ''
                                    }
                                    this.setState({
                                        preName: item.Title != null ? item.Title : '',
                                        firstName: item.FirstName != null ? item.FirstName : '',
                                        middleName: item['MiddleName'] != null ? item['MiddleName'] : '',
                                        lastName: item['LastName'] != null ? item['LastName'] : '',
                                        designation: item['Designation'] != null ? item['Designation'] : '',
                                        institute: item['Institute_Organisation'] != null ? item['Institute_Organisation'] : '',
                                        preMobNo: item['MobileNo'] ? Mob1Code : "",
                                        mobNo: item['MobileNo'] ? Mob1No : "",
                                        preWhatsAppMobNo: item['WhatsappNo'] ? Mob2Code : "",
                                        whatsAppMobNo: item['WhatsappNo'] ? Mob2No : "",
                                        isSameAsMobNo: item['IsSameAsMobileNo'] != null ? item['IsSameAsMobileNo'] : '',
                                        regState: item['State'] != null ? item['State'] : '',
                                        city: item['City'] != null ? item['City'] : '',
                                        pincode: item['Pincode'] != null ? item['Pincode'] : '',
                                        website: item['Website'] != null ? item['Website'] : '',
                                        email: item['Email'] != null ? item['Email'] : '',
                                        preTelNo: item['Telephone'] != null ? telCode : '',
                                        telNo: item['Telephone'] != null ? telNumber : '',
                                        preTel1No: item['Telephone1'] != null ? tel1Code : '',
                                        tel1No: item['Telephone1'] != null ? tel1Number : '',
                                        AccountID: item['AccountID'] != null && item['AccountID'],
                                        ExhVisitorID: item['ExhVisitorID'] != null && item['ExhVisitorID'],
                                        isCoordinatorVisit: item['isVisit'] != null && item['isVisit'],
                                        isFirst: false,

                                        countryId: item.CountryID,
                                        AccountID: item.AccountID,
                                        ExhVisitorID: item.ExhVisitorID,
                                        daysList: daysList,
                                        ApplicantEmailIDCoordinator: needVisaAssistance ? item.ApplicantEmailID : '',
                                        ApplicantMobileNoCoordinator: needVisaAssistance ? passMobNoNumber ? `${passMobNoCode}-${passMobNoNumber}` : '' : '',
                                        B2BDepartureDateCoordinator: null,
                                        B2BPreferredTimeCoordinator: null,
                                        B2BRemarkCoordinator: '',
                                        CarparkingCoordinator: false,
                                        DelegationArrivalDateCoordinator: IsGroupDelegation ? new Date(item.DelegationArrivalDate) : null,
                                        DelegationCountryIDCoordinator: IsGroupDelegation ? item.DelegationCountryID : null,
                                        DelegationDepartureDateCoordinator: IsGroupDelegation ? new Date(item.DelegationDepartureDate) : null,
                                        DelegationNameCoordinator: IsGroupDelegation ? item.DelegationName : '',
                                        DelegationRemarkCoordinator: IsGroupDelegation ? item.DelegationRemark : '',
                                        IsGroupDelegationCoordinator: IsGroupDelegation,
                                        // ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
                                        NameOnPassportCoordinator: needVisaAssistance ? item.NameOnPassport : '',
                                        PassportExpiryDateCoordinator: needVisaAssistance ? new Date(item.PassportExpiryDate) : null,
                                        PassportFileCoordinator: needVisaAssistance ? FileName : null,
                                        PassportFileNameCoordinator: needVisaAssistance ? FileName : '',
                                        PassportFilePathCoordinator: needVisaAssistance ? filePath : '',
                                        PassportFileTypeCoordinator: null,
                                        PassportIssueDateCoordinator: needVisaAssistance ? new Date(item.PassportIssueDate) : '',
                                        PassportIssuingCountryIDCoordinator: needVisaAssistance ? item.PassportIssuingCountryID : '',
                                        PassportNoCoordinator: needVisaAssistance ? item.PassportNo : '',
                                        // UserID': null,
                                        areaOfInterestListCoordinator: [],
                                        ParkingChargesCoordinator: 0,
                                        hallChapterListCoordinator: chapterListData2,//
                                        interestSeminarListCoordinator: [],
                                        isVisaAssistanceCoordinator: needVisaAssistance,
                                        purposeOfVisitListCoordinator: [],
                                        visitorChapterListCoordinator: chapterListData,//
                                        visitorRegNoCoordinator: item.VisitorRegistrationNo,
                                        isVisaAppliedCoordinator: needVisaAssistance,
                                        isPassportFilePresentCoordinator: needVisaAssistance ? true : false
                                    })
                                } else {
                                    var visitDaysArr = []
                                    var selectedDaysList = data.data['SelectedVisitDays'] != null ? data.data['SelectedVisitDays'] : []
                                    // daysList.length > 0 && daysList.map((item2) => {
                                    selectedDaysList.length > 0 && selectedDaysList.map((element) => {
                                        if (element.ExhVisitorID == item.ExhVisitorID) {
                                            // if (element.VisitDate == item2.description) {
                                            var mapdata = {
                                                "visitDay": element.VisitDate
                                            }
                                            visitDaysArr.push(mapdata)
                                            // }
                                        }
                                    })
                                    // })

                                    var chapterListData = []
                                    var selectedChapter = data.data['chapterList'] != null ? data.data['chapterList'] : []
                                    selectedChapter.map((item2) => {
                                        if (item2.ExhVisitorID == item.ExhVisitorID) {
                                            var mapdata = {
                                                "ChapterID": item2.ChapterID,
                                                "ChapterNames": item2['ChapterName'],
                                                "OtherNames": item2.ChapterID == 16 ? item2.other != undefined ? item2.other : '' : ''
                                            }
                                            chapterListData.push(mapdata)
                                        }
                                    })


                                    // Preparing Udt For Hall Chapter List
                                    // var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
                                    // var filerArray = allArray.filter((mapdata) => mapdata.IsCheckbox && mapdata.IsSelect)
                                    var chapterListData2 = []
                                    var selectedhallChapter = data.data['hallChapterList'] != null ? data.data['hallChapterList'] : []
                                    selectedhallChapter.map((item2) => {
                                        if (item2.MappingExhVisitorID == item.ExhVisitorID) {
                                            var mapdata = {
                                                "ChapterID": item2.MappingChapterID,
                                                "ChapterNames": item2.MappingChapterName,
                                                // "OtherNames": ''
                                            }
                                            chapterListData2.push(mapdata)
                                        }
                                    })

                                    var mob1 = item['MobileNo']
                                    const parts = mob1 != '' ? mob1.split("-") : '';
                                    const Mob1Code = parts.length > 1 && parts != '' ? parts[0] : ''
                                    const Mob1No = parts != '' ? parts.length > 1 ? parts[1] : parts[0] : ''
                                    // WhatsApp Mobile No
                                    var mob2 = item['WhatsappNo']
                                    const parts2 = mob2 != '' ? mob2.split("-") : ''
                                    const Mob2Code = parts2.length > 1 && parts2 != '' ? parts2[0] : ''
                                    const Mob2No = parts2 != '' ? parts2.length > 1 ? parts2[1] : parts2[0] : ''
                                    // TelePhone No
                                    var tel = item['Telephone']
                                    const parts3 = tel != '' ? tel.split("-") : ''
                                    const telCode = parts3.length > 1 && parts3 != '' ? parts3[0] : ''
                                    const telNumber = parts3 != '' ? parts3.length > 1 ? parts3[1] : parts3[0] : ''
                                    // Telephone 1 Mobile No
                                    var tel1 = item['Telephone1']
                                    const parts4 = tel1 != '' ? tel1.split("-") : ''
                                    const tel1Code = parts4.length > 1 && parts4 != '' ? parts4[0] : ''
                                    const tel1Number = parts4 != '' ? parts4.length > 1 ? parts4[1] : parts4[0] : ''

                                    var applicantMob = item['ApplicantMobileNo']
                                    const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
                                    const passMobNoCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
                                    const passMobNoNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''

                                    // var mob1 = item['MobileNo']
                                    // const parts = mob1.split("-");
                                    // const Mob1Code = parts[0]
                                    // const Mob1No = parts[1]
                                    // // WhatsApp Mobile No
                                    // var mob2 = item['WhatsappNo']
                                    // const parts2 = mob2.split("-")
                                    // const Mob2Code = parts2[0]
                                    // const Mob2No = parts2[1]
                                    // // TelePhone No
                                    // var tel = item['Telephone']
                                    // const parts3 = tel.split("-")
                                    // const telCode = parts3[0]
                                    // const telNumber = parts3[1]
                                    // // Telephone 1 Mobile No
                                    // var tel1 = item['Telephone1']
                                    // const parts4 = tel1.split("-")
                                    // const tel1Code = parts4[0]
                                    // const tel1Number = parts4[1]
                                    // var passMobNo = item['ApplicantMobileNo']
                                    // const parts5 = passMobNo.split("-")
                                    // const passMobNoCode = parts5[0]
                                    // const passMobNoNumber = parts5[1]
                                    var needVisaAssistance = item.isVisaAssistance
                                    var IsGroupDelegation = item.IsGroupDelegation
                                    var FileName = ''
                                    if (needVisaAssistance) {
                                        var filePath = item['PassportFilePath'] != null ? item['PassportFilePath'] : ''
                                        var array = item['PassportFilePath'] != null && item['PassportFilePath'] != "" ? filePath.split("/") : []
                                        FileName = array.length > 0 ? array[array.length - 1] : ''
                                    }

                                    var obj = {
                                        'Title': item.Title ? item.Title : '',
                                        'firstName': item.FirstName ? item.FirstName : '',
                                        'middleName': item.MiddleName ? item.MiddleName : '',
                                        'lastName': item.LastName ? item.LastName : '',
                                        'institute': item.Institute_Organisation ? item.Institute_Organisation : '',
                                        'isOtherPreName': '',
                                        'otherName': '',
                                        'designation': item.Designation ? item.Designation : '',
                                        'mobNo': Mob1No ? `${Mob1Code}-${Mob1No}` : '',
                                        'whatsAppMobNo': Mob2No ? `${Mob2Code}-${Mob2No}` : '',
                                        'pincode': item.Pincode ? item.Pincode : '',
                                        'regState': item.State ? item.State : '',
                                        'isSameAsMobNo': item.IsSameAsMobileNo ? item.IsSameAsMobileNo : '',
                                        'city': item.City ? item.City : '',
                                        'website': item.Website ? item.Website : '',
                                        'AccountID': item.AccountID ? item.AccountID : '',
                                        'ExhVisitorID': item.ExhVisitorID ? item.ExhVisitorID : '',
                                        'email': item.Email ? item.Email : '',
                                        'telNo': telNumber ? `${telCode}-${telNumber}` : '',
                                        'tel1No': tel1Number ? `${tel1Code}-${tel1Number}` : '',
                                        'id': i + 1,
                                        'isVisit': true,
                                        'isCoordinator': false,
                                        'countryId': item.CountryID,
                                        'AccountID': item.AccountID,
                                        'ExhVisitorID': item.ExhVisitorID,
                                        'DateArr': visitDaysArr,////////////////////////
                                        'ApplicantEmailID': needVisaAssistance ? item.ApplicantEmailID : '',
                                        'ApplicantMobileNo': needVisaAssistance ? passMobNoNumber ? `${passMobNoCode}-${passMobNoNumber}` : '' : '',
                                        'B2BDepartureDate': null,
                                        'B2BPreferredTime': null,
                                        'B2BRemark': '',
                                        'Carparking': false,
                                        'DelegationArrivalDate': IsGroupDelegation ? new Date(item.DelegationArrivalDate) : null,
                                        'DelegationCountryID': IsGroupDelegation ? item.DelegationCountryID : null,
                                        'DelegationDepartureDate': IsGroupDelegation ? new Date(item.DelegationDepartureDate) : null,
                                        'DelegationName': IsGroupDelegation ? item.DelegationName : '',
                                        'DelegationRemark': IsGroupDelegation ? item.DelegationRemark : '',
                                        'IsGroupDelegation': IsGroupDelegation,
                                        'ExhibitionID': parseInt(sessionStorage.getItem('sessionExhibitionID')),
                                        'NameOnPassport': needVisaAssistance ? item.NameOnPassport : '',
                                        'PassportExpiryDate': needVisaAssistance ? new Date(item.PassportExpiryDate) : null,
                                        'PassportFile': needVisaAssistance ? FileName : null,
                                        'PassportFileName': needVisaAssistance ? FileName : '',
                                        'PassportFilePath': needVisaAssistance ? filePath : '',
                                        'PassportFileType': null,
                                        'PassportIssueDate': needVisaAssistance ? new Date(item.PassportIssueDate) : '',
                                        'PassportIssuingCountryID': needVisaAssistance ? item.PassportIssuingCountryID : '',
                                        'PassportNo': needVisaAssistance ? item.PassportNo : '',
                                        'UserID': null,
                                        'areaOfInterestList': [],
                                        'ParkingCharges': 0,
                                        'hallChapterList': chapterListData2,//
                                        'interestSeminarList': [],
                                        'isVisaAssistance': needVisaAssistance,
                                        'purposeOfVisitList': [],
                                        'visitorChapterList': chapterListData,//
                                        'visitorRegNo': item.VisitorRegistrationNo,
                                        'isVisaApplied': needVisaAssistance,
                                        'isPassportFilePresent': needVisaAssistance ? true : false
                                    }
                                    this.state.GroupDetailsList.push(obj)
                                }
                            })

                        if (data.data['registrationDetails'].length == 1) {
                            var selectedDaysList = data.data['SelectedVisitDays'] != null ? data.data['SelectedVisitDays'] : []
                            daysList.length > 0 && daysList.map((item2) => {
                                selectedDaysList.length > 0 && selectedDaysList.map((element) => {
                                    // if (element.ExhVisitorID == item2.ExhVisitorID) {/\
                                    if (element.VisitDate == item2.VisitDate) {
                                        item2.isSelect = true
                                        // }
                                    }
                                })
                            })
                            console.log(daysList, attendeeList)
                            this.setState({
                                isLoading: false,
                                countryId: data.data['registrationDetails'][0]['CountryID'] != null ? data.data['registrationDetails'][0]['CountryID'] : '',
                                VisitorTypeID: data.data['registrationDetails'].length == 1 ? 1 : 2,
                                daysList: daysList

                            })
                        }
                        this.setState({
                            attendeeTypeId: data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : '',
                            attendeeList: attendeeList,
                            VisitorTypeID: data.data['registrationDetails'].length == 1 ? 1 : 2,

                        })
                        console.log(data.data['registrationDetails'].length == 1, data.data['registrationDetails'][0]['FirstName'])
                        data.data['registrationDetails'].length == 1 && this.setState({
                            preName: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Title'] != null && data.data['registrationDetails'][0]['Title'],
                            firstName: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['FirstName'] != null && data.data['registrationDetails'][0]['FirstName'],
                            middleName: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['MiddleName'] != null && data.data['registrationDetails'][0]['MiddleName'],
                            lastName: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['LastName'] != null && data.data['registrationDetails'][0]['LastName'],
                            designation: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Designation'] != null && data.data['registrationDetails'][0]['Designation'],
                            institute: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Institute_Organisation'] != null && data.data['registrationDetails'][0]['Institute_Organisation'],
                            preMobNo: data.data['registrationDetails'][0]['MobileNo'] && Mob1Code,
                            mobNo: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['MobileNo'] && Mob1No,
                            preWhatsAppMobNo: data.data['registrationDetails'][0]['WhatsappNo'] && Mob2Code,
                            whatsAppMobNo: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['WhatsappNo'] && Mob2No,
                            isSameAsMobNo: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['IsSameAsMobileNo'] != null && data.data['registrationDetails'][0]['IsSameAsMobileNo'],
                            regState: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['State'] != null && data.data['registrationDetails'][0]['State'],
                            city: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['City'] != null && data.data['registrationDetails'][0]['City'],
                            pincode: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Pincode'] != null && data.data['registrationDetails'][0]['Pincode'],
                            website: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Website'] != null && data.data['registrationDetails'][0]['Website'],
                            email: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Email'] != null && data.data['registrationDetails'][0]['Email'],
                            preTelNo: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Telephone'] != null && telCode,
                            telNo: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Telephone'] != null && telNumber,
                            preTel1No: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Telephone1'] != null && tel1Code,
                            tel1No: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['Telephone1'] != null && tel1Number,
                            AccountID: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['AccountID'] != null && data.data['registrationDetails'][0]['AccountID'],
                            ExhVisitorID: data.data['registrationDetails'].length == 1 && data.data['registrationDetails'][0]['ExhVisitorID'] != null && data.data['registrationDetails'][0]['ExhVisitorID'],
                        })
                        this.setState({ isLoading: false })
                    } else {
                        toastError(data.msg)
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            setTimeout(() => {
                console.log(this.state.GroupDetailsList)
            }, 500);
        }, 400);
    }
    // // On File Pick
    // onFilePick = async (e, file, key) => {
    //     e.preventDefault()
    //     console.log("File01010101------", file, key)
    //     const { } = this.state
    //     if (file[0]['size'] >= 5 * 1024 * 1024) {
    //         toastError('File size more than 5mb are not allow.')
    //     } else {
    //         var image = '';
    //         if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
    //             file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
    //             file[0]['name'].toString().toLowerCase().includes('.png') ||
    //             file[0]['name'].toString().toLowerCase().includes('.jpg')
    //         ) {

    //             if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
    //                 file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
    //                 file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
    //             ) {
    //                 image = await this.resizeFile(file[0]);
    //                 if (key == 1) {
    //                     this.setState({ photoFile: image })
    //                 } else if (key == 2) {
    //                     this.setState({ photoIdFile: image })
    //                 } else if (key == 3) {
    //                     this.setState({ vaccineCertificateFile: image })
    //                 }
    //             }
    //             else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
    //                 image = await this.onPdfSelect(file[0]);
    //                 if (key == 1) {
    //                     this.setState({ photoFile: image })
    //                 } else if (key == 2) {
    //                     this.setState({ photoIdFile: image })
    //                 } else if (key == 3) {
    //                     this.setState({ vaccineCertificateFile: image })
    //                 }
    //             }

    //             var fileType = file[0]['type']
    //             var fileTypeList;
    //             if (fileType.includes("/")) {
    //                 fileTypeList = fileType.split("/")
    //             }
    //             if (key == 1) {
    //                 this.setState({ photoFileType: image })
    //             } else if (key == 2) {
    //                 this.setState({ photoIdFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
    //             } else if (key == 3) {
    //                 this.setState({ vaccineCertificateFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
    //             }
    //         } else {
    //             toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
    //         }
    //     }
    // }

    // // If Pdf is selected in file
    // onPdfSelect = (file) =>
    //     new Promise((resolve) => {
    //         let base64;
    //         // FileReader function for read the file.
    //         let fileReader = new FileReader();
    //         // Onload of file read the file content
    //         fileReader.onload = function (fileLoadedEvent) {
    //             base64 = fileLoadedEvent.target.result;
    //             // Print data in console
    //             resolve(base64);
    //         };
    //         // Convert data to base64
    //         fileReader.readAsDataURL(file);
    //     });

    // // File resize
    // resizeFile = (file) =>
    //     new Promise((resolve) => {
    //         Resizer.imageFileResizer(
    //             file,
    //             500,
    //             600,
    //             'JPEG',
    //             80,
    //             0,
    //             (uri) => {
    //                 resolve(uri);
    //             },
    //             "base64",
    //             300,
    //             300,
    //         );
    //     });

    // // on Cross click
    // onCrossClick(key) {

    //     if (key == 1) {
    //         this.setState({ photoFile: "" })
    //         document.getElementById("photoFile").value = ""
    //     } else if (key == 2) {
    //         this.setState({ photoIdFile: "" })
    //         document.getElementById("photoIdFile").value = ""
    //     } else if (key == 3) {
    //         this.setState({ vaccineCertificateFile: "" })
    //         document.getElementById("vaccFile").value = ""
    //     }
    // }

    renderPassCountryList(id) {
        const { tempPassCountryList, } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPassCountryList.length > 0 && tempPassCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => sessionStorage.getItem('VisitorTypeID') == 2 ? this.onVisitorCountryselect(id, data.Description, data.Code) : this.setState({
                                passCountry: data.Description, passCountryId: data.Code,
                                isDisplayPassCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On PassPort Country Search
    onPassportCountrySearch(e, id) {
        const { passCountryList, tempPassCountryList, visitorGroupList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempPassCountryList ++++----------->", tempPassCountryList)

            var searchCoutryList = tempPassCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)
            // sessionStorage.getItem('VisitorTypeID') == 1 && 
            this.setState({
                passCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                passCountryErr: '',
                tempPassCountryList: searchCoutryList,
                isDisplayPassCountryList: true
            })
            // if (sessionStorage.getItem('VisitorTypeID') == 2) {
            //     console.log("searchCoutryList ++++----------->", e.target.value)
            //     visitorGroupList[id].isDisplayPassCountryList = true
            //     visitorGroupList[id].passCountryErr = ''
            //     visitorGroupList[id].passCountry = e.target.value
            //     this.setState({ visitorGroupList: visitorGroupList, tempPassCountryList: searchCoutryList, })
            // }
        }
        else {
            // sessionStorage.getItem('VisitorTypeID') == 1 && 
            this.setState({
                passCountry: e.target.value,
                tempPassCountryList: passCountryList,
                isDisplayPassCountryList: false
            })
            // if (sessionStorage.getItem('VisitorTypeID') == 2) {
            //     visitorGroupList[id].isDisplayPassCountryList = false
            //     visitorGroupList[id].passCountry = e.target.value
            //     this.setState({ visitorGroupList: visitorGroupList, tempPassCountryList: passCountryList, })
            // }
        }
    }

    // On File Pick
    onFilePick = async (e, file, key, i) => {
        e.preventDefault()
        const { visitorGroupList } = this.state
        console.log("File01010101------", file, key)
        const { } = this.state
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {

                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        if (key == 1) {
                            // if (sessionStorage.getItem('VisitorTypeID') == 2) {
                            //     visitorGroupList[i].passportFile = image
                            //     visitorGroupList[i].passportFileErr = ''
                            //     this.setState({ visitorGroupList: visitorGroupList })
                            // } else {
                            this.setState({ passportFile: image, passportFileErr: "", passPortFileName: file[0]['name'].toString() })
                            // }
                        } else if (key == 2) {
                            this.setState({ visitorLetterFile: image })
                        }
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelect(file[0]);
                        if (key == 1) {
                            // if (sessionStorage.getItem('VisitorTypeID') == 2) {
                            //     visitorGroupList[i].passportFile = image
                            //     this.setState({ visitorGroupList: visitorGroupList })
                            // } else {
                            this.setState({ passportFile: image, passportFileErr: "" })
                            // }
                            // this.setState({ passportFile: image, passportFileErr: "" })
                        } else if (key == 2) {
                            this.setState({ visitorLetterFile: image })
                        }
                    }

                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")
                    }
                    if (key == 1) {
                        // if (sessionStorage.getItem('VisitorTypeID') == 2) {
                        //     visitorGroupList[i].passportFileType = fileTypeList.length > 0 ? fileTypeList[1] : ""
                        //     this.setState({ visitorGroupList: visitorGroupList })
                        // } else {
                        this.setState({ passportFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                        // }
                    } else if (key == 2) {
                        this.setState({ visitorLetterFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                    }
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                    // if (key == 1) {
                    //     this.setState({ passportFile: "" })
                    //     document.getElementById("passportFile").value = ""
                    // } else if (key == 2) {
                    //     this.setState({ visitorLetterFile: "" })
                    //     document.getElementById("visitorLetter").value = ""
                    // }
                }
            }
        }
    }

    // If Pdf is selected in file
    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    // File resize
    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    // on Cross click
    onCrossClick(key, mode) {
        const { passportFileType, isPassportFilePresent, passportFile, visitorGroupList } = this.state
        // i = i - 1
        // console.log(isPassportFilePresent, i + 1)
        // if (sessionStorage.getItem('VisitorTypeID') == 1) {
        if (isPassportFilePresent) {
            const { isOpen3 } = this.state
            this.setState({ isOpen3: !isOpen3 })
            console.log("condition------------1111111111")
        } else {
            console.log("condition------------2222222222")
            if (key == 1) {
                if (mode == 1) {
                    this.setState({ passportFile: "", isVisaApplied: false })
                } else {
                    this.setState({ passportFile: "", isVisaApplied: false })
                    document.getElementById("passportFile").value = ""
                }
            } else if (key == 2) {
                this.setState({ visitorLetterFile: "" })
                document.getElementById("visitorLetter").value = ""
            }
        }
        // }
        // if (sessionStorage.getItem('VisitorTypeID') == 2) {
        //     if (isPassportFilePresent) {
        //         const { isOpen3 } = this.state
        //         this.setState({ isOpen3: !isOpen3, docDelAccountID: visitorGroupList[i].AccountID, docDelExhVisitorID: visitorGroupList[i].ExhVisitorID })
        //         console.log("condition------------1111111111")
        //     } else {
        //         console.log("condition------------2222222222")
        //         if (key == 1) {
        //             if (sessionStorage.getItem('VisitorTypeID') == 2) {
        //                 console.log(i + 1)
        //                 visitorGroupList[i].passportFile = ''
        //                 this.setState({ visitorGroupList: visitorGroupList })
        //                 document.getElementById(`path${i}`).value = ""
        //             } else {
        //                 this.setState({ passportFile: "" })
        //                 document.getElementById("passportFile").value = ""
        //             }
        //         } else if (key == 2) {
        //             this.setState({ visitorLetterFile: "" })
        //             document.getElementById("visitorLetter").value = ""
        //         }
        //     }
        // }
    }

    //On Country Search In Personal Information
    onPersonalCountrySearch(e) {
        const { personalCountryList, tempPersonalCountryList } = this.state
        this.setState({ personalCountry: '' })
        if (e.target.value != '') {
            console.log("tempPersonalCountryList ++++----------->", tempPersonalCountryList)

            var searchCoutryList = tempPersonalCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                personalCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                personalCountryErr: '',
                tempPersonalCountryList: searchCoutryList,
                isDisplayPersonalCountryList: true
            })
        }
        else {

            this.setState({
                personalCountry: e.target.value,
                tempPersonalCountryList: personalCountryList,
                isDisplayPersonalCountryList: false
            })
        }
    }

    // Render Country List in Personal Information
    renderPersonalCountryList() {
        const { tempPersonalCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPersonalCountryList.length > 0 && tempPersonalCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                personalCountry: data.Description, personalCountryId: data.Code,
                                isDisplayPersonalCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On Select Attendee 
    onAttendeeSelect(e) {
        const { attendeeList } = this.state
        var checked = e.target.checked
        var id = e.target.value
        attendeeList.length > 0 && attendeeList.map((data) => {
            if (checked) {
                if (id == data.AttendeeTypeID) {
                    data.isChecked = true
                    this.setState({ attendeeTypeId: data.AttendeeTypeID, attendeeTypeErr: "" })
                } else {
                    data.isChecked = false
                }
            } else {
                data.isChecked = false
            }
        })
        console.log("attendeeList------->", attendeeList)
        this.setState({ attendeeList: attendeeList })
    }

    // Email Verify after Input
    verifyEmail(e, status) {
        var value = e.target.value
        if (value != "") {
            var emailError = emailValidator(value)
            if (emailError != '') {
                status == 1 ? this.setState({ emailErrModal: emailError }) : this.setState({ emailErr: emailError })
                return
            } else {
                this.setState({ isLoading: true })
                this.props.verifyVisitorEmail_action(value)
                    .then((data) => {
                        if (data.error == 1) {
                            this.setState({ isLoading: false })
                            console.log("verifyEmail--->", data.ErrMsg)
                            if (data.ErrMsg != "" && data.ErrMsg != null) {
                                status == 1 ? this.setState({ emailErrModal: data.ErrMsg }) : this.setState({ emailErr: data.ErrMsg })
                            } else {
                                status == 1 ? this.setState({ emailErrModal: '' }) : this.setState({ emailErr: "" })
                            }
                        } else {
                            this.setState({ isLoading: false })
                            status == 1 ? this.setState({ emailErrModal: '' }) : this.setState({ emailErr: "" })
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }


        } else {
            status == 1 ? this.setState({ emailErrModal: "Please enter Email" }) : this.setState({ emailErr: "Please enter Email" })
        }
    }

    onPreviousClick(e) {
        e.preventDefault();
        const win = window.location.replace('https://www.itme-africa.com/', '_blank');
        if (win != null) {
            win.focus();
        }
    }

    inputValidateIndustrySegment() {
        const { passName, applicantMobNo, applicantEmail, passNo, passDate, passExipryDate, passCountryId, needVisaAssistance,
            passportFile, level1, delegationCountry, visitPurposeList, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate,
            delegationRemark, chapterList, isInterestTwo, prefTime, visitorGroupList, preffDate, b2bRemark, primaryChapterListData, level2, level3, level4 } = this.state

        if (!(chapterList.some(obj => obj.IsSelect == true))) {
            this.setState({ chapterErr: "Please Select Chapter" })
        }
        var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
        if (!(filerArray.some(obj => obj.IsSelect == true))) {
            this.setState({ ChapterHallErr: "Please Select Hall" })
        }
        if (isGrpDelegation) {
            if (leader == "") {
                this.setState({ leaderErr: "Please enter Leader/Organisation" });
            }
            if (delegationCountry == "") {
                this.setState({ delegationCountryErr: "Please enter Country" });
            }
            if (arrivalDate == "") {
                this.setState({ arrivalDateErr: "Please enter Arrival Date" });
            }
            if (departureDate == "") {
                this.setState({ departureDateErr: "Please enter Departure Date" });
            }
            if (delegationRemark == "") {
                this.setState({ delegationRemarkErr: "Please enter Delegation Remark" });
            }
        }
        else {
            this.setState({
                leaderErr: "",
                delegationCountryErr: "",
                arrivalDateErr: "",
                departureDateErr: "",
                delegationRemarkErr: "",
            })
        }
        if (needVisaAssistance) {
            if (passName == "") {
                this.setState({ passNameErr: "Please enter Name " });
            }
            if (applicantMobNo == "") {
                this.setState({ applicantMobNoErr: "Please enter Mobile NO " });
            }
            if (applicantEmail == "") {
                this.setState({ applicantEmailErr: "Please enter Email " });
            }

            if (passNo == "") {
                this.setState({ passNoErr: "Please enter Passport No " });
            }
            if (passDate == "") {
                this.setState({ passDateErr: "Please enter Passport Date " });
            }
            if (passExipryDate == "") {
                this.setState({ passExipryDateErr: "Please enter Passport Expiry Date " });
            }
            if (passCountryId == "") {
                this.setState({ passCountryErr: "Please Select Country " });
            }
            if (passportFile == "") {
                this.setState({ passportFileErr: "Please Upload File " });
            }
            if (applicantEmail != "") {
                var emailError = emailValidator(applicantEmail)
                if (emailError != '') {
                    this.setState({ applicantEmailErr: emailError })
                    return
                }
            }

        }
        else {
            this.setState({
                passNameErr: "",
                applicantMobNoErr: "",
                applicantEmailErr: "",
                passNoErr: "",
                passDateErr: "",
                passExipryDateErr: "",
                passCountryErr: "",
                passportFileErr: ""
            })
        }

    }

    cancelModal() {
        const { chapterList, daysModalList, modalID, primaryChapterListData, applicantMobNo, level0, level2, level3, level4 } = this.state
        primaryChapterListData.length > 0 && primaryChapterListData.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        level0.length > 0 && level0.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        level2.length > 0 && level2.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        level3.length > 0 && level3.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        level4.length > 0 && level4.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        chapterList.length > 0 && chapterList.map(item => {
            // item.IsCheckbox = false
            item.IsSelect = false
        })
        daysModalList.length > 0 && daysModalList.map(item => {
            // item.IsCheckbox = false
            item.isSelect = false
        })
        // var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        this.setState({
            isOtherPreNameModal: false, otherNameModal: '', emailErrModal: '', firstNameModal: '', firstNameErrModal: '', prenameModal: '', middleNameModal: '', lastNameModal: '', lastNameErrModal: '', designationModal: '', designationErrModal: '', instituteModal: '', instituteErrModal: '', mobNoModal: '', mobNoErrModal: '', preMobNoModal: '', whatsAppMobNoModal: '', whatsAppMobNoErrModal: '', preWhatsAppMobNoModal: '', isSameAsMobNoModal: false, pincodeModal: '', pincodeErrModal: '', regStateModal: '', regStateErrModal: '', cityModal: '', cityErrModal: '', websiteModal: '', emailModal: '', telNoModal: '', preTelNoModal: '', tel1NoModal: '', preTel1NoModal: '', isOpen: false, countryIdModal: '', applicantEmail: '',
            applicantMobNo: '', preApplicantMobNo: '',
            arrivalDate: '', delegationCountry: '', departureDate: '', leader: '', delegationRemark: '', isGrpDelegation: false, passName: '', passExipryDate: '', passportFilePath: '', passportFileType: '', passDate: '', passCountry: '', passNo: '', needVisaAssistance: false, primaryChapterListData: primaryChapterListData, level0: level0, level2: level2, level3: level3, level4: level4, chapterList: chapterList,
            //  daysModalList: daysModalList,
            tempObj: {}, modalStep2: false, passportFile: '', passportFileType: '', passPortFileName: '', isVisaApplied: false, isPassportFilePresent: false,
        })
    }

    handleAddGroupDetail(status) {
        const { GroupDetailsList, isOtherPreNameModal, otherNameModal, emailErrModal, firstNameModal, firstNameErrModal, prenameModal, middleNameModal, lastNameModal, lastNameErrModal, designationModal, designationErrModal, other, otherName, instituteModal, instituteErrModal, mobNoModal, mobNoErrModal, preMobNoModal, whatsAppMobNoModal, whatsAppMobNoErrModal, preWhatsAppMobNoModal, isSameAsMobNoModal, pincodeModal, pincodeErrModal, regStateModal, modalID, regStateErrModal, cityModal, cityErrModal, websiteModal, daysModalList, emailModal, telNoModal, level2, level0, level3, level4, primaryChapterListData, chapterErr, chapterList, ChapterHallErr, isGrpDelegation, leader, leaderErr, delegationCountry, isDisplayDelegationCountryList, delegationCountryErr, arrivalDate, departureDate, arrivalDateErr, departureDateErr, delegationRemarkErr, delegationRemark, passCountry, passCountryErr, passCountryList, passDate, passDateErr, passExipryDate, passExipryDateErr, passName, passNameErr, passNo, passNoErr, passPortFileName, passportFile, passportFileErr, passportFilePath, passportFileType, isCoordinatorVisit, isDisplayPassCountryList, isFirst, isModalEdit, isOpen2, isOtherOpen, isPassportFilePresent, isVisaApplied, needVisaAssistance, AccountID, activeModalStep, applicantEmail, applicantEmailErr, applicantMobNo, applicantMobNoErr, preApplicantMobNo, primaryChapterListLevel3, primaryChapterListLevel4, preTelNoModal, tel1NoModal, preTel1NoModal, countryId, countryIdModal, tempObj } = this.state
        if (status == 1) {
            this.inputValidateGroup()
            var emailErr = false
            !isModalEdit && !this.state.modalStep2 && GroupDetailsList.length > 0 && GroupDetailsList.map(item => {
                if (item.email == emailModal) {
                    toastError("Please enter different email")
                    emailErr = true
                }
            })
            var daysListValidation = false
            for (var i = 0; i < this.state.daysModalList.length; i++) {
                if (this.state.daysModalList[i].isSelect == true) {
                    daysListValidation = true
                }
            }
            // preparing Selected Days for udt
            var selectedDays = []
            // selectedDays = daysList.filter(data => data.isSelect == true)
            selectedDays = daysModalList.filter(data => data.isSelect == true)
            var list = []
            selectedDays.map((data) => {
                var data = {
                    "visitDay": data.VisitDate
                }
                list.push(data)
            })
            console.log(GroupDetailsList, "lplp", firstNameModal, instituteModal, lastNameModal, mobNoModal, whatsAppMobNoModal, pincodeModal, regStateModal, cityModal, designationModal, emailModal, list)
            var modalErr = true
            setTimeout(() => {
                if (firstNameModal == "") {
                    this.setState({ firstNameErrModal: "Please enter first name" });
                    modalErr = false
                }
                if (lastNameModal == "") {
                    this.setState({ lastNameErrModal: "Please enter last name" });
                    modalErr = false
                }
                if (designationModal == "") {
                    this.setState({ designationErrModal: "Please enter designation" });
                    modalErr = false
                }
                if (instituteModal == "") {
                    this.setState({ instituteErrModal: "Please enter institue/Organization" });
                    modalErr = false
                }
                if (mobNoModal == "") {
                    this.setState({ mobNoErrModal: "Please enter mobile no" });
                    modalErr = false
                }
                if (whatsAppMobNoModal == "") {
                    this.setState({ whatsAppMobNoErrModal: "Please enter whatsapp no" });
                    modalErr = false
                }
                // if (photoFile == "") {
                //     this.setState({ photoFileErr: "Please upload photo" });
                // }
                // if (photoIdFile == "") {
                //     this.setState({ photoIdFileErr: "Please upload photo ID" });
                // }
                if (regStateModal == "" && countryId == "91") {
                    this.setState({ regStateErrModal: "Please enter province/state" });
                    modalErr = false
                }
                if (cityModal == "") {
                    this.setState({ cityErrModal: "Please enter city" });
                    modalErr = false
                }
                if (pincodeModal == "") {
                    this.setState({ pincodeErrModal: "Please enter Pincode" });
                    modalErr = false
                }
                if (emailModal == "") {
                    this.setState({ emailErrModal: "Please enter Email" });
                    modalErr = false
                }
                if (emailModal != "") {
                    var emailError = emailValidator(emailModal)
                    if (emailError != '') {
                        this.setState({ emailErrModal: emailError })
                        modalErr = false
                        // return
                    }
                }
                if (modalErr) {
                    var emailError = emailValidator(emailModal)
                    if (emailError != '') {
                        return
                    } else {
                        if (!this.state.isModalEdit && !this.state.modalStep2) {
                            var obj = {
                                'Title': isOtherPreNameModal ? otherNameModal : prenameModal,
                                'firstName': firstNameModal,
                                'middleName': middleNameModal,
                                'lastName': lastNameModal,
                                'institute': instituteModal,
                                'isOtherPreName': isOtherPreNameModal,
                                'otherName': otherNameModal,
                                'designation': designationModal,
                                'mobNo': preMobNoModal != '' && preMobNoModal != null ? `${preMobNoModal}-${mobNoModal}` : mobNoModal,
                                'whatsAppMobNo': preWhatsAppMobNoModal != '' && preWhatsAppMobNoModal != null ? `${preWhatsAppMobNoModal}-${whatsAppMobNoModal}` : whatsAppMobNoModal,
                                'pincode': pincodeModal,
                                'countryId': countryIdModal,
                                'regState': regStateModal,
                                'isSameAsMobNo': isSameAsMobNoModal,
                                'city': cityModal,
                                'website': websiteModal,
                                'AccountID': null,
                                'ExhVisitorID': null,
                                'email': emailModal,
                                'telNo': preTelNoModal != '' && preTelNoModal != null ? `${preTelNoModal}-${telNoModal}` : telNoModal,
                                'tel1No': preTel1NoModal != '' && preTel1NoModal != null ? `${preTel1NoModal}-${tel1NoModal}` : tel1NoModal,
                                'id': GroupDetailsList.length + 1,
                                'isCoordinator': false,
                                'isVisit': true,
                                'DateArr': list,

                            }
                            // GroupDetailsList.push(obj)
                            this.setState({ activeModalStep: 2, modalStep2: true, tempObj: obj, preApplicantMobNo: preMobNoModal, applicantMobNo: mobNoModal, applicantEmail: emailModal })
                            // this.setState({
                            //     isOtherPreNameModal: false, otherNameModal: '', emailErrModal: '', firstNameModal: '', firstNameErrModal: '', prenameModal: '', middleNameModal: '', lastNameModal: '', lastNameErrModal: '', designationModal: '', designationErrModal: '', instituteModal: '', instituteErrModal: '', mobNoModal: '', mobNoErrModal: '', preMobNoModal: '', whatsAppMobNoModal: '', whatsAppMobNoErrModal: '', preWhatsAppMobNoModal: '', isSameAsMobNoModal: false, pincodeModal: '', pincodeErrModal: '', regStateModal: '', regStateErrModal: '', cityModal: '', cityErrModal: '', websiteModal: '', emailModal: '', telNoModal: '', preTelNoModal: '', tel1NoModal: '', preTel1NoModal: '', isOpen: false
                            // })
                            // setTimeout(() => {
                            //     toastSuccess("visitor added successfully")
                            // }, 200);


                        } else {
                            GroupDetailsList.length > 0 && GroupDetailsList.map((item, index) => {
                                if (this.state.modalID == 'Coordinator') {
                                    this.setState({
                                        preName: isOtherPreNameModal ? otherNameModal : prenameModal,
                                        firstName: firstNameModal,
                                        middleName: middleNameModal,
                                        lastName: lastNameModal,
                                        institute: instituteModal,
                                        isOtherPreName: isOtherPreNameModal,
                                        otherName: otherNameModal,
                                        designation: designationModal,
                                        mobNo: preMobNoModal != '' && preMobNoModal != null ? `${preMobNoModal}-${mobNoModal}` : mobNoModal,
                                        whatsAppMobNo: preWhatsAppMobNoModal != '' && preWhatsAppMobNoModal != null ? `${preWhatsAppMobNoModal}-${whatsAppMobNoModal}` : whatsAppMobNoModal,
                                        pincode: pincodeModal,
                                        regState: regStateModal,
                                        countryId: countryIdModal,
                                        isSameAsMobNo: isSameAsMobNoModal,
                                        city: cityModal,
                                        website: websiteModal,
                                        email: emailModal,
                                        telNo: preTelNoModal != '' && preTelNoModal != null ? `${preTelNoModal}-${telNoModal}` : telNoModal,
                                        tel1No: preTel1NoModal != '' && preTel1NoModal != null ? `${preTel1NoModal}-${tel1NoModal}` : tel1NoModal,
                                        DateArrCoordinator: list
                                    })
                                }
                                else if (this.state.modalID == index) {
                                    GroupDetailsList[index].Title = isOtherPreNameModal ? otherNameModal : prenameModal
                                    GroupDetailsList[index].firstName = firstNameModal
                                    GroupDetailsList[index].middleName = middleNameModal
                                    GroupDetailsList[index].lastName = lastNameModal
                                    GroupDetailsList[index].institute = instituteModal
                                    GroupDetailsList[index].isOtherPreName = isOtherPreNameModal
                                    GroupDetailsList[index].otherName = otherNameModal
                                    GroupDetailsList[index].designation = designationModal
                                    GroupDetailsList[index].mobNo = preMobNoModal != '' && preMobNoModal != null ? `${preMobNoModal}-${mobNoModal}` : mobNoModal
                                    GroupDetailsList[index].whatsAppMobNo = preWhatsAppMobNoModal != '' && preWhatsAppMobNoModal != null ? `${preWhatsAppMobNoModal}-${whatsAppMobNoModal}` : whatsAppMobNoModal
                                    GroupDetailsList[index].pincode = pincodeModal
                                    GroupDetailsList[index].regState = regStateModal
                                    GroupDetailsList[index].countryId = countryIdModal
                                    GroupDetailsList[index].isSameAsMobNo = isSameAsMobNoModal
                                    GroupDetailsList[index].city = cityModal
                                    GroupDetailsList[index].website = websiteModal
                                    GroupDetailsList[index].email = emailModal
                                    GroupDetailsList[index].telNo = preTelNoModal != '' && preTelNoModal != null ? `${preTelNoModal}-${telNoModal}` : telNoModal
                                    GroupDetailsList[index].tel1No = preTel1NoModal != '' && preTel1NoModal != null ? `${preTel1NoModal}-${tel1NoModal}` : tel1NoModal
                                    GroupDetailsList[index].DateArr = list
                                } else {

                                }
                            })
                            this.setState({ activeModalStep: 2, GroupDetailsList: GroupDetailsList })

                            // this.setState({
                            //     isOtherPreNameModal: false, otherNameModal: '', emailErrModal: '', firstNameModal: '', firstNameErrModal: '', prenameModal: '', middleNameModal: '', lastNameModal: '', lastNameErrModal: '', designationModal: '', designationErrModal: '', instituteModal: '', instituteErrModal: '', mobNoModal: '', mobNoErrModal: '', preMobNoModal: '', whatsAppMobNoModal: '', whatsAppMobNoErrModal: '', preWhatsAppMobNoModal: '', isSameAsMobNoModal: false, pincodeModal: '', pincodeErrModal: '', regStateModal: '', regStateErrModal: '', cityModal: '', cityErrModal: '', websiteModal: '', emailModal: '', telNoModal: '', preTelNoModal: '', tel1NoModal: '', preTel1NoModal: '', isOpen: false, GroupDetailsList: GroupDetailsList, isModalEdit: false
                            // })
                            // setTimeout(() => {
                            //     toastSuccess("visitor update successfully")
                            // }, 200);
                        }
                    }
                } else {
                    toastError("Please enter mandatory fields")
                    console.log("lplplllllllllllllllllllll")
                }
            }, 200);

        } else {
            this.inputValidateIndustrySegment()
            // Preparing Udt For Chapter List
            const selectedChapter = chapterList.filter(data => data.IsSelect == true)
            var chapterListData = []
            selectedChapter.map((data) => {
                var data = {
                    "ChapterID": data.IndustrySegmentID,
                    "ChapterNames": data['Industrial Segment'],
                    "OtherNames": data.IndustrySegmentID == 16 ? other : ''
                }
                chapterListData.push(data)
            })


            // Preparing Udt For Hall Chapter List
            var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
            var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
            var chapterListData2 = []
            filerArray.map((data) => {
                var data = {
                    "ChapterID": data.ChapterID,
                    "ChapterNames": data.ChapterName,
                    // "OtherNames": ''

                }
                chapterListData2.push(data)
            })
            setTimeout(() => {
                var visaFlag = true
                var grpDelFlag = true
                if (isGrpDelegation) {
                    if (leader == "") {
                        this.setState({ leaderErr: "Please enter Leader/Organisation" });
                        grpDelFlag = false
                    }
                    if (delegationCountry == "") {
                        this.setState({ delegationCountryErr: "Please enter Country" });
                        grpDelFlag = false
                    }
                    if (arrivalDate == "") {
                        this.setState({ arrivalDateErr: "Please enter Arrival Date" });
                        grpDelFlag = false
                    }
                    if (departureDate == "") {
                        this.setState({ departureDateErr: "Please enter Departure Date" });
                        grpDelFlag = false
                    }
                    if (delegationRemark == "") {
                        this.setState({ delegationRemarkErr: "Please enter Delegation Remark" });
                        grpDelFlag = false
                    }
                }
                if (needVisaAssistance) {
                    if (passName == "") {
                        this.setState({ passNameErr: "Please enter Name " });
                        visaFlag = false
                    }
                    if (applicantMobNo == "") {
                        this.setState({ applicantMobNoErr: "Please enter Mobile NO " });
                        visaFlag = false
                    }
                    if (applicantEmail == "") {
                        this.setState({ applicantEmailErr: "Please enter Email " });
                        visaFlag = false
                    }

                    if (passNo == "") {
                        this.setState({ passNoErr: "Please enter Passport No " });
                        visaFlag = false
                    }
                    if (passDate == "") {
                        this.setState({ passDateErr: "Please enter Passport Date " });
                        visaFlag = false
                    }
                    if (passExipryDate == "") {
                        this.setState({ passExipryDateErr: "Please enter Passport Expiry Date " });
                        visaFlag = false
                    }
                    if (passportFile == "") {
                        this.setState({ passportFileErr: "Please Upload File " });
                        visaFlag = false
                    }
                    if (applicantEmail != "") {
                        var emailError = emailValidator(applicantEmail)
                        if (emailError != '') {
                            this.setState({ applicantEmailErr: emailError })
                            visaFlag = false
                            // return
                        }
                    }

                }
                if ((chapterListData2.length != 0 && chapterListData.length != 0) && visaFlag && chapterErr == "" && grpDelFlag && ChapterHallErr == "") {
                    if (this.state.isModalEdit) {
                        if (this.state.isModalEdit) if (modalID == 'Coordinator') {
                            this.setState({
                                ApplicantEmailIDCoordinator: needVisaAssistance ? applicantEmail : '',
                                ApplicantMobileNoCoordinator: needVisaAssistance ? preApplicantMobNo != null && preApplicantMobNo != '' ? `${preApplicantMobNo}-${applicantMobNo}` : applicantMobNo : '',
                                B2BDepartureDateCoordinator: '',
                                B2BPreferredTimeCoordinator: '',
                                B2BRemarkCoordinator: '',
                                CarparkingCoordinator: false,
                                DelegationArrivalDateCoordinator: isGrpDelegation ? arrivalDate : null,
                                DelegationCountryIDCoordinator: isGrpDelegation ? delegationCountry : null,
                                DelegationDepartureDateCoordinator: isGrpDelegation ? departureDate : null,
                                DelegationNameCoordinator: isGrpDelegation ? leader : '',
                                DelegationRemarkCoordinator: isGrpDelegation ? delegationRemark : '',
                                // ExhibitionIDCoordinator: parseInt(sessionStorage.getItem('sessionExhibitionID')),
                                IsGroupDelegationCoordinator: isGrpDelegation,
                                NameOnPassportCoordinator: needVisaAssistance ? passName : '',
                                ParkingChargesCoordinator: 0,
                                PassportExpiryDateCoordinator: needVisaAssistance ? passExipryDate : null,
                                PassportFileCoordinator: needVisaAssistance ? passportFile : null,
                                PassportFileNameCoordinator: needVisaAssistance ? passPortFileName : null,
                                PassportFilePathCoordinator: needVisaAssistance ? passportFilePath : '',
                                PassportFileTypeCoordinator: needVisaAssistance ? passportFileType : '',
                                PassportIssueDateCoordinator: needVisaAssistance ? passDate : null,
                                PassportIssuingCountryIDCoordinator: needVisaAssistance ? passCountry : null,
                                PassportNoCoordinator: needVisaAssistance ? passNo : '',
                                UserIDCoordinator: null,
                                areaOfInterestListCoordinator: [],
                                hallChapterListCoordinator: chapterListData2,
                                interestSeminarListCoordinator: [],
                                isVisaAssistanceCoordinator: needVisaAssistance,
                                purposeOfVisitListCoordinator: [],
                                visitorChapterListCoordinator: chapterListData,
                                visitorRegNoCoordinator: null,
                                isVisaAppliedCoordinator: needVisaAssistance,
                                isPassportFilePresentCoordinator: false,
                            })
                        } else {
                            GroupDetailsList.length > 0 && GroupDetailsList.map((item, index) => {
                                if (this.state.modalID == index) {
                                    GroupDetailsList[index].ApplicantEmailID = needVisaAssistance ? applicantEmail : ''
                                    GroupDetailsList[index].ApplicantMobileNo = needVisaAssistance ? preApplicantMobNo != null && preApplicantMobNo != '' ? `${preApplicantMobNo}-${applicantMobNo}` : applicantMobNo : ''
                                    GroupDetailsList[index].B2BDepartureDate = ''
                                    GroupDetailsList[index].B2BPreferredTime = ''
                                    GroupDetailsList[index].B2BRemark = ''
                                    GroupDetailsList[index].Carparking = false
                                    GroupDetailsList[index].DelegationArrivalDate = isGrpDelegation ? arrivalDate : null
                                    GroupDetailsList[index].DelegationCountryID = isGrpDelegation ? delegationCountry : null
                                    GroupDetailsList[index].DelegationDepartureDate = isGrpDelegation ? departureDate : null
                                    GroupDetailsList[index].DelegationName = isGrpDelegation ? leader : ''
                                    GroupDetailsList[index].DelegationRemark = isGrpDelegation ? delegationRemark : ''
                                    GroupDetailsList[index].ExhibitionID = parseInt(sessionStorage.getItem('sessionExhibitionID'))
                                    GroupDetailsList[index].IsGroupDelegation = isGrpDelegation
                                    GroupDetailsList[index].NameOnPassport = needVisaAssistance ? passName : ''
                                    GroupDetailsList[index].ParkingCharges = 0
                                    GroupDetailsList[index].PassportExpiryDate = needVisaAssistance ? passExipryDate : null
                                    GroupDetailsList[index].PassportFile = needVisaAssistance ? passportFile : null
                                    GroupDetailsList[index].PassportFileName = needVisaAssistance ? passPortFileName : null
                                    GroupDetailsList[index].PassportFilePath = needVisaAssistance ? passportFilePath : ''
                                    GroupDetailsList[index].PassportFileType = needVisaAssistance ? passportFileType : ''
                                    GroupDetailsList[index].PassportIssueDate = needVisaAssistance ? passDate : null
                                    GroupDetailsList[index].PassportIssuingCountryID = needVisaAssistance ? passCountry : null
                                    GroupDetailsList[index].PassportNo = needVisaAssistance ? passNo : ''
                                    GroupDetailsList[index].UserID = null
                                    GroupDetailsList[index].areaOfInterestList = []
                                    GroupDetailsList[index].hallChapterList = chapterListData2
                                    GroupDetailsList[index].interestSeminarList = []
                                    GroupDetailsList[index].isVisaAssistance = needVisaAssistance
                                    GroupDetailsList[index].purposeOfVisitList = []
                                    GroupDetailsList[index].visitorChapterList = chapterListData
                                    GroupDetailsList[index].visitorRegNo = null
                                    GroupDetailsList[index].isVisaApplied = needVisaAssistance
                                    GroupDetailsList[index].isPassportFilePresent = false
                                }
                            })
                            this.setState({ GroupDetailsList: GroupDetailsList })
                        }
                    }
                    else {
                        tempObj.ApplicantEmailID = needVisaAssistance ? applicantEmail : ''
                        tempObj.ApplicantMobileNo = needVisaAssistance ? preApplicantMobNo != null && preApplicantMobNo != '' ? `${preApplicantMobNo}-${applicantMobNo}` : applicantMobNo : ''
                        tempObj.B2BDepartureDate = ''
                        tempObj.B2BPreferredTime = ''
                        tempObj.B2BRemark = ''
                        tempObj.Carparking = false
                        tempObj.DelegationArrivalDate = isGrpDelegation ? arrivalDate : null
                        tempObj.DelegationCountryID = isGrpDelegation ? delegationCountry : null
                        tempObj.DelegationDepartureDate = isGrpDelegation ? departureDate : null
                        tempObj.DelegationName = isGrpDelegation ? leader : ''
                        tempObj.DelegationRemark = isGrpDelegation ? delegationRemark : ''
                        tempObj.ExhibitionID = parseInt(sessionStorage.getItem('sessionExhibitionID'))
                        tempObj.IsGroupDelegation = isGrpDelegation
                        tempObj.NameOnPassport = needVisaAssistance ? passName : ''
                        tempObj.ParkingCharges = 0
                        tempObj.PassportExpiryDate = needVisaAssistance ? passExipryDate : null
                        tempObj.PassportFile = needVisaAssistance ? passportFile : null
                        tempObj.PassportFileName = needVisaAssistance ? passPortFileName : null
                        tempObj.PassportFilePath = needVisaAssistance ? passportFilePath : ''
                        tempObj.PassportFileType = needVisaAssistance ? passportFileType : ''
                        tempObj.PassportIssueDate = needVisaAssistance ? passDate : null
                        tempObj.PassportIssuingCountryID = needVisaAssistance ? passCountry : null
                        tempObj.PassportNo = needVisaAssistance ? passNo : ''
                        tempObj.UserID = null
                        tempObj.areaOfInterestList = []
                        tempObj.hallChapterList = chapterListData2
                        tempObj.interestSeminarList = []
                        tempObj.isVisaAssistance = needVisaAssistance
                        tempObj.purposeOfVisitList = []
                        tempObj.visitorChapterList = chapterListData
                        tempObj.visitorRegNo = null
                        tempObj.isVisaApplied = needVisaAssistance
                        tempObj.isPassportFilePresent = false

                        GroupDetailsList.push(tempObj)
                        this.setState({ GroupDetailsList: GroupDetailsList, })
                    }
                    this.cancelModal()
                    modalID != 'Coordinator' && toastSuccess("Visitoe Added Successfully")
                }
                else {
                    toastError("Please enter mandatory fields")
                    console.log("lplplllllllllllllllllllll")
                }
            }, 200);
        }
        setTimeout(() => {
            console.log(this.state.GroupDetailsList)
        }, 1000);
    }

    handleEditGroup(i) {
        const { GroupDetailsList, isOtherPreNameModal, otherNameModal, emailErrModal, firstNameModal, firstNameErrModal, prenameModal, middleNameModal, lastNameModal, lastNameErrModal, designationModal, designationErrModal, instituteModal, instituteErrModal, mobNoModal, mobNoErrModal, preMobNoModal, whatsAppMobNoModal, whatsAppMobNoErrModal, preWhatsAppMobNoModal, isSameAsMobNoModal, pincodeModal, pincodeErrModal, regStateModal, regStateErrModal, cityModal, cityErrModal, websiteModal, emailModal, telNoModal, preTelNoModal, tel1NoModal, preTel1NoModal, daysModalList, chapterList, primaryChapterListData, level0, level2, leader, level3, level4, mobNo, whatsAppMobNo, tel1No, telNo, ApplicantMobileNoCoordinator, ExhVisitorID, AccountID, preName, middleName, lastName, firstName, designation, institute, isSameAsMobNo, pincode, regState, city, website, email, countryId, } = this.state
        console.log(GroupDetailsList, this.state.DateArrCoordinator, 'llllllllllllllll')
        if (i == 'Coordinator') {
            this.state.DateArrCoordinator.length > 0 && this.state.DateArrCoordinator.map(item2 => {
                daysModalList.length > 0 && daysModalList.map(item => {
                    if (item.Code == item2.visitDay) {
                        item.isSelect = true
                    }
                })
            })
            this.state.visitorChapterListCoordinator.length > 0 && this.state.visitorChapterListCoordinator.map(item2 => {
                chapterList.length > 0 && chapterList.map(item => {
                    if (item.IndustrySegmentID == item2.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            var SelectedHallChapters = this.state.hallChapterListCoordinator != null ? this.state.hallChapterListCoordinator : []
            primaryChapterListData.length > 0 && primaryChapterListData.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level2.length > 0 && level2.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level3.length > 0 && level3.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level4.length > 0 && level4.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            var mob1 = mobNo
            const parts = mob1 != '' ? mob1.split("-") : '';
            const Mob1Code = parts.length > 1 && parts != '' ? parts[0] : ''
            const Mob1No = parts != '' ? parts.length > 1 ? parts[1] : parts[0] : ''
            // WhatsApp Mobile No
            var mob2 = whatsAppMobNo
            const parts2 = mob2 != '' ? mob2.split("-") : ''
            const Mob2Code = parts2.length > 1 && parts2 != '' ? parts2[0] : ''
            const Mob2No = parts2 != '' ? parts2.length > 1 ? parts2[1] : parts2[0] : ''
            // TelePhone No
            var tel = telNo
            const parts3 = tel != '' ? tel.split("-") : ''
            const telCode = parts3.length > 1 && parts3 != '' ? parts3[0] : ''
            const telNumber = parts3 != '' ? parts3.length > 1 ? parts3[1] : parts3[0] : ''
            // Telephone 1 Mobile No
            var tel1 = tel1No
            const parts4 = tel1 != '' ? tel1.split("-") : ''
            const tel1Code = parts4.length > 1 && parts4 != '' ? parts4[0] : ''
            const tel1Number = parts4 != '' ? parts4.length > 1 ? parts4[1] : parts4[0] : ''

            var applicantMob = ApplicantMobileNoCoordinator
            const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
            const applicantMobCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
            const applicantMobNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''
            console.log(mobNo, ';;;', this.state.preMobNo, ';;;', ApplicantMobileNoCoordinator, ';;', Mob1Code, ';;;', Mob1No)
            this.setState({
                // isOtherPreNameModal: false,//
                // otherNameModal: GroupDetailsList[i],//
                // emailErrModal: GroupDetailsList[i],
                ExhVisitorIDModal: ExhVisitorID,
                AccountIDModal: AccountID,
                firstNameModal: firstName,
                // firstNameErrModal: GroupDetailsList[i],
                prenameModal: preName,
                middleNameModal: middleName,
                lastNameModal: lastName,
                // lastNameErrModal: GroupDetailsList[i],
                designationModal: designation,
                // designationErrModal: GroupDetailsList[i],
                instituteModal: institute,
                // instituteErrModal: GroupDetailsList[i],
                mobNoModal: mobNo,
                // mobNoErrModal: Mob1No,
                preMobNoModal: this.state.preMobNo,
                whatsAppMobNoModal: whatsAppMobNo,
                // whatsAppMobNoErrModal: GroupDetailsList[i],
                preWhatsAppMobNoModal: this.state.preWhatsAppMobNo,
                isSameAsMobNoModal: isSameAsMobNo,
                pincodeModal: pincode,
                // pincodeErrModal: GroupDetailsList[i],
                regStateModal: regState,
                // regStateErrModal: GroupDetailsList[i],
                cityModal: city,
                // cityErrModal: GroupDetailsList[i],
                websiteModal: website,
                emailModal: email,
                // emailerModal: GroupDetailsList[i],
                telNoModal: telNumber,
                preTelNoModal: telCode,
                tel1NoModal: tel1Number,
                preTel1NoModal: tel1Code,
                isOpen: true,
                modalID: i,
                isModalEdit: true,
                countryIdModal: countryId,
                preApplicantMobNo: ApplicantMobileNoCoordinator != '' ? applicantMobCode : this.state.preMobNo,
                applicantMobNo: ApplicantMobileNoCoordinator != '' ? applicantMobNumber : mobNo,
                arrivalDate: this.state.DelegationArrivalDateCoordinator,
                delegationCountry: this.state.DelegationCountryIDCoordinator,
                departureDate: this.state.DelegationDepartureDateCoordinator,
                leader: this.state.DelegationNameCoordinator,
                delegationRemark: this.state.DelegationRemarkCoordinator,
                isGrpDelegation: this.state.IsGroupDelegationCoordinator,
                passName: this.state.NameOnPassportCoordinator,
                passportFileType: this.state.PassportFileTypeCoordinator,
                passportFile: this.state.PassportFileCoordinator,
                passportFilePath: this.state.PassportFilePathCoordinator,
                passPortFileName: this.state.PassportFileNameCoordinator,
                passDate: this.state.PassportIssueDateCoordinator,
                passExipryDate: this.state.PassportExpiryDateCoordinator,
                passCountry: this.state.PassportIssuingCountryIDCoordinator,
                passNo: this.state.PassportNoCoordinator,
                applicantEmail: this.state.ApplicantEmailIDCoordinator != '' ? this.state.ApplicantEmailIDCoordinator : this.state.email,
                needVisaAssistance: this.state.isVisaAssistanceCoordinator,
                primaryChapterListData: primaryChapterListData,
                level0: level0,
                level2: level2,
                level3: level3,
                level4: level4,
                daysModalList: daysModalList,
                chapterList: chapterList,
                isVisaApplied: this.state.isVisaAppliedCoordinator,
                isPassportFilePresent: this.state.isPassportFilePresentCoordinator,
                modalStep2: true

            })
        } else {
            GroupDetailsList[i].DateArr.length > 0 && GroupDetailsList[i].DateArr.map(item2 => {
                daysModalList.length > 0 && daysModalList.map(item => {
                    if (item.Code == item2.visitDay) {
                        item.isSelect = true
                    }
                })
            })
            GroupDetailsList[i].visitorChapterList.length > 0 && GroupDetailsList[i].visitorChapterList.map(item2 => {
                chapterList.length > 0 && chapterList.map(item => {
                    if (item.IndustrySegmentID == item2.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            var SelectedHallChapters = GroupDetailsList[i]['hallChapterList'] != null ? GroupDetailsList[i]['hallChapterList'] : []
            primaryChapterListData.length > 0 && primaryChapterListData.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level2.length > 0 && level2.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level3.length > 0 && level3.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            level4.length > 0 && level4.map((item) => {
                SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                    if (element.ChapterID == item.ChapterID) {
                        item.IsSelect = true
                    }
                })
            })
            var mob1 = GroupDetailsList[i].mobNo
            const parts = mob1 != '' ? mob1.split("-") : '';
            const Mob1Code = parts.length > 1 && parts != '' ? parts[0] : ''
            const Mob1No = parts != '' ? parts.length > 1 ? parts[1] : parts[0] : ''
            // WhatsApp Mobile No
            var mob2 = GroupDetailsList[i].whatsAppMobNo
            const parts2 = mob2 != '' ? mob2.split("-") : ''
            const Mob2Code = parts2.length > 1 && parts2 != '' ? parts2[0] : ''
            const Mob2No = parts2 != '' ? parts2.length > 1 ? parts2[1] : parts2[0] : ''
            // TelePhone No
            var tel = GroupDetailsList[i].telNo
            const parts3 = tel != '' ? tel.split("-") : ''
            const telCode = parts3.length > 1 && parts3 != '' ? parts3[0] : ''
            const telNumber = parts3 != '' ? parts3.length > 1 ? parts3[1] : parts3[0] : ''
            // Telephone 1 Mobile No
            var tel1 = GroupDetailsList[i].tel1No
            const parts4 = tel1 != '' ? tel1.split("-") : ''
            const tel1Code = parts4.length > 1 && parts4 != '' ? parts4[0] : ''
            const tel1Number = parts4 != '' ? parts4.length > 1 ? parts4[1] : parts4[0] : ''

            var applicantMob = GroupDetailsList[i].ApplicantMobileNo
            const parts5 = applicantMob != '' ? applicantMob.split("-") : ''
            const applicantMobCode = parts5.length > 1 && parts5 != '' ? parts5[0] : ''
            const applicantMobNumber = parts5 != '' ? parts5.length > 1 ? parts5[1] : parts5[0] : ''
            this.setState({
                // isOtherPreNameModal: false,//
                // otherNameModal: GroupDetailsList[i],//
                // emailErrModal: GroupDetailsList[i],
                ExhVisitorIDModal: GroupDetailsList[i].ExhVisitorID,
                AccountIDModal: GroupDetailsList[i].AccountID,
                firstNameModal: GroupDetailsList[i].firstName,
                // firstNameErrModal: GroupDetailsList[i],
                prenameModal: GroupDetailsList[i].Title,
                middleNameModal: GroupDetailsList[i].middleName,
                lastNameModal: GroupDetailsList[i].lastName,
                // lastNameErrModal: GroupDetailsList[i],
                designationModal: GroupDetailsList[i].designation,
                // designationErrModal: GroupDetailsList[i],
                instituteModal: GroupDetailsList[i].institute,
                // instituteErrModal: GroupDetailsList[i],
                mobNoModal: Mob1No,
                // mobNoErrModal: Mob1No,
                preMobNoModal: Mob1Code,
                whatsAppMobNoModal: Mob2No,
                // whatsAppMobNoErrModal: GroupDetailsList[i],
                preWhatsAppMobNoModal: Mob2Code,
                isSameAsMobNoModal: GroupDetailsList[i].isSameAsMobNo,
                pincodeModal: GroupDetailsList[i].pincode,
                // pincodeErrModal: GroupDetailsList[i],
                regStateModal: GroupDetailsList[i].regState,
                // regStateErrModal: GroupDetailsList[i],
                cityModal: GroupDetailsList[i].city,
                // cityErrModal: GroupDetailsList[i],
                websiteModal: GroupDetailsList[i].website,
                emailModal: GroupDetailsList[i].email,
                // emailerModal: GroupDetailsList[i],
                telNoModal: telNumber,
                preTelNoModal: telCode,
                tel1NoModal: tel1Number,
                preTel1NoModal: tel1Code,
                isOpen: true,
                modalID: i,
                isModalEdit: true,
                countryIdModal: GroupDetailsList[i].countryId,
                preApplicantMobNo: applicantMobCode,
                applicantMobNo: applicantMobNumber,
                arrivalDate: GroupDetailsList[i].DelegationArrivalDate,
                delegationCountry: GroupDetailsList[i].DelegationCountryID,
                departureDate: GroupDetailsList[i].DelegationDepartureDate,
                leader: GroupDetailsList[i].DelegationName,
                delegationRemark: GroupDetailsList[i].DelegationRemark,
                isGrpDelegation: GroupDetailsList[i].IsGroupDelegation,
                passName: GroupDetailsList[i].NameOnPassport,
                passportFileType: GroupDetailsList[i].PassportFileType,
                passportFile: GroupDetailsList[i].PassportFile,
                passportFilePath: GroupDetailsList[i].PassportFilePath,
                passPortFileName: GroupDetailsList[i].PassportFileName,
                passDate: GroupDetailsList[i].PassportIssueDate,
                passExipryDate: GroupDetailsList[i].PassportExpiryDate,
                passCountry: GroupDetailsList[i].PassportIssuingCountryID,
                passNo: GroupDetailsList[i].PassportNo,
                applicantEmail: GroupDetailsList[i].ApplicantEmailID,
                needVisaAssistance: GroupDetailsList[i].isVisaAssistance,
                primaryChapterListData: primaryChapterListData,
                level0: level0,
                level2: level2,
                level3: level3,
                level4: level4,
                daysModalList: daysModalList,
                chapterList: chapterList,
                isVisaApplied: GroupDetailsList[i].isVisaApplied,
                isPassportFilePresent: GroupDetailsList[i].isPassportFilePresent,
                modalStep2: true

            })
        }
        setTimeout(() => {

            console.log(i, GroupDetailsList, primaryChapterListData, level0, level2, level3, level4)
        }, 500);
    }

    // render Visa Application
    renderGroupList() {
        const { GroupDetailsList } = this.state
        return (
            <ul class="list-group " style={{ position: 'relative', zIndex: 1 }}>
                {
                    GroupDetailsList.length > 0 && GroupDetailsList.map((data, index) => (<>
                        {data.isCoordinator != true && <li class="list-group-item" key={Math.random()}>
                            <div className="row" style={{ display: 'inline' }}>
                                <h5 className="" style={{ color: "#0056ff" }}>Visitor {++index}</h5>
                                <div className="row" style={{ display: 'inline' }}>
                                    <button
                                        type="button"
                                        className="btn btn-warning rounded-0"
                                        style={{ marginRight: '10px', width: '100px', float: 'right' }}
                                        onClick={() => this.handleEditGroup(index - 1)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger rounded-0"
                                        style={{ marginRight: '10px', width: '100px', float: 'right' }}
                                        onClick={() => this.handleDeleteAddedVisitor(index - 1)}
                                    >
                                        delete
                                    </button>
                                </div>
                            </div>
                            <div className="added-more p-3 rounded-2">
                                <div className="row mt-4">
                                    <div className="col-md-4">
                                        <h6>First Name: {data.firstName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Middel Name: {data.middleName}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Last Name: {data.lastName}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>Designation: {data.designation}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Institue/Organisation: {data.institute}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Mobile: {data.mobNo}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>WhatsApp No:  {data.whatsAppMobNo}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Pincode: {data.pincode}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>State: {data.regState}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>City: {data.city}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>website: {data.website}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Email: {data.email}</h6>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <h6>TelePhone: {data.telNo}</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>TelePhone 1: {data.tel1No}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-1" />

                        </li>}
                    </>
                    ))
                }
            </ul>
        )
    }

    handleDeleteAddedVisitor(id) {
        const { GroupDetailsList } = this.state
        console.log(GroupDetailsList[id], id)
        GroupDetailsList.splice(id, 1)
        this.setState({ GroupDetailsList: GroupDetailsList })
    }

    toggleModel(status) {
        const { isOpen, isFirst } = this.state
        const { countryId, attendeeTypeId, city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender,
            institute, firstName, preWhatsAppMobNo, whatsAppMobNo, pincode, email, firstNameErr, lastNameErr, designationErr,
            instituteErr, mobNoErr, whatsAppMobNoErr, regStateErr, cityErr, pincodeErr, emailErr, preName, middleName,
            isSameAsMobNo, website, preTelNo, preTel1No, daysList, telNo, isModalEdit, modalStep2, activeModalStep, tel1No, countryIdErr, attendeeTypeErr, daysErr } = this.state
        this.inputValidate(1)
        var selectedDays = []
        selectedDays = this.state.isCoordinatorVisit ? daysList.filter(data => data.isSelect == true) : []

        setTimeout(() => {
            console.log(selectedDays)
            if (firstName != "" && lastName != "" && designation != "" && institute != "" && mobNo != "" && whatsAppMobNo != "" &&
                city != "" && pincode != "" && email != "" && countryId != "" && attendeeTypeId != "") {
                if (firstNameErr == "" && lastNameErr == "" && designationErr == "" && instituteErr == "" && mobNoErr == "" &&
                    whatsAppMobNoErr == "" && regStateErr == "" && cityErr == "" && pincodeErr == "" && (emailErr == "" && emailErr != "Please enter proper email." && emailErr != "your emailId already register as a visitor account") && countryIdErr == ""
                    && attendeeTypeErr == "" && (this.state.isCoordinatorVisit ? selectedDays.length > 0 : true)
                ) {
                    if (status == 2) {
                        this.setState({ isOpen2: true, isFirst: false, instituteModal: this.state.institute, websiteModal: this.state.website, preWhatsAppMobNoModal: this.state.preWhatsAppMobNo, preMobNoModal: this.state.preMobNo, whatsAppMobNoModal: this.state.whatsAppMobNoModal, instituteErrModal: '', countryIdModal: this.state.countryId, isModalEdit: false, activeModalStep: 1, regStateModal: this.state.regState, cityModal: this.state.city, pincodeModal: this.state.pincode })
                    } else if (status == 'Coordinator') {
                        this.handleEditGroup(status)
                    } else {
                        if (status == 1) {
                            this.setState({ isCoordinatorVisit: true })
                        }
                        this.setState({ isOpen: !isOpen, isModalEdit: false, activeModalStep: 1, instituteModal: this.state.institute, websiteModal: this.state.website, preWhatsAppMobNoModal: this.state.preWhatsAppMobNo, preMobNoModal: this.state.preMobNo, whatsAppMobNoModal: this.state.whatsAppMobNoModal, instituteErrModal: '', isOpen2: false, countryIdModal: this.state.countryId, regStateModal: this.state.regState, cityModal: this.state.city, pincodeModal: this.state.pincode })
                        // if (isFirst) {
                        //     this.setState({ isOpen2: true, isFirst: false })
                        // }
                    }
                } else {
                    toastError("please enter Mandatory details")
                }
            } else {
                toastError("please enter Mandatory details")
            }
            console.log(this.state.daysList, this.state.daysModalList)
        })
    }

    // toggleModel2() {
    //     const { isOpen, isFirst } = this.state
    //     this.setState({ isOpen: !isOpen, instituteModal: this.state.institute, websiteModal: this.state.website, preWhatsAppMobNoModal: this.state.preWhatsAppMobNo, preMobNoModal: this.state.preMobNo, whatsAppMobNoModal: this.state.whatsAppMobNoModal, instituteErrModal: '' })
    //     if (isFirst) {
    //         this.setState({ isOpen2: true, isFirst: false })
    //     }
    // }
    handleDelete(e) {
        e.preventDefault()
        const { isOpen3, docDelAccountID, docDelExhVisitorID, ExhVisitorIDModal, GroupDetailsList, AccountIDModal, visitorGroupList } = this.state
        this.setState({ isLoading: true })
        this.props.delVisaDoc_action(AccountIDModal, ExhVisitorIDModal)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Passport Document Deleted SuccessFully")
                    // sessionStorage.getItem('VisitorTypeID') == 1 && this.setState({ passportFile: "", passPortFileName: "", isPassportFilePresent: false })
                    GroupDetailsList.map(item => {
                        if (item.AccountID == AccountIDModal) {
                            item.passportFile = ''
                            item.passportFileType = ''
                            item.passPortFileName = ''
                            item.passportFilePath = ''
                            item.isPassportFilePresent = false
                        }
                    })
                    // sessionStorage.getItem('VisitorTypeID') == 2 && this.setState({ visitorGroupList: visitorGroupList })
                    this.setState({ isLoading: false, isOpen3: !isOpen3, passportFile: '', passportFileType: '', passPortFileName: '', isPassportFilePresent: false, passportFilePath: '' })
                    this.getVisitorRegistrationDetails()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { isLoading, country, countryList, countryId, daysList, firstName, middleName, lastName, preName, GroupDetailsList,
            isOtherPreName, otherName, gender, designation, institute, preMobNo, mobNo, preWhatsAppMobNo, whatsAppMobNo,
            add1, add2, add3, pincode, city, regState, website, gstNo, panNo, email, preTelNo, telNo, tel1No, preTel1No,
            isDisplayPincodeList, isDisplayCityList, isDisplayStateList, isSameAsMobNo, vaccineCertificateFile, photoFile,
            photoIdFile, countryIdErr, attendeeTypeErr, cityErr, preMobNoErr, mobNoErr, regStateErr, designationErr, photoFileErr,
            lastNameErr, photoIdFileErr, genderErr, instituteErr, firstNameErr, isOpen, preWhatsAppMobNoErr, whatsAppMobNoErr, personalCountryId,
            personalCountry, isDisplayPersonalCountryList, personalCountryErr, attendeeList, attendeeTypeId, pincodeErr, emailErr, daysErr, isExhbitionClosed,
            isOtherPreNameModal, otherNameModal, emailErrModal, firstNameModal, firstNameErrModal, prenameModal, middleNameModal, lastNameModal, lastNameErrModal, designationModal, designationErrModal, instituteModal, instituteErrModal, mobNoModal, mobNoErrModal, preMobNoModal, whatsAppMobNoModal, whatsAppMobNoErrModal, preWhatsAppMobNoModal, isSameAsMobNoModal, pincodeModal, pincodeErrModal, regStateModal, regStateErrModal, cityModal, cityErrModal, websiteModal, emailModal, telNoModal, preTelNoModal, tel1NoModal, preTel1NoModal, tempCityList, tempGroupCityList, tempGroupPincodeList, tempGroupStateList, tempPersonalCountryList, tempPincodeList, tempStateList, isDataLoad, isDisplayGroupCityList, isDisplayGroupPersonalCountryList, isDisplayGroupPincodeList, isDisplayGroupStateList,
            level2, level0, level3, level4, primaryChapterListData, chapterErr, chapterList, ChapterHallErr,
            isGrpDelegation, leader, leaderErr, delegationCountry, isDisplayDelegationCountryList, delegationCountryErr, arrivalDate, departureDate, arrivalDateErr, departureDateErr, delegationRemarkErr, delegationRemark, isOpen3,
            passCountry, passCountryErr, passCountryList, passDate, passDateErr, passExipryDate, passExipryDateErr, passName, passNameErr, passNo, passNoErr, passPortFileName, passportFile, passportFileErr, passportFilePath, passportFileType, isCoordinatorVisit, isDisplayPassCountryList, isFirst, isModalEdit, isOpen2, isOtherOpen, isPassportFilePresent, isVisaApplied, needVisaAssistance, AccountID, activeModalStep, applicantEmail, applicantEmailErr, applicantMobNo, applicantMobNoErr, preApplicantMobNo, primaryChapterListLevel3, primaryChapterListLevel4 } = this.state

        const allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        const filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {sessionStorage.getItem('VisitorDataSS') != null ? < VisitorHeader /> : this.state.isDataLoad && < VisitorHeader />}
                <div
                    className="main-content app-content mt-0"
                    style={{ marginInlineStart: 0 }}
                >
                    <div className="side-app">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div
                                    className="step-name" style={{ backgroundColor: "#bc202b", color: "#fff;", marginTop: '85px' }}
                                >
                                    <p>Visitor Registration form</p>
                                </div>
                            </div>
                        </div>
                        {!isExhbitionClosed ? (
                            <div className="needs-validation after-step mt-1">
                                <div id="Menu1">
                                    <div id="section-1">
                                        {/* <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Country</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mb-3">
                                                <div className="d-flex">
                                                    <label className="mt-3 w-auto" >
                                                        Select Country
                                                        <span className="text-red">*</span>

                                                    </label>
                                                    {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{countryIdErr}</span>}
                                                </div>
                                                <select className="form-control" value={countryId}
                                                    onChange={(e) => this.onCountrySelect(e)}
                                                >
                                                    <option>Select Your Country</option>
                                                    {
                                                        countryList.length > 0 && countryList.map((data) => (
                                                            <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Visitor Type <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{this.state.visitorTypeErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <div className="row mt-4">
                                                    {
                                                        this.state.visitorTypeList.length > 0 && this.state.visitorTypeList.map((data) => (
                                                            <div className="col-sm-4" key={data.VisitorTypeID}>
                                                                <label className="d-flex">
                                                                    <input type="radio" name="vistorType" value={data.VisitorTypeID}
                                                                        checked={data.VisitorTypeID == this.state.VisitorTypeID}
                                                                        // onChange={e => this.setState({ attendeeType: "Business Visitor" })}
                                                                        onChange={(e) => this.setState({ VisitorTypeID: data.VisitorTypeID, GroupDetailsList: [] })}
                                                                    />
                                                                    {data.VisitorType}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Attendee Type <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{attendeeTypeErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <div className="row mt-4">
                                                    {
                                                        attendeeList.length > 0 && attendeeList.map((data) => (
                                                            <div className="col-sm-4" key={data.AttendeeTypeID}>
                                                                <label className="d-flex">
                                                                    <input type="radio" name="attendeeType" value={data.AttendeeTypeID}
                                                                        checked={data.isChecked}
                                                                        // onChange={e => this.setState({ attendeeType: "Business Visitor" })}
                                                                        onChange={(e) => this.onAttendeeSelect(e)}
                                                                    />
                                                                    {data.AttendeeTypeName}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">{this.state.VisitorTypeID == 2 ? 'Coordinator Information' : 'Personal Information'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.VisitorTypeID == 2 && <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-3">
                                                            <input type="checkbox" name="fav_language"
                                                                value={this.state.isCoordinatorVisit} checked={this.state.isCoordinatorVisit}
                                                                onClick={(e) => this.setState({ isCoordinatorVisit: !this.state.isCoordinatorVisit })}
                                                            />
                                                            is Coordinator Visiting?
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-xl-3 mt-3">
                                                        <label>
                                                            First Name<span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherPreName &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                maxLength={'40'}
                                                                value={otherName}
                                                                onChange={e => this.setState({ otherName: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'50'}
                                                            style={{ position: "relative", paddingLeft: 55 }}
                                                            value={firstName}
                                                            onChange={(e) => this.setState({ firstName: e.target.value, firstNameErr: "" })}
                                                        />

                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: "-57px",
                                                                    left: 0
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 form-control select2 select-hidden-accessible"
                                                                    id="validationCustom03"
                                                                    required=""
                                                                    style={{
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: 2,
                                                                        borderRadius: 0,
                                                                        borderLeft: 0,
                                                                        height: 33,
                                                                        background: "none"
                                                                    }}
                                                                    value={preName}
                                                                    onChange={(e) => this.setState({
                                                                        preName: e.target.value,
                                                                        isOtherPreName: e.target.value == "Other" && true
                                                                    })}
                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block'>{firstNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Middle Name</label>
                                                        <input type="text" className="form-control" value={middleName}
                                                            maxLength={'50'}
                                                            onChange={(e) => this.setState({ middleName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Last Name
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={lastName} maxLength={'50'}
                                                            onChange={(e) => this.setState({ lastName: e.target.value, lastNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErr}</span>}
                                                    </div>

                                                    <div className="col-xl-3">
                                                        <div className="d-flex">
                                                            <label className="mt-3 w-auto" >
                                                                Select Country
                                                                <span className="text-red">*</span>

                                                            </label>
                                                            {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{countryIdErr}</span>}
                                                        </div>
                                                        <select className="form-control" value={countryId}
                                                            onChange={(e) => this.onCountrySelect(e)}
                                                        >
                                                            <option>Select Your Country</option>
                                                            {
                                                                countryList.length > 0 && countryList.map((data) => (
                                                                    <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-4">
                                                <div className="row">

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Institute/Organisation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={institute} maxLength={'500'}
                                                            onChange={(e) => this.setState({ institute: e.target.value, instituteErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{instituteErr}</span>}

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={mobNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ mobNo: e.target.value, mobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="ISD"
                                                            maxLength={'10'}
                                                            value={preMobNo}
                                                            onChange={(e) => this.setState({ preMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> WhatsApp Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={whatsAppMobNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ whatsAppMobNo: e.target.value, whatsAppMobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preWhatsAppMobNo}
                                                            placeholder="ISD"
                                                            maxLength={'10'}
                                                            onChange={(e) => this.setState({ preWhatsAppMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{whatsAppMobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-6">
                                                            <input type="checkbox" name="fav_language"
                                                                value={isSameAsMobNo} checked={isSameAsMobNo}
                                                                onClick={(e) => this.onSameAsMobNoClick(e)}
                                                            />
                                                            Same as Mobile No
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Designation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={designation} maxLength={'50'}
                                                            onChange={(e) => this.setState({ designation: e.target.value, designationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Pincode/Zipcode
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        {
                                                            countryId == "91" &&
                                                            <input type="text" className="form-control" value={pincode}
                                                                maxLength={6} onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            countryId != "91" &&
                                                            <input type="text" className="form-control" value={pincode}
                                                                maxLength={10} onChange={(e) => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase}
                                                            />
                                                        }
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pincodeErr}</span>}
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            State/Province
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        <input type="text" className="form-control" value={regState} maxLength={'50'}
                                                            onChange={(e) => this.onStateSearch(e)}
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regStateErr}</span>}
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">City
                                                            <span className="red-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={city} maxLength={'50'}
                                                            onChange={(e) => this.onCitySearch(e)} />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Website</label>
                                                        <input type="text" className="form-control" value={website} maxLength={'50'}
                                                            onChange={(e) => this.setState({ website: e.target.value })}
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> GST No</label>
                                                    <input type="text" className="form-control" value={gstNo}
                                                        onChange={(e) => this.setState({ gstNo: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> PAN No</label>
                                                    <input type="text" className="form-control" value={panNo}
                                                        onChange={(e) => this.setState({ panNo: e.target.value })}
                                                    />
                                                </div> */}
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Email
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={email} maxLength={'50'}
                                                            onChange={(e) => this.setState({ email: e.target.value, emailErr: "" })}
                                                            onBlur={(e) => this.verifyEmail(e)}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={telNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ telNo: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preTelNo} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTelNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No 1</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={tel1No}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ tel1No: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preTel1No} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTel1No: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.VisitorTypeID == 2 && !this.state.isCoordinatorVisit ? null : <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Date of Visit Single Day Pass / All Day Pass{" "}
                                                                <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{daysErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mb-4">
                                                <div className="row mt-4" >
                                                    {
                                                        daysList.length > 0 && daysList.map((data) => (
                                                            <div className="col-sm-4" key={data.Code}>
                                                                <label className="d-flex">
                                                                    <input type="checkbox" select={data.isSelect}
                                                                        checked={data.isSelect} value={data.Code}
                                                                        onChange={(e) => this.onSelectDates(e)}
                                                                    />
                                                                    {data.VisitDate}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>}
                                        {this.state.VisitorTypeID == 2 && <div className="btn-group ms-2 mt-4 mb-4 save-can d-block">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary rounded-0 d-block"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => this.state.isFirst && !this.state.isCoordinatorVisit ? this.toggleModel(2) : this.toggleModel()}
                                                >
                                                    Add Visitor
                                                </button>
                                                {this.state.isCoordinatorVisit && <button
                                                    type="button"
                                                    className="btn btn-primary rounded-0 d-block"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => this.toggleModel('Coordinator')}
                                                >
                                                    Coordinator details
                                                </button>}
                                                {/* <button type="button" class="btn btn-primary rounded-0">Payment Listing Page</button> */}
                                            </div>

                                        </div>}
                                        {this.state.VisitorTypeID == 2 && this.renderGroupList()}

                                        <div className="main-container m-2">
                                            <div className="mb-4">
                                                {this.state.VisitorTypeID == 2 && <a
                                                    className="btn-primary btn"
                                                    type="submit"
                                                    onClick={(e) => this.state.VisitorTypeID == 2 ? this.onVisitorRegistrationGroupNext(e, 1) : this.onVisitorRegistrationNext(e, 1)}
                                                >
                                                    save
                                                </a>}
                                                <a
                                                    className="btn btn-next-bot ms-2"
                                                    type="submit"
                                                    onClick={(e) => this.state.VisitorTypeID == 2 ? this.onVisitorRegistrationGroupNext(e) : this.onVisitorRegistrationNext(e)}
                                                >
                                                    Next
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="main-content app-content mt-0"
                                style={{ backgroundColor: "#ececec" }}
                            >
                                <div className="side-app">
                                    <div className="main-container container-fluid top-form-name">
                                        <div className="row ">
                                            <form className="needs-validation " noValidate="">
                                                <div className="form-row mt-4">
                                                    <div className="form-group col-md-7 p-4 thank-box">
                                                        <div className="form-group mb-2">
                                                            <h4
                                                                className="mb-0 pt-2 pb-4 thank-top-mes"
                                                                style={{ color: "#318958" }}
                                                            >
                                                                <strong>Exhibition Closed</strong>
                                                            </h4>
                                                            <p className="mb-1 mt-1 text-center com-font">
                                                                Thank you for your interest but the
                                                            </p>
                                                            <p className="mt-1 mb-0 text-center com-font">
                                                                ITME AFRICA &amp; M.E. 2023 from 30 November 2023 to 2
                                                                December 2023
                                                            </p>
                                                            <p className="mb-3 text-center com-font">
                                                                at Kenyatta International Convention Centre, Nairobi, Kenya is now closed.
                                                            </p>
                                                            <p className="mb-3 text-center com-font">
                                                                This Event already completed. Please Visit Again once the next edition is announced.
                                                            </p>
                                                        </div>
                                                        <div classname="">
                                                            <div classname="mt-1 mb-4">

                                                                <button
                                                                    classname="btn btn-next-bot m-1"
                                                                    style={{
                                                                        background: "#007bff",
                                                                        padding: "6px 20px",
                                                                        fontSize: 14,
                                                                        border: "1px solid #007bff",
                                                                        borderRadius: 2,
                                                                        color: "#fff",
                                                                        marginTop: 16
                                                                    }}
                                                                    onClick={e => this.onPreviousClick(e)}
                                                                >
                                                                    {" "}
                                                                    BACK TO EVENT WEBSITE
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <Modal
                        size="lg"
                        show={isOpen}
                        // dialogClassName="modal-90w"
                        onHide={() => this.toggleModel()}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                <h5 class="modal-title modal_title_text text-danger">{this.state.isModalEdit ? this.state.modalID == 'Coordinator' ? 'Update Coordinator' : 'Update Visitor' : 'Add Visitor'}</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="navgred mt-0" style={{ backgroundColor: 'white', border: '2px solid #DC3545' }}>
                                <ul className="nav nav-pillsred">
                                    <li className="nav-item " style={{ border: 'none' }}>
                                        <button onClick={() => this.setState({ activeModalStep: 1, isVisaApplied: passportFile != '' && passportFile != null ? true : isVisaApplied })} style={this.state.activeModalStep == 1 ? { backgroundColor: '#DC3545' } : { backgroundColor: 'white', border: 'none' }} className={this.state.activeModalStep == 1 ? "nav-linkred active px-4 py-2" : "nav-linkred  px-4 py-2"}>
                                            {" "}
                                            <a style={this.state.activeModalStep == 1 ? { backgroundColor: '#DC3545' } : { backgroundColor: 'white' }} className={this.state.activeModalStep == 1 ? "nav-linkred active px-4 py-2 " : "nav-linkred  px-4 py-2"}>
                                                Step 1{" "}
                                            </a>{" "}
                                        </button>

                                    </li>
                                    {/* </Link> */}
                                    <li className="nav-item " style={{ border: 'none' }}>
                                        {" "}
                                        <button onClick={() => this.setState({ activeModalStep: 2 })} disabled={!this.state.modalStep2} className={this.state.activeModalStep == 2 ? "nav-linkred active px-4 py-2" : "nav-linkred  px-4 py-2"} style={this.state.activeModalStep == 2 ? { backgroundColor: '#DC3545' } : { backgroundColor: 'white', border: 'none' }}>
                                            <a style={this.state.activeModalStep == 2 ? { backgroundColor: '#DC3545' } : { backgroundColor: 'white' }} className={this.state.activeModalStep == 2 ? "nav-linkred active px-4 py-2 " : "nav-linkred  px-4 py-2"}>
                                                Step 2{" "}
                                            </a>{" "}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            {/* <div class="row"> */}
                            {this.state.activeModalStep == 1 && <>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-xl-3 mt-3">
                                            <label>
                                                First Name<span className="text-red">*</span>
                                            </label>
                                            {
                                                isOtherPreNameModal &&
                                                <input
                                                    type="text"
                                                    className="form-control-1 other-fil"
                                                    maxLength={'40'}
                                                    value={otherNameModal}
                                                    onChange={e => this.setState({ otherNameModal: e.target.value })}
                                                    placeholder={'Other'}
                                                    style={{
                                                        // position: "absolute",
                                                        paddingLeft: '5px'
                                                    }}
                                                />
                                            }
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={'50'}
                                                style={{ position: "relative", paddingLeft: 55 }}
                                                value={firstNameModal}
                                                onChange={(e) => this.setState({ firstNameModal: e.target.value, firstNameErrModal: "" })}
                                            />

                                            <div className="col-xl-1 mb-3">
                                                <div
                                                    className="form-group"
                                                    style={{
                                                        width: 45,
                                                        position: "absolute",
                                                        top: "-57px",
                                                        left: 0
                                                    }}
                                                >
                                                    <label />
                                                    <select
                                                        className="form-control-1 form-control select2 select-hidden-accessible"
                                                        id="validationCustom03"
                                                        required=""
                                                        style={{
                                                            borderTop: "none",
                                                            borderBottom: "none",
                                                            marginTop: 2,
                                                            borderRadius: 0,
                                                            borderLeft: 0,
                                                            height: 33,
                                                            background: "none"
                                                        }}
                                                        value={prenameModal}
                                                        onChange={(e) => this.setState({
                                                            prenameModal: e.target.value,
                                                            isOtherPreNameModal: e.target.value == "Other" && true
                                                        })}
                                                    >
                                                        <option value="Mr">Mr</option>
                                                        <option value="Ms">Ms</option>
                                                        <option value="DR">DR</option>
                                                        <option value="Prof">Prof</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>

                                            </div>
                                            {<span className='text-danger text-start text-left d-block'>{firstNameErrModal}</span>}

                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label className="mt-3">Middle Name</label>
                                            <input type="text" className="form-control" value={middleNameModal}
                                                maxLength={'50'}
                                                onChange={(e) => this.setState({ middleNameModal: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-xl-3 mb-1">
                                            <label className="mt-3">Last Name
                                                <span className="text-red">*</span>
                                            </label>
                                            <input type="text" className="form-control" value={lastNameModal} maxLength={'50'}
                                                onChange={(e) => this.setState({ lastNameModal: e.target.value, lastNameErrModal: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErrModal}</span>}
                                        </div>
                                        <div className="col-xl-3">
                                            <div className="d-flex">
                                                <label className="mt-3 w-auto" >
                                                    Select Country
                                                    <span className="text-red">*</span>

                                                </label>
                                                {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{this.state.countryIdErrModal}</span>}
                                            </div>
                                            <select className="form-control" value={this.state.countryIdModal}
                                                onChange={(e) => this.onCountrySelect(e, 1)}
                                            >
                                                <option>Select Your Country</option>
                                                {
                                                    countryList.length > 0 && countryList.map((data) => (
                                                        <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 mb-4">
                                    <div className="row">

                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">
                                                Institute/Organisation <span className="rad-star text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" value={instituteModal} maxLength={'500'}
                                                onChange={(e) => this.setState({ instituteModal: e.target.value, instituteErrModal: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{instituteErrModal}</span>}

                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">Mobile No
                                                <span className="rad-star text-danger">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={mobNoModal}
                                                maxLength={'10'}
                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onPaste={(e) => this.handlePaste(e)}
                                                onWheel={(e) => e.target.blur()}
                                                style={{ paddingLeft: 50 }}
                                                onChange={(e) => e.target.value >= 0 &&
                                                    this.setState({ mobNoModal: e.target.value, mobNoErrModal: "" })}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                placeholder="ISD"
                                                maxLength={'10'}
                                                value={preMobNoModal}
                                                onChange={(e) => this.setState({ preMobNoModal: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 30,
                                                    padding: " 6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 34
                                                }}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErrModal}</span>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2"> WhatsApp Mobile No
                                                <span className="rad-star text-danger">*</span>
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={whatsAppMobNoModal}
                                                maxLength={'10'}
                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onPaste={(e) => this.handlePaste(e)}
                                                onWheel={(e) => e.target.blur()}
                                                style={{ paddingLeft: 50 }}
                                                onChange={(e) => e.target.value >= 0 &&
                                                    this.setState({ whatsAppMobNoModal: e.target.value, whatsAppMobNoErrModal: "" })}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                value={preWhatsAppMobNoModal}
                                                placeholder="ISD"
                                                maxLength={'10'}
                                                onChange={(e) => this.setState({ preWhatsAppMobNoModal: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 30,
                                                    padding: " 6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 34
                                                }}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{whatsAppMobNoErrModal}</span>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-6">
                                                <input type="checkbox" name="fav_language"
                                                    value={isSameAsMobNoModal} checked={isSameAsMobNoModal}
                                                    onClick={(e) => this.onSameAsMobNoClick(e, 1)}
                                                />
                                                Same as Mobile No
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-3">
                                                Designation <span className="rad-star text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" value={designationModal} maxLength={'50'}
                                                onChange={(e) => this.setState({ designationModal: e.target.value, designationErrModal: "" })}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{designationErrModal}</span>}

                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">
                                                Pincode/Zipcode
                                                {countryId == "91" && <span className="red-star text-danger">*</span>}
                                            </label>
                                            {
                                                countryId == "91" &&
                                                <input type="text" className="form-control" value={pincodeModal}
                                                    maxLength={6} onChange={(e) => this.onPincodeSearch(e, 1)}
                                                />
                                            }
                                            {
                                                countryId != "91" &&
                                                <input type="text" className="form-control" value={pincodeModal}
                                                    maxLength={10} onChange={(e) => this.onPincodeSearch(e, 1)}
                                                    onInput={toInputUppercase}
                                                />
                                            }
                                            {<span className='text-danger text-start text-left d-block mt-1'>{pincodeErrModal}</span>}
                                            {isDisplayGroupPincodeList && this.renderGroupPincodeList()}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">
                                                State/Province
                                                {countryId == "91" && <span className="red-star text-danger">*</span>}
                                            </label>
                                            <input type="text" className="form-control" value={regStateModal} maxLength={'50'}
                                                onChange={(e) => this.onStateSearch(e, 1)}
                                            />
                                            {isDisplayGroupStateList && this.renderGroupStateList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{regStateErrModal}</span>}
                                        </div>

                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">City
                                                <span className="red-star text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" value={cityModal} maxLength={'50'}
                                                onChange={(e) => this.onCitySearch(e, 1)} />
                                            {isDisplayGroupCityList && this.renderGroupCityList()}
                                            {<span className='text-danger text-start text-left d-block mt-1'>{cityErrModal}</span>}
                                        </div>

                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2"> Website</label>
                                            <input type="text" className="form-control" value={websiteModal} maxLength={'50'}
                                                disabled
                                                onChange={(e) => this.setState({ websiteModal: e.target.value })}
                                            />
                                        </div>
                                        {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> GST No</label>
                                                    <input type="text" className="form-control" value={gstNo}
                                                        onChange={(e) => this.setState({ gstNo: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> PAN No</label>
                                                    <input type="text" className="form-control" value={panNo}
                                                        onChange={(e) => this.setState({ panNo: e.target.value })}
                                                    />
                                                </div> */}
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2"> Email
                                                <span className="rad-star text-danger">*</span>
                                            </label>
                                            <input type="text" className="form-control" value={emailModal} maxLength={'50'}
                                                onChange={(e) => this.setState({ emailModal: e.target.value, emailErrModal: "" })}
                                                onBlur={(e) => this.verifyEmail(e, 1)}
                                            />
                                            {<span className='text-danger text-start text-left d-block mt-1'>{emailErrModal}</span>}
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">Telephone No</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={telNoModal}
                                                maxLength={'10'}
                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onPaste={(e) => this.preventMinus(e)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => e.target.value >= 0 && this.setState({ telNoModal: e.target.value })}
                                                style={{ paddingLeft: 50 }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                value={preTelNoModal} placeholder="STD" maxLength={'10'}
                                                onChange={(e) => this.setState({ preTelNoModal: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 30,
                                                    padding: " 6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 34
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="d-flex mt-2">Telephone No 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={tel1NoModal}
                                                maxLength={'10'}
                                                onInput={(e) => this.maxLengthCheck(e, 10)}
                                                onKeyDown={(e) => this.preventMinus(e)}
                                                onPaste={(e) => this.handlePaste(e)}
                                                onWheel={(e) => e.target.blur()}
                                                onChange={(e) => e.target.value >= 0 && this.setState({ tel1NoModal: e.target.value })}
                                                style={{ paddingLeft: 50 }}
                                            />
                                            <input
                                                type="text"
                                                className="form-control-1"
                                                value={preTel1NoModal} placeholder="STD" maxLength={'10'}
                                                onChange={(e) => this.setState({ preTel1NoModal: e.target.value })}
                                                style={{
                                                    width: 45,
                                                    position: "absolute",
                                                    top: 30,
                                                    padding: " 6px !important",
                                                    paddingLeft: 5,
                                                    borderLeft: 0,
                                                    borderTop: 0,
                                                    background: "none",
                                                    borderBottom: 0,
                                                    height: 34
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="one-section">
                                    <div className="containerfluid">
                                        <div className="row mt-2">
                                            <div className="col-md-12">
                                                <div className="p-1 title-bg d-flex">
                                                    <p className="mb-0">
                                                        Date of Visit Single Day Pass / All Day Pass{" "}
                                                        <span className="text-red">*</span>
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{this.state.daysModalErr}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-8 mb-4">
                                        {console.log(daysList)}
                                        <div className="row mt-4" >
                                            {
                                                this.state.daysModalList.length > 0 && this.state.daysModalList.map((data) => (
                                                    <div className="col-sm-4" key={data.Code}>
                                                        <label className="d-flex">
                                                            <input type="checkbox" select={data.isSelect}
                                                                checked={data.isSelect} value={data.Code}
                                                                onChange={(e) => this.onModalSelectDates(e)}
                                                            />
                                                            {data.VisitDate}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>
                            </>
                            }
                            {
                                this.state.activeModalStep == 2 &&
                                <>
                                    <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg d-flex">
                                                        <p className="mb-0">Industry Segment <span className="text-red">*</span>
                                                        </p>
                                                        {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{this.state.chapterErr}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" mb-2">
                                            <div className="row mt-1">
                                                <div className="asc">
                                                    <div className="" style={{ paddingLeft: 10 }}>
                                                        <form className="needs-validation pb-2" noValidate="">
                                                            <div className="form-row mt-3">
                                                                {
                                                                    chapterList.length > 0 && chapterList.map((data) => (
                                                                        <div className="col-sm-3 mb-3">
                                                                            <section className="" key={data.IndustrySegmentID}>
                                                                                {/* <input type="checkbox" name="collapse"
                                                                                select={data.IsSelect} checked={data.IndustrySegmentID}
                                                                                value={data.IndustrySegmentID}
                                                                            /> */}
                                                                                <h2 className="handle-no-icon m-0" style={{ fontSize: '13px' }}>
                                                                                    <label style={{ color: '#666' }}>
                                                                                        <input type="checkbox"
                                                                                            select={data.IsSelect} checked={data.IsSelect}
                                                                                            value={data.IndustrySegmentID}
                                                                                            onChange={(e) => this.onSelectPrimaryChapter(e)} />
                                                                                        {data['Industrial Segment']}
                                                                                    </label>
                                                                                </h2>
                                                                                {data.IndustrySegmentID == 16 && this.state.isOtherOpen && <input type="text" className="form-control" value={this.state.other}
                                                                                    // style={{ marginRight: "50px" }}
                                                                                    // maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                                    // onPaste={(e) => this.handlePaste(e)}
                                                                                    // onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                                    onChange={(e) =>
                                                                                        this.setState({ other: e.target.value, })}
                                                                                />}
                                                                            </section>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg d-flex">
                                                        <p className="mb-0">
                                                            Hall to Visit <span className="text-red">*</span>
                                                        </p>
                                                        {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{ChapterHallErr}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-4">
                                                <div class="accordion" id="accordionExample">
                                                    {
                                                        level2.length > 0 && level2.map(level2Data => (
                                                            <>
                                                                {
                                                                    // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                            <button class={level2Data.isHighlight ? "accordion-button collapsed selected-chapter" : "accordion-button collapsed"}
                                                                                type="button" data-bs-toggle="collapse"
                                                                                data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                                aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                                {/* // checkbox for level2 */}
                                                                                {
                                                                                    level2Data.IsCheckbox &&
                                                                                    <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                        checked={level2Data.IsSelect}
                                                                                        onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)} />
                                                                                }
                                                                                {level2Data.ChapterName} </button>
                                                                        </h2>
                                                                        {this.recordTwo(level2Data, level3, level4)}
                                                                    </div>
                                                                }

                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="" style={{ marginLeft: "10px" }}>
                                            <p className="mb-0" style={{ fontSize: 15 }}>
                                                <strong>  Selected Hall to Visit</strong>
                                            </p>
                                        </div>
                                        <div style={{ padding: "0px 0px 0px 10px" }}>
                                            {
                                                filerArray.length > 0 ? filerArray.map((data) => (
                                                    <p>
                                                        {data.ChapterName}
                                                    </p>
                                                )) :
                                                    <p>
                                                        {"No Results"}
                                                    </p>
                                            }
                                        </div>
                                    </div>

                                    {!(parseInt(this.state.countryIdModal) == parseInt(sessionStorage.getItem('ExhibitionCountryID'))) && <div className="one-section mb-3 mt-3">
                                        <div className="col-sm-3 ">
                                            <label className="d-flex">
                                                <input className="do-you"
                                                    type="checkbox"
                                                    name="fav_language"
                                                    checked={isGrpDelegation}
                                                    onChange={(e) => this.setState({
                                                        isGrpDelegation: !isGrpDelegation

                                                    })}
                                                />
                                                <span className="do-you1" style={{ whiteSpace: "nowrap", color: "#bc202b", fontSize: "16px" }}><strong>Are you part of Group Delegation ?</strong>
                                                    (If yes below section would open)</span>
                                            </label>
                                        </div>
                                        {
                                            isGrpDelegation &&
                                            <div
                                                className="col-md-12 mb-2"
                                                id="divMsg"
                                            >
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Delegation Leader/Organisation<span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={leader} maxLength={'50'}
                                                            onChange={(e) => this.setState({ leader: e.target.value, leaderErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{leaderErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="d-flex">
                                                            <label className="mt-3 w-auto" >
                                                                Country/Region
                                                                <span className="text-red">*</span>

                                                            </label>
                                                            {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{delegationCountryErr}</span>}
                                                        </div>
                                                        <select className="form-control" value={delegationCountry}
                                                            onChange={(e) => this.onCountrySelect(e, 3)}
                                                        >
                                                            <option>Select Your Country</option>
                                                            {
                                                                countryList.length > 0 && countryList.map((data) => (
                                                                    <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* <div className="col-xl-3">
                                                        <label className="mt-3">
                                                           <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={delegationCountry}
                                                            onChange={(e) => this.onDelegationCountrySearch(e)}
                                                        />
                                                        {isDisplayDelegationCountryList && this.renderDelegationCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{}</span>}
                                                    </div> */}
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Arrival Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={arrivalDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    arrivalDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    arrivalDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Arrival Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{arrivalDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Departure Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={departureDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    departureDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    departureDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Departure Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{departureDateErr}</span>}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-12 mb-1">
                                                            <label className="mt-3">Remark
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                rows={3}
                                                                maxLength={'250'}
                                                                defaultValue={""}
                                                                value={delegationRemark}
                                                                onChange={(e) => this.setState({ delegationRemark: e.target.value, delegationRemarkErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{delegationRemarkErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>}
                                    {!(parseInt(this.state.countryIdModal) == parseInt(sessionStorage.getItem('ExhibitionCountryID'))) && <div className="one-section mb-3">
                                        <div className="col-sm-12 ">
                                            <label className="d-flex">
                                                <input className="do-you" style={{ marginBottom: '25px' }}
                                                    type="checkbox"
                                                    name="fav_language"
                                                    checked={needVisaAssistance}
                                                    onChange={(e) => this.setState({
                                                        needVisaAssistance: !needVisaAssistance,
                                                    })}
                                                />
                                                <span className="" style={{ color: "#bc202b", fontSize: "16px" }}><strong>Do you need Visa Assistance?</strong>
                                                    (<b>Note</b> : Visa Application letter will be issued {sessionStorage.getItem("ExhVisitorVisaIssueDate")}. Visitor can avail tourist visa also to attend the exhibition.In case applying for Business visa kindly<br /> fill the below details to obtain Visa Invitation Letter.)</span>
                                            </label>
                                        </div>
                                        {
                                            needVisaAssistance &&
                                            <div
                                                className="col-md-12 mb-2"
                                                id="divMsg"
                                            >
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Name as per Passport<span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={passName} maxLength={'50'}
                                                            onChange={(e) => this.setState({ passName: e.target.value, passNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passNameErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Applicant mobile number<span className="text-red">*</span>
                                                        </label>
                                                        <input type="number" className="form-control" value={applicantMobNo}
                                                            style={{ paddingLeft: "50px" }}
                                                            maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ applicantMobNo: e.target.value, applicantMobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1 mt-2"
                                                            // placeholder="ISD"
                                                            value={preApplicantMobNo}
                                                            onChange={(e) => this.setState({ preApplicantMobNo: e.target.value })}
                                                            maxLength={'10'}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{applicantMobNoErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            Applicant email id<span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={applicantEmail} maxLength={'50'}
                                                            onChange={(e) => this.setState({ applicantEmail: e.target.value, applicantEmailErr: "" })}
                                                            onBlur={(e) => this.verifyEmail(e)}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{applicantEmailErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Passport No
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={passNo} maxLength={'10'}
                                                            onChange={(e) => this.setState({ passNo: e.target.value, passNoErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passNoErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Passport issue Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={passDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    passDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    passDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            maxDate={passExipryDate}
                                                            dropdownMode="select"
                                                            placeholderText="Passport Issued Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <label className="mt-3">Passport Expiry Date
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <DatePicker selected={passExipryDate}
                                                            onChange={(date) =>
                                                                this.setState({
                                                                    passExipryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                    passExipryDateErr: ""
                                                                })}
                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            minDate={passDate}
                                                            dropdownMode="select"
                                                            placeholderText="Passport Expiry Date"
                                                            dayClassName={() => "example-datepicker-day-class"}
                                                            popperClassName="example-datepicker-class" />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passExipryDateErr}</span>}
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="d-flex">
                                                            <label className="mt-3 w-auto" >
                                                                Passport issuing Country
                                                                <span className="text-red">*</span>

                                                            </label>
                                                            {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{passCountryErr}</span>}
                                                        </div>
                                                        <select className="form-control" value={passCountry}
                                                            onChange={(e) => this.onCountrySelect(e, 2)}
                                                        >
                                                            <option>Select Your Country</option>
                                                            {
                                                                countryList.length > 0 && countryList.map((data) => (
                                                                    <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    {/* <div className="col-xl-3">
                                                        <label className="mt-3">
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={passCountry}
                                                            onChange={(e) => this.onPassportCountrySearch(e)}
                                                        />
                                                        {isDisplayPassCountryList && this.renderPassCountryList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{ }</span>}
                                                    </div> */}
                                                    {!(isPassportFilePresent) && !isVisaApplied &&
                                                        < div className="col-xl-5">
                                                            <label className="mt-3">
                                                                Passport Front Page (Maximum 5mb / File Type .jpg, .jpeg, .png, .pdf)
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <form action="/action_page.php" style={{ display: "inline-flex" }}>
                                                                <input type="file" id="passportFile" name="filename"
                                                                    accept=".jpg, .jpeg, .png, .pdf" maxLength={"50"}
                                                                    onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{passportFileErr}</span>}
                                                                {
                                                                    passportFile != "" &&
                                                                    <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1)}></i>
                                                                }
                                                            </form>
                                                        </div>
                                                    }
                                                    {
                                                        isVisaApplied && passportFile != "" && passportFile != null &&
                                                        <div className="col-xl-5">
                                                            <label className="mt-3">
                                                                Passport Front Page File
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <div style={{
                                                                border: '1px solid #ccc',
                                                                padding: '10px',
                                                                // display: 'inline-flex',
                                                                alignItems: 'center',
                                                                marginTop: '10px',
                                                                // marginLeft: '12px',
                                                                maxWidth: '300px'
                                                            }}>
                                                                <span style={{ marginRight: '10px' }}
                                                                >{passPortFileName}</span>
                                                                <i className="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1, 1)}></i>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {console.log(this.state.visitorGroupList)}
                                        {/* {
                                            needVisaAssistance && sessionStorage.getItem('VisitorTypeID') == 2 &&
                                            //map+all data points
                                            this.state.visitorGroupList.length > 0 && this.state.visitorGroupList.map((data, i) => (<>
                                                {data.isVisit && < div
                                                    className="list-group-item col-md-12 m-3"
                                                    id="divMsg"
                                                // style={{backgroundColor:}}
                                                >
                                                    <div className="row">
                                                        <h5>{data.isCoordinator == true ? 'Coordinator' : `Visitor ${i}`}</h5>
                                                        {console.log('ppp')}
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Name as per Passport<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={data.passName} maxLength={'50'}
                                                                onChange={(e) => this.onInput(e, e.target.value, i, 'name')}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.passNameErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Applicant mobile number<span className="text-red">*</span>
                                                            </label>
                                                            <input type="number" className="form-control" value={data.applicantMobNo}
                                                                style={{ paddingLeft: "50px" }}
                                                                maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                onPaste={(e) => this.handlePaste(e)}
                                                                onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                disabled
                                                            />
                                                            <input
                                                                type="text"
                                                                className="form-control-1 mt-2"
                                                                // placeholder="ISD"
                                                                value={data.preApplicantMobNo}
                                                                disabled
                                                                // onChange={(e) => this.setState({ preApplicantMobNo: e.target.value })}
                                                                maxLength={'10'}
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: 30,
                                                                    padding: " 6px !important",
                                                                    paddingLeft: 5,
                                                                    borderLeft: 0,
                                                                    borderTop: 0,
                                                                    background: "none",
                                                                    borderBottom: 0,
                                                                    height: 34
                                                                }}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.applicantMobNoErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Applicant email id<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={data.applicantEmail} maxLength={'50'}
                                                                disabled
                                                            // onChange={(e) => this.setState({ applicantEmail: e.target.value, applicantEmailErr: "" })}
                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.applicantEmailErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport No
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={data.passNo} maxLength={'10'}
                                                                // onChange={(e) => this.setState({ passNo: e.target.value, passNoErr: "" })}
                                                                onChange={(e) => this.onInput(e, e.target.value, i, 'passno')}

                                                            />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.passNoErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport issue Date
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <DatePicker selected={data.passDate}
                                                                onChange={(date) => this.onInput(date, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), i, 'passDate')}
                                                                // onChange={(date) =>
                                                                //     this.setState({
                                                                //         passDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                //         passDateErr: ""
                                                                //     })}
                                                                dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                maxDate={data.passExipryDate}
                                                                dropdownMode="select"
                                                                placeholderText="Passport Issued Date"
                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                popperClassName="example-datepicker-class" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.passDateErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">Passport Expiry Date
                                                                <span className="text-red">*</span>
                                                            </label>
                                                            <DatePicker selected={data.passExipryDate}
                                                                onChange={(date) => this.onInput(date, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())), i, 'passDateEx')}
                                                                // onChange={(date) =>
                                                                //     this.setState({
                                                                //         passExipryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                //         passExipryDateErr: ""
                                                                //     })}
                                                                dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                minDate={data.passDate}
                                                                dropdownMode="select"
                                                                placeholderText="Passport Expiry Date"
                                                                dayClassName={() => "example-datepicker-day-class"}
                                                                popperClassName="example-datepicker-class" />
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.passExipryDateErr}</span>}
                                                        </div>
                                                        <div className="col-xl-3">
                                                            <label className="mt-3">
                                                                Passport issuing Country<span className="text-red">*</span>
                                                            </label>
                                                            <input type="text" className="form-control" value={data.passCountry}
                                                                onChange={(e) => this.onInput(e, e, i, 'passCountry')}

                                                            />
                                                            {data.isDisplayPassCountryList && this.renderPassCountryList(i)}
                                                            {<span className='text-danger text-start text-left d-block mt-1'>{data.passCountryErr}</span>}
                                                        </div>
                                                        {!(data.isPassportFilePresent) &&
                                                            <div className="col-xl-5">
                                                                <label className="mt-3">
                                                                    Passport Front Page (Maximum 5mb / File Type .jpg, .jpeg, .png, .pdf)
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <form action="/action_page.php" style={{ display: "inline-flex" }}>
                                                                    <input
                                                                        // type="file" id="passportFile" name="filename"
                                                                        //     accept=".jpg, .jpeg, .png, .pdf" maxLength={"50"}
                                                                        //     onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                        type="file"
                                                                        id={`path${i}`}
                                                                        className="image-upload"
                                                                        accept=".jpg, .jpeg, .png"
                                                                        onChange={e => this.onInput(e, e.target.files, i, 'passImg')}
                                                                    />
                                                                    {<span className='text-danger text-start text-left d-block mt-1'>{data.passportFileErr}</span>}
                                                                    {
                                                                        data.passportFile != "" &&
                                                                        <i class="fa fa-times text-danger img-can"
                                                                            onClick={() => this.onCrossClick(1, i)}
                                                                        ></i>
                                                                    }
                                                                </form>
                                                            </div>
                                                        }
                                                        {
                                                            data.passportFilePath != "" && data.passportFilePath != null && data.isPassportFilePresent &&
                                                            <div className="col-xl-5">
                                                                <label className="mt-3">
                                                                    Passport Front Page File
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <div style={{
                                                                    border: '1px solid #ccc',
                                                                    padding: '10px',
                                                                    // display: 'inline-flex',
                                                                    alignItems: 'center',
                                                                    marginTop: '10px',
                                                                    // marginLeft: '12px',
                                                                    maxWidth: '300px'
                                                                }}>
                                                                    <span style={{ marginRight: '10px' }}
                                                                    >{data.passportFile}</span>
                                                                    <i className="fa fa-times text-danger img-can"
                                                                        onClick={() => this.onCrossClick(1, i)}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>}
                                            </>
                                            ))
                                        } */}
                                    </div>}
                                </>}
                            {/* </div> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="form-group text-right mod-fot">
                                <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleAddGroupDetail(this.state.activeModalStep)}>{this.state.activeModalStep == 2 ? this.state.isModalEdit ? 'Update' : 'Add' : 'Next'}</button>
                                <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.cancelModal()}>cancel</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        size="lg"
                        show={this.state.isOpen2}
                        // dialogClassName="modal-90w"
                        // onHide={() => this.toggleModel()}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                <h5 class="modal-title modal_title_text text-danger">Coordinator visitng?</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <div class="row"> */}
                            <div className="col-md-12">
                                <p>Kindly make sure if the Coordinator will be visiting the Exhibition or the Coordinator will only fill the visitor registration form.</p>
                            </div>
                            {/* </div> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <div class="form-group text-right mod-fot">
                                <button type="button" class="btn btn-del-pop" onClick={() => this.toggleModel(1)}>will Visit the Exhibition</button>
                                <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.toggleModel()}>only Filling Form</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal isOpen={isOpen3} onRequestClose={() => this.setState({ isOpen3: false })}>
                        <div className="modal-dialog">
                            <div className="modal-content confirm-logout">
                                <div class="modal-header p-2">
                                    <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                    <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen3: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="pop_label">Do you really want to delete this Document?</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer p-1">
                                    <div class="form-group text-right mod-fot">
                                        <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                        <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isOpen3: false })}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {console.log(parseInt(sessionStorage.getItem('sessionExhibitionID')), '///')}

                    {console.log(daysList, this.state.daysModalList, '8888888888888888')}
                    {console.log(this.state.applicantMobNo, this.state.ApplicantMobileNoCoordinator, this.state.preApplicantMobNo, '8888888888888888', this.state.applicantMobNo)}
                    {
                        console.log(';;;', GroupDetailsList)

                    }
                </div >
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, getVistiorRegistarionDetails_action, visitorRegistrationNext_action, visitorRegistrationGroupNext_action, delVisaDoc_action,
    verifyVisitorEmail_action
})(VisitorRegistration));